import React, { Component } from "react";
import { Alert } from "reactstrap";
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Bankname from "../TL/bank.json";
import { ACCESS_POINT } from "../../../../config";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Rating from 'react-simple-star-rating'
import moment from 'moment'
import { MDBBtn, MDBIcon } from "mdbreact";
import Camera from '../../../../MiddleWare/camera'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


class AT_Booklet_Purchase_Contract_Proof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      answerJson1: [],
      rc_file: [],
      policy_file: [],
      json: [],
      json1: [],
      json2: [],
      UserID: JSON.parse(localStorage.getItem("userlog")),
      usertype: JSON.parse(localStorage.getItem('usertypelog')),
      CustomerId: this.props.match.params.cusid,
      alertVisible: false,
      button: "Save",
      checkbox: false,
      ViewHistory: false,
      visible: true,
      Relationshipopt:
        [
          {
            label: "Mother",
            value: 1,
          },
          {
            label: "Father",
            value: 2
          },
          {
            label: "Brother",
            value: 3
          },
          {
            label: "Sister",
            value: 4
          },
          {
            label: "Wife",
            value: 5
          },
          {
            label: "Uncle",
            value: 6
          },
          {
            label: "Aunt",
            value: 7
          },
          {
            label: "GrandFather",
            value: 8
          },
          {
            label: "GrandMother",
            value: 9
          },
          {
            label: "Mother-in-law",
            value: 10
          },
          {
            label: "Sister-in-law",
            value: 11
          },
          {
            label: "Friend",
            value: 12
          },
          {
            label: "Brother-in-law",
            value: 13
          },
          {
            label: "Husband",
            value: 14
          }
        ],
      structural_flood: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      modeofpayment: [{ label: "Payable", value: 1 }, { label: "payment to reg owner", value: 2 }],
      total: 0,
      ViewHistoryData: [],
      ViewHistoryData2: [],
      history: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      YesOrNo: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      hp: [{ label: "HP", value: 5 }, { label: "Preclosure Amount", value: 6 }],
      options: [{ label: "HP", value: 5 }, { label: "Preclosure Amount", value: 6 }, { label: "NOC", value: 1 }, { label: "Foreclosure", value: 2 }, { label: "Bank", value: 3 }, { label: "Not Available", value: 4 }],
      vech: [{ label: "Bad", value: 1 }, { label: "Not Bad", value: 2 }, { label: "Average", value: 3 }, { label: "Good", value: 4 }, { label: "Excellent", value: 5 }],
      banknames: Bankname,
      ownershipOpt: [
        {
          label: "1",
          value: 1
        },
        {
          label: "2",
          value: 2
        },
        {
          label: "3",
          value: 3
        },
        {
          label: "4 and above",
          value: 4
        }
      ],
      Loanopt: [{ label: "WE HAVE TO CLOSE", value: 1 }, { label: "LOAN CLOSED. WAITIING FOR NOC", value: 2 }, { label: "VALID NOC AVAILABLE", value: 3 }],
      Loanopt1: [{ label: "WE HAVE TO CLOSE", value: 1 }, { label: "LOAN CLOSED. WAITIING FOR NOC", value: 2 }, { label: "VALID NOC AVAILABLE", value: 3 }, { label: "NO HP", value: 4 }],
      Loan_opt: "",
      loan_Available_YC: true,
      loan_Available: true,
      All: [],
      total: 0,
      Transaction: false,
      files: [],
      fileArr: [],
      filesArray: [{
        Rcbook: [], InsurancePolicy: [], ForecloserLetter: [], KYC: [],
        NoObjectionCertificate: [], RelationshipCertificate: [], VahinCertificate: [], aadhar: [], pan: [], Bankbook: [], Bankdocuments: [],
      }],
      Docs: [{
        Rcbook: [], InsurancePolicy: [], ForecloserLetter: [], KYC: [],
        NoObjectionCertificate: [], RelationshipCertificate: [], VahinCertificate: [], aadhar: [], pan: [], Bankbook: [], Bankdocuments: [],
      }],
      arr: [],

      fileJson: [],
      ApplyOwnership: false, ApplyNoObjectionCertificate: false, Applyaadhar: false, Applypan: false, ApplyBankbook: false, ApplyBankdocuments: false,
      ApplyDuplicateKeys: false, ApplyTaxToken: false, ApplyInsurancePolicy: false, ApplyRelationshipCertificate: false, ApplyVahinCertificate: false,
      Rcbook: false,
      ApplyRcbook: false,
      RelationshipCertificate: false,
      VahinCertificate: false,
      Ownership: false,
      InsurancePolicy: false,
      TaxToken: false,
      checked: false,
      DuplicateKeys: false,
      NoObjectionCertificate: false,
      aadhar: false,
      pan: false,
      Bankbook: false,
      Bankdocuments: false,
      aadharFiles: [],
      panFiles: [],
      BankbookFiles: [],
      BankdocumentsFiles: [],
      IsBankdocumentsCam: false,
      RcbookFiles: [],
      RelationshipCertificateFiles: [],
      VahinCertificateFiles: [],
      OwnershipFiles: [],
      InsurancePolicyFiles: [],
      TaxTokenFiles: [],
      DuplicateKeysFiles: [],
      NoObjectionCertificateFiles: [],
      EditMode: false,
      filedisplay: false,
      Paymentmode: [{ label: "To the Old Car Registered Owner", value: 1 }, { label: "New Car Account", value: 2 }, { label: "Channel Partner", value: 3 }],
      newcarOpt: [{ label: "SAME AS USED CAR", value: 1 }, { label: "OTHER NAME", value: 2 }],
      Insurance: [
        { label: "COMPREHENSIVE", value: 1 },
        { label: "3RD PARTY", value: 2 },
        { label: "EXPIRED", value: 3 }
      ],
      activeInput: null,
      listening: false,
      selectedLanguage: 'en-US',
      micON: false,
      buying_price_suggest: [],
      Exchange_Bonus_suggest: [],
      showOptions: false,
      buyshowOptions: false,
      kmshow: false,
      kmdrivenSuggestOptiopns: [],
      languageOptions: [
        { label: 'English', value: 'en-US' },
        { label: 'Tamil', value: 'ta-IN' },
        { label: 'Hindi', value: 'hi-IN' },
      ],
    };
    this.recognition = null;
  }
  async componentDidMount() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript.trim())
          .join('');
        this.updateActiveInput(transcript);
      };
      this.recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };
      this.recognition.onend = () => {
        this.setState({ listening: false });
      };
    } else {
      alert("Your browser doesn't support the Web Speech API.");
    }
    const buyingLocalgot = JSON.parse(localStorage.getItem("buying_price")) || []
    const exchangeLocalgot = JSON.parse(localStorage.getItem("exchange_bonus")) || []
    const kmDrivenLocalgot = JSON.parse(localStorage.getItem("km_driven")) || []

    const kmdrivenLocal = [...new Set(kmDrivenLocalgot)]
    const buyingLocal = [...new Set(buyingLocalgot)]
    const exchangeLocal = [...new Set(exchangeLocalgot)]
    if (buyingLocal.length === 0) {
      buyingLocal.push("No suggestions");
    }
    if (exchangeLocal.length === 0) {
      exchangeLocal.push("No suggestions");
    }
    //if we need to get only if the data entered more than twice . code is right below 
    let buyingrepeatedValues = [...new Set(buyingLocal.filter((item, index, self) => self.indexOf(item) !== index))];
    let exchangerepeatedValues = [...new Set(exchangeLocal.filter((item, index, self) => self.indexOf(item) !== index))];
    this.setState({
      buying_price_suggest: [...buyingLocal]
    })
    this.setState({
      kmdrivenSuggestOptiopns: [...kmdrivenLocal]
    })
    this.setState({
      Exchange_Bonus_suggest: [...exchangeLocal]
    })
    this.Mainfunc()
  }
  async Mainfunc() {
    try {

      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        this.setState({
          makeopt: allOpt.makeopt,
          colopt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modelopt: allOpt.modelopt,
          makeYearOption: allOpt.yearopt
        })
      }


      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        "parentid = 122"
      );
      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });

      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));
      //Dear,Model,Variant options

      if (formQues) {
        formQues.data.map((qval, q) => {

          if (qval.id == 450) {
            this.setState({ checkopt: qval.options })
          }

          if (qval.id == 831) {
            qval.options = this.state.makeopt;
          }
          if (qval.id == 830) {
            qval.options = this.state.makeYearOption;
          }
          if (qval.id == 832) {
            qval.options = this.state.modelopt
          }
          if (qval.id == 833) {
            qval.options = this.state.varopt
          }
          if (qval.id == 834) {
            qval.options = this.state.colopt;
          }
          if (qval.id == 841) {
            qval.options = this.state.ownershipOpt;
          }
          if (qval.id == 840) {
            qval.type = ''
          }
          if (qval.id == 843) {
            qval.type = ''
          }
          if (qval.id == 844) {
            qval.type = ''

          }
          if (qval.id == 847) {
            qval.type = ''

          }


        });
        this.setState({ forms: formQues.data });
      }
      if (this.props.cusid) {
        this.setState({ CustomerId: this.props.cusid, global: false })
      }
      else if (this.props.match.params.cusid) {
        this.setState({ CustomerId: this.props.match.params.cusid, global: true });
      }

      await this.setState({ data: formQues.data });
      // console.log(this.state.data,"this.state.data");
      // console.log(this.state.CustomerId,"CustomerId");



      let fproof = await CmsContent.getFreedom(
        "*",
        "tbl_FProof",
        `customerid = ${this.state.CustomerId}`,
        "id",
        "id"
      );

      let Bproof = await CmsContent.getFreedom(
        '*',
        'tbl_BProof',
        `customerid = ${this.state.CustomerId}`,
        'id',
        'id'
      );
      // console.log(Bproof, 'Bproof');
      if (Bproof && Bproof.data && Bproof.data[0]) {
        let img = {
          Rcbook: [], ForecloserLetter: [], InsurancePolicy: [], KYC: [],
          NoObjectionCertificate: [], VahinCertificate: [], RelationshipCertificate: [], aadhar: [], pan: [], Bankbook: []
        };
        if (Bproof.data[0].policy_file && JSON.parse(Bproof.data[0].policy_file).length > 0) {
          let policy_file = JSON.parse(Bproof.data[0].policy_file)
          if (policy_file && policy_file.length > 0) {
            // console.log(policy_file.length , policy_file, 'policy_file')
            await this.setState({ policy_file })
            policy_file.map((ival, i) => {
              if (ival) {
                let spliting = ival.split(".");
                if (['pdf', 'doc', 'xlsx', 'csv', 'jpg', 'jpeg'].includes(spliting[1])) {
                  let a = null
                  a = "false/pdfIconTrans_1599478202382.png";
                  img.InsurancePolicy.push(
                    <div>
                      {/* <div style={{ paddingLeft: '160px', position: 'relative' }} >
                        <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                          onClick={() => this.removeimg(ival, i, 'policy_img1', 'oldpolicy_file', 'single')}></img>
                      </div> */}
                      <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a} style={{
                        width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                      }}
                        onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                    </div>
                  )


                }
              }
            })
            this.setState({ Rcbook: true, ApplyRcbook: true })
          }
        }
        if (Bproof.data[0].rc_file && JSON.parse(Bproof.data[0].rc_file).length > 0) {
          let rc_file = JSON.parse(Bproof.data[0].rc_file)
          if (rc_file && rc_file.length > 0) {
            await this.setState({ rc_file })

            rc_file.map((ival, i) => {
              if (ival) {
                let spliting = ival.split(".");
                if (['pdf', 'doc', 'xlsx', 'csv', 'jpg', 'jpeg'].includes(spliting[1])) {
                  let a = null
                  a = "false/pdfIconTrans_1599478202382.png";
                  let wait = img.Rcbook.push(
                    <div>
                      {/* <div style={{ paddingLeft: '160px', position: 'relative' }} >
                        <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                          onClick={() => this.removeimg(ival, i, 'policy_img1', 'oldpolicy_file', 'single')}></img>
                      </div> */}
                      <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a} style={{
                        width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                      }}
                        onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                    </div>
                  )
                }
              }
            })
            this.setState({ InsurancePolicy: true, ApplyInsurancePolicy: true })

          }
        }
        this.setState({ img })
      }

      if (fproof) {
        // console.log(fproof,"fproof");
        let d = fproof.data[0];
        let Transaction = false;
        if (d.transaction == "Exchange") {
          Transaction = true;
        }
        if (d.hp_lease == "YES") {
          this.setState({ loan_Available: false })
          // loan_Available = false
        } else if (d.hp_lease == "YET TO CONFIRM" || d.hp_lease == null) {
          this.setState({
            loan_Available: false, loan_Available_YC: false
          })
        } else if (d.hp_lease == "NO") {
          this.setState({ Loan_opt: "NO HP" })
        }

        this.setState({ Transaction });
      }
      let inventory = await CmsContent.getFreedom(
        "*",
        "tbl_Inventory_Sheet_Reproof",
        `customerid=${this.state.CustomerId}`,
        "id",
        "id desc"
      );

      //INVENTORY FILL
      if (inventory && inventory.data.length > 0) {

        this.setState({ inventory: inventory.data });
        this.form_filled1();
      }




      let booklet = await CmsContent.getFreedom(
        "*",
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        `customerId=${this.state.CustomerId}`,
        "id",
        "id"
      );


      if (booklet.data.length) {
        // console.log(booklet, "booklet");
        this.setState({ EditMode: true });
        let FilesData = JSON.parse(booklet.data[0].documentsfiles);
        let answerJson = this.state.answerJson
        let answerJson1 = this.state.answerJson1

        //doc
        try {
          let val = booklet.data[0];
          answerJson[547] = val.name;

          answerJson[548] = val.Reg_No;
          let registerPlugin = await CmsContent.getFreedom(
            "*",
            "tbl_dfcarz_prosperities",
            `reg_no='${val.Reg_No}' and name != '${val.name}'`,
            "id",
            "id"
          );

          let registerPlugin2 = await CmsContent.getFreedom(
            "*",
            "tbl_AT_Booklet_Purchase_Contract_Proof",
            `Reg_no='${val.Reg_No}' and name != '${val.name}'`,
            "id",
            "id"
          );

          if (registerPlugin.data.length) {
            this.setState({ ViewHistory: true, ViewHistoryData: registerPlugin.data })
          } else if (registerPlugin2.data.length) {
            this.setState({ ViewHistory: true, ViewHistoryData2: registerPlugin2.data })
          }
          // let book = bookform.data[0];
          let documentBook = JSON.parse(val.documentsfiles)
          let pendingDocument = []


          //let FilesData
          let F = JSON.parse(booklet.data[0].documentsfiles);
          let FilesData = Array.isArray(F) ? F : [F]
          if (FilesData) {
            let wait = await FilesData.map(async (ival, i) => {
              if (ival.Rcbook.length > 0) {
                this.setState({ Rcbook: true, ApplyRcbook: true });

              }
              if (ival.RelationshipCertificate && ival.RelationshipCertificate.length) {
                this.setState({ RelationshipCertificate: true, ApplyRelationshipCertificate: true });

              }
              if (ival.VahinCertificate && ival.VahinCertificate.length) {
                this.setState({ VahinCertificate: true, ApplyVahinCertificate: true });

              }
              if (ival.InsurancePolicy && ival.InsurancePolicy.length) {
                this.setState({ InsurancePolicy: true, ApplyInsurancePolicy: true })
              }
              if (ival.Ownership && ival.Ownership.length) {
                this.setState({ Ownership: true, ApplyOwnership: true })
              }
              if (ival.TaxToken && ival.TaxToken.length) {
                this.setState({ TaxToken: true, ApplyTaxToken: true })
              }
              if (ival.NoObjectionCertificate && ival.NoObjectionCertificate.length) {
                this.setState({ NoObjectionCertificate: true, ApplyNoObjectionCertificate: true })
              }
              if (ival.aadhar && ival.aadhar.length) {
                this.setState({ aadhar: true, Applyaadhar: true })
              }
              if (ival.pan && ival.pan.length) {
                this.setState({ pan: true, Applypan: true })
              }
              if (ival.Bankbook && ival.Bankbook.length) {
                this.setState({ Bankbook: true, ApplyBankbook: true })
              }
              if (ival.Bankdocuments && ival.Bankdocuments.length) {
                this.setState({ Bankdocuments: true, ApplyBankdocuments: true })
              }
            });
            await Promise.all(wait);
            //this.setState({ filesArray: FilesData })
            this.setState({ Docs: FilesData })

          }
        } catch (err) { console.log(err, "bookleterr"); }


      }
      let answerJson = this.state.answerJson;
      let answerJson1 = this.state.answerJson1;
      // F-PROOF DATA FILL
      if (fproof && fproof.data.length > 0) {
        let val = fproof.data[0];

        var dateStr = moment(new Date()).format("YYYY-MM-DD")
        answerJson[826] = dateStr;
        answerJson[827] = val.customer_name;
        answerJson[835] = val.reg_no;
        answerJson[828] = val.address;
        answerJson[829] = val.mobile;
        this.setState({ Evaluatorid: val.userid })
        this.state.makeopt.map((ival, n) => {
          if (ival.value == val.make) {
            answerJson[831] = ival;
          }
        });
        this.state.makeYearOption.map((jval, b) => {
          if (jval.label == val.year_of_manu) {
            answerJson[830] = jval;
          }
        });
        this.state.colopt.map((cval, n) => {
          if (cval.value == val.color) {
            answerJson[834] = cval;
          }
        });
        this.state.modelopt.map((jval, b) => {
          if (jval.value == val.model) {
            answerJson[832] = jval;
          }
        });
        this.state.varopt.map((cval, b) => {
          if (cval.value == val.variant) {
            answerJson[833] = cval;
          }
        });
        if (val.exchange_bonus) {
          this.setState({ exchangeBonus: val.exchange_bonus })
        }
        if (val.insurance) {
          this.autopopulate(839, val.insurance, 'selectbox')
        }
        if (val.ins_validity) {
          answerJson[840] = val.ins_validity;
        }
        if (val.duplicatekey) {
          this.state.YesOrNo.map((kval) => {
            if (kval.label.toUpperCase() == val.duplicatekey.toUpperCase()) {
              answerJson[845] = kval
            }
          })
        }
        answerJson[836] = val.chassis_no;
        answerJson[837] = val.engine_no;
        answerJson[841] = this.state.ownershipOpt.filter(e => e.value == val.ownership_no)[0];
        answerJson[838] = val.km_travel;

        answerJson[436] = val.std_sell_price;
        answerJson[437] = val.exchange_bonus;
        answerJson1[561] = val.trade_in_price; //561
        answerJson1[569] = val.std_sell_price; //569
        answerJson1[555] = val.ownership_no; //555
        answerJson[438] = val.total_exchange_price;
        answerJson[604] = val.mobile;
        answerJson1[577] = val.address;
        answerJson1[562] = val.ins_validity;
        answerJson1[554] = val.km_travel;
        answerJson1[569] = val.std_sell_price;
        answerJson1[567] = val.recondition_est;

        //---OLD --

        // this.state.salesopt.map((sval, i) => {
        //   if (sval.label == val.saleCons_name) {
        //     answerJson1[581] = sval;
        //   }
        // });

        // answerJson1[571] = val.enclosure; //571

        // if (val.transaction == "Exchange") {
        //   // let A = 0;
        //   let A = [];
        //   let B = [];
        //   if (answerJson[436]) {
        //     A = parseInt(answerJson[436]);
        //   }
        //   // let B = 0;
        //   if (answerJson[437]) {
        //     B = parseInt(answerJson[437]);
        //   }
        //   let total = A - B;
        //   // console.log(total);
        //   answerJson[438] = total;
        //   answerJson[442] = total;
        // }
        // if (val.transaction == "Non-Exchange") {
        //   let A = [];
        //   let B = [];
        //   if (answerJson[436]) {
        //     A = parseInt(answerJson[436]);
        //   }
        //   if (answerJson[437]) {
        //     B = 0;
        //   }
        //   let total = A;
        //   this.state.forms.map((ival, n) => {
        //     if (ival.id == 437) {
        //       ival.type = null;
        //     }
        //   });
        //   // console.log(total);
        //   answerJson[438] = total;
        //   answerJson[442] = total;
        // }
        // answerJson[443] = val.finance_name;
        this.setState({ answerJson1, answerJson })
      }

      //DOCUMENTATION FILL
      if (booklet && booklet.data.length > 0) {
        this.setState({ booklet: booklet.data, button: "Update" });
        this.form_filled();
      }
      if (this.state.button != 'Update') {
        if (localStorage.getItem("formFillData")) {
          let formFillData = JSON.parse(localStorage.getItem("formFillData"))
          const Data = formFillData.filter(e => e.prosid == this.state.CustomerId && e.ref == 'DOCUMENTATION')
          if (Data && Data.length) {
            answerJson = Data[0].data
            this.setState({ answerJson })
          }
        }
      }
      this.setState({
        answerJson, transcationtype: fproof.transaction
      });
      if (fproof.data[0].transaction == "Non-Exchange") {
        this.process();
      } else {
        this.process();


      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        this.setState({ error: 'Geolocation is not supported' });
      }

    } catch (error) {
      console.error(error);
    }
  }
  handleSuccess = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      error: null
    });
  };
  handleError = (error) => {
    this.setState({ error: error.message });
  };

  form_filled1 = async () => {
    try {
      let answerJson = this.state.answerJson;
      let inv = this.state.inventory[0];
      const { data } = this.state
      // console.log("inventory", inv);

      let Inventeditid = inv.id
      if (inv.hp) {
        this.state.hp.map((ival, i) => {
          if (ival.label == inv.hp) {
            answerJson[564] = ival;
          }
        })
      }
      if (inv.mode_of_payment) {
        this.state.modeofpayment.map((ival, i) => {
          if (ival.label == inv.mode_of_payment) {
            answerJson[627] = ival;
          }
        })
      }
      if (inv.service_histaory) {
        this.state.history.map((ival, i) => {
          if (ival.label == inv.service_histaory) {
            this.answers(ival, null, null, null, 'selectbox', 842)
          }
        })
      }
      if (inv.vech_condition) {
        this.state.vech.map((ival, i) => {
          if (ival.label == inv.vech_condition) {
            answerJson[848] = ival
          }
        })
      }
      if (inv.acc_hold_noc) {
        this.state.options.map((ival, i) => {
          if (ival.label == inv.acc_hold_noc) {
            answerJson[565] = ival
          }
        })
      }

      if (inv.structural_flood) {
        this.state.YesOrNo.map((ival) => {
          if (ival.label == inv.structural_flood) {
            this.answers(ival, null, null, null, 'selectbox', 846)
          }
        })
      }
      answerJson[843] = inv.last_service_kms;
      answerJson[844] = inv.last_service_date;

      // answerJson[556] = inv.vin_no;    not avai
      if (inv.buying_price) {
        this.setState({ buyingPrice: inv.buying_price })
      }
      if (inv.insurance) {
        this.autopopulate(839, inv.insurance, 'selectbox')
      }
      answerJson[840] = inv.inc_exp_date ? moment(inv.inc_exp_date).format('YYYY-MM-DD') : null;
      if (inv.Holding_amount) {
        this.setState({ holding_amount: inv.Holding_amount })
      }
      answerJson[847] = inv.Remark;

      // not available old
      // answerJson[553] = inv.model_year; not avail      
      // answerJson[585] = inv.incharge_remarks;
      // this.state.manopt.map((ival, i) => {
      //   if (ival.label == inv.manager) {
      //     answerJson[586] = ival;
      //   }
      // });
      // answerJson[278] = inv.exp_selling_price;

      // this.state.Relationshipopt.map((ival, n) => {
      //   if (ival.label == inv.relation) {      
      //     answerJson[575] = ival
      //   }

      // })
      // answerJson[576] = inv.contact_no;
      // answerJson[577] = inv.address;
      // answerJson[578] = inv.exchange_again;
      // if (inv.delivery_date) {
      //   let date1 = inv.delivery_date.split(" ")[0];
      //   answerJson[580] = date1;
      // }
      // answerJson[567] = inv.refurish_cost;
      // answerJson[573] = inv.accessories;
      // answerJson[569] = inv.exp_selling_price;
      // if (inv.adj_new_car) {
      //   answerJson[568] = inv.adj_new_car;
      // }
      // else {
      //   let total = 0
      //   let A = answerJson[561] ? parseInt(answerJson[561]) : 0;
      //   console.log(A);
      //   let B = answerJson[563] ? parseInt(answerJson[563]) : 0;
      //   console.log(B);
      //   let C = answerJson[628] ? parseInt(answerJson[628]) : 0;
      //   console.log(C);
      //   let D = answerJson[566] ? parseInt(answerJson[566]) : 0;
      //   console.log(D);
      //   total = A + B - C - D;
      //   answerJson[568] = total;
      // }
      // // answerJson[568] = inv.adj_new_car;
      // answerJson[587] = inv.manager_remarks;
      // if (inv.sales_consultant) {
      //   this.state.salesopt.map((sval, n) => {
      //     if (sval.label == inv.sales_consultant) {
      //       answerJson[581] = sval;
      //     }
      //   });
      // }
      // answerJson[582] = inv.buyerName;
      // answerJson[583] = inv.buyer_remarks;
      // this.state.inopt.map((ival, i) => {
      //   if (ival.label == inv.incharge_name) {
      //     answerJson[584] = ival;
      //   }
      // });
      // answerJson[585] = inv.incharge_remarks;
      // answerJson[563] = inv.New_Car;


      this.setState({
        answerJson: answerJson,
        button: "Update",
        Inventeditid
      });
      this.process();


    } catch (err) { console.log(err, "inv error"); }

  }
  autopopulate = async (id, val, type) => {
    const { data } = this.state;
    if (type == 'selectbox') {
      let ob = data.filter(e => e.id == id)
      if (ob && ob.length) {
        let option = ob[0].options ? ob[0].options.split(',') : []
        option.map((ival, i) => {
          if (val == ival) {
            this.answers({ label: ival, value: i }, null, null, null, 'selectbox', id)
          }
        })
      }
    }
  }
  fileCheck = async (e, label, val) => {
    val = (val === 1) ? true : false
    await this.setState({ [label]: val });
    if (this.state[label] === false) {
      let filesArray = this.state.filesArray
      filesArray[0][e] = [];
      await this.setState({ filesArray });
    }
    // if (answer[questionId].indexOf(v) === -1) {
    //   answer[questionId].push(v);
    // } else {
    //   answer[questionId] = answer[questionId].filter(ival => ival !== v);
    // }
    this.process()
  };
  form_filled = async () => {
    let answerJson = this.state.answerJson;
    let fileArr = this.state.fileArr;
    let fileJson = this.state.fileJson;
    const { respect, makeopt, colopt, YesOrNo, Loanopt, Loanopt1 } = this.state;
    let prof = this.state.booklet && this.state.booklet[0];
    this.setState({ checked: true })
    answerJson.id = prof.id;
    if (prof.date) {
      answerJson[826] = prof.date;
    }
    answerJson[827] = prof.name;
    answerJson[828] = prof.To_address;
    answerJson[835] = prof.Reg_No;
    this.state.makeYearOption.map((kval, v) => {
      if (kval.label == prof.yom) {
        answerJson[830] = kval;
      }
    });
    if (makeopt) {
      makeopt.map((jval, j) => {
        if (jval.value == prof.make) {
          answerJson[831] = jval;
        }
      });
    }
    if (colopt) {
      this.state.colopt.map((cval, j) => {
        if (cval.value == prof.color) {
          answerJson[834] = cval;
        }
      });
    }
    if (this.state.modelopt) {
      this.state.modelopt.map((jval, k) => {
        if (jval.value == prof.model) {
          answerJson[832] = jval;
        }
      });
    }
    if (this.state.varopt) {
      this.state.varopt.map((aval, m) => {
        if (aval.value == prof.variant) {
          answerJson[833] = aval;
        }
      });
    }

    answerJson[837] = prof.Engine_No;
    answerJson[836] = prof.chassis_no;

    if (YesOrNo) {
      YesOrNo.map((ival) => {
        if (ival.label == prof.spare_key) {
          answerJson[845] = ival
        }
      })
    }
    if (this.state.newcarOpt) {
      this.state.newcarOpt.map((ival) => {
        if (ival.label == prof.new_car_name) {
          this.ans(ival, null, null, null, 'static-selectbox', 'newcarName')
        }
      })
    }
    if (prof.Exchange_Bonus) {
      this.setState({ exchangeBonus: prof.Exchange_Bonus })
    }

    this.state.banknames.map((bval, m) => {
      if (bval.label == prof.bankname) {
        this.setState({ bankName: bval })  //waiting
      }
    });
    if (prof.out_standing_amount) {
      this.setState({ os_amount: prof.out_standing_amount })
    }
    if (prof.new_car_support) {
      this.setState({ newcarSupport: prof.new_car_support })
    }
    if (Loanopt) {
      this.state.Loanopt.map((bval, m) => {
        if (bval.value == prof.loan_available) {
          this.ans(bval, null, null, null, 'static-selectbox', 'LoanAvailable')
        }
      })
    }
    if (Loanopt1) {
      this.state.Loanopt1.map((bval, m) => {
        if (bval.value == prof.loan_available) {
          console.log(bval.value, "bval.value", prof.loan_available, "prof.loan_available");
          this.ans(bval, null, null, null, 'static-selectbox', 'LoanAvailable')
        }
      })
    }
    if (prof.loan_exp_date) {
      this.ans(prof.loan_exp_date, null, null, null, "date", "loan_exp_date")
    }

    if (prof.loan_acc_no) {
      this.setState({ loanAcnumber: prof.loan_acc_no })
    };
    if (this.state.checkopt) {
      let opt = this.state.checkopt.split(",");
      this.setState({ opt })
    }

    if (prof.payment_mode) {
      this.state.Paymentmode.map((ival, i) => {
        if (prof.payment_mode == ival.label || prof.payment_mode == ival.value) {
          this.ans(ival, null, null, null, 'static-selectbox', 'paymentMode')
        }
      })
    }
    if (prof.channel_partner) {
      this.setState({ chpartner: prof.channel_partner })
    }
    if (prof.Files) {
      fileJson = JSON.parse(prof.Files);
      fileArr = JSON.parse(prof.Files);
    }

    // answerJson[429] = prof.Model_Variant;
    // answerJson[436] = prof.Non_Exchange_Price; not avail
    // answerJson[438] = prof.total_exchange_price; not avail
    // answerJson[439] = prof.old_reg_no; not avail
    // answerJson[445] = prof.loan_rs; not avail
    // answerJson[449] = prof.other_partner; not avail
    // answerJson[433] = prof.type; not avail
    // if (prof.documents) {
    //   let doc = prof.documents.split(",")
    //   this.setState({ doc })
    // }


    let images = this.state.images;
    images = JSON.parse(prof.documentsfiles);

    let img = {
      Rcbook: [], ForecloserLetter: [], InsurancePolicy: [], KYC: [],
      NoObjectionCertificate: [], VahinCertificate: [], RelationshipCertificate: [], aadhar: [], pan: [], Bankbook: [], Bankdocuments: []
    };
    if (images != null) {
      Object.keys(images).map((key) => {
        if (key != null) {
          images[key].map((ival, i) => {
            if (ival) {
              let spliting = ival.split(".");
              if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                let a = null
                a = "false/pdfIconTrans_1599478202382.png";
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              } else {
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              }
            }
          })
        }
      })
      this.setState({ img })
    }
    this.setState({
      answerJson,
      fileJson,
      images
    });
    this.process();
  };
  imgFun = () => {
    let images = this.state.images;
    let Docs = []
    let img = {
      Rcbook: [], Ownership: [], InsurancePolicy: [], TaxToken: [], DuplicateKeys: [],
      NoObjectionCertificate: [], VahinCertificate: [], RelationshipCertificate: [], aadhar: [], pan: [], Bankbook: [], Bankdocuments: []
    };
    if (images != null) {
      Object.keys(images).map((key) => {
        if (key != null) {
          images[key].map((ival, i) => {
            if (ival) {
              let spliting = ival.split(".");
              if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
                let a = null
                a = "false/pdfIconTrans_1599478202382.png";
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', float: "right" }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              } else {
                let wait = img[key].push(
                  <div>
                    <div style={{ paddingLeft: '160px', position: 'relative' }} >
                      <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', float: "right" }}
                        onClick={() => this.removeimg(key, i)}></img>
                    </div>
                    <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                      onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }}
                      style={{
                        width: "200px", height: "200px", display: "inline-block", padding: "10px"

                      }} />
                  </div>
                )
              }
            }
          })
        }
      })
      Docs[0] = images
      this.setState({ img, Docs })
      console.log(Docs, 'docs');
    }
  }
  removeimg = (key, i) => {
    let { Docs, img, images } = this.state;
    if (images || img) {
      console.log(images[key], key);
      images[key] && images[key].splice(i, 1)
      img[key] && img[key].splice(i, 1)
      this.setState({ images, img })
      console.log(images, img, 'docim');
      this.imgFun()
    }
  }
  async ans(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId
  ) {
    const { LoanAvailable, os_amount, buyingPrice, holding_amount, Bankdocuments } = this.state;
    if (questionId == "LoanAvailable") {
      if (label.value == 1) {
        let { filesArray } = this.state
        filesArray[0].NoObjectionCertificate = []
        this.setState({ bankdetails: true, holdingAmount: true, exdate: false, Bankdocuments: true, loan_exp_date: null, noc: false, filesArray })
      } else if (label.value == 2) {
        let { filesArray } = this.state
        filesArray[0].ForecloserLetter = []
        filesArray[0].NoObjectionCertificate = []
        this.setState({ bankdetails: false, holdingAmount: true, bankName: null, loanAcnumber: null, os_amount: null, exdate: false, noc: false, loan_exp_date: null, Bankdocuments: true, filesArray })
      } else if (label.value == 3) {
        let { filesArray } = this.state
        filesArray[0].ForecloserLetter = []
        filesArray[0].NoObjectionCertificate = []
        // [0].Bankdocuments = []
        this.state.holding_amount = []
        this.setState({ bankdetails: false, bankName: null, loanAcnumber: null, os_amount: null, holdingAmount: false, exdate: true, Bankdocuments: true, filesArray })
      } else if (label.value == 4) {
        let { filesArray } = this.state
        filesArray[0].ForecloserLetter = []
        filesArray[0].NoObjectionCertificate = []
        filesArray[0].Bankdocuments = []
        this.state.holding_amount = []
        this.setState({ bankdetails: false, holdingAmount: false, exdate: false, noc: false, bankName: null, os_amount: null, loanAcnumber: null, holding_amount: null, loan_exp_date: null, Bankdocuments: false, filesArray })
      }
    }
    if (questionId == 'loan_exp_date') {
      const current = moment(new Date());
      const Expiry = moment(label);
      let dif = current.diff(Expiry, 'days')
      if (0 <= dif) {
        this.setState({ holdingAmount: true, noc: true })
      }
      else if (dif >= -90 && dif < 0) {

        this.setState({ holdingAmount: false, noc: true })

      }
    }
    if (questionId == "newcarName") {
      if (label.value == 1) {
        this.setState({ iskyc: true, isRproof: false })
      } else if (label.value == 2) {
        this.setState({ iskyc: true, isRproof: true })
      }
    }
    if (questionId == "paymentMode") {
      if (label.value == 1 || label.value == 2) {
        this.setState({ loanstatus: true, chPartner: false })
        let Finalprice = 0
        if (LoanAvailable && LoanAvailable.value == 1) {
          let A1 = buyingPrice ? parseInt(buyingPrice) : 0
          let A2 = os_amount ? parseInt(os_amount) : 0
          Finalprice = A1 - A2 - 20000
        } else if (LoanAvailable && LoanAvailable.value == 2) {
          let A1 = buyingPrice ? parseInt(buyingPrice) : 0
          let A3 = holding_amount ? parseInt(holding_amount) : 0
          Finalprice = A1 - A3
        }
        this.setState({ Finalprice })
      } else if (label.value == 3) {
        this.setState({ chPartner: true, loanstatus: false })
      }
    }

    if (type == "static-text" || type == "static-selectbox" || type == "date") {

      this.setState({ [questionId]: label })
    }
  }
  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    const { data, modelopt, varopt } = this.state;
    let grade = this.state.vech;
    let answerJson = await this.state.answerJson;
    let get = [];
    if (questionId && questionId == 830) { //year
      let dateofregyear = ""

      dateofregyear = label.label
      await this.setState({ dateofregyear })
      if (answerJson[831]) answerJson[831] = null; //make
      if (answerJson[832]) answerJson[832] = null; //model
      if (answerJson[833]) answerJson[833] = null; //variant


      if (answerJson[831]) { //make
        modelopt.map((mval, i) => {
          if (mval.value == parseInt(answerJson[831].value)) {
            get.push(mval);
          }
        });
        if (get.length && questionId == 830) {
          const manfuc = this.state.makeYearOption.filter(e => e.value == parseInt(label.value))[0]
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }

        data.map((ival, k) => {
          if (ival.id == 832) {
            ival.options = get;
          }
        });

      }
    }
    if (questionId == 831) { //make
      if (answerJson[434]) answerJson[832] = null; //model
      if (answerJson[435]) answerJson[833] = null; //varient
      modelopt.map((mval, i) => {
        if (mval.makeid == label.value) {
          get.push(mval);
        }
      });
      if (get.length) {
        if (answerJson[830]) { //year
          const manfuc = this.state.makeYearOption.filter(e => e.value == parseInt(answerJson[830].value))[0];
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }
      }
      data.map((ival, k) => {
        if (ival.id == 832) {
          ival.options = get;
        }
      });

    }

    let variant = [];

    if (questionId == 832) { //model
      varopt.map((val, i) => {
        if (val.modelid == label.value) {
          variant.push(val);
        }
      });
      data.map((jval, j) => {
        if (jval.id == 833) { //varient
          jval.options = variant;
        }
      });
    }



    // insurance
    if (questionId == 839) {
      if (label.label == "COMPREHENSIVE" || label.label == "3RD PARTY") {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 840) {
            ival.type = "date"
          }
        })
      } else {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 840) {
            ival.type = ""
          }
        })
      }
    }

    // service history
    if (questionId == 842) {
      if (label.label == "YES") {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 843) {
            ival.type = "number"
          } else if (parseInt(ival.id) == 844) {
            ival.type = "date"
          }
        })
      } else {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 843 || parseInt(ival.id) == 844) {
            ival.type = ""
          }
        })
      }
    }

    // STUCTURAL / FLOOD
    if (questionId == 846) {
      if (label.label == "YES") {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 847) {
            ival.type = "text"
          }
        })
      } else {
        data.map((ival, i) => {
          if (parseInt(ival.id) == 847) {
            ival.type = ""
          }
        })
      }
    }

    // rating
    if (questionId == 848) {
      let value;
      if (label === 1) {
        value = grade[label - 1];
      }
      else if (label === 2) {
        value = grade[label - 1];
      }
      else if (label === 3) {
        value = grade[label - 1];
      }
      else if (label === 4) {
        value = grade[label - 1];
      }
      else if (label === 5) {
        value = grade[label - 1];
      }
      answerJson[questionId] = value;

    }

    //mobile num validation
    if (questionId == 829) {
      label = label.slice(0, 10)
      answerJson[questionId] = label;
    }

    if (type == "text") {
      answerJson[questionId] = label;
    } if (type == "number") {
      answerJson[questionId] = label;
    } else if (type == "date") {
      var d = new Date(label);
      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label;
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {

      if (this.state.checked == false) {
        let check = [];
        options.map((ival, i) => {
          var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

          if (checkBox.checked == true) {
            check.push(ival);
          }

        });



        answerJson[questionId] = check.toString();

      }
      if (this.state.checked == true) {
        let check = [];
        options.map((ival, i) => {
          var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

          if (checkBox.checked == true) {
            check.push(ival);
            // reg.push({ label: ival, value: i })
          }
          else {
            checkBox.checked = false;
          }

        });
        console.log(check)
        this.setState({ doc: check })
        answerJson[questionId] = check.toString();


      }
    }

    if (questionId == 436) {
      let A = []
      if (answerJson[436]) {
        A = parseInt(answerJson[436]);
      }
      let B = []
      if (answerJson[437]) {
        B = parseInt(answerJson[437]);
      }
      let total = A + B;
      answerJson[438] = total;
    }

    //Local save
    if (this.state.button != 'Update') { await this.formSave('DOCUMENTATION', this.state.CustomerId, answerJson) }
    await this.setState({ answerJson, data });
    if (type == "textarea" || type == "text") {
      this.TypingControl(this.process);//avoid many time call
    }
    else { this.process() }

  };
  remove = (val, index) => {
    const { file } = this.state;
    let get = [];
    let a = [];
    file.map((val, j) => {
      if (j == index) {
        val = "";
        a = a[j];
        this.setState({ close: "none" });
        file.splice(index, 1);
      }
      //get.push(val)
    });
    this.setState({ file });
    //this.setState({ file: get })
    this.process();
    //this.setState({ checked: false })
  };
  GotoViewhistory = async () => {

    const { ViewHistoryData2, ViewHistoryData } = this.state;
    if (ViewHistoryData2.length) {

      // window.location.href=;
      window.open(`/DFCars/TransactionHistory/${ViewHistoryData2[0].customerid}`, '_self');
    }

    if (ViewHistoryData.length) {
      window.open(`/DFCars/TransactionHistory/${ViewHistoryData[0].id}`, '_self');
    }
  }
  process = async (val) => {
    const { micON } = this.state
    let data = await this.state.data;
    const pdf = ACCESS_POINT + "/superAdmin/file?fileurl=false/pdfIconTrans_1599478202382.png";
    if (data) {
      let data2 = []
      data.map(async (ival, i) => {
        data2.push(ival)
      })
      let arr = data2;
      let contentJson = [];
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;
        let json = await arr.map(async (item, index) => {
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5 voice-input">
                    <input
                      type="text"
                      id={`input-${item.id}`}
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    // value={answerJson[item.id]||""}
                    />
                    <div className="mic-icon">
                      <p id={`para-${item.id}`} className="bi bi-mic-fill" onClick={() => this.voiceSetup("start", item.id)}></p>
                      {/* {micON ? (
                        <p
                          className="bi bi-x-circle"
                          onClick={() => this.handleStop()}
                        ></p>
                      ) : (
                        <p
                          className="bi bi-mic-fill"
                          onClick={() =>
                            this.handleStart(item.id)
                          }
                        ></p>
                      )} */}
                    </div>
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "number") {
            { console.log(item.question) }
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="number"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "number",
                          item.id,
                          item.parentid
                        )
                      }
                      disabled={item.id == 829 ? true : false}
                      value={answerJson[item.id]}
                    />
                    <div className="mic-icon">
                      <p id={`para-${item.id}`} className="bi bi-mic-fill" onClick={() => this.voiceSetup("start", item.id)}></p></div>
                    {["KM"].includes(item.question.split(' ')[0]) && (
                      <div>
                        <div className="history-icon" >
                          <i
                            className={`bi bi-clock-history histroyicon `}
                            onClick={(e) => this.turnOff("km")}
                          ></i>

                        </div>
                        <div className={this.state.kmshow ? "subclass-parent" : "d-none"}>
                          <div className="subclass mx-2">
                            {this.state.kmdrivenSuggestOptiopns.map((val, i) => (
                              <p
                                className="paragg"
                                onClick={() => this.handleSuggestionSelect1(val, index,
                                  null,
                                  null,
                                  "number",
                                  item.id,
                                  item.parentid, "km")}
                                key={i}
                              >
                                {val}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            this.state.date = answerJson[item.id];
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      min={item.id == 840 ? moment().format("YYYY-MM-DD") : ""}
                      className="form-control"
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                      disabled={item.id == 826 ? true : false}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                    // value={answerJson[item.id]}
                    ></textarea>
                    <div className="mic-icon">
                      <p id={`para-${item.id}`} className="bi bi-mic-fill" onClick={() => this.voiceSetup("start", item.id)}></p></div>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                    //value={element == answerJson[item.id]}
                    //  checked={element == answerJson[item.id]}
                    //value={element[index]}
                    />

                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {
            let option = [];
            if (item.options) {
              let opt = typeof item.options == 'string' ? item.options.split(',') : null
              if (opt) {
                opt.map((label, i) => {
                  option.push({ label: label, value: i });
                })
              } else {
                option = item.options
              }
            }

            this.state.selectbox = answerJson[item.id];


            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}

                      selectedService={this.state.selectbox}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            let value = [];

            await item.options.split(",").map((element, key) => {
              if (element == answerJson[item.id]) {
                this.setState({ Top: "90px" });
              }

              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="check"
                      id={`inlineCheckbox${index}${key}`}
                      onChange={e => {
                        this.answers(
                          element,
                          index,
                          item.options.split(","),
                          key,
                          "checkbox",
                          item.id,
                          item.parentid
                        );
                      }}

                      value={element}

                    //checked={answerJson[item.id]}
                    />

                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${index}${key}`}
                    >
                      {`${element}`}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == 'rating') {
            let option = [];
            if (item.options) {
              item.options.map((ival, i) => {
                option.push(ival);
              });
            }
            let value = answerJson[item.id] ? answerJson[item.id].value : 0;
            let label = answerJson[item.id] ? answerJson[item.id].label : "";
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">

                    <Rating
                      onClick={e => {
                        this.answers(
                          e,
                          index,
                          null,
                          null,
                          "",
                          item.id,
                          item.parentid
                        );
                      }}
                      ratingValue={value}
                      size={20}
                      stars={5}
                      fillColor='orange'
                      emptyColor='gray'
                      className='foo'
                    />
                    {/* Use rating value */}
                    {label}

                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );

          }
        });
        await Promise.all(json);
        await this.setState({ json: contentJson, answerJson });
      }
    }
  };
  updateActiveInput = (transcript) => {
    const { activeInput, voiceIndex, answerJson } = this.state;
    console.log(voiceIndex, "enters transcript")
    var input = document.getElementById(`input-${voiceIndex}`)
    console.log(input, "inputtttttt")
    if (input) {
      input.defaultValue = transcript
      input.value = transcript
    }
    if (voiceIndex >= 0 && voiceIndex < answerJson.length) {
      answerJson[voiceIndex] = transcript;
    }
    console.log(answerJson);
  };
  voiceSetup = (value, index) => {
    var para = document.getElementById(`para-${index}`)
    if (para.classList[1] === "bi-mic-fill") {
      this.handleStart(index)
      console.log(para.classList[1], "para")
      para.classList.remove("bi-mic-fill")
      para.classList.add("bi-x-circle")
      console.log(para.classList[1], "para")
    }
    else {
      this.handleStop()
      var para = document.getElementById(`para-${index}`)
      para.classList.remove("bi-x-circle")
      para.classList.add("bi-mic-fill")
      console.log(para, "para")
    }
  }
  handleStart = (index) => {
    console.log(index, "enters in to start")
    const { selectedLanguage } = this.state;
    if (this.recognition) {
      this.recognition.lang = selectedLanguage;
      this.recognition.start();
    }
    this.setState({ micON: true, listening: true, voiceIndex: index }, () => {
      this.process(this.state.micON)
      this.updateActiveInput("hearing your voice", this.state.voiceIndex)
    });
  };
  handleStop = (val) => {
    if (this.recognition) {
      this.recognition.stop();
    }
    this.setState({ listening: false, activeInput: null, micON: false, voiceIndex: val });
    this.setState({ micON: false, activeInput: null, listening: false }, () => {
      this.process(this.state.micON)
      // this.updateActiveInput("sathesh", this.state.voiceIndex)
    });
  };
  update = async () => {
    const { answerJson, answerJson1, files, filesArray, fileJson
      , RcbookFiles, InsurancePolicyFiles,
      TaxTokenFiles, DuplicateKeysFiles, NoObjectionCertificateFiles,
      OwnershipFiles, RelationshipCertificateFiles, VahinCertificateFiles
    } = this.state;

    // let imagealeart = ''
    // if (filesArray && (filesArray[0].Bankbook.length || filesArray[0].Bankdocuments.length || filesArray[0].ForecloserLetter.length || filesArray[0].InsurancePolicy.length ||
    //   filesArray[0].KYC.length || filesArray[0].NoObjectionCertificate.length || filesArray[0].Rcbook.length || filesArray[0].RelationshipCertificate.length ||
    //   filesArray[0].VahinCertificate.length || filesArray[0].aadhar.length || filesArray[0].pan.length)) {

    //   let obj = Object.values(filesArray[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         this.setState({ btnDisable: false })
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }

    // console.log(filesArray);
    const formData = new FormData();


    let id = answerJson.id;

    let gotoapproval = true;

    if (filesArray) {
      filesArray[0].Rcbook.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('Rcbook', ival);
      });
    } else gotoapproval = false;

    if (filesArray) {
      filesArray[0].RelationshipCertificate.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('RelationshipCertificate', ival);
      });
    }

    if (filesArray) {
      filesArray[0].VahinCertificate.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('VahinCertificate', ival);
      });
    } else gotoapproval = false;


    if (filesArray) {
      filesArray[0].InsurancePolicy.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('InsurancePolicy', ival);
      });
    } else gotoapproval = false;

    // // if (TaxTokenFiles && TaxTokenFiles.length) {
    // //   TaxTokenFiles.map((ival, i) => {
    // //     let a = Object.keys(ival)[0];
    // //     formData.append(a, ival[a]);
    // //   });
    // // } else gotoapproval = false;

    if (filesArray) {
      filesArray[0].DuplicateKeys.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('DuplicateKeys', ival);
      });
    } else gotoapproval = false;

    if (filesArray) {
      filesArray[0].NoObjectionCertificate.map((ival, i) => {
        let a = Object.keys(ival)[0];
        formData.append('NoObjectionCertificate', ival);
      });
    }

    if (filesArray) {
      filesArray[0].Ownership.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('Ownership', ival);
      });
    } else gotoapproval = false;

    if (filesArray) {
      filesArray[0].aadhar.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('aadhar', ival);
      });
    } else gotoapproval = false;
    if (filesArray) {
      filesArray[0].pan.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('pan', ival);
      });
    } else gotoapproval = false;
    if (filesArray) {
      filesArray[0].Bankbook.map((ival, i) => {
        //let a = Object.keys(ival)[0];
        formData.append('Bankbook', ival);
      });
    } else gotoapproval = false;
    if (filesArray) {
      filesArray[0].Bankdocuments.map((ival, i) => {
        formData.append('Bankdocuments', ival);
      });
    } else gotoapproval = false;
    if (gotoapproval) {
      formData.append("gotoapproval", "1");
    } else formData.append("gotoapproval", "0")
    /////////////////////////
    // console.log(filesArray);
    //  let CategoryArray = {};
    // let id = answerJson.id;
    // CategoryArray("id", answerJson.id);
    formData.append("id", answerJson.id)
    formData.append("name", answerJson[452]);
    formData.append("contact", answerJson[604]);
    if (answerJson[426]) {
      formData.append("respect", answerJson[426].label);
    }

    formData.append("Reg_No", answerJson[425]);
    formData.append("To_address", answerJson[424]);
    if (answerJson[423]) {
      var d = new Date(answerJson[423]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      //answerJson[]
      formData.append("date", dateStr);
    }

    //CategoryArray.date = answerJson[423];
    if (answerJson[427]) {
      formData.append("make", answerJson[427].value);
    }
    if (answerJson[428]) {
      formData.append("yom", answerJson[428].label);
    }
    // formData.append("yom", answerJson[428]);
    formData.append("Model_Variant", answerJson[429]);
    formData.append("Engine_No", answerJson[430]);
    formData.append("chassis_no", answerJson[431]);
    console.log("chassis_no", answerJson[431]);
    if (answerJson[432]) {
      formData.append("color", answerJson[432].value);
    }
    if (answerJson[434]) {
      formData.append("model", answerJson[434].refid);
    }
    if (answerJson[435]) {
      formData.append("variant", answerJson[435].valid);
    }

    formData.append("Non_Exchange_Price", answerJson[436]);
    formData.append("Exchange_Bonus", answerJson[437]);
    formData.append("total_exchange_price", answerJson[438]);
    if (answerJson[603]) {
      formData.append("payment_mode", answerJson[603].label)
    }
    formData.append("old_reg_no", answerJson[439]);
    if (answerJson[440]) {
      formData.append("old_bank", answerJson[440].label);
    }

    formData.append("old_bank_no", answerJson[441]);
    formData.append("old_rupee", answerJson[442]);
    formData.append("bank_fin_cmp", answerJson[443]);
    formData.append("loan_acc_no", answerJson[444]);
    formData.append("loan_rs", answerJson[445]);
    formData.append("new_car_acc", answerJson[446]);
    formData.append("allotORref_no", answerJson[447]);
    formData.append("new_rupee", answerJson[448]);
    formData.append("other_partner", answerJson[449]);
    formData.append("type", answerJson[433]);
    formData.append("documents", answerJson[450]);
    formData.append("customerid", this.state.CustomerId);
    formData.append("userid", this.state.UserID.id);
    console.log(this.state.UserID.id, 'userid..')
    formData.append("status", "active");
    formData.append("approval", 0);
    //  formData.append("id",null);
    //formData.append("documentsfiles", JSON.stringify(filesArray));
    formData.append('Docs', JSON.stringify(this.state.Docs))
    console.log([...formData], 'update dat');

    let categoryArray = {};
    if (answerJson1[260]) {
      categoryArray.date = dateStr;
    }
    categoryArray.reg_ownerName = answerJson[452];
    categoryArray.reg_no = answerJson[425];
    if (answerJson1[549]) {
      categoryArray.make = answerJson[427].value;
    }
    if (answerJson1[550]) {
      categoryArray.model = answerJson[434].refid;
    }
    categoryArray.km = answerJson1[554];
    if (answerJson1[551]) {
      categoryArray.variant = answerJson[435].valid;
    }
    if (answerJson1[557]) {
      categoryArray.structural_flood = answerJson1[557].label;
      console.log(answerJson1[557] && answerJson1[557].label, 'flood');
    }
    categoryArray.model_year = answerJson1[553];
    if (answerJson1[558]) {
      categoryArray.service_histaory = answerJson1[558].label;
    }
    categoryArray.ownership = answerJson1[555];
    categoryArray.last_service_kms = answerJson1[559];
    if (answerJson1[779]) {
      categoryArray.last_service_date = answerJson1[779];
    }
    categoryArray.vin_no = answerJson1[556];
    console.log(answerJson1[556], 'vinno');
    categoryArray.eng_no = answerJson1[430];
    categoryArray.buying_price = answerJson1[561];
    categoryArray.inc_exp_date = answerJson1[562];
    categoryArray.refurish_cost = answerJson1[567];
    if (answerJson1[564]) {
      categoryArray.hp = answerJson1[564].label;
    }
    if (answerJson1[627]) {
      categoryArray.mode_of_payment = answerJson1[627].label;
    }
    categoryArray.exp_selling_price = answerJson1[569];
    categoryArray.adj_new_car = answerJson1[568];
    if (answerJson1[564]) {
      categoryArray.preclosure_amt = answerJson1[564] ? answerJson1[564].label : null;
    }
    if (answerJson1[565]) {
      categoryArray.acc_hold_noc = answerJson1[565].label;
    }
    if (answerJson1[570]) {
      categoryArray.vech_condition = answerJson1[570].label;
    }
    categoryArray.enclosure = answerJson1[571];
    categoryArray.pnding_docs = answerJson1[572];
    categoryArray.accessories = answerJson1[573];
    categoryArray.customerName = answerJson1[574];
    // if (answerJson1[575]) {
    //   categoryArray.relation = answerJson1[575].label;
    // }
    categoryArray.contact_no = answerJson1[576];
    categoryArray.address = answerJson1[577];
    categoryArray.exchange_again = answerJson1[578];
    if (answerJson1[580]) {
      var d1 = new Date(answerJson1[580]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      categoryArray.delivery_date = dateStr1;
    }
    categoryArray.exchange_bonus = answerJson1[579];
    if (answerJson1[581]) {
      categoryArray.sales_consultant = answerJson1[581].label;
    }
    categoryArray.buyerName = answerJson1[582];
    categoryArray.New_Car = answerJson1[563];
    categoryArray.holding_amount = answerJson1[566];
    categoryArray.adj_new_car = answerJson1[568];

    categoryArray.buyer_remarks = answerJson1[583];
    if (answerJson1[584]) {
      categoryArray.incharge_name = answerJson1[584].label;
    }
    categoryArray.incharge_remarks = answerJson1[585];
    if (answerJson1[586]) {
      categoryArray.manager = answerJson1[586].label;
    }
    categoryArray.manager_remarks = answerJson1[587];

    categoryArray.customerid = this.state.CustomerId;
    //categoryArray.userid = this.state.userid.id;
    categoryArray.status = "active";
    //console.log(categoryArray);
    console.log(answerJson1.id, "answerJson1", this.state.Inventeditid);
    try {
      let result = await CmsContent.tbl_AT_Booklet_Purchase_Contract_Proof(
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        formData,
        "noid"
      );
      let result1 = await CmsContent.updateMaster(
        "tbl_Inventory_Sheet_Reproof",
        this.state.Inventeditid,
        categoryArray
      );
      if (result) {
        //console.log(result);

        this.setState({
          answerJson: "",
          json: "",
          alertVisible: true,
          textalert: "Your Form Has Been Updated",
          color: "success"
        });

        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        if (this.state.alertVisible == true) {
          setTimeout(
            () => window.location.reload(),
            2000
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  handleSuggestionSelect = (value, name) => {
    console.log(value, name)
    if (name === "buyingPrice") {
      this.setState((prev) => ({
        buyshowOptions: !prev.buyshowOptions
      }))
    }
    else {
      this.setState((prev) => ({
        showOptions: !prev.showOptions
      }))
    }
    if (value == "No suggestions") {

    }
    else {
      this.setState({ [name]: value })
    }

  }

  handleSuggestionSelect1 = (label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid) => {
    console.log(label,
      index,
      options = null,
      key = null,
      type,
      questionId,
      formid);

    this.setState((prev) => ({
      kmshow: !prev.kmshow
    }))
    this.answers(label,
      index,
      options = null,
      key = null,
      type,
      questionId,
      formid)
    this.process()
  }

  turnOff = (val) => {
    if (val === "buyingPrice") {
      this.setState((prev) => ({
        buyshowOptions: !prev.buyshowOptions
      }))
    }
    if (val === "exchangeBonus") {
      this.setState((prev) => ({
        showOptions: !prev.showOptions
      }))
    }
    if (val === "km") {
      this.setState((prev) => ({
        kmshow: !prev.kmshow
      }))
      this.process()
    }
  }
  formSubmit = async () => {
    const { answerJson, answerJson1, files, filesArray, button, fileJson,
      loanAcnumber, bankName, newcarName, LoanAvailable, os_amount, loan_exp_date, chpartner, newcarSupport, policy_file, rc_file
    } = this.state;

    // let imagealeart = ''
    // if (filesArray && (filesArray[0].Bankbook.length || filesArray[0].Bankdocuments.length || filesArray[0].ForecloserLetter.length || filesArray[0].InsurancePolicy.length ||
    //   filesArray[0].KYC.length || filesArray[0].NoObjectionCertificate.length || filesArray[0].Rcbook.length || filesArray[0].RelationshipCertificate.length ||
    //   filesArray[0].VahinCertificate.length || filesArray[0].aadhar.length || filesArray[0].pan.length)) {
    //   let obj = Object.values(filesArray[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         this.setState({ btnDisable: false })
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true;
    //         return true;
    //       }
    //     }
    //     else { console.log(ival, "ival"); }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }

    // console.log(this.state.latitude,"latitude",this.state.longitude,"longitude");
    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }
    console.log(policy_file, "policy_file");
    console.log(rc_file, 'rc_file');
    if (this.state.buyingPrice == "" || this.state.buyingPrice == null) {
      this.setState({
        alertVisible: true,
        textalert: "Please Enter Buying Price",
        color: "warning"
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return false
    }
    this.setState({ btnDisable: true })
    const formData = new FormData();

    let gotoapproval = true;
    if (filesArray && filesArray[0] && filesArray[0].RelationshipCertificate) {
      filesArray[0].RelationshipCertificate.map((ival, i) => {

        formData.append('RelationshipCertificate', ival);
      });
    }

    if (filesArray && filesArray[0] && filesArray[0].VahinCertificate) {
      filesArray[0].VahinCertificate.map((ival, i) => {

        formData.append('VahinCertificate', ival);
      });
    }

    if (filesArray && filesArray[0] && filesArray[0].Rcbook) {
      filesArray[0].Rcbook.map((ival, i) => {

        formData.append('Rcbook', ival);
      });
    } else {
      gotoapproval = false;

    }

    if (policy_file && policy_file.length > 0) {
      formData.append('previousIns', JSON.stringify(policy_file))
    } else {
      formData.append('previousIns', [])
    }

    if (filesArray && filesArray[0] && filesArray[0].InsurancePolicy) {
      filesArray[0].InsurancePolicy.map((ival, i) => {

        formData.append('InsurancePolicy', ival);
      });
    } else {
      gotoapproval = false;

    }

    if (rc_file && rc_file.length > 0) {
      formData.append('previousRC', JSON.stringify(rc_file))
    } else {
      formData.append('previousRC', [])
    }

    if (filesArray && filesArray[0] && filesArray[0].ForecloserLetter) {
      filesArray[0].ForecloserLetter.map((ival, i) => {

        formData.append('ForecloserLetter', ival);
      });
    }

    if (filesArray && filesArray[0] && filesArray[0].NoObjectionCertificate) {
      filesArray[0].NoObjectionCertificate.map((ival, i) => {

        formData.append('NoObjectionCertificate', ival);
      });
    }

    if (filesArray && filesArray[0] && filesArray[0].KYC) {
      filesArray[0].KYC.map((ival, i) => {

        formData.append('KYC', ival);
      });
    }
    if (filesArray && filesArray[0] && filesArray[0].aadhar) {
      filesArray[0].aadhar.map((ival, i) => {

        formData.append('aadhar', ival);
      });
    }
    if (filesArray && filesArray[0] && filesArray[0].pan) {
      filesArray[0].pan.map((ival, i) => {

        formData.append('pan', ival);
      });
    }
    if (filesArray && filesArray[0] && filesArray[0].pan) {
      filesArray[0].Bankbook.map((ival, i) => {

        formData.append('Bankbook', ival);
      });
    }

    if (filesArray && filesArray[0] && filesArray[0].Bankdocuments) {
      filesArray[0].Bankdocuments.map((ival, i) => {

        formData.append('Bankdocuments', ival);
      });
    }
    if (gotoapproval) {
      formData.append("gotoapproval", 1)
    } else formData.append("gotoapproval", 0)


    let id = answerJson.id;
    id && formData.append('id', id)
    formData.append("name", answerJson[827]);

    formData.append("Reg_No", answerJson[835]);
    formData.append("To_address", answerJson[828]);
    if (answerJson[826]) {
      var d = new Date(answerJson[826]);
      var dateStr = moment(d).format('YYYY-MM-DD')
      formData.append("date", dateStr);
    }


    if (answerJson[830]) {
      formData.append("yom", answerJson[830].label);
    }
    if (answerJson[831]) {
      formData.append("make", answerJson[831].value);
    }
    formData.append("contact", answerJson[829]);
    formData.append("chassis_no", answerJson[836]);
    formData.append("Engine_No", answerJson[837]);

    if (answerJson[832]) {
      formData.append("model", answerJson[832].value);
    }
    if (answerJson[833]) {
      formData.append("variant", answerJson[833].value);
    }
    if (answerJson[834]) {
      formData.append("color", answerJson[834].value);
    }
    if (this.state.exchangeBonus) {
      formData.append("Exchange_Bonus", this.state.exchangeBonus);
    }

    if (this.state.paymentMode) {
      formData.append("payment_mode", this.state.paymentMode.value)
    }
    if (answerJson[842]) {
      formData.append("spare_key", answerJson[842].label);
    }
    if (newcarName) {
      formData.append("new_car_name", newcarName.label);
    }

    formData.append("type", answerJson[433]);
    formData.append("documents", answerJson[450]);
    formData.append("customerid", this.state.CustomerId);
    // formData.append("userid", this.state.UserID.id);
    if ([1, 2, 3, 15].includes(this.state.usertype.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.UserID.id);
    }
    if ([1, 2, 3, 15].includes(this.state.usertype.orderBy)) {
      formData.append("UpdatedBy", this.state.UserID.id)
    }
    formData.append("status", "active");
    formData.append("approval", 0);
    formData.append('Docs', JSON.stringify(this.state.Docs))
    formData.append("documentsfiles", JSON.stringify(filesArray));
    if (fileJson.length) {
      fileJson.map((ival, i) => {
        formData.append(`files`, ival.file);
      });
    }
    formData.append("loan_available", LoanAvailable ? LoanAvailable.value : this.state.Loanopt1 ? this.state.Loanopt1[3].value : null);
    // formData.append("loan_available", LoanAvailable ? LoanAvailable.value : null);
    formData.append("loan_acc_no", loanAcnumber ? loanAcnumber : null);
    formData.append("out_standing_amount", os_amount ? os_amount : null);
    formData.append("loan_exp_date", loan_exp_date ? loan_exp_date : null);
    formData.append("new_car_support", newcarSupport ? newcarSupport : null);
    formData.append("channel_partner", chpartner ? chpartner : null);
    if (bankName) {
      formData.append("bankname", bankName.label);
    }

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    // inventory
    let categoryArray = {};
    if (answerJson1[260]) {
      categoryArray.date = dateStr;
    }
    categoryArray.reg_ownerName = answerJson[827];
    categoryArray.reg_no = answerJson[835];
    if (answerJson[831]) {
      categoryArray.make = answerJson[831].value;
    }
    if (answerJson[832]) {
      categoryArray.model = answerJson[832].value;
    }
    if (answerJson[833]) {
      categoryArray.variant = answerJson[833].value;
    }
    if (answerJson[834]) {
      categoryArray.color = answerJson[834].value;
    }
    categoryArray.km = answerJson[838];
    if (answerJson[846]) {
      categoryArray.structural_flood = answerJson[846].label;
    }
    categoryArray.Remark = answerJson[847];

    if (answerJson[842]) {
      categoryArray.service_histaory = answerJson[842].label;
    }
    if (answerJson[841]) {
      categoryArray.ownership = answerJson[841].label;
    }
    categoryArray.last_service_kms = answerJson[843];
    if (answerJson[844]) {
      categoryArray.last_service_date = answerJson[844];
    }
    categoryArray.vin_no = answerJson1[556];
    categoryArray.structural_flood = answerJson[846] ? answerJson[846].label : null;
    categoryArray.eng_no = answerJson[837];
    categoryArray.buying_price = this.state.buyingPrice;
    categoryArray.insurance = answerJson[839] ? answerJson[839].label : null;
    categoryArray.inc_exp_date = answerJson[840];
    if (answerJson1[565]) {
      categoryArray.hp = answerJson1[565].label;
    }
    if (this.state.paymentMode) {
      categoryArray.mode_of_payment = this.state.paymentMode.value;
    }
    categoryArray.preclosure_amt = answerJson1[565];
    if (answerJson1[565]) {
      categoryArray.acc_hold_noc = answerJson1[565].label;
    }
    if (answerJson[848]) {
      categoryArray.vech_condition = answerJson[848].label;
    }
    categoryArray.customerName = answerJson[827];
    categoryArray.contact_no = answerJson[829];
    categoryArray.address = answerJson[828];
    categoryArray.exchange_bonus = this.state.exchangeBonus
    categoryArray.Holding_amount = this.state.holding_amount;
    categoryArray.customerid = this.state.CustomerId;

    categoryArray.status = "active";
    if ([1, 2, 3, 15].includes(this.state.usertype.orderBy)) {
      categoryArray.userid = this.state.Evaluatorid;
    } else {
      categoryArray.userid = this.state.UserID.id;
    }
    if ([1, 2, 3, 15].includes(this.state.usertype.orderBy)) {
      categoryArray.UpdatedBy = this.state.UserID.id
    }
    categoryArray.refurish_cost = answerJson1[567];
    categoryArray.exp_selling_price = answerJson1[569];
    categoryArray.adj_new_car = answerJson1[568];
    categoryArray.enclosure = answerJson1[571];
    categoryArray.pnding_docs = answerJson1[572];
    categoryArray.accessories = answerJson1[573];
    categoryArray.exchange_again = answerJson1[578];
    categoryArray.latitude = this.state.latitude;
    categoryArray.longitude = this.state.longitude;
    if (answerJson1[580]) {
      var d1 = new Date(answerJson1[580]);
      let dateStr = moment(d1).format('YYYY-MM-DD')
      categoryArray.delivery_date = dateStr;
    }
    // if (answerJson1[575]) {
    //   categoryArray.relation = answerJson1[575].label;
    // }
    // if (answerJson1[581]) {
    //   categoryArray.sales_consultant = answerJson1[581].label;
    // }
    // categoryArray.buyerName = answerJson1[582];
    // categoryArray.New_Car = answerJson1[563];
    // categoryArray.adj_new_car = answerJson1[568];

    // categoryArray.buyer_remarks = answerJson1[583];
    // if (answerJson1[584]) {
    //   categoryArray.incharge_name = answerJson1[584].label;
    // }
    // categoryArray.incharge_remarks = answerJson1[585];
    // if (answerJson1[586]) {
    //   categoryArray.manager = answerJson1[586].label;
    // }
    // categoryArray.manager_remarks = answerJson1[587];
    //console.log(categoryArray);
    console.log(...formData, "..Formdata");
    try {
      const buying_price = categoryArray.buying_price
      const exchange_bonus = categoryArray.exchange_bonus
      const kmDriven = this.state.answerJson[838]
      const val1 = await JSON.parse(localStorage.getItem('buying_price')) || []
      const val2 = await JSON.parse(localStorage.getItem('exchange_bonus')) || []
      const localKmDriven = await JSON.parse(localStorage.getItem('km_driven')) || []
      val1.push(buying_price)
      val2.push(exchange_bonus)
      localKmDriven.push(kmDriven)

      let result = button == "Save" ? await CmsContent.tbl_AT_Booklet_Purchase_Contract_Proof(
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        formData,
        "id"
      ) : await CmsContent.tbl_AT_Booklet_Purchase_Contract_Proof(
        "tbl_AT_Booklet_Purchase_Contract_Proof",
        formData,
        "noid"
      );
      let result1 = button == "Save" ? await CmsContent.addMaster(
        "tbl_Inventory_Sheet_Reproof",
        categoryArray
      ) : await CmsContent.updateMaster(
        "tbl_Inventory_Sheet_Reproof",
        this.state.Inventeditid,
        categoryArray
      );

      if (result && result1) {
        if (button == "Save") {
          let formJson = await JSON.parse(localStorage.getItem("formFillData"))
          if (formJson && formJson.length) {
            let Index = await formJson.findIndex(e => e.prosid == this.state.CustomerId && e.ref == 'DOCUMENTATION')
            if (Index > -1) formJson.splice(Index, 1)
              localStorage.setItem("buying_price", JSON.stringify([...new Set(val1)]));
            localStorage.setItem("exchange_bonus", JSON.stringify([...new Set(val2)]));
            localStorage.setItem("km_driven", JSON.stringify([...new Set(localKmDriven)]));
            
            localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        }
        await this.setState({
          btnDisable: false,
          answerJson: [],
          file: "",
          filename: "",
          json: "",
          fileJson: [],
          alertVisible: true,
          textalert: "Your form has been Submitted",
          color: "success"
        });

        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        if (this.state.alertVisible == true) {
          setTimeout(
            () => window.location.reload(),
            2000
          );
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  download = (name) => {
    window.open(`${ACCESS_POINT}/cmsContent/documentpdf/${name}`, '_self');
  }
  generatepdf = async (id, name) => {
    let exportpdf = await CmsContent.document("tbl_AT_Booklet_Purchase_Contract_Proof", id);
    //console.log(exportpdf)
    if (exportpdf.data == "success") {
      await this.download(name)
    }
  }
  fileupload1 = async e => {
    let files = this.state.files;
    let filesArr = this.state.filesArr;
    let fileJson = [];

    e.map((fileItem, index) => {
      fileJson.push({
        index: index,
        file: fileItem.file,
        name: fileItem.file.name
      });
    });
    console.log(files)
    console.log(fileJson)
    this.setState({
      filesArr: e.map(fileItem => fileItem.file),
      fileJson
    });
  }
  fileupload = async (e, j) => {
    let fileObj = [];
    let fileArray = [];
    let files = [];
    let display = [];
    display[j] = [];
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      let ext = fileObj[0][i].name.split(".");

      this.setState({ files: fileObj[0] });
      fileArray.push({
        file: URL.createObjectURL(fileObj[0][i]),
        extension: ext[1]
      });
      files.push({ file: fileObj[0][i], extension: ext[1] });
      display[j].push({ file: fileObj[0][i], extension: ext[1] });
    }
    files.map((ival, i) => {
      if (
        ival.extension !== "png" &&
        ival.extension !== "gif" &&
        ival.extension !== "jpg" &&
        ival.extension !== "jpeg" &&
        ival.extension !== "pdf"
      ) {
        this.setState({
          errfiles: "Please upload file format of .jpg,.jpeg,.png,.pdf"
        });
        return false;
      } else {
        this.setState({ file: fileArray });
      }
    });


    await this.setState({ index: j, files, display });
    this.process();
  };
  FileReader = async () => {
    await this.state.img.map((ival, n) => {
      //console.log(ival)

      let reader = new FileReader();
      //let file = e.target.files[0];
      let file = this.state.img;
      let arr = [];
      reader.onloadend = () => {
        //console.log(reader);
        //arr.push({ data: reader.result })

        this.setState({
          file: ival,
          imagePreview: reader.result
        });
      };
      reader.readAsDataURL(ival);
    });
  };
  newtab = e => {
    window.open(e, "_self");
  };
  authorimage = (e, index) => {
    let fileJson = this.state.fileJson;
    if (e) {
      let filename = e;
      let filepath = e.name;
      // var filesize = filepath.size;
      fileJson[index].index = index;
      fileJson[index].file = e;
      fileJson[index].name = e.name;
      this.setState({
        fileJson,
        file: filename,
        filename: filepath
        // filepath: filesize
      });
    }
  };
  handleChange = async (e) => {

    this.setState({ [e.target.name]: !this.state[e.target.name] })
    // console.log(this.state.Rcbook)
    if (this.state[e.target.name] == false) {
      this.state.filesArray[0][e.target.name] = [];
      //this.setState({fileArray});
    }
  }
  EditImageMode = async (doc) => {
    const { filesArray, RcbookFiles, OwnershipFiles, InsurancePolicyFiles,
      TaxTokenFiles, DuplicateKeysFiles, NoObjectionCertificateFiles, RelationshipCertificateFiles, VahinCertificateFiles, aadharFiles, panFiles, BankbookFiles, BankdocumentsFiles } = this.state;
    if (doc == "Rcbook") {
      filesArray[0].Rcbook.push("");
    } else if (doc == "RelationshipCertificate") {
      filesArray[0].RelationshipCertificate.push("");
    } else if (doc == "VahinCertificate") {
      filesArray[0].VahinCertificate.push("");
    } else if (doc == "Ownership") {
      filesArray[0].Ownership.push("");
    } else if (doc == "InsurancePolicy") {
      filesArray[0].InsurancePolicy.push("");
    } else if (doc == "TaxToken") {
      filesArray[0].TaxToken.push("");
    } else if (doc == "DuplicateKeys") {
      filesArray[0].DuplicateKeys.push("");
    } else if (doc == "NoObjectionCertificate") {
      filesArray[0].NoObjectionCertificate.push("");
    } else if (doc == "aadhar") {
      filesArray[0].aadhar.push("");
    } else if (doc == "pan") {
      filesArray[0].pan.push("");
    } else if (doc == "Bankbook") {
      filesArray[0].Bankbook.push("");
    } else if (doc == "Bankdocuments") {
      filesArray[0].Bankdocuments.push("");
    }
    this.setState({
      filesArray, RcbookFiles, OwnershipFiles, InsurancePolicyFiles,
      TaxTokenFiles, DuplicateKeysFiles, NoObjectionCertificateFiles, RelationshipCertificateFiles, VahinCertificateFiles, aadharFiles, panFiles, BankbookFiles, BankdocumentsFiles
    });
  }
  exportpdf = async () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[452]
    window.open(`${ACCESS_POINT}/cmsContent/documentpdf/${name}`, '_self')
  }
  FILEUPLOAD = (e, doc) => {
    let filesArray = this.state.filesArray;

    if (doc == "Rcbook") {
      filesArray[0].Rcbook = e.map(fileItem => fileItem.file)
    } else if (doc == "RelationshipCertificate") {
      filesArray[0].RelationshipCertificate = e.map(fileItem => fileItem.file)
    } else if (doc == "VahinCertificate") {
      filesArray[0].VahinCertificate = e.map(fileItem => fileItem.file)
    } else if (doc == "InsurancePolicy") {
      filesArray[0].InsurancePolicy = e.map(fileItem => fileItem.file)
    } else if (doc == "KYC") {
      filesArray[0].KYC = e.map(fileItem => fileItem.file)
    } else if (doc == "ForecloserLetter") {
      filesArray[0].ForecloserLetter = e.map(fileItem => fileItem.file)
    } else if (doc == "NoObjectionCertificate") {
      filesArray[0].NoObjectionCertificate = e.map(fileItem => fileItem.file)
    } else if (doc == "aadhar") {
      filesArray[0].aadhar = e.map(fileItem => fileItem.file)
    } else if (doc == "pan") {
      filesArray[0].pan = e.map(fileItem => fileItem.file)
    } else if (doc == "Bankbook") {
      filesArray[0].Bankbook = e.map(fileItem => fileItem.file)
    } else if (doc == "Bankdocuments") {
      filesArray[0].Bankdocuments = e.map(fileItem => fileItem.file)
    }
    this.setState({
      // VahinCertificateFiles: e.map(fileItem => ({ [fileItem.file.name]: fileItem.file })),
      filesArray
    });
  };
  webcam2 = (e, field, side, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files[0][side] = Files[0][side] ? Files[0][side] : []
      Files[0][side].push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }
  render() {
    const { resSelected, fileArr, EditMode, fileJson, json, json1, json2, button, ApplyRcbook, Rcbook, Ownership, NoObjectionCertificate,
      DuplicateKeys, TaxToken, InsurancePolicy, RelationshipCertificate, VahinCertificate, ApplyOwnership, ApplyNoObjectionCertificate,
      ApplyDuplicateKeys, ApplyTaxToken, ApplyInsurancePolicy, Loanopt, Loanopt1, ApplyRelationshipCertificate, ApplyVahinCertificate
      , filesArray, Docs, answerJson, bankdetails, holdingAmount, exdate, aadhar, Applyaadhar, pan, Applypan, Bankbook, ApplyBankbook, Bankdocuments, ApplyBankdocuments } = this.state;
    if (this.state.checked == true) {
      if (this.state.opt && this.state.doc && this.state.closure) {
        this.state.opt.map((ival, k) => {
          this.state.closure.map((jval, l) => {
            var checkBox = document.getElementById(`inlineCheckbox${24}${k}`);

            if (ival == jval) {
              //checkBox.checked = true;
              if (checkBox != null) {
                checkBox.checked = true
              }
            }
          })
          this.state.doc.map((jval, j) => {
            var checkBox = document.getElementById(`inlineCheckbox${27}${k}`);

            if (ival == jval) {
              //checkBox.checked = true;
              if (checkBox != null) {
                console.log(checkBox)
                checkBox.checked = true
              }
            }
          })
        })
      }

    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>AT Booklet Form</h1>
                  </div>
                  <div className="card-body">
                    <div>

                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {json && json.length ? json : <div>Loading...</div>}
                      {json && json.length ? <React.Fragment>
                        <div className="bgcolor">

                          <div className="row form-group">

                            <div className="col-sm-1"></div>
                            <div className="col-sm-3">

                              DOCMENTS UPLOAD:

                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2" >
                              <label>RC</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="checkbox"
                                name="Rcbook"
                                onChange={(e) => this.handleChange(e)}
                                checked={this.state.Rcbook}
                              />{" "}
                              <label style={{ color: "orange" }}>RC *</label>
                            </div>
                            <div className="col-sm-3"></div>
                          </div>
                          {Rcbook ?
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-5">
                                  <input type="radio" name="Rcbook" checked={ApplyRcbook} onChange={e => { this.fileCheck(e, 'ApplyRcbook', 1) }} /> Yes
                                  <input style={{ marginLeft: "20px" }} type="radio" name="Rcbook" checked={!ApplyRcbook} onChange={e => { this.fileCheck(e, 'ApplyRcbook', 2) }} /> No
                                </div>

                              </div>
                            </React.Fragment> : null}

                          {Rcbook && ApplyRcbook ?
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  >
                                    RC Upload{<button className="btn btn-default btn-sm" value={this.state.Isrcfilecam} onClick={e => { this.setState({ Isrcfilecam: !this.state.Isrcfilecam }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:
                                  </label>
                                </div>
                                {this.state.Isrcfilecam ? <div className="col-sm-5">
                                  <Camera
                                    image={(e) => { this.webcam2(e, 'filesArray', 'Rcbook', 'Isrcfilecam') }}
                                    width={300}
                                    height={400}
                                    id={'Isrcfilecam'}
                                  />
                                </div> :
                                  <div className="col-sm-5">
                                    <FilePond
                                      files={this.state.filesArray[0].Rcbook}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.FILEUPLOAD(fileItems, "Rcbook");
                                      }}

                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}

                              </div>
                            </React.Fragment> : null}
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2" />
                            <div className="col">
                              {Rcbook && ApplyRcbook && this.state.img ? this.state.img.Rcbook : null}
                            </div>
                          </div>
                          <br />
                          {answerJson[839] && (answerJson[839].value == 0 || answerJson[839].value == 1) ? <div>
                            <div className="row form-group">
                              <div className="col-sm-4">
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="checkbox"
                                  name="InsurancePolicy"
                                  onChange={this.handleChange}
                                  checked={this.state.InsurancePolicy}
                                />{" "}
                                <label style={{ color: "orange" }}>INSURANCE *</label>
                              </div>
                            </div>
                            {InsurancePolicy ?
                              <React.Fragment>
                                <div className="row form-group">
                                  <div className="col-sm-4" />
                                  <div className="col-sm-5">
                                    <input type="radio" name="InsurancePolicy" checked={ApplyInsurancePolicy} onChange={e => this.fileCheck(e, 'ApplyInsurancePolicy', 1)} /> Yes
                                    <input type="radio" style={{ marginLeft: "20px" }} name="InsurancePolicy" checked={!ApplyInsurancePolicy} onChange={e => this.fileCheck(e, 'ApplyInsurancePolicy', 2)} /> No
                                  </div>
                                </div>
                              </React.Fragment> : null}

                            {InsurancePolicy && ApplyInsurancePolicy ?
                              <React.Fragment>
                                <div className="row form-group">
                                  <div className="col-sm-2"></div>
                                  <div className="col-sm-2">
                                    <label htmlFor="exampleInputEmail1">
                                      Insurance Upload{<button className="btn btn-default btn-sm" value={this.state.Isinsurancecam} onClick={e => { this.setState({ Isinsurancecam: !this.state.Isinsurancecam }) }}>
                                        <MDBIcon icon="camera" size="lg" />
                                      </button>}:
                                    </label>
                                  </div>

                                  {
                                    this.state.Isinsurancecam
                                      ?
                                      <div className="col-sm-5">
                                        <Camera
                                          image={(e) => { this.webcam2(e, 'filesArray', 'InsurancePolicy', 'Isinsurancecam') }}
                                          width={300}
                                          height={400}
                                          id={'Isinsurancecam'}
                                        />
                                      </div>
                                      :
                                      <div className="col-sm-5">
                                        <FilePond
                                          files={this.state.filesArray[0].InsurancePolicy}
                                          allowReorder={true}
                                          allowMultiple={true}
                                          allowImageResize={true}
                                          imagePreviewMinHeight="50"
                                          imagePreviewMaxHeight="50"
                                          //  maxFileSize={'3072KB'}
                                          acceptedFileTypes={["image/*", "application/pdf"]}
                                          onupdatefiles={fileItems => {
                                            this.FILEUPLOAD(fileItems, "InsurancePolicy");
                                          }}

                                          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                        />
                                        {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                        <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                      </div>
                                  }
                                </div>
                              </React.Fragment> : null}
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2" />
                              <div className="col">
                                {InsurancePolicy && ApplyInsurancePolicy && this.state.img ? this.state.img.InsurancePolicy : null}
                              </div>

                            </div>
                          </div> : null}
                          <br />

                          {/* load available */}
                          {this.state.loan_Available ?
                            <>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="position">LOAN AVAILABLE</label>
                                </div>
                                <div className="col-sm-5">
                                  <input
                                    disabled={true}
                                    type="text"
                                    className="form-control"
                                    name='LoanAvailable'
                                    onChange={e => this.handleUserInput(e)
                                    }
                                    value={this.state.Loan_opt}
                                  />
                                </div>
                                <div className="col-sm-3"></div>
                              </div>
                            </>
                            :
                            this.state.loan_Available_YC == true
                              ?
                              <>
                                <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-2">
                                    <label>LOAN AVAILABLE</label>
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      options={Loanopt}
                                      placeholder={'Select Loan available'}
                                      handleChange={e => this.ans(
                                        e,
                                        null,
                                        null,
                                        null,
                                        "static-selectbox",
                                        'LoanAvailable'

                                      )}
                                      selectedService={this.state.LoanAvailable}
                                    />
                                  </div>
                                  <span className="error-shows" id='loan'>
                                    {this.state.Loanerr}
                                  </span>
                                  <div className="col-sm-3"></div>
                                </div>
                              </>
                              :
                              <>
                                <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-2">
                                    <label>LOAN AVAILABLE</label>
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      options={Loanopt1}
                                      placeholder={'Select Loan available'}
                                      handleChange={e => this.ans(
                                        e,
                                        null,
                                        null,
                                        null,
                                        "static-selectbox",
                                        'LoanAvailable'

                                      )}
                                      selectedService={this.state.LoanAvailable}
                                    />
                                  </div>
                                  <span className="error-shows" id='loan'>
                                    {this.state.Loanerr}
                                  </span>
                                  <div className="col-sm-3"></div>
                                </div>
                              </>
                          }

                          <br />
                          {bankdetails && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">BANK NAME</label>
                              </div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  options={this.state.banknames}
                                  placeholder={'SELECT BANK NAME'}
                                  handleChange={e => this.ans(
                                    e,
                                    null,
                                    null,
                                    null,
                                    "static-selectbox",
                                    'bankName'

                                  )}
                                  selectedService={this.state.bankName}
                                />
                                <span className="error-shows" >
                                  {this.state.erro}
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">LOAN ACCOUNT NUMBER</label>
                              </div>

                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={'ENTER LOAN ACCOUNT NUMBER'}
                                  name='loanAcnumber'
                                  onChange={e => this.handleUserInput(e)
                                  }
                                  value={this.state.loanAcnumber}
                                />
                                <span className="error-shows" >
                                  {this.state.erro}
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>

                            {/* A2 */}
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">OUTSTANDING AMOUNT</label>
                              </div>

                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={'ENTER OUTSTANDING AMOUNT'}
                                  name='os_amount'
                                  onChange={e => this.handleUserInput(e)
                                  }
                                  value={this.state.os_amount}
                                />
                                <span className="error-shows" >
                                  {this.state.erro}
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >

                                  <label
                                  >
                                    FORECLOSER LETTER {<button className="btn btn-default btn-sm" value={this.state.IsForecloserCam} onClick={e => { this.setState({ IsForecloserCam: !this.state.IsForecloserCam }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}

                                  </label>
                                </div>
                                {this.state.IsForecloserCam ? <div className="col-sm-5">
                                  <Camera
                                    image={(e) => { this.webcam2(e, 'filesArray', 'ForecloserLetter', 'IsForecloserCam') }}
                                    width={300}
                                    height={400}
                                    id={'IsForecloserCam'}
                                  />
                                </div> : <div className="col-sm-5">

                                  <FilePond
                                    files={this.state.filesArray[0].ForecloserLetter}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    // imageResizeMode="cover"
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.FILEUPLOAD(fileItems, 'ForecloserLetter');

                                    }}

                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2" />
                                <div className="col">
                                  {this.state.img && this.state.img.ForecloserLetter ? this.state.img.ForecloserLetter : null}
                                </div>
                              </div>
                            </React.Fragment>
                          </React.Fragment>}
                          {Bankdocuments &&
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    DOCUMENT UPLOAD{<button className="btn btn-default btn-sm" value={this.state.IsBankdocumentsCam} onClick={e => { this.setState({ IsBankdocumentsCam: !this.state.IsBankdocumentsCam }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>} :
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  {this.state.IsBankdocumentsCam ?
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'filesArray', 'Bankdocuments', 'IsBankdocumentsCam') }}
                                      width={300}
                                      height={400}
                                      id={'IsBankdocumentsCam'}
                                    />
                                    :
                                    <div>
                                      <FilePond
                                        files={this.state.filesArray[0].Bankdocuments}
                                        allowReorder={true}
                                        allowMultiple={true}
                                        allowImageResize={true}
                                        imagePreviewMinHeight="50"
                                        imagePreviewMaxHeight="50"
                                        //  maxFileSize={'3072KB'}
                                        acceptedFileTypes={["image/*", "application/pdf"]}
                                        onupdatefiles={fileItems => {
                                          this.FILEUPLOAD(fileItems, 'Bankdocuments');
                                        }}
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                      />
                                      {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                      <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    </div>
                                  }
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2"></div>
                                <div className="col">
                                  {Bankdocuments && ApplyBankdocuments && this.state.img ? this.state.img.Bankdocuments : null}
                                </div>
                              </div>
                            </React.Fragment>}

                          {exdate && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">NOC EXPIRY DATE</label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="date"
                                  id="myDate"
                                  className="form-control"
                                  onChange={e =>
                                    this.ans(
                                      e.target.value,
                                      null,
                                      null,
                                      null,
                                      "date",
                                      "loan_exp_date"
                                    )
                                  }
                                  value={this.state.loan_exp_date}
                                />
                                <span className="error-shows" >
                                  {this.state.err}
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                          </React.Fragment>}

                          <br />

                          {/* A3 */}
                          {holdingAmount && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">HOLDING AMOUNT</label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={'ENTER HOLDING AMOUNT'}
                                  name='holding_amount'
                                  onChange={e => this.handleUserInput(e)
                                  }
                                  value={this.state.holding_amount}
                                />
                                <span className="error-shows" >
                                  {this.state.erro}
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                          </React.Fragment>}

                          <br />


                          {this.state.noc && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" >

                                <label
                                >
                                  NOC {<button className="btn btn-default btn-sm" value={this.state.IsNOCCam} onClick={e => { this.setState({ IsNOCCam: !this.state.IsNOCCam }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}

                                </label>
                              </div>
                              {this.state.IsNOCCam ? <div className="col-sm-5">
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'NoObjectionCertificate', 'IsNOCCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsNOCCam'}
                                />
                              </div> :
                                <div className="col-sm-5">

                                  <FilePond
                                    files={this.state.filesArray[0].NoObjectionCertificate}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    // imageResizeMode="cover"
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.FILEUPLOAD(fileItems, 'NoObjectionCertificate');

                                    }}

                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>}
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2" />
                              <div className="col">
                                {this.state.img && this.state.img.NoObjectionCertificate ? this.state.img.NoObjectionCertificate : null}
                              </div>
                            </div>
                          </React.Fragment>}
                          <br />
                          {/* vahan document */}
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label>VAHAN DOCUMENT</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="checkbox"
                                name="VahinCertificate"
                                onChange={this.handleChange}
                                checked={this.state.VahinCertificate}
                              />{" "}
                              <label style={{ color: 'orange' }}>Vahan Documentation *</label>
                            </div>
                          </div>
                          {VahinCertificate ?
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-5">
                                  <input type="radio" name="VahinCertificate" checked={ApplyVahinCertificate} onChange={e => this.fileCheck(e, 'ApplyVahinCertificate', 1)} /> Yes
                                  <input type="radio" style={{ marginLeft: "20px" }} name="VahinCertificate" checked={!ApplyVahinCertificate} onChange={e => this.fileCheck(e, 'ApplyVahinCertificate', 2)} /> No
                                </div>
                              </div>
                            </React.Fragment> : null}

                          {VahinCertificate && ApplyVahinCertificate ?
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2">
                                  <label>
                                    NOC {<button className="btn btn-default btn-sm" value={this.state.IsVahinCam} onClick={e => { this.setState({ IsVahinCam: !this.state.IsVahinCam }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}

                                  </label>
                                </div>
                                {this.state.IsVahinCam ? <div className="col-sm-5">
                                  <Camera
                                    image={(e) => { this.webcam2(e, 'filesArray', 'VahinCertificate', 'IsVahinCam') }}
                                    width={300}
                                    height={400}
                                    id={'IsVahinCam'}
                                  />
                                </div> :
                                  <div className="col-sm-5">
                                    <FilePond
                                      files={this.state.filesArray[0].VahinCertificate}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.FILEUPLOAD(fileItems, "VahinCertificate");
                                      }}

                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                            </React.Fragment> : null}
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2" />
                            <div className="col">
                              {VahinCertificate && ApplyVahinCertificate && this.state.img ? this.state.img.VahinCertificate : null}
                            </div>
                          </div>
                          {/* vahin End */}

                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label>NEW CAR NAME</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                options={this.state.newcarOpt}
                                placeholder={'Select New Car Name'}
                                handleChange={e => this.ans(
                                  e,
                                  null,
                                  null,
                                  null,
                                  "static-selectbox",
                                  'newcarName'
                                )}
                                selectedService={this.state.newcarName}
                              />
                            </div>
                            <span className="error-shows" id='loan'>
                              {this.state.Loanerr}
                            </span>
                            <div className="col-sm-3"></div>
                          </div>

                          <br />
                          {this.state.iskyc && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" >

                                <label
                                >
                                  KYC{<button className="btn btn-default btn-sm" value={this.state.IsKycCam} onClick={e => { this.setState({ IsKycCam: !this.state.IsKycCam }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}
                                </label>
                              </div>
                              {this.state.IsKycCam ? <div className="col-sm-5">
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'KYC', 'IsKycCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsKycCam'}
                                />
                              </div> :
                                <div className="col-sm-5">

                                  <FilePond
                                    files={this.state.filesArray[0].KYC}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    // imageResizeMode="cover"
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.FILEUPLOAD(fileItems, 'KYC');

                                    }}

                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>}
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2" />
                              <div className="col">
                                {this.state.img && this.state.img.KYC ? this.state.img.KYC : null}
                              </div>
                            </div>
                            {this.state.isRproof && <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" >

                                Relationship Certificate{<button className="btn btn-default btn-sm" value={this.state.IsRelationCam} onClick={e => { this.setState({ IsRelationCam: !this.state.IsRelationCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>}

                              </div>
                              {this.state.IsKycCam ? <div className="col-sm-5">
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'RelationshipCertificate', 'IsRelationCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsRelationCam'}
                                />
                              </div> : <div className="col-sm-5">
                                <FilePond
                                  files={this.state.filesArray[0].RelationshipCertificate}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  // imageResizeMode="cover"
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'RelationshipCertificate');

                                  }}

                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                              </div>}
                            </div>}
                            <div className="row form-group">
                              <div className="col-sm-2"></div>
                              <div className="col-sm-2" />
                              <div className="col">
                                {this.state.img && this.state.img.RelationshipCertificate ? this.state.img.RelationshipCertificate : null}
                              </div>
                            </div>
                          </React.Fragment>}
                          <br />

                          {/* A1 */}
                          <div className="row form-group parentclass">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="position" style={{ color: "orange" }}>BUYING PRICE *</label>
                            </div>
                            <div className="col-sm-5 ">
                              <input
                                type="number"
                                className="form-control suggest-input"
                                placeholder={'ENTER BUYING PRICE'}
                                name='buyingPrice'
                                onChange={e => this.handleUserInput(e)
                                }
                                value={this.state.buyingPrice}
                              />
                              <div className="history-icon" >
                                <i class="bi bi-clock-history histroyicon" onClick={() => this.turnOff("buyingPrice")}></i>
                              </div>
                              <div className={this.state.buyshowOptions ? "subclass-parent" : "d-none"}>
                                <div className="subclass mx-2">
                                  {this.state.buying_price_suggest.map((val, i) => (
                                    <p
                                      className="paragg"
                                      onClick={() => this.handleSuggestionSelect(val, "buyingPrice")}
                                      key={i}
                                    >
                                      {val}
                                    </p>
                                  ))}
                                </div>
                              </div>
                              <span className="error-shows" >
                                {this.state.erro}
                              </span>
                            </div>
                            <div className="col-sm-3"></div>
                          </div>

                          <br />
                          <div className="row form-group">

                            <div className="col-sm-1"></div>
                            <div className="col-sm-3">

                              ADDITIONAL SUPPORT:

                            </div>
                          </div>
                          <br />
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="position">NEW CAR SUPPORT</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={'ENTER NEW CAR SUPPORT'}
                                name='newcarSupport'
                                onChange={e => this.handleUserInput(e)
                                }
                                value={this.state.newcarSupport}
                              />
                              <span className="error-shows" >
                                {this.state.erro}
                              </span>
                            </div>
                            <div className="col-sm-3"></div>
                          </div>
                          <br />
                          <div className="row form-group parentclass">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="position">EXCHANGE BONUS</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="number"
                                className="form-control  suggest-input"
                                placeholder={'ENTER EXCHANCE BONUS'}
                                name='exchangeBonus'
                                onChange={e => this.handleUserInput(e)
                                }
                                value={this.state.exchangeBonus}
                              />
                              <div className="history-icon" >
                                <i class="bi bi-clock-history histroyicon" onClick={() => this.turnOff("exchangeBonus")}></i>
                              </div>
                              <div className={this.state.showOptions ? "subclass-parent" : "d-none"}>
                                <div className="subclass mx-2">
                                  {this.state.Exchange_Bonus_suggest.map((val, i) => (
                                    <p
                                      className="paragg"
                                      onClick={() => this.handleSuggestionSelect(val, "exchangeBonus")}
                                      key={i}
                                    >
                                      {val}
                                    </p>
                                  ))}
                                </div>
                              </div>
                              <span className="error-shows" >
                                {this.state.erro}
                              </span>
                            </div>
                            <div className="col-sm-3"></div>
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label>PAYMENT MODE</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                options={this.state.Paymentmode}
                                placeholder={'Select Payment Mode'}
                                handleChange={e => this.ans(
                                  e,
                                  null,
                                  null,
                                  null,
                                  "static-selectbox",
                                  'paymentMode'

                                )}
                                selectedService={this.state.paymentMode}
                              />
                            </div>
                            <span className="error-shows" id='loan'>
                              {this.state.Loanerr}
                            </span>
                            <div className="col-sm-3"></div>
                          </div>

                          <br />

                          {this.state.loanstatus && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">LOAN STATUS</label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  name='Finalprice'
                                  onChange={e => this.handleUserInput(e)
                                  }
                                  disabled={true}
                                  value={this.state.Finalprice}
                                />
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                          </React.Fragment>}
                          {this.state.chPartner && <React.Fragment>
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position">CHANNEL PARTNER</label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  name='chpartner'
                                  onChange={e => this.handleUserInput(e)
                                  }
                                  value={this.state.chpartner}
                                />
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                          </React.Fragment>}

                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                AADHAAR {<button className="btn btn-default btn-sm" value={this.state.IsAadharCam} onClick={e => { this.setState({ IsAadharCam: !this.state.IsAadharCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>} :
                              </label>
                            </div>
                            <div className="col-sm-5">
                              {this.state.IsAadharCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'aadhar', 'IsAadharCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsAadharCam'}
                                />
                                :
                                <div> <FilePond
                                  files={this.state.filesArray[0].aadhar}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'aadhar');
                                  }}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2"></div>
                            <div className="col">
                              {aadhar && Applyaadhar && this.state.img ? this.state.img.aadhar : null}
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                PAN{<button className="btn btn-default btn-sm" value={this.state.IsPanCam} onClick={e => { this.setState({ IsPanCam: !this.state.IsPanCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>} :
                              </label>
                            </div>
                            <div className="col-sm-5">
                              {this.state.IsPanCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'pan', 'IsPanCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsPanCam'}
                                />
                                :
                                <div><FilePond
                                  files={this.state.filesArray[0].pan}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'pan');
                                  }}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2"></div>
                            <div className="col">
                              {pan && Applypan && this.state.img ? this.state.img.pan : null}
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                BANK BOOK {<button className="btn btn-default btn-sm" value={this.state.IsBankbookCam} onClick={e => { this.setState({ IsBankbookCam: !this.state.IsBankbookCam }) }}>
                                  <MDBIcon icon="camera" size="lg" />
                                </button>} :
                              </label>
                            </div>
                            <div className="col-sm-5">
                              {this.state.IsBankbookCam ?
                                <Camera
                                  image={(e) => { this.webcam2(e, 'filesArray', 'Bankbook', 'IsBankbookCam') }}
                                  width={300}
                                  height={400}
                                  id={'IsBankbookCam'}
                                />
                                :
                                <div><FilePond
                                  files={this.state.filesArray[0].Bankbook}
                                  allowReorder={true}
                                  allowMultiple={true}
                                  allowImageResize={true}
                                  imagePreviewMinHeight="50"
                                  imagePreviewMaxHeight="50"
                                  //  maxFileSize={'3072KB'}
                                  acceptedFileTypes={["image/*", "application/pdf"]}
                                  onupdatefiles={fileItems => {
                                    this.FILEUPLOAD(fileItems, 'Bankbook');
                                  }}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-2"></div>
                            <div className="col">
                              {Bankbook && ApplyBankbook && this.state.img ? this.state.img.Bankbook : null}
                            </div>
                          </div>


                        </div>
                      </React.Fragment> : null}

                      {json.length > 0 && (
                        <React.Fragment>

                          <div className="bgcolor">

                            {this.state.global && (
                              <div className="row form-group">
                                {/* <div className="col-sm-2" />
                                <div className="col-sm-2" /> */}
                                <div className="col text-center">
                                  {[1, 2, 3].includes(this.state.usertype?.orderBy) ?
                                    (<button
                                      type="button"
                                      className="btn btn-primary"
                                      disabled={this.state.btnDisable}
                                      onClick={
                                        button == "Save" ? this.formSubmit : this.formSubmit
                                      }


                                    >
                                      {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                      {button == "Save" ? "Save" : "Update"}
                                    </button>
                                    )
                                    :
                                    button == "Save" ?
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={this.state.btnDisable}
                                        onClick={
                                          button == "Save" ? this.formSubmit : this.formSubmit
                                        }


                                      >
                                        {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                        {button == "Save" ? "Save" : "Update"}
                                      </button>
                                      :
                                      null}
                                </div>
                              </div>
                            )}
                            {this.state.global && (
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {/* {this.state.button == "Save" && (
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      style={{ marginLeft: "10px" }}
                                      onClick={
                                        button == "Save" ? this.formSubmitPdf : null
                                      }
                                    >
                                      {button == "Save" ? "Save and Export PDF" : null}
                                    </button>
                                  )} */}
                                </div>
                              </div>
                            )}

                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AT_Booklet_Purchase_Contract_Proof;
