import React, { Component } from "react";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../../components/Form/FormMiddleware";
import SingleSelect from "../../../../components/Form/SingleSelect";
import redisMiddleware from "../../../../MiddleWare/redisMiddleware";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Preloader from "../preloader.js";
import bankname from "../TL/bank.json";
import Category from "../../CMS/Category";
import state_city from "../../../Json/state_city";
import state from "../../../Json/state";
import { ACCESS_POINT } from "../../../../config/index";
//import BProof from "./Evaluation_Checklist_B_Proof"
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { MDBBtn, MDBIcon } from "mdbreact";
import LoginModal from "../../../../components/Modal/Modal";
import Datatable from "../../../../components/Datatable/Datatable";
import watermark from 'watermarkjs'
import { result, takeRightWhile } from "lodash-es";
import { invalid } from "moment";
import moment from 'moment'
import iconClose from "../../../../images/CMS/icon-hamburger-close.svg";
import Camera from '../../../../MiddleWare/camera'
import CanvasMap from "../../../../MiddleWare/canvas"
import Imagereader from "../../../../MiddleWare/Imagereader";
import Geolocationfun from "../../../../MiddleWare/Geolocation";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize
);


class Evaluation_Checklist_F_Proof extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      watermark_link: 'https://exp.difuza.com/superAdmin/file?fileurl=false/difuzaWebLogo_1597046790718.png',
      watermark_text: 'SteerAuto',
      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      answerJson1: [],
      cardata: [],
      finalprice: "",
      json: [],
      accidentans: "",
      //viewlistcar:false,
      EXTERIORfilevisible: false,
      INTERIORfilevisible: false,
      viewvisible: false,
      fileJson: [],
      Islocation: false,
      fileJson1: [],
      filevalue: [],
      followup: ["FIRST FOLLOW UP", "SECOND FOLLOW UP", "THIRD FOLLOW UP"],
      interior_ans_Json: [],
      CalculateJson: [],
      followJson: [],
      followJson1: [{
        date: "",
        status1: "",
        status2: "",
        status3: "",
        remarks: "",
        follow: ""
      }],
      polstatus1: [],
      polstatus2: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      ServiceId: localStorage.getItem("currentService"),
      usertype: JSON.parse(localStorage.getItem('usertypelog')),
      bank: bankname,
      currentDate: new Date(),
      rcoptions: [{ value: "Original", label: "Original" },
      { value: "Duplicate", label: "Duplicate" },
      { value: "Smart Card", label: "Smart Card" },
      { value: "No Docs to check", label: "No Docs to check" }],
      PanelRepairopt: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" }

      ],
      PanelRepair: "",
      CountRepair: "",
      rcval: "",
      rcfileupload: [],
      others: false,
      othernewcarmodel: "",
      rcfilejson: [],
      CanvasVals: { lhs: [], rhs: [], front: [], back: [], top: [] },
      insurancefileJson: [],
      insuranceFiles: [],
      insurance_val: "",
      interiors: [
        "DASHBOARD",
        "INSTRUMENT PANEL",
        "SEATS/COVERS",
        "DOOR PADS",
        "FLOOR CARPET",
        "ROOF LININGS",
        // "AVN",
        "MUSIC SYSTEM",
        "SWITCHES KNOBS",
        "ANTENNA",
        "POWER WINDOWS",
        "WIS WIPER & WASHER",
        "AIR CONDITIONER",
        // 'ENGINE ROOM'
      ],
      inter: [
        "DASHBOARD",
        "INSTRUMENT",
        "SEATS/COVERS",
        "DOOR",
        "FLOOR",
        "ROOF",
        // "AVN",
        "MUSIC",
        "SWITCHES",
        "ANTENNA",
        "POWER",
        "WIS",
        "AIR",
        // 'ENGINE'
      ],
      interiorJson: [],
      savepdf: false,
      PrizeRaised: "",
      exterior: [
        "SMOKE",
        "ENGINE OIL",
        "MISSING",
        "NOISE",
        "OVER HEATING",
        "PICK UP/POWER",
        "RADIATOR",
        "EXHAUST CONDITION",
        // "SOOT DEPOSIT",
        "SHIFTING",
        "AXLES",
        "SUSPENSION",
        "STEERING",
        "CLUTCH",
        "BRAKES"
      ],
      exter: [
        "SMOKE",
        "ENGINE OIL",
        "MISSING",
        "NOISE",
        "OVER",
        "PICK",
        "RADIATOR",
        "CONDITION",
        // "SOOT",
        "SHIFTING",
        "AXLES",
        "SUSPENSION",
        "STEERING",
        "CLUTCH",
        "BRAKES"
      ],
      exteriorOption: [
        {
          label: "Good",
          value: 0
        },
        {
          label: "Average",
          value: 1
        },
        {
          label: "Repair",
          value: 2
        },
        {
          label: "Replacement",
          value: 3
        }
      ],
      interiorOption: [
        {
          label: "Good",
          value: 0
        },
        {
          label: "Average",
          value: 1
        },
        {
          label: "Repair",
          value: 2
        },
        {
          label: "Replacement",
          value: 3
        }
      ],
      ownershipOpt: [
        {
          label: "1",
          value: 1
        },
        {
          label: "2",
          value: 2
        },
        {
          label: "3",
          value: 3
        },
        {
          label: "4 and above",
          value: 4
        }
      ],
      carlist1: [
        {
          Header: "MAKE",
          accessor: "make"
        },
        {
          Header: "MODEL",
          accessor: "model"
        },
        {
          Header: "MAKE YEAR",
          accessor: "year"
        },
        {
          Header: "Owner",
          accessor: "ownership"
        },
        {
          Header: "Price",
          accessor: "buyprice"
        }],
      carlist: [
        {
          Header: "MAKE",
          accessor: "make"
        },
        {
          Header: "MODEL",
          accessor: "modelname"
        },
        {
          Header: "MAKE YEAR",
          accessor: "make_year"
        },
        {
          Header: "KMS",
          accessor: "kms"
        },
        {
          Header: "COLOUR",
          accessor: "ColourName"
        },
        {
          Header: "OWNER_TYPE",
          accessor: "owner_type"
        },
        {
          Header: "Price",
          accessor: "price"
        },

      ],
      exteriorJson: [],
      alertVisible: false,
      button: "Save",
      total: 0,
      T_I: 0,
      T_E: 0,
      T_B: 0,
      T_L: 0,
      T_T: 0,
      T_O: 0,
      T_O1: 0,
      T_O2: 0,
      T_O3: 0,
      body_shop: 0,
      files: [],
      badinteriorfiles: [],
      badexteriorfiles: [],
      badinteriorImages: [],
      badexteriorImages: [],
      innerView: [
        "Interior from front left",
        "Interior from front right",
        "Interior from back left",
        "Interior from back right",
        "Dashboard",
        "Meter console",
        "Engine room",
      ],
      innerView1: ["ifleft", "ifright", "ibleft", "ibright", "dashborad", "meter", "engine"],
      fileSnaps1: [{ ifleft: [], ifright: [], ibleft: [], ibright: [], dashborad: [], meter: [], engine: [] }],
      fileSnap: [{ LHS: [], RHS: [], FRONT: [], BACK: [] }],
      filesArray: [],
      filesArray1: [],
      vehicleINTImg: null,
      arr: {},
      status: [
        { label: "Live", value: 1 },
        { label: "Closed", value: 2 },
        { label: "Evaluated", value: 3 }
      ],
      livestatus: [
        //{ label: "Appointment taken", value: 1 },
        { label: "Under Follow up", value: 2 },
        { label: "Ringing no response", value: 3 },
        { label: "Researching", value: 4 },
        { label: "Not attempted", value: 5 }
      ],
      closedstatus: [
        { label: "Plan dropped", value: 1 },
        { label: "Non contactable", value: 2 },
        { label: "Lost", value: 3 },
        { label: "Wrong capture", value: 4 },
        { label: "No response", value: 5 },
        { label: "Huge Price Gap", value: 6 }
      ],
      evaluatedstatus: [
        { label: "Trade in", value: 1 },
        { label: "Follow-up", value: 2 },
        { label: "Researching", value: 3 },
        { label: "Postponed", value: 4 },
        { label: "Dropped", value: 5 },
        { label: "Not attempted", value: 6 },
        { label: "Non contactable", value: 7 },
        { label: "Price not offered", value: 8 },
        { label: "Huge Price gap", value: 9 },
        { label: "Lost", value: 10 }
      ],
      droppedstatus: [
        { label: "Due to price", value: 1 },
        { label: "Budget constraint", value: 2 },
        { label: "Just enquired", value: 3 },
        { label: "Others", value: 4 },
        { label: "Not known", value: 5 }
      ],
      noncontact: [
        { label: "RNR-3", value: 1 },
        { label: "Wrong number", value: 2 }
      ],
      Loststatus: [
        { label: "Co dealer", value: 1 },
        { label: "Competition", value: 2 },
        { label: "End user", value: 3 },
        { label: "Broker", value: 4 },
        { label: "Not known", value: 5 }
      ],
      Negotiation: [
        { label: "Gap >30", value: 1 },
        { label: "Gap 30-50", value: 2 },
        { label: "<50", value: 3 }
      ],
      Consideration: [
        { label: "With Co dealer", value: 1 },
        { label: "Competition", value: 2 },
        { label: "Used car", value: 3 },
        { label: "New entrant", value: 4 }
      ],
      Postponed: [
        { label: "Next month", value: 1 },
        { label: "After 3 month", value: 2 },
        { label: "Not known", value: 3 }
      ],
      sources: [{ label: "Walkin Showroom", value: 1 }, { label: "Walk in Event", value: 2 }, { label: "Tele in", value: 3 }, { label: "Tele Out / Sms", value: 4 }, { label: "Web Leads", value: 5 }, { label: "OLX", value: 6 }, { label: "Car Trade", value: 7 }, { label: "Car Dekho", value: 8 }, { label: "Workshop", value: 9 }, { label: "Other Portals", value: 10 }],
      Typesoftrans: [
        { label: "Exchange", value: 1 },
        { label: "Non-Exchange", value: 2 }
      ],
      Insurance: [
        { label: "COMPRENSIVE", value: 1 },
        { label: "3RD PARTY", value: 2 },
        { label: "EXPIRED", value: 3 }
      ],
      taxpaid: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }],
      HP: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }, { label: "YET TO CONFIRM", value: 3 }],
      fuel: [
        { label: "PETROL", value: 1 },
        { label: "Diesel", value: 2 },
        // { label: "Hybrid", value: 3 },
        // { label: "LPG", value: 4 },
        // { label: "CNG", value: 5 },
      ],
      oldcardata: [],
      Mt: [
        { label: "MT", value: 1 },
        { label: "AT", value: 2 },
        { label: "CVT", value: 3 }
      ],

      respect: [{ label: "Sir", value: 1 }, { label: "Madam", value: 1 }],
      answerJson: [],
      bumpfilevisible: false,
      LIGHTSfilevisible: false,
      Tyresfilevisible: false,
      Wheelfilevisible: false,
      filesArray11: [],
      filesArray2: [],
      filesArray3: [],
      fileJson11: [],
      fileJson2: [],
      fileJson3: [],
      json1: [],
      json3: [],
      tyersval: [],
      followup: ["FIRST FOLLOW UP", "SECOND FOLLOW UP", "THIRD FOLLOW UP"],
      followup1: [{ value: 1 }],
      status1options: [{ value: 0, label: "Live" }, { value: 1, label: "Closed" }, { value: 2, label: "Deal Closed" },],
      status2options: [],
      status3options: [],
      userid: JSON.parse(localStorage.getItem("userlog")),
      follow: [
        { label: "DENTS", value: "D" },
        { label: "SCRATCHES", value: "S" },
        { label: "RUST", value: "RU" },
        { label: "RE PAINT", value: "RE" }
      ],
      view: ["FRONT", "REAR"],
      // lights: [
      //   "HEADLAMPS",
      //   "TAIL LAMPS",
      //   "INDICATORS-FRONT",
      //   "INDICATORS-REAR",
      //   "DOOR MIRRORS"
      // ],
      lights: [
        "HEAD LAMPS RHS",
        "HEAD LAMPS LHS",
        "TAIL LAMPS RHS",
        "TAIL LAMPS LHS",
        "INDICATOR-FRONT RHS",
        "INDICATOR-FRONT LHS",
        "INDICATOR-REAR RHS",
        "INDICATOR-REAR LHS",
        "DOOR MIRRORS RHS",
        "DOOR MIRRORS LHS"
      ],
      option: [{ label: "Good", value: 1 }, { label: "Average", value: 2 }, { label: "Repair", value: 3 }, { label: "Replacement", value: 4 }],
      tyreopt: [{ label: "< 25%", value: "Worst" }, { label: "25% - 50%", value: "Repair" }, { label: "50% - 75%", value: "Average" }, { label: "> 75%", value: "Good" }],
      option2: [{ label: 'Available', value: "Good" }, { label: 'Not available', value: "Repair" }],
      vcondition: null,
      vconditionopt: [
        {
          label: "Good car",
          value: "Good"
        },
        {
          label: "Average car",
          value: "Average"
        },
        {
          label: "Accidental car",
          value: "Repair"
        },
        {
          label: "Flood car",
          value: "Worst"
        },
      ],
      pro: ["EMBLEMS", "WEATHER STRIPS", "RUN CHANNEL", "DOOR PROTECTION"],
      wheel: ["WHEEL CAPS", "WHEEL RIMS", "TRUNK AREA", "TOOL KIT&JACK"],
      wind: ["WINDSCREEN"],
      dateofregyear: "",
      dateofregmon: "",
      monthOptions: [
        {
          "value": "01",
          "label": "January"
        },
        {
          "value": "02",
          "label": "February"
        },
        {
          "value": "03",
          "label": "March"
        },
        {
          "value": "04",
          "label": "April"
        },
        {
          "value": "05",
          "label": "May"
        },
        {
          "value": "06",
          "label": "June"
        },
        {
          "value": "07",
          "label": "July"
        },
        {
          "value": "08",
          "label": "August"
        },
        {
          "value": "09",
          "label": "September"
        },
        {
          "value": "10",
          "label": "October"
        },
        {
          "value": "11",
          "label": "November"
        },
        {
          "value": "12",
          "label": "December"
        }
      ],
      interiorJson: [],
      interior_ans_Json: [],
      bodyJson: [],
      bumperJson: [{ name: "", body: "", remarks: "", option: "", estimate: "" }],
      windJson: [{ name: "", body: "", remarks: "", estimate: "" }],
      lightJson: [{ name: "", rhs: "", lhs: "", body: '', remarks: "", estimate: "" }],
      tyersJson: [{ name: "", rhs: "", lhs: "", remarks: "", estimate: "" }],
      wheeklJson: [{ name: "", body: "", remarks: "", estimate: "" }],
      proJson: [{ name: "", body: [], remarks: "", estimate: "" }],
      yesorno: [{ label: "YES", value: 1 }, { label: "NO", value: 2 }, { label: "YET TO CONFIRM", value: 3 }],
      flood: [{ label: "YES", value: 1 }, { label: "No", value: 2 }],
      windChecked: false,
      alertVisible: false,
      filledinterior: [],
      filledexterior: [],
      filledbumper: false,
      filledtyre: false,
      filledlight: false,
      visible: true,
      button: "Save",
      bidviewform: true,
      evalRemarksOptions: [],
      evalotherEstimates: [],
      standard_selling_price: [],
      exchange_bonus: [],
      evalFinalPrice: [],
      evalStatusRemarks: [],
      WheelremarkSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false }
      ],

      WheelEstimateSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false }
      ],
      bumperremarkSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
      ],

      bumperEstimateSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
      ],
      tyreremarkSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
      ],
      tyreEstimateSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
      ],
      lightremarksuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
      ],
      lightEstimatesuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
      ],
      windremarkSuggest: [
        { ind: 0, value: false },
      ],
      windEstimateSuggest: [
        { ind: 0, value: false },
      ],
      proremarkSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false }
      ],

      proEstimateSuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false }
      ],
      interiorremarksuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
        { ind: 10, value: false },
        { ind: 11, value: false },
      ],
      interiorEstimatesuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
        { ind: 10, value: false },
        { ind: 11, value: false },


      ],
      exteriorremarksuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
        { ind: 10, value: false },
        { ind: 11, value: false },
        { ind: 12, value: false },
        { ind: 13, value: false },
      ],
      exteriorEstimatesuggest: [
        { ind: 0, value: false },
        { ind: 1, value: false },
        { ind: 2, value: false },
        { ind: 3, value: false },
        { ind: 4, value: false },
        { ind: 5, value: false },
        { ind: 6, value: false },
        { ind: 7, value: false },
        { ind: 8, value: false },
        { ind: 9, value: false },
        { ind: 10, value: false },
        { ind: 11, value: false },
        { ind: 12, value: false },
        { ind: 13, value: false },
      ],
      wheelEstimateSuggestoptions: [],
      wheelRemarkSuggestoptions: [],
      lightEstimateSuggestoptions: [],
      lightRemarkSuggestoptions: [],
      windEstimateSuggestoptions: [],
      windRemarkSuggestoptions: [],
      bumpEstimateSuggestoptions: [],
      bumpRemarkSuggestoptions: [],
      interiorEstimateSuggestoptions: [],
      interiorRemarkSuggestoptions: [],
      exteriorEstimateSuggestoptions: [],
      exteriorRemarkSuggestoptions: [],
      tyreRemarkSuggestoptions: [],
      tyreEstimateSuggestoptions: [],
      leadsavailableoptions: [],
      proEstimateSuggestoptions: [],
      proRemarkSuggestoptions: [],
      newmodelopt: [],
      vehclassRemarks: false,
      STANDARD: false,
      EXCHANGE: false,
      BODY: false,
      TOTAL: false,
      vehclassFinalPrice: false,
      // options
      NewCarModel: [
      ],
      insurance_opn: [
        {
          label: "National Insurance Co. Ltd.",
          value: 0,
          year: "1906",
          location: "Kolkata"
        },
        {
          label: "Go Digit General Insurance Ltd.",
          value: 1,
          year: "2016",
          location: "Bengaluru"
        },
        {
          value: 2,
          label: "Bajaj Allianz General Insurance Co. Ltd.",
          year: "2001",
          location: "Pune"
        },
        {
          value: 3,
          label: "Cholamandalam MS General Insurance Co. Ltd.",
          year: "2001",
          location: "Chennai"
        },
        {
          value: 4,
          label: "Bharti AXA General Insurance Co. Ltd.",
          year: "2008",
          location: "Mumbai"
        },
        {
          value: 5,
          label: "HDFC ERGO General Insurance Co. Ltd.",
          year: "2002",
          location: "Mumbai"
        },
        {
          value: 6,
          label: "Future Generali India Insurance Co. Ltd.",
          year: "2007",
          location: "Mumbai"
        },
        {
          value: 7,
          label: "The New India Assurance Co. Ltd.",
          year: "1919",
          location: "Mumbai"
        },
        {
          value: 8,
          label: "Iffco Tokio General Insurance Co. Ltd.",
          year: "2000",
          location: "Gurugram"
        },
        {
          value: 9,
          label: "Reliance General Insurance Co. Ltd.",
          year: "2000",
          location: "Mumbai"
        },
        {
          value: 10,
          label: "Royal Sundaram General Insurance Co. Ltd.",
          year: "2001",
          location: "Chennai"
        },
        {
          value: 11,
          label: "The Oriental Insurance Co. Ltd.",
          year: "1947",
          location: "New Delhi"
        },
        {
          value: 12,
          label: "Tata AIG General Insurance Co. Ltd.",
          year: "2001",
          location: "Mumbai"
        },
        {
          value: 13,
          label: "SBI General Insurance Co. Ltd.",
          year: "2009",
          location: "Mumbai"
        },
        {
          value: 14,
          label: "Acko General Insurance Ltd.",
          year: "2016",
          location: "Mumbai"
        },
        {
          value: 15,
          label: "Navi General Insurance Ltd.",
          year: "2016",
          location: "Mumbai"
        },
        {
          value: 16,
          label: "Edelweiss General Insurance Co. Ltd.",
          year: "2016",
          location: "Mumbai"
        },
        {
          value: 17,
          label: "ICICI Lombard General Insurance Co. Ltd.",
          year: "2001",
          location: "Mumbai"
        },
        {
          value: 18,
          label: "Kotak Mahindra General Insurance Co. Ltd.",
          year: "2015",
          location: "Mumbai"
        },
        {
          value: 19,
          label: "Liberty General Insurance Ltd.",
          year: "2013",
          location: "Mumbai"
        },
        {
          value: 20,
          label: "Magma HDI General Insurance Co. Ltd.",
          year: "2009",
          location: "Kolkata"
        },
        {
          value: 21,
          label: "Raheja QBE General Insurance Co. Ltd.",
          year: "2007",
          location: "Mumbai"
        },
        {
          value: 22,
          label: "Shriram General Insurance Co. Ltd.",
          year: "2006",
          location: "Jaipur"
        },
        {
          value: 23,
          label: "United India Insurance Co. Ltd.",
          year: "1938",
          location: "Chennai"
        },
        {
          value: 24,
          label: "Universal Sompo General Insurance Co. Ltd.",
          year: "2007",
          location: "Mumbai"
        }
      ],
      activeInput: null,
      listening: false,
      selectedLanguage: 'en-US',
      micON: false,
      languageOptions: [
        { label: 'English', value: 'en-US' },
        { label: 'Tamil', value: 'ta-IN' },
        { label: 'Hindi', value: 'hi-IN' },
      ],
      voiceIndex: "",
      voiceType: "",
      voiceOptions: "",
      voiceQuestionId: "",
      voiceFormId: "",
      voiceInput: "",
      SpeechType: ""
    };
    this.recognition = null;
  }

  turnoffOthers = (val) => {
    if (val === "finalPrice") {
      this.setState((prev) => ({
        vehclassFinalPrice: !prev.vehclassFinalPrice
      }))
      this.process1()
    }
    if (val === "vehclassRemarks") {
      this.setState((prev) => ({
        vehclassRemarks: !prev.vehclassRemarks
      }))
      this.process1()
    }

    if (val === "STANDARD") {
      this.setState((prev) => ({
        STANDARD: !prev.STANDARD
      }))
      this.process()
    }
    if (val === "TOTAL") {
      this.setState((prev) => ({
        TOTAL: !prev.TOTAL
      }))
      this.process()
    }
    if (val === "EXCHANGE") {
      this.setState((prev) => ({
        EXCHANGE: !prev.EXCHANGE
      }))
      this.process()
    }
    if (val === "BODY") {
      this.setState((prev) => ({
        BODY: !prev.BODY
      }))
      this.process()
    }




  }
  handleSuggestionSelect3 = (label, index, options = null, key = null, type, questionId, formid, field) => {
    console.log(label, index, options = null, key = null, type, questionId, formid, field)
    if (field === "STANDARD") {
      this.setState((prev) => ({
        STANDARD: !prev.STANDARD
      }))
      console.log(this.state.STANDARD);
    }
    if (field === "EXCHANGE") {
      this.setState((prev) => ({
        EXCHANGE: !prev.EXCHANGE
      }))
      console.log(this.state.EXCHANGE);
    }
    if (field === "BODY") {
      this.setState((prev) => ({
        BODY: !prev.BODY
      }))
      console.log(this.state.BODY);
    }
    if (field === "TOTAL") {
      this.setState((prev) => ({
        TOTAL: !prev.TOTAL
      }))
      console.log(this.state.BODY);
    }
    this.answers(label, index, options = null, key = null, type, questionId, formid)
    this.process();
  }

  handleSuggestionSelect2 = (value, index, name, field) => {
    if (index === "finalPrice") {
      this.setState((prev) => ({
        vehclassFinalPrice: !prev.vehclassFinalPrice
      }))
      this.setState({
        finalprice: value
      })
    }
    console.log(value, index, name, field)
    if (field === "vehclassRemarks") {
      this.setState((prev) => ({
        vehclassRemarks: !prev.vehclassRemarks
      }))
      this.process1()
      this.ans(value, index, name)
    }
    else {
      this.setState((prev) => ({
        showOptions: !prev.showOptions
      }))
    }
    if (value == "No suggestions") {

    }
    else {
      this.setState({ [name]: value })
    }

  }

  turnOffEstimate = (e, val, name) => {
    console.log(name);
    if (name === "wheel") {
      this.setState((prevState) => ({
        WheelEstimateSuggest: prevState.WheelEstimateSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "bumper") {
      this.setState((prevState) => ({
        bumperEstimateSuggest: prevState.bumperEstimateSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }))
    }
    if (name === "lights") {
      this.setState((prevState) => ({
        lightEstimatesuggest: prevState.lightEstimatesuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "wind") {
      this.setState((prevState) => ({
        windEstimateSuggest: prevState.windEstimateSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "pro") {
      this.setState((prevState) => ({
        proEstimateSuggest: prevState.proEstimateSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "tyre") {
      this.setState((prevState) => ({
        tyreEstimateSuggest: prevState.tyreEstimateSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));

    }
    if (name === "interior") {

      this.setState((prevState) => ({
        interiorEstimatesuggest: prevState.interiorEstimatesuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
      this.process()
    }
    if (name === "exterior") {
      this.setState((prevState) => ({
        exteriorEstimatesuggest: prevState.exteriorEstimatesuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
      this.process()
    }
  };
  turnOffRemarks = (e, val, name) => {
    console.log(val);

    if (name === "wheel") {
      this.setState((prevState) => ({
        WheelremarkSuggest: prevState.WheelremarkSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "bumper") {
      this.setState((prevState) => ({
        bumperremarkSuggest: prevState.bumperremarkSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }))
    }
    if (name === "lights") {
      this.setState((prevState) => ({
        lightremarksuggest: prevState.lightremarksuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "wind") {
      this.setState((prevState) => ({
        windremarkSuggest: prevState.windremarkSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "pro") {
      this.setState((prevState) => ({
        proremarkSuggest: prevState.proremarkSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "tyre") {
      this.setState((prevState) => ({
        tyreremarkSuggest: prevState.tyreremarkSuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (name === "interior") {
      console.log("log viludhu");

      this.setState((prevState) => ({
        interiorremarksuggest: prevState.interiorremarksuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
      this.process()
    }
    if (name === "exterior") {
      console.log("log viludhu");

      this.setState((prevState) => ({
        exteriorremarksuggest: prevState.exteriorremarksuggest.map(item =>
          item.ind === val ? { ...item, value: !item.value } : item
        )
      }));
      this.process()
    }

  };


  handleSuggestionSelect = async (label, value, index, type, field) => {
    console.log(label, value, index, type, field);
    if (value === "No suggestion") {

    }
    if (type === "remarks") {
      console.log(field);
      if (field == "wheel") {
        this.setState((prevState) => ({
          WheelremarkSuggest: prevState.WheelremarkSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.wheeelfun(label, value, index, type)
      }
      if (field == "wind") {
        this.setState((prevState) => ({
          windremarkSuggest: prevState.windremarkSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.windfun(label, value, index, type)
      }
      if (field == "bumper") {
        this.setState((prevState) => ({
          bumperremarkSuggest: prevState.bumperremarkSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.bump(label, value, index, type)
      }
      if (field == "tyre") {
        this.setState((prevState) => ({
          tyreremarkSuggest: prevState.tyreremarkSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.tyers(label, value, index, type)
      }
      if (field == "pro") {
        this.setState((prevState) => ({
          proremarkSuggest: prevState.proremarkSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.profun(label, value, index, type)
      }
      if (field == "light") {
        this.setState((prevState) => ({
          lightremarksuggest: prevState.lightremarksuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.lights(label, value, index, type)
      }
      if (field == "interior") {
        this.setState((prevState) => ({
          interiorremarksuggest: prevState.interiorremarksuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.checkinterior(label, value, index, type)
      }
      if (field == "exterior") {
        this.setState((prevState) => ({
          exteriorremarksuggest: prevState.exteriorremarksuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.checkexterior(label, value, index, type)
      }
    }
    else {
      if (field == "wheel") {
        this.setState((prevState) => ({
          WheelEstimateSuggest: prevState.WheelEstimateSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.wheeelfun(label, value, index, type)
      }
      if (field == "wind") {
        this.setState((prevState) => ({
          windEstimateSuggest: prevState.windEstimateSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.windfun(label, value, index, type)
      }
      if (field == "bumper") {
        this.setState((prevState) => ({
          bumperEstimateSuggest: prevState.bumperEstimateSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.bump(label, value, index, type)
      }
      if (field == "pro") {
        this.setState((prevState) => ({
          proEstimateSuggest: prevState.proEstimateSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.profun(label, value, index, type)
      }
      if (field == "tyre") {
        this.setState((prevState) => ({
          tyreEstimateSuggest: prevState.tyreEstimateSuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.tyers(label, value, index, type)
      }
      if (field == "light") {
        this.setState((prevState) => ({
          lightEstimatesuggest: prevState.lightEstimatesuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.lights(label, value, index, type)
      }
      if (field == "interior") {
        this.setState((prevState) => ({
          interiorEstimatesuggest: prevState.interiorEstimatesuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.checkinterior(label, value, index, type)
        this.process()
      }
      if (field == "exterior") {

        this.setState((prevState) => ({
          exteriorEstimatesuggest: prevState.exteriorEstimatesuggest.map(item =>
            item.ind === index ? { ...item, value: !item.value } : item
          )
        }));
        this.checkexterior(label, value, index, type)
        this.process()
      }
    }

  }
  async componentDidMount() {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript.trim())
          .join('');
        this.updateActiveInput(transcript);
      };
      this.recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
      };
      this.recognition.onend = () => {
        this.setState({ listening: false });
      };
    } else {
      alert("Your browser doesn't support the Web Speech API.");
    }
    //evalStatusRemarks
    const evalStatusRemarks = [...new Set(JSON.parse(localStorage.getItem("evalStatusRemarks")) || [])];
    this.setState({ evalStatusRemarks });

    const exchange_bonus = [...new Set(JSON.parse(localStorage.getItem("exchange_bonus")) || [])];
    this.setState({ exchange_bonus });

    const evalFinalPrice = [...new Set(JSON.parse(localStorage.getItem("finalPrice")) || [])];
    this.setState({ evalFinalPrice });

    const evalotherEstimates = [...new Set(JSON.parse(localStorage.getItem("evalOtherEstimates")) || [])];
    this.setState({ evalotherEstimates });


    //standard_selling_price
    const standard_selling_price = [...new Set(JSON.parse(localStorage.getItem("standard_selling_price")) || [])];
    this.setState({ standard_selling_price });



    const locwheelSug = JSON.parse(localStorage.getItem("evalWheelSuggest")) || []
    const wheelEstimate = [...new Set(locwheelSug.map(item => item.estimate))] || []
    const wheelremarks = [...new Set(locwheelSug.map(item => item.remarks))] || []

    this.setState({
      wheelEstimateSuggestoptions: wheelEstimate
    })
    this.setState({
      wheelRemarkSuggestoptions: wheelremarks
    })


    const locLightSug = JSON.parse(localStorage.getItem("evallightSuggest")) || []
    const lightEstimate = [...new Set(locLightSug.map(item => item.estimate))] || []
    const lightremarks = [...new Set(locLightSug.map(item => item.remarks))] || []
    this.setState({
      lightEstimateSuggestoptions: lightEstimate
    })
    this.setState({
      lightRemarkSuggestoptions: lightremarks
    })

    const locWindSug = JSON.parse(localStorage.getItem("evalwindSuggest")) || []
    const windEstimate = [...new Set(locWindSug.map(item => item.estimate))] || []
    const windremarks = [...new Set(locWindSug.map(item => item.remarks))] || []
    this.setState({
      windEstimateSuggestoptions: windEstimate
    })
    this.setState({
      windRemarkSuggestoptions: windremarks
    })

    const locBumpSug = JSON.parse(localStorage.getItem("evalbumperSuggest")) || []
    const bumpEstimate = [...new Set(locBumpSug.map(item => item.estimate))] || []
    const bumpremarks = [...new Set(locBumpSug.map(item => item.remarks))] || []
    this.setState({
      bumpEstimateSuggestoptions: bumpEstimate
    })
    this.setState({
      bumpRemarkSuggestoptions: bumpremarks
    })

    const locIntSug = JSON.parse(localStorage.getItem("evalInteriorSuggest")) || []
    const intEstimate = [...new Set(locIntSug.map(item => item.estimate))] || []
    const intremarks = [...new Set(locIntSug.map(item => item.remarks))] || []
    this.setState({
      interiorEstimateSuggestoptions: intEstimate
    })
    this.setState({
      interiorRemarkSuggestoptions: intremarks
    })

    const locExSug = JSON.parse(localStorage.getItem("evalExteriorSuggest")) || []
    const ExEstimate = [...new Set(locExSug.map(item => item.estimate))] || []
    const Exremarks = [...new Set(locExSug.map(item => item.remarks))] || []
    this.setState({
      exteriorEstimateSuggestoptions: ExEstimate
    })
    this.setState({
      exteriorRemarkSuggestoptions: Exremarks
    })

    const locTyreSug = JSON.parse(localStorage.getItem("evalTyreSuggest")) || []
    const tyreEstimate = [...new Set(locTyreSug.map(item => item.estimate))] || []
    const tyreremarks = [...new Set(locTyreSug.map(item => item.remarks))] || []
    this.setState({
      tyreEstimateSuggestoptions: tyreEstimate
    })
    this.setState({
      tyreRemarkSuggestoptions: tyreremarks
    })
    const locproSug = JSON.parse(localStorage.getItem("evalProSuggest")) || []
    const proEstimate = [...new Set(locproSug.map(item => item.estimate))] || []
    const proremarks = [...new Set(locproSug.map(item => item.remarks))] || []
    this.setState({
      proEstimateSuggestoptions: proEstimate
    })
    this.setState({
      proRemarkSuggestoptions: proremarks
    })


    try {
      let makeopt = [];
      let modelopt = [];
      let colopt = [];
      let salesopt = [];
      let varopt = [];
      let sourceopt = [];

      let service = 10;
      let Cus = [];

      if (this.props.cusid) {

        Cus = this.props.cusid;
      } else if (this.props.match.params.cusid) {

        Cus = this.props.match.params.cusid;
      }
      console.log(Cus, "cus");
      let PrizeList = await CmsContent.getFreedom(
        `tbl_prize_list.*`,
        `tbl_dfcarz_prosperities,tbl_prize_list`,
        `tbl_dfcarz_prosperities.id = ${Cus} and tbl_dfcarz_prosperities.make = tbl_prize_list.make and tbl_prize_list.model = tbl_dfcarz_prosperities.model`
      );
      if (PrizeList.data.length) {

        this.setState({ PrizeList: PrizeList.data[0].price });
      }


      // new sales old userType-158
      // const getnewsales = await CmsContent.getFreedom(
      //   "name as label,id as value",
      //   "tbl_dfcars_sales_consultant",
      //   "status = 'active'",
      //   1,
      //   1
      // );


      // if (getnewsales && getnewsales.data.length) {

      //   await this.setState({ salesopt: getnewsales.data })

      // }
      //Warranty master
      const warranty = await CmsContent.getFreedom("*",
        "tbl_dfcars_warranty",
        `serviceid=${service}`, 1, 1
      );
      // console.log(warranty);
      let warranty_master = []
      if (warranty) {
        warranty.data.map((sval, s) => {
          warranty_master.push(sval);
        })
        this.setState({ warranty_master });
      }

      let allOpt = await this.decryption('getcar')
      if (allOpt) {
        // console.log(allOpt, "decryption");
        await this.setState({
          makeopt: allOpt.makeopt,
          colopt: allOpt.coloropt,
          varopt: allOpt.variantopt,
          modelopt: allOpt.modelopt,
          makeYearOption: allOpt.yearopt,
          NewCarModel: allOpt.newmakeopt,
          newmodelopt: allOpt.newmodelopt,
          salesopt: allOpt.salesopt
        })
      }

      // let olddata = await CmsContent.getFreedom("*", "tbl_dfcars_oldcarsdata", 1, 1, `id desc`)
      // this.setState({ oldcardata: olddata })
      let locopt = [];
      const location = await CmsContent.getFreedom(
        "*",
        "tbl_location",
        `serviceid=10`,
        1,
        1
      );
      // console.log(location)
      if (location && location.data.length > 0) {
        location.data.map((kval, b) => {
          locopt.push({ label: kval.name, value: kval.id });
        });
        this.setState({ locopt });
      }


      // let Buyer = await CmsContent.getFreedom(
      //   `tbl_items.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname,tbl_colour.colour as ColourName`,
      //   `tbl_items,tbl_make_master,tbl_dfcarsmodel,tbl_colour`,
      //   `tbl_items.make=tbl_make_master.id and tbl_dfcarsmodel.id = tbl_items.model and tbl_colour.id = tbl_items.colour`
      // );
      // if (Buyer) {
      //   this.setState({ Buyer: Buyer.data })
      // }

      let answerJson = this.state.answerJson;


      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        "parentid=77",
        "id",
        "id"
      );
      if (formQues) {

        formQues.data.map((ival, i) => {
          if (ival.Orderby == null) {
            ival.Orderby = 1000 + i;
          }
        });

        formQues.data = formQues.data.sort(
          await CmsContent.compareValues("Orderby", "int", "asc")
        );

        formQues.data.map((qval, q) => {

          if (qval.id == 302) {
            this.setState({ checkOption: qval.options });
          }
          if (qval.id == 314) {
            qval.options = this.state.makeopt;
          }
          if (qval.id == 534) {
            qval.type = "selectbox";
            qval.options = this.state.bank;
          }
          if (qval.id == 653) {
            qval.type = "selectbox";
            qval.options = this.state.NewCarModel;
          }
          if (qval.id == 633) {
            qval.type = "selectbox";
            qval.options = this.state.yesorno;
          }
          if (qval.id == 626) {
            qval.type = "";
          }
          if (qval.id == 322) {
            qval.options = this.state.Insurance;

          }
          if (qval.id == 311) {
            qval.options = this.state.Typesoftrans;

          }
          if (qval.id == 315) {
            qval.options = this.state.modelopt
          }
          if (qval.id == 308) {
            qval.options = allOpt.salesopt;
          }
          if (qval.id == 302) {
            qval.options = this.state.sources;
          }
          if (qval.id == 324) {
            qval.options = this.state.taxpaid;
          }
          if (qval.id == 325) {
            qval.options = this.state.HP;
          }
          if (qval.id == 317) {
            qval.options = this.state.colopt;
          }
          if (qval.id == 525) {
            qval.options = this.state.fuel;
          }
          if (qval.id == 321) {
            qval.options = this.state.ownershipOpt;
          }
          if (qval.id == 532) {
            qval.options = state;
          }
          if (qval.id == 533) {
            qval.options = [];
          }
          if (qval.id == 544) {
            qval.options = this.state.varopt;
          }
          if (qval.id == 336) {
            qval.options = this.state.Mt;
          }
          if (qval.id == 316) {
            qval.options = this.state.makeYearOption;
          }
          if (qval.id == 715) {
            qval.options = this.state.makeYearOption;
          }
          if (qval.id == 714) {
            qval.type = '';
            // qval.options = this.state.monthOptions;
          }
          if (qval.id == 638 || qval.id == 639 || qval.id == 306 || qval.id == 605) {
            qval.type = "";
          }
          if (qval.id == 312) {
            qval.type = ""
          }
          // if (qval.type == "selectbox") {
          //   let option = []
          //   if (qval.options) {
          //     qval.options.map((jval, j) => {
          //       if (715 == qval.id) {
          //         if (answerJson[316] && jval.label >= answerJson[316].label) {
          //           option.push(jval);
          //         }
          //       } else {
          //         option.push(jval);
          //       }
          //     });
          //     qval.options = option
          //   }
          // }
        });
      }
      // let customerId=[];

      if (this.props.cusid) {
        this.setState({ customerId: this.props.cusid, global: false });
      } else if (this.props.match.params.cusid) {
        this.setState({
          customerId: this.props.match.params.cusid,
          global: true
        });
      }
      if (this.props.match.params.cusid && this.props.match.params.bid) {
        this.setState({
          customerId: this.props.match.params.cusid,
          global: false,
          bidviewform: false
        });
      }

      this.setState({ data: formQues.data });

      let interiorJson = [];
      this.state.interiors.map((ival, ind) => {
        interiorJson.push({
          name: null,
          evaluation: null,
          remarks: null,
          estimate: null
        });
      });

      let exteriorJson = [];
      this.state.exterior.map((ival, ind) => {
        exteriorJson.push({
          name: null,
          evaluation: null,
          remarks: null,
          estimate: null
        });
      });
      let interior_ans_Json = [];
      interior_ans_Json.push({
        index: "",
        name: "",
        date: "",
        remarks: "",
        status: "",
        nextfollowup: ""
      });
      await this.setState({ interiorJson, exteriorJson, interior_ans_Json });

      let prospect = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_prosperities",
        `id = ${this.state.customerId}`,
        "id",
        "id"
      );
      // // console.log(prospect,'prospect');
      // let items = await CmsContent.getFreedom(
      //   "*",
      //   "tbl_items",
      //   `ServiceId=10`,
      //   1,
      //   1
      // );
      // if (items) {
      //   this.setState({ items: items.data });
      // }

      let enquiry = await CmsContent.getFreedom(
        "*",
        "tbl_dfcarz_enquiryform",
        `id = ${prospect.data[0].enquiry_id}`,
        "id",
        "id"
      );

      if (enquiry && enquiry.data.length) {

        // console.log(enquiry.data, 'enquiry');
        let val = enquiry.data[0];

        this.setState({ Evaluatorid: val.evaluatorid })
        let form = formQues.data;

        var d = new Date();

        var date = d.getDate();
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var hr = d.getHours();
        var min = d.getMinutes();
        var sec = d.getSeconds();
        if (date < 10) date = "0" + date;
        if (month < 10) month = "0" + month;
        var dateStr = year + "-" + month + "-" + date;
        if (this.state.locopt) {
          this.state.locopt.map((kval, m) => {
            if (kval.value == val.location) {
              this.setState({ locvalue: kval.value })
              // answerJson[307] = kval.label;
            }
          });
        }
        if (val.sources) {
          this.state.sources.map((ival, i) => {
            if (ival.label == val.sources) {
              answerJson[302] = ival;
            }
          });
        }
        if (val.othernewcarmodel) {
          this.setState({ othernewcarmodel: val.othernewcarmodel })
        }
        answerJson[306] = dateStr;
        answerJson[303] = val.name;
        answerJson[304] = val.mobile;
        answerJson[310] = val.email;
        answerJson[318] = val.Km;
        if (val.newcarmake) {
          answerJson[653] = val.newcarmake;
        }
        let get = [];

        this.state.modelopt.map((mval, i) => {
          if (answerJson[653] === mval.label || parseInt(answerJson[653]) === mval.value) {
            get.push(mval);
          }
        });


        form.map((ival, k) => {
          if (ival.id == 319) {
            ival.options = get;
          }
        });
        this.state.NewCarModel.map((ival, i) => {
          if (answerJson[653] == ival.label || parseInt(answerJson[653]) == ival.value) {
            answerJson[653] = ival
          }
        })
        if (val.newcarmodel) {
          answerJson[319] = val.newcarmodel
        }

        this.state.modelopt.map((jval, j) => {
          if (answerJson[319] == jval.value || answerJson[319] === jval.label) {
            answerJson[319] = jval
          }
        })
        if (val.newcarmodel) {
          answerJson[311] = this.state.Typesoftrans[0]
        } else {
          answerJson[311] = this.state.Typesoftrans[1]
        }
        this.state.makeYearOption.map((ival, j) => {
          if (ival.label == val.yom) {
            answerJson[316] = ival;
          }
        });
        if (val.fuel) {
          this.state.fuel.map((ival, i) => {
            if (ival.label.toUpperCase() == val.fuel.toUpperCase()) {
              answerJson[525] = ival;
            }
          });
        }
        //answerJson[316] = val.yom;
        answerJson[322] = this.state.Insurance[0]
        answerJson[313] = val.Reg_no;

        answerJson[323] = val.Insurance_expiry;
        this.state.ownershipOpt.map((kval, n) => {
          if (kval.label == val.owner_type) {
            answerJson[321] = kval;
          }
        });

        var d = new Date();
        var date = d.getDate();
        var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
        var year = d.getFullYear();
        var hr = d.getHours();
        var min = d.getMinutes();
        var sec = d.getSeconds();
        if (date < 10) date = "0" + date;
        if (month < 10) month = "0" + month;
        var dateStr = year + "-" + month + "-" + date;
        answerJson[306] = dateStr;

        this.state.modelopt.map((mval, k) => {
          if (mval.value == val.Model) {
            answerJson[315] = mval;
          }
        });
        if (this.state.makeopt) {
          this.state.makeopt.map((jval, n) => {
            if (jval.value == val.Make) {
              answerJson[314] = jval;
            }
          });
        }
        this.state.colopt.map((cval, j) => {
          if (cval.value == val.color) {
            answerJson[317] = cval;
          }
        });
        this.state.varopt.map((kval, n) => {
          if (kval.value == val.Variant) {
            answerJson[544] = kval;
          }
        });
        if (this.state.salesopt) {
          this.state.salesopt.map((ival, i) => {
            if (ival.value == val.sales_consultant) {
              answerJson[308] = ival;
            }
          });
        }

        // if (warranty_master) {
        //   warranty_master.map((lval, l) => {
        //     if (lval.model == val.Model && lval.make == val.Make) {
        //       answerJson[346] = lval.warranty;
        //     }
        //   })
        // }
      }


      let fproof = await CmsContent.getFreedom(
        "*",
        "tbl_FProof",
        `customerid = ${this.state.customerId}`,
        "id",
        "id desc"
      );
      let isfillform = true
      if (fproof && fproof.data.length > 0) {
        isfillform = false
        this.setState({ ans: fproof.data });
        this.form_filled();
      } else {
        if (isfillform) {
          let savedData = await this.getSavedData('FPROOF', this.state.customerId)
          if (savedData) {
            answerJson = savedData.data ? savedData.data : answerJson
            interiorJson = savedData.interiorJson ? savedData.interiorJson : interiorJson
            exteriorJson = savedData.exteriorJson ? savedData.exteriorJson : exteriorJson
          }
        }
        console.log(interiorJson);

        await this.setState({ answerJson, interiorJson, exteriorJson })
        // this.leads();
        this.process();

        // this.process2()
      }
      // this.process();
      this.bproof();
      this.NewMakeChanger()


      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.handleSuccess,
          this.handleError
        );
      } else {
        this.setState({ error: 'Geolocation is not supported' });
      }


    } catch (error) {
      console.error(error);
    }
  }

  handleSuccess = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      error: null
    });
  };

  handleError = (error) => {
    this.setState({ error: error.message });
  };

  async bproof() {
    try {
      const formQues = await CmsContent.getFreedom(
        "*",
        "tbl_formquestion",
        "parentid=73",
        "id",
        "id"
      );
      // formQues.data.shift()
      formQues.data.map((ival, i) => {
        if (ival.Orderby == null) {
          ival.Orderby = 1000 + i;
        }
      });

      formQues.data = formQues.data.sort(await CmsContent.compareValues("Orderby", "int", "asc"));

      formQues.data.map((qval, q) => {

        if (qval.id == 630) {
          qval.type = "selectbox";
          qval.options = this.state.yesorno;
        }
        if (qval.id == 207) {
          qval.type = "";
        }
        if (qval.id == 208) {
          qval.type = "";

        }
        if (qval.id == 629) {
          qval.type = "";
        }
        if (qval.id == 630) {
          qval.type = "";

        }
      })


      let interior_ans_Json = [];
      let bodyJson = [];
      this.state.follow.map((ival, i) => {
        bodyJson.push({
          name: null,
          remarks: null
        });
        interior_ans_Json.push({
          index: "",
          name: "",
          date: "",
          remarks: ""
        });
      });
      let bumperJson = [];
      let tyersJson = [];
      this.state.view.map((ival, ind) => {
        bumperJson.push({
          name: "",
          body: "",
          remarks: "",
          evaluation: '',
          estimate: ""
        });
        tyersJson.push({
          name: "",
          rhs: "",
          lhs: "",
          remarks: "",
          estimate: ""
        });
      });

      let proJson = [];
      this.state.pro.map((ival, i) => {
        proJson.push({ name: "", body: "", remarks: "", estimate: "" });
      });
      let lightJson = [];
      this.state.lights.map((ival, i) => {
        lightJson.push({ name: "", rhs: "", lhs: "", body: '', remarks: "", estimate: "" });
      });
      let wheeklJson = [];
      this.state.wheel.map((ival, i) => {
        wheeklJson.push({ name: "", body: "", remarks: "", estimate: "" });
      });

      let followJson1 = [];
      this.state.followup1.map((ival, i) => {
        followJson1.push({
          date: "",
          status1: "",
          status2: "",
          status3: "",
          remarks: "",
          follow: "",
        });
      });
      await this.setState({
        data1: formQues.data,
        interior_ans_Json,
        bumperJson,
        proJson,
        tyersJson,
        lightJson,
        wheeklJson,
        // followJson1
      });
      let result = await CmsContent.getFreedom(
        "*",
        "tbl_BProof",
        `customerid = ${this.state.customerId}`
      );

      let isfillform1 = true
      if (result && result.data.length > 0) {
        isfillform1 = false
        await this.setState({ ans1: result.data });

        this.form_filled1();
      } else {
        if (isfillform1) {
          let savedData = await this.getSavedData('BPROOF', this.state.customerId);
          let savedData2 = await this.getSavedData('FPROOF', this.state.customerId);
          if (savedData) {
            let answerJson1 = savedData.data ? savedData.data : this.state.answerJson1
            followJson1 = savedData.followJson1 ? savedData.followJson1 : followJson1
            let insurance_val = savedData.insurance_val ? savedData.insurance_val : this.state.insurance_val
            let rcval = savedData.rcval ? savedData.rcval : this.state.rcval
            this.setState({ answerJson1, followJson1, insurance_val, rcval })
          }
          if (savedData2) {

            bumperJson = savedData2.bumperJson ? savedData2.bumperJson : bumperJson
            proJson = savedData2.proJson ? savedData2.proJson : proJson
            tyersJson = savedData2.tyersJson ? savedData2.tyersJson : tyersJson
            lightJson = savedData2.lightJson ? savedData2.lightJson : lightJson
            wheeklJson = savedData2.wheeklJson ? savedData2.wheeklJson : wheeklJson
            let windJson = savedData2.windJson ? savedData2.windJson : this.state.windJson
            await this.setState({ bumperJson, proJson, tyersJson, wheeklJson, lightJson, windJson })
          }
        }
        this.process1();
      }
      this.setState({ isfillform1 })
    } catch (error) {
      console.error(error);
    }

  }

  form_filled1 = async () => {
    let ans = this.state.ans1[0];
    let answerJson = this.state.answerJson1;
    let bodyJson = this.state.bodyJson;
    let lightJson = this.state.lightJson;
    let bumperJson = this.state.bumperJson;
    let tyersJson = this.state.tyersJson;
    let wheeklJson = this.state.wheeklJson;
    let windJson = this.state.windJson;
    let interiorJson = this.state.interiorJson;
    let proJson = this.state.proJson;
    let interior_ans_Json = this.state.interior_ans_Json;
    let followJson1 = this.state.followJson1;
    let fileJson2 = this.state.fileJson2;
    let fileJson3 = this.state.fileJson3;
    let fileJson11 = this.state.fileJson11;
    let filesArray11 = this.state.filesArray11;
    let filesArray2 = this.state.filesArray2;
    let filesArray3 = this.state.filesArray3;
    let bproofid = ans.id;
    let rcval = this.state.rcval;
    let insurance_val = this.state.insurance_val;
    // answerJson.id = ans.id;

    answerJson[207] = "";

    answerJson[208] = ans.carriage;

    if (ans.flood && ans.flood != 'undefined') {
      answerJson[630] = JSON.parse(ans.flood);
    }

    if (ans.tampered && ans.tampered != 'undefined') {
      answerJson[629] = JSON.parse(ans.tampered);
    }

    if (ans.body && ans.body != 'undefined') {
      bodyJson = JSON.parse(ans.body);
    }

    if (ans.lights && ans.lights != 'undefined') {
      lightJson = JSON.parse(ans.lights);
    }

    bumperJson = JSON.parse(ans.bumper);
    tyersJson = JSON.parse(ans.tyres);
    wheeklJson = JSON.parse(ans.wheels);
    proJson = JSON.parse(ans.protection);
    windJson = JSON.parse(ans.windscreen);

    if (ans.rc_name) {
      this.state.rcoptions.map((ival) => {
        if (ival.label == ans.rc_name) {
          rcval = ival
        }
      })
    }
    if (ans.policy_name) {

      this.state.insurance_opn.map((val, i) => {
        if (val.label == ans.policy_name) {
          insurance_val = val
        }
      })
    }

    if (ans.follow) {
      let follow = JSON.parse(ans.follow);
      // console.log(follow, 'folloe')
      followJson1[0].remarks = follow.length ? follow[0].remarks : ''
      followJson1[0].date = follow.length ? follow[0].date : ''
      followJson1[0].follow = follow.length ? follow[0].follow : ''
    }

    if (ans.policy_file) {
      this.imgFun('policy_img1', ans)

    }

    if (ans.rc_file && ans.rc_file !== "undefined") {
      this.imgFun('rc_img1', ans)

    }

    if (ans.bumperfiles) {

      this.imgFun('bump', ans)

    }

    if (ans.lightfiles) {
      this.imgFun('light', ans)

    }

    if (ans.tyrefiles) {
      this.imgFun('tyre', ans)

    }

    await this.setState({
      answerJson1: answerJson,
      interior_ans_Json,
      interiorJson,
      bodyJson,
      fileJson3,
      fileJson2,
      fileJson11,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      windJson,
      proJson,
      followJson1,
      bproofid,
      accidentans: ans.accident,
      rcval,
      insurance_val,
      oldtyrefiles: ans.tyrefiles ? JSON.parse(ans.tyrefiles) : null,
      oldlightfiles: ans.lightfiles ? JSON.parse(ans.lightfiles) : null,
      oldbumperfiles: ans.bumperfiles ? JSON.parse(ans.bumperfiles) : null,
      oldrc_file: ans.rc_file ? JSON.parse(ans.rc_file) : null,
      oldpolicy_file: ans.policy_file ? JSON.parse(ans.policy_file) : null,
      button: "Update"
    });
    //    console.log(...this.state,'st');
    this.process1();
    this.bump("start")
    this.lights("start")
    this.windfun("start")
    this.wheeelfun("start")
    this.tyers("start")
  };

  async answers1(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    let answerJson = await this.state.answerJson1;
    const { isfillform1 } = this.state
    console.log(label, questionId, type, 'ans1');
    if (type == "text") {
      answerJson[questionId] = label.toUpperCase();
    } else if (type == "date") {
      var d = new Date(label);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      answerJson[questionId] = dateStr;
    } else if (type == "textarea") {
      answerJson[questionId] = label.toUpperCase();
    } else if (type == "selectbox") {
      answerJson[questionId] = label;

    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        if (checkBox.checked == true) {
          check.push(ival);
        }
      });
      answerJson[questionId] = check.toString();
    }
    if (isfillform1) { await this.formSave("BPROOF", this.state.customerId, answerJson) }
    await this.setState({ answerJson1: answerJson });

    this.process1();
  }
  process1 = async () => {
    let data = await this.state.data1;
    // console.log(data, "data")
    if (data) {
      let followJson = await this.state.followJson1;
      // console.log(followJson, 'VmK');
      let arr = data;
      let contentJson = [];
      let followjsonbproof = [];
      if (arr && arr.length) {

        let answerJson = await this.state.answerJson1;

        let json1 = await arr.map(async (item, index) => {
          if (item.id == 312) {
            item.type = ""
          }
          //console.log(item.id,'itemid');
          if (item.type == "text") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={e =>
                        this.answers1(
                          e.target.value,
                          index,
                          null,
                          null,
                          "text",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}
                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "date") {
            // console.log(item, item.id);
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      min={item.id == 715 && `${this.state.dateofregyear}-01-01`}
                      onChange={e =>
                        this.answers1(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}

                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "month") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position">{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="month"
                      id="myDate"
                      className="form-control"
                      onChange={e =>
                        this.answers1(
                          e.target.value,
                          index,
                          null,
                          null,
                          "date",
                          item.id,
                          item.parentid
                        )
                      }
                      value={answerJson[item.id]}

                    />
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "textarea") {
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      onBlur={e =>
                        this.answers1(
                          e.target.value,
                          index,
                          null,
                          null,
                          "textarea",
                          item.id,
                          item.parentid
                        )
                      }
                      defaultValue={answerJson[item.id]}
                      // value={answerJson[item.id]}
                      style={{ resize: 'none', width: '100%', textTransform: 'uppercase' }}
                    ></textarea>
                  </div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          if (item.type == "radio") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name={`element${index}`}
                      // value={`${element}`}
                      id={`inlineCheckbox${key}`}
                      onChange={e =>
                        this.answers1(
                          element,
                          key,
                          null,
                          key,
                          "radio",
                          item.id,
                          item.parentid
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${key}`}
                      style={{ marginLeft: "10px" }}
                    >
                      {`${element}`}{" "}
                    </label>
                  </div>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {arr1}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "selectbox") {

            let option = [];
            if (item.options != null) {
              if (item.options) {
                item.options.map((ival, i) => {
                  option.push(ival);
                });
              }
            }

            this.state.selectbox1 = answerJson[item.id];
            //  console.log(item);
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={option}
                      placeholder={item.placeholder}
                      handleChange={e => {
                        this.answers1(
                          e,
                          index,
                          null,
                          null,
                          "selectbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      selectedService={this.state.selectbox1}
                    />
                  </div>
                  <span className="error-shows" id={`${index}`}>
                    {/* {this.state.error[index]} */}
                  </span>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );
          }
          if (item.type == "checkbox") {
            let arr1 = [];
            await item.options.split(",").map((element, key) => {
              arr1.push(
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}${key}`}
                    onChange={e => {
                      this.answers1(
                        element,
                        index,
                        item.options.split(","),
                        key,
                        "checkbox",
                        item.id,
                        item.parentid
                      );
                    }}
                    value={element}
                  />
                  <label
                    className="form-check-label"
                    for={`inlineCheckbox${index}${key}`}
                    style={{ marginLeft: "10px" }}
                  >{`${element}`}</label>
                </div>
              );
            });
            await contentJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label>{item.question}</label>
                  </div>
                  <div className="col-sm-5">{arr1}</div>
                  <div className="col-sm-3">
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });

        if (this.state.bidviewform) {

          await followjsonbproof.push(
            <div className="bgcolor">
              <div class="set-form">
                <table id="myTable" class="table table-bordered">
                  <tr>
                    <th>VEHICLE CLASSIFICATION</th>
                    <td>
                      <SingleSelect
                        placeholder="Enter Condition"
                        options={this.state.vconditionopt}
                        handleChange={e => {
                          this.onChanges1(e, 'vcondition')
                        }}
                        selectedService={this.state.vcondition}
                        style={{ resize: "none", width: "100%" }}
                      />
                    </td><td>&nbsp;</td>
                  </tr>
                  {this.state.vcondition && this.state.vcondition.value && (this.state.vcondition.value == "Repair" || this.state.vcondition.value == "Worst")
                    ?
                    <tr>
                      <th>{this.state.vcondition.value == "Repair" ? "Accident" : "Flood"} Information</th>
                      <td>
                        <textarea
                          // name={this.state.vcondition.value == "Bad" ? "Accident Information" : "Flood Information"}
                          className="form-control"
                          onChange={e => {
                            this.setState({ accidentans: e.target.value });
                            this.process1()
                          }}

                          placeholder={this.state.vcondition.value == "Repair" ? "Accident Information" : "Flood Information"}

                          // id="remarks"
                          value={this.state.accidentans}
                        />
                      </td>
                    </tr>
                    : ""
                  }
                  {this.state.followJson1.map((ival, index) => {
                    return (<tbody>
                      <th>CUSTOMER FOLLOWUP STATUS</th>
                      <tr><th style={{ color: 'orange' }}>Status 1 *</th>
                        <td>
                          <SingleSelect
                            placeholder="Enter Status1"
                            options={this.state.status1options}
                            handleChange={e => {
                              this.ans(e, index, "status1")
                            }}
                            selectedService={followJson[index] && followJson[index].status1
                              ? followJson[index].status1
                              : null}
                            style={{ resize: "none", width: "100%" }}
                          />
                        </td><td>&nbsp; </td> </tr>
                      {followJson[index] && followJson[index].status1 && followJson[index].status1.value == 2 ? null : < tr >
                        <th style={{ color: 'orange' }}>Status 2 *</th>
                        <td>
                          <SingleSelect
                            placeholder="Enter Status2"
                            options={this.state.status2options[index]}
                            handleChange={e => {
                              this.ans(e, index, "status2")
                            }
                            }
                            selectedService={followJson[index] && followJson[index].status2
                              ? followJson[index].status2
                              : null}
                            style={{ resize: "none", width: "100%" }}
                          />
                        </td><td>&nbsp; </td>
                      </tr>}
                      {
                        followJson[index].status2 && followJson[index].status2.label == "Lost" || followJson[index].status2 && followJson[index].status2.label == "Non contactable" ? (<tr>
                          <th>Status 3</th>
                          <td>
                            <SingleSelect
                              placeholder="Enter Status3"
                              options={this.state.status3options[index]}
                              handleChange={e => {
                                this.ans(e, index, "status3")
                              }}
                              selectedService={followJson[index] && followJson[index].status3
                                ? followJson[index].status3
                                : null}
                              style={{ resize: "none", width: "100%" }}
                            />
                          </td><td>&nbsp; </td> </tr>
                        ) : null
                      }{
                        followJson[index].status1 && followJson[index].status1.label == "Live" ?
                          (<tr>
                            <th>Follow up date</th>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                min={moment().format("YYYY-MM-DD")}
                                onChange={e => this.ans(e.target.value, index, "follow")}
                                style={{ resize: "none", width: "100%" }}
                                value={
                                  followJson[index] && followJson[index].follow
                                    ? followJson[index].follow
                                    : null
                                }
                              ></input>
                            </td><td>&nbsp; </td></tr>) : null
                      }
                      <tr>
                        <th>REMARKS</th>
                        <td>
                          <div className="suggest-input">
                            <input
                              type="textarea"
                              name="REMARKS"
                              className="form-control "
                              placeholder="Enter remarks"
                              id="remarks"
                              onChange={e => {
                                this.ans(e.target.value.toUpperCase(), index, "remarks");
                              }}
                              value={
                                followJson[index] && followJson[index].remarks
                                  ? followJson[index].remarks
                                  : null
                              }

                              nstyle={{ resize: "none", width: "100%" }}
                            ></input>
                            <div className="history-icon" >
                              <i class="bi bi-clock-history histroyicon" onClick={() => this.turnoffOthers("vehclassRemarks")}></i>
                            </div>
                            <div className={this.state.vehclassRemarks ? "subclass-parent" : "d-none"}>
                              <div className="subclass-bottom mx-2">
                                {this.state.evalStatusRemarks.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={() => this.handleSuggestionSelect2(val.toUpperCase(), index, "remarks", "vehclassRemarks")}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>
                                    {val}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>

                        </td><td>&nbsp; </td></tr>
                    </tbody>);
                  })}
                </table>

              </div >
            </div >
          );
        }

        // await Promise.all(json1);
        if (contentJson.length == 0) {
          contentJson.push(
            <div className="form-check form-check-inline"></div>
          )
        }
        console.log(contentJson, "contentJson");
        await this.setState({ json1: contentJson, followjsonbproof });
      }
    }
  };

  ans = async (label, value, type, index = null) => {
    console.log(label, "label", value, "value", type, "type")
    // return true
    let followJson1 = this.state.followJson1;
    console.log(followJson1, "followJson1");
    followJson1[value][type] = label;
    let status2options = [];
    let status3options = [];
    this.setState({ followJson1 });
    console.log(followJson1);

    if (type == "status1") {
      followJson1[value].status2 = followJson1[value].status2 != "" && ""
      this.setState({ followJson1 })
      if (followJson1[value].status1 && followJson1[value].status1.label == "Live") {
        status2options[value] = [
          //{ label: "Appointment taken", value: 0 },
          { label: "Under Follow up", value: 1 },
          { label: "Ringing no response", value: 2 },
          { label: "Researching", value: 3 },
          { label: "Not attempted", value: 4 }];
        status3options[value] = [{ label: "Negotitation", value: 0 }, { label: " consideration", value: 1 }]
        await this.setState({ status2options, status3options })

      } else if (followJson1[value].status1 && followJson1[value].status1.label == "Closed") {
        status2options[value] = [
          //   { label: " Plan dropped", value: 0 },
          // { label: "Non contactable", value: 1 },
          { label: "Lost", value: 2 },
          { label: 'Dropped', value: 3 }
          // { label: "Wrong capture", value: 3 },
          // { label: "No response", value: 4 },
          // { label: "Huge Price Gap", value: 5 },
        ];
        this.setState({ status2options })
      } else if (followJson1[value].status1 && followJson1[value].status1.label == "Deal Closed") {
        status2options[value] = [
          { label: "Trade in", value: 0 },

        ];
        followJson1[value].status2 = { label: "Trade in", value: 0 }
        await this.setState({ status2options, followJson1 })
      }
    }
    if (type == "status2") {
      if (followJson1[value].status2 && followJson1[value].status2.label == "Lost") {
        status3options[value] = [
          { label: "Competitor", value: 0 },
          { label: "Co Dealer", value: 1 },
          { label: "End Customer", value: 2 },
          { label: "User Car Dealer", value: 3 },
          { label: "Online Aggression", value: 4 },
        ];
        this.setState({ status3options });
      }

    };
    await this.formSave('BPROOF', this.state.customerId, followJson1, 'followJson1')
    this.process1();

  };

  interiorEva1 = async (name, value, index, type) => {
    let interior_ans_Json = this.state.interior_ans_Json;

    interior_ans_Json[index].name = name;
    interior_ans_Json[index][type] = value;

    await this.setState({ interior_ans_Json });
  };
  interiorRem1 = async (name, value, index, type) => {

    let interiorJson = this.state.interiorJson;
    interiorJson[index] = {
      index: index,
      name: name
    };
    interiorJson[index].remarks = value;

    await this.setState({ interiorJson });
  };

  body = (label, value, index, type) => {
    let bodyJson = this.state.bodyJson;
    bodyJson[index] = {
      name: label.label,
      remarks: value
    };
    this.setState({ bodyJson });
  };
  wind = (label, value, type, index = "") => {
    // console.log(label);
    let windJson = this.state.windJson;

    windJson[0].name = label;
    windJson[0][type] = value;

    this.setState({ windJson });
  };
  interiros = async (iname, value, index, type) => {
    let interiorJson = this.state.interiorJson;
    interiorJson[index].name = iname;
    interiorJson[index][type] = value;

    this.setState({ interiorJson });
  };

  lights = async (label, value, index = "", type) => {
    let lightJson = this.state.lightJson;
    let { T_I, T_E, T_B, T_T, T_O, T_O1, T_O2, T_O3, answerJson, body_shop, button } = this.state;


    if (label && label != "start") {
      lightJson[index] = lightJson[index] ? lightJson[index] : { name: '', lhs: '', rhs: '', remarks: '', body: '', estimate: '' }
      lightJson[index].name = label;
      lightJson[index][type] = value;
    }
    let total = 0;
    let wait = lightJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });

    answerJson[344] = total + T_E + T_I + T_B + T_T + T_O1 + T_O2 + T_O3 + body_shop;
    this.state.lights.map((ival, ind) => {
      if (lightJson[ind] && lightJson[ind].body == null) {
        lightJson[ind].body = 'Good'
      }
    })
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, lightJson, 'lightJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ lightJson, answerJson, T_L: total });

    this.process();
  };
  suggescBump = async (label, value, index, type) => {
    let bumperJson = this.state.bumperJson;
    const vals = JSON.parse(localStorage.getItem("evalRemarks")) || []
    vals.push(value)
    console.log(vals);
    if (type === "remarks") {
      this.setState((prevState) => ({
        bumperremarkSuggest: prevState.bumperremarkSuggest.map(item =>
          item.ind === index ? { ...item, value: !item.value } : item
        )
      }));
    }
    if (type === "estimate") {
      this.setState((prevState) => ({
        bumperEstimateSuggest: prevState.bumperEstimateSuggest.map(item =>
          item.ind === index ? { ...item, value: !item.value } : item
        )
      }));
    }
    let { T_I, T_E, T_L, T_T, T_O, T_O1, T_O2, T_O3, answerJson, body_shop, button } = this.state;

    if (label && label != "start") {
      bumperJson[index] = bumperJson[index] ? bumperJson[index] : { name: "", body: "", remarks: "", evaluation: '', estimate: '' }
      bumperJson[index].name = label;
      bumperJson[index][type] = value;
    }
    let total = 0;
    let wait = bumperJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });

    answerJson[344] = total + T_E + T_I + T_L + T_T + T_O1 + T_O2 + T_O3 + body_shop;
    this.state.view.map((ival, ind) => {
      if (bumperJson[ind] && bumperJson[ind].evaluation == null) {
        bumperJson[ind].evaluation = 'Good'
      }
    })
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, bumperJson, 'bumperJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ bumperJson, answerJson, T_B: total });

    this.process();

  }
  bump = async (label, value, index, type) => {
    let bumperJson = this.state.bumperJson;

    let { T_I, T_E, T_L, T_T, T_O, T_O1, T_O2, T_O3, answerJson, body_shop, button } = this.state;

    if (label && label != "start") {
      bumperJson[index] = bumperJson[index] ? bumperJson[index] : { name: "", body: "", remarks: "", evaluation: '', estimate: '' }
      bumperJson[index].name = label;
      bumperJson[index][type] = value;
    }
    let total = 0;
    let wait = bumperJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });

    answerJson[344] = total + T_E + T_I + T_L + T_T + T_O1 + T_O2 + T_O3 + body_shop;
    this.state.view.map((ival, ind) => {
      if (bumperJson[ind] && bumperJson[ind].evaluation == null) {
        bumperJson[ind].evaluation = 'Good'
      }
    })
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, bumperJson, 'bumperJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ bumperJson, answerJson, T_B: total });

    this.process();
  };
  profun = async (label, value, index, type) => {
    let proJson = this.state.proJson;

    let { T_B, T_I, T_E, T_L, T_T, T_O, T_O2, T_O3, answerJson, body_shop, button } = this.state;

    if (label && label != "start") {
      proJson[index].name = label;
      proJson[index][type] = value;
    }
    let total = 0
    let wait = proJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });

    answerJson[344] = total + T_E + T_I + T_L + T_T + T_B + T_O2 + T_O3 + body_shop;
    this.state.pro.map((ival, ind) => {
      if (proJson[ind] && proJson[ind].body == null) {
        proJson[ind].body = 'Good'
      }
    })
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, proJson, 'proJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ proJson, answerJson, T_O1: total })

    this.process();
  };
  tyers = async (label, value, index, type) => {

    let tyersJson = this.state.tyersJson;
    let { T_B, T_I, T_E, T_L, T_T, T_O, T_O1, T_O2, T_O3, answerJson, body_shop, button } = this.state;


    if (label && label != "start") {
      tyersJson[index] = tyersJson[index] ? tyersJson[index] : { name: "", rhs: "", lhs: "", remarks: "", estimate: '' }
      tyersJson[index].name = label;
      tyersJson[index][type] = value;
    }
    let total = 0;
    let wait = tyersJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });
    // await Promise.all(wait);
    answerJson[344] = total + T_E + T_I + T_L + T_B + T_O1 + T_O2 + T_O3 + body_shop;
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, tyersJson, 'tyersJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ tyersJson, answerJson, T_T: total })

    this.process();
  };

  wheeelfun = async (label, value, index, type) => {
    let wheeklJson = this.state.wheeklJson;
    let { T_B, T_I, T_E, T_L, T_T, T_O, T_O1, T_O3, answerJson, body_shop, button } = this.state;
    if (label && label != "start") {
      wheeklJson[index].name = label;
      wheeklJson[index][type] = value
    }
    let total = 0
    let wait = wheeklJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });
    // await Promise.all(wait);
    answerJson[344] = total + T_E + T_I + T_L + T_T + T_B + T_O1 + T_O3 + body_shop;
    // console.log(wheeklJson);
    this.state.wheel.map((ival, ind) => {
      if (wheeklJson[ind] && wheeklJson[ind].body == null) {
        wheeklJson[ind].body = 'Good'
      }
    })
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, wheeklJson, 'wheeklJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ wheeklJson, answerJson, T_O2: total });
    this.process();
  };
  windfun = async (label, value, index, type) => {
    let windJson = this.state.windJson;
    let { T_B, T_I, T_E, T_L, T_T, T_O, T_O1, T_O2, answerJson, body_shop, button } = this.state;
    if (label && label != "start") {
      windJson[index].name = label;
      windJson[index][type] = value;
    }
    let total = 0
    let wait = windJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });
    // await Promise.all(wait);
    answerJson[344] = total + T_E + T_I + T_L + T_T + T_B + T_O1 + T_O2 + body_shop;
    this.state.wind.map((ival, ind) => {
      if (windJson[ind] && windJson[ind].body == null) {
        windJson[ind].body = 'Good'
      }
    })
    // console.log(windJson);
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, windJson, 'windJson')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ windJson, answerJson, T_O3: total });
    this.process();
  };

  exportpdf1 = async () => {
    let answerJson = this.state.bproofid;
    let id = answerJson;
    window.open(`${ACCESS_POINT}/cmsContent/downloadbproof/${id}`, '_blank')
  }
  print = () => {

  }

  fileupload1 = e => {
    let files = this.state.files;
    let filesArray11 = this.state.filesArray11;
    let fileJson11 = [];
    if (e) {
      filesArray11 = e.map(fileItem => fileItem.file)
      // console.log("filesArray11filesArray11filesArray11", filesArray11);
      this.setState({ filesArray11, fileJson11: filesArray11 });
      // console.log(fileSnap);
    }

  };
  fileupload2 = async e => {
    let files = this.state.files;
    let filesArray2 = this.state.filesArray2;
    let fileJson2 = [];
    if (e) {
      filesArray2 = e.map(fileItem => fileItem.file)
      this.setState({ filesArray2, fileJson2: filesArray2 });
      // console.log(fileSnap);
    }

  };
  fileupload3 = async e => {
    let files = this.state.files;
    let filesArray3 = this.state.filesArray3;
    if (e) {
      filesArray3 = e.map(fileItem => fileItem.file)
      this.setState({ filesArray3, fileJson3: filesArray3 });

    }
  };

  update1 = async () => {
    const {
      answerJson1,
      interiorJson,
      bodyJson,
      interior_ans_Json,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson,
      followJson1,
      fileJson11,
      fileJson2,
      fileJson3,
      bproofid,
      rcfileJson,
      insurancefileJson,
      rcval,
      insurance_val
    } = this.state;
    this.setState({ btnDisable: true })
    //let categoryArray = {};
    let answerJson = answerJson1;
    let id = bproofid;
    let ans = this.state.answerJson
    if (!ans[313]) {
      alert("Please Enter Reg No")
      return false
    }
    if (!ans[325]) {
      alert("Please Enter Hp/Lease Field")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status1)) {
      alert("Please Select Status1")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status2)) {
      alert("Please Select Status2")
      return false
    }
    const formData = new FormData();

    formData.append("flood", JSON.stringify(answerJson[630]));
    formData.append("tampered", JSON.stringify(answerJson[629]));
    // formData.append("accident", answerJson[207]);
    formData.append("accident", this.state.accidentans);
    formData.append("carriage", answerJson[208]);
    formData.append("body", JSON.stringify(bodyJson));
    formData.append("windscreen", JSON.stringify(windJson));
    formData.append("bumper", JSON.stringify(bumperJson));
    formData.append("lights", JSON.stringify(lightJson));
    formData.append("protection", JSON.stringify(proJson));
    formData.append("tyres", JSON.stringify(tyersJson));
    formData.append("wheels", JSON.stringify(wheeklJson));
    formData.append("follow", JSON.stringify(followJson1));
    formData.append("customerid", this.state.customerId);
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    if (insurance_val) {
      formData.append("policy_name", insurance_val.label)
    }
    formData.append("policy_file", insurancefileJson)
    formData.append("rc_file", rcfileJson)
    if (rcval) {
      formData.append("rc_name", rcval.label);
    }
    let sta = "active";
    formData.append("status", sta);


    if (fileJson11 && fileJson11.length) {
      fileJson11.map((ival, i) => {
        formData.append(`bumperfile`, ival);
      });
    }

    if (insurancefileJson && insurancefileJson.length) {
      insurancefileJson.map((ival, i) => {
        formData.append("policy_file", ival)
      })
    }
    if (rcfileJson && rcfileJson.length) {
      rcfileJson.map((ival, i) => {
        formData.append("rc_file", ival)
      })
    }
    if (fileJson2 && fileJson2.length) {
      fileJson2.map((jval, j) => {
        formData.append(`lightfile`, jval);
      });
    }
    if (fileJson3 && fileJson3.length) {
      fileJson3.map((jval, j) => {
        formData.append(`tyrefile`, jval);
      });
    }

    if (this.state.oldtyrefiles) formData.append("oldtyrefiles", JSON.stringify(this.state.oldtyrefiles))
    if (this.state.oldlightfiles) formData.append("oldlightfiles", JSON.stringify(this.state.oldlightfiles))
    if (this.state.oldbumperfiles) formData.append("oldbumperfiles", JSON.stringify(this.state.oldbumperfiles))
    if (this.state.oldrc_file) formData.append("oldrc_file", JSON.stringify(this.state.oldrc_file))
    if (this.state.oldpolicy_file) formData.append("oldpolicy_file", JSON.stringify(this.state.oldpolicy_file))



    try {
      let result = await CmsContent.UpdateBProof("tbl_BProof", formData, id);

      if (result) {
        await this.setState({
          btnDisable: false,
          answerJson: "",
          json: "",
          answerJson1: "",
          json1: "",
          alertVisible: true,
          visible: false,
          textalert: "Your form has been updated successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    } catch (err) {
      console.log(err);
      this.setState({ btnDisable: false })
    }
  };
  download1 = (id) => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadbproof/${id}`, '_blank')
  }
  generatepdf1 = async (id) => {
    let exportpdf = await CmsContent.bproof("tbl_BProof", id);
    if (exportpdf.data == "success") {
      setTimeout(() => {
        this.download1(id)
      }, 3000);
    }
  }
  formSubmitPdf1 = async () => {

    const {
      answerJson1,
      interiorJson,
      interior_ans_Json,
      bodyJson,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson,
      fileJson11,
      fileJson2,
      fileJson3,
      followJson1,
      rcfileJson,
      insurancefileJson,
    } = this.state;
    // let categoryArray = {};
    let answerJson = answerJson1;
    const formData = new FormData();
    formData.append("flood", JSON.stringify(answerJson[630]));
    formData.append("tampered", JSON.stringify(answerJson[629]));
    // formData.append("accident", answerJson[207]);
    formData.append("accident", this.state.accidentans);
    formData.append("carriage", answerJson[208]);
    formData.append("body", JSON.stringify(bodyJson));
    formData.append("windscreen", JSON.stringify(windJson));
    formData.append("bumper", JSON.stringify(bumperJson));
    formData.append("lights", JSON.stringify(lightJson));
    formData.append("protection", JSON.stringify(proJson));
    formData.append("tyres", JSON.stringify(tyersJson));
    formData.append("wheels", JSON.stringify(wheeklJson));
    //formData.append("follow", JSON.stringify(followJson1));
    formData.append("customerid", this.state.customerId);
    formData.append("userid", this.state.userid.id);
    let sta = "active";
    formData.append("status", sta);
    formData.append("approval", 0);

    if (fileJson11 && fileJson11.length) {
      fileJson11.map((ival, i) => {
        formData.append(`bumperfile`, ival.file);
      });
    }

    if (insurancefileJson && insurancefileJson.length) {
      insurancefileJson.map((ival, i) => {
        formData.append("policy_file", ival.file)
      })
    }
    if (rcfileJson && rcfileJson.length) {
      rcfileJson.map((ival, i) => {
        formData.append("rc_file", ival.file)
      })
    }
    if (fileJson2 && fileJson2.length) {
      fileJson2.map((jval, j) => {
        formData.append(`lightfile`, jval.file);
      });
    }
    if (fileJson3 && fileJson3.length) {
      fileJson3.map((jval, j) => {
        formData.append(`tyrefile`, jval.file);
      });
    }



    try {
      let result = await CmsContent.UpdateBProof("tbl_BProof", formData);

      if (result) {
        this.generatepdf1(this.state.customerId);
        await this.setState({
          answerJson1: "",
          alertVisible: true,
          json1: "",
          visible: false,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }


    } catch (error) {
      console.log(error);
    }
  };
  formSubmit1 = async () => {
    const {
      answerJson1,
      interiorJson,
      interior_ans_Json,
      bodyJson,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      wheeklJson,
      proJson,
      fileJson11,
      fileJson2,
      fileJson3,
      rcfileJson,
      insurancefileJson,
      rcval,
      insurance_val,
      followJson1
    } = this.state;
    let categoryArray = {};
    let answerJson = answerJson1;
    let ans = this.state.answerJson
    if (!ans[313]) {
      alert("Please Enter Reg No")
      return false
    }
    if (!ans[325]) {
      alert("Please Enter Hp/Lease Field")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status1)) {
      alert("Please Select Status1")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status2)) {
      alert("Please Select Status2")
      return false
    }
    this.setState({ btnDisable: true })
    const formData = new FormData();

    formData.append("flood", JSON.stringify(answerJson[630]));
    formData.append("tampered", JSON.stringify(answerJson[629]));
    // formData.append("accident", answerJson[207]);
    formData.append("accident", this.state.accidentans);

    formData.append("carriage", answerJson[208]);
    formData.append("body", JSON.stringify(bodyJson));
    formData.append("windscreen", JSON.stringify(windJson));
    formData.append("bumper", JSON.stringify(bumperJson));
    formData.append("lights", JSON.stringify(lightJson));
    formData.append("protection", JSON.stringify(proJson));
    formData.append("tyres", JSON.stringify(tyersJson));
    formData.append("wheels", JSON.stringify(wheeklJson));
    formData.append("follow", JSON.stringify(followJson1));
    formData.append("customerid", this.state.customerId);


    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    if (insurance_val) {
      formData.append("policy_name", insurance_val.label);
    }

    if (rcval) {
      formData.append("rc_name", rcval.label)
    }
    let sta = "active";
    formData.append("status", sta);
    formData.append("approval", 0);

    if (fileJson11 && fileJson11.length) {
      fileJson11.map((ival, i) => {
        formData.append(`bumperfile`, ival);
      });
    }

    if (insurancefileJson && insurancefileJson.length) {
      insurancefileJson.map((ival, i) => {
        formData.append("policy_file", ival)
      })
    }
    if (rcfileJson && rcfileJson.length) {
      rcfileJson.map((ival, i) => {
        formData.append("rc_file", ival)
      })
    }
    if (fileJson2 && fileJson2.length) {
      fileJson2.map((jval, j) => {
        formData.append(`lightfile`, jval);
      });
    }
    if (fileJson3 && fileJson3.length) {
      fileJson3.map((jval, j) => {
        formData.append(`tyrefile`, jval);
      });
    }



    try {
      let result = await CmsContent.UpdateBProof("tbl_BProof", formData);

      if (result) {
        await this.RemoveFormData('FPROOF', this.state.customerId)
        await this.RemoveFormData('BPROOF', this.state.customerId)
        await this.setState({
          btnDisable: false,
          answerJson1: "",
          alertVisible: true,
          answerJson: "",
          json: "",
          fileJson: [],
          json1: "",
          visible: false,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => window.location.reload(), 3000);
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }

  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  form_filled = async () => {
    let ans = this.state.ans[0];
    console.log(ans, "ans");

    let body_shop = this.state.body_shop
    let answerJson = this.state.answerJson;
    let interiorJson = this.state.interiorJson;
    let exteriorJson = this.state.exteriorJson;
    let fileJson = this.state.fileJson;
    let filesArray = this.state.filesArray;
    let fileJson1 = this.state.fileJson1;
    let filesArray1 = this.state.filesArray1;
    let warranty_master = this.state.warranty_master;
    let total_panel_repair = this.state.total_panel_repair

    this.setState({ isedit: true });
    answerJson.id = ans.id;
    let CanvasVals = { lhs: [], rhs: [], front: [], back: [], top: [] }
    if (ans.total_panel_repair && ans.total_panel_repair != "") {
      console.log("enter");
      this.state.PanelRepairopt.map((val, i) => {
        if (val.label == ans.total_panel_repair) {
          this.state.PanelRepair = val
        }
      })
    }
    if (ans.bodymap) {
      CanvasVals = await JSON.parse(ans.bodymap)
      await this.setState({ CanvasVals });
    }
    if (ans.source) {
      this.state.sources.map((ival, i) => {
        if (ival.label == ans.source) {
          answerJson[302] = ival;
        }
      });
    }
    if (ans.duplicatekey) {
      this.state.yesorno.map((ival, i) => {
        if (ival.label.toUpperCase() == ans.duplicatekey.toUpperCase()) {
          answerJson[633] = ival;
        }
      });
    }

    if (ans.status1) {

      let followJson1 = this.state.followJson1;
      this.state.status1options.map((ival, i) => {
        if (ival.label == ans.status1) {
          this.ans(ival, 0, "status1")
        }
      })
    }

    if (ans.status2) {
      let followJson1 = this.state.followJson1;
      this.state.status2options[0] && this.state.status2options[0].map((ival, i) => {

        if (ival.label == ans.status2) {

          this.ans(ival, 0, "status2")
        }
      })

    }


    answerJson[304] = ans.mobile;
    answerJson[305] = ans.alter_mob;
    if (ans.othernewcarmodel) {
      this.setState({ othernewcarmodel: ans.othernewcarmodel })
    }
    if (ans.entry_date != null) {
      let date = ans.entry_date.split(" ")[0];
      answerJson[306] = date;
    }
    //answerJson[306] = ans.entry_date;
    answerJson[307] = ans.address;
    if (this.state.salesopt) {
      this.state.salesopt.map((ival, i) => {
        if (ival.label == ans.saleCons_name) {
          answerJson[308] = ival;
        }
      });
    }

    if (ans.year_of_manu) {
      let dateofregyear = ans.YOR
      await this.setState({ dateofregyear })
    }
    if (ans.YOR) {
      answerJson[715] = ans.YOR;
    }
    if (ans.MOM) {
      let dateofregmon = ans.MOM


      this.state.monthOptions.map((ival, i) => {
        if (ival.value == ans.MOM) {
          answerJson[714] = ival;
        }
      });
      await this.setState({ dateofregmon })

    }
    if (ans.VCondition) {
      let { vcondition } = this.state;
      this.state.vconditionopt.map((val) => {
        if (val.value == ans.VCondition) {
          vcondition = val
        }
      })
      this.setState({ vcondition })
    }

    if (ans.pincode) {

      answerJson[605] = ans.pincode;
    }
    answerJson[310] = ans.email;

    if (ans.closer_date && ans.closer_date != null) {
      let date = ans.closer_date.split(" ")[0];
      answerJson[312] = date;
    }

    answerJson[313] = ans.reg_no;
    if (this.state.makeopt) {
      this.state.makeopt.map((mval, k) => {
        if (mval.value == ans.make) {
          answerJson[314] = mval;
        }
      });
    }

    if (this.state.modelopt) {
      this.state.modelopt.map((jval, i) => {
        if (jval.value == ans.model) {
          answerJson[315] = jval;
        }
      });
    }
    if (this.state.NewCarModel && ans.new_car_make) {
      this.state.NewCarModel.map((ival) => {
        if (ival.label == ans.new_car_make) {
          answerJson[653] = ival
        }
      })
    }
    if (this.state.modelopt) {
      this.state.modelopt.map((jval, i) => {
        if (jval.label == ans.new_car_model) {
          answerJson[319] = jval;
        }
      });
    }
    if (this.state.varopt) {
      this.state.varopt.map((jval, i) => {
        if (jval.value == ans.variant) {
          answerJson[544] = jval;
        }
      });
    }

    if (this.state.colopt) {
      this.state.colopt.map((cval, j) => {
        if (cval.value == ans.color) {
          answerJson[317] = cval;
        }
      });
    }
    this.state.makeYearOption.map((kval, v) => {
      if (kval.label == ans.year_of_manu) {
        answerJson[316] = kval;
      }
    });
    state.map((jval, v) => {
      if (jval.label == ans.state) {
        answerJson[532] = jval;
      }
    });
    state_city.map((ival, n) => {
      if (ival.label == ans.city) {
        answerJson[533] = ival;
      }
    });

    if (ans.ownership_no) {
      this.state.ownershipOpt.map((kval, n) => {
        if (kval.label == ans.ownership_no) {
          answerJson[321] = kval;
        }
      });
    }


    if (ans.insurance) {
      this.state.Insurance.map((ival, i) => {
        if (ans.insurance == ival.label) {
          answerJson[322] = ival;


        }
      });
    }


    answerJson[323] = ans.ins_validity;

    if (ans.tax_paid) {
      this.state.taxpaid.map((ival, i) => {
        if (ans.tax_paid == ival.label) {
          answerJson[324] = ival;
        }
      });
    }
    answerJson[326] = ans.chassis_no;
    if (ans.hp_lease) {
      this.state.HP.map((ival, i) => {
        if (ival.label == ans.hp_lease) {
          answerJson[325] = ival;
        }
      });
    }

    answerJson[320] = ans.engine_no;

    this.state.bank.map((ival, n) => {
      if (ival.label == ans.finance_name) {
        answerJson[534] = ival;
      }
    });


    if (ans.interiors) {
      interiorJson = JSON.parse(ans.interiors);
    }
    if (ans.exteriors) {
      exteriorJson = JSON.parse(ans.exteriors);
    }

    if (ans.Files) {
      let images = JSON.parse(ans.Files);

      let img = { LHS: [], RHS: [], FRONT: [], BACK: [] };
      if (images != null) {
        Object.keys(images).map((key) => {
          images[key].map((ival, i) => {

            let wait = img[key].push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(key, i, 'vehicleImg', 'oldFiles')}></img>
                </div>
                <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                  }}
                  onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              </div>
            )
          })
        })


        this.setState({ vehicleImg: img })
      }
    }
    if (ans.IntFiles) {
      let images = JSON.parse(ans.IntFiles);
      let img = { ifleft: [], ifright: [], ibleft: [], ibright: [], dashborad: [], meter: [], engine: [] }
      if (images != null) {
        Object.keys(images).map((key) => {
          images[key].map((ival, i) => {
            let wait = img[key].push(
              <div>
                <div style={{ paddingLeft: '160px', position: 'relative' }} >
                  <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                    onClick={() => this.removeimg(key, i, 'vehicleINTImg', 'oldIntFiles')}></img>
                </div>
                <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                  style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                  }}
                  onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
              </div>
            )
          })
        })


        this.setState({ vehicleINTImg: img })
      }
    }


    let { badinteriorfiles, badinteriorImages, badexteriorfiles, badexteriorImages, inter, exter } = this.state;
    if (ans.interfile) {
      let arrayofile = []
      let samp = JSON.parse(ans.interfile);
      arrayofile.push(samp)
      arrayofile[0].map((ival, i) => {
        if (ival.length) {
          ival.map((jval, j) => {
            if (jval) {
              badinteriorImages[i] = badinteriorImages[i] ? badinteriorImages[i] : []
              let uri = ACCESS_POINT + "/superAdmin/file?fileurl=" + jval
              // console.log("interuri,", uri);
              badinteriorImages[i].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(i, j, 'badinteriorImages', 'oldinterfile')}></img>
                  </div>
                  <img src={uri} style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                  }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + jval) }} />
                </div>
              );
              Promise.all(badinteriorImages[i])
            }
          })
        }
        Promise.all(arrayofile[0])
      })
      this.setState({ badinteriorImages })
      // console.log("badinteriorImages", this.state.badinteriorImages);
    }
    // let urllinkinterios = []

    if (ans.exterfile) {
      let arrayofile = []
      let samp = JSON.parse(ans.exterfile);
      arrayofile.push(samp)
      arrayofile[0].map((ival, i) => {
        if (ival.length) {

          ival.map((jval, j) => {
            if (jval) {

              badexteriorImages[i] = badexteriorImages[i] ? badexteriorImages[i] : []
              let uri = ACCESS_POINT + "/superAdmin/file?fileurl=" + jval;

              badexteriorImages[i].push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(i, j, 'badexteriorImages', 'oldexterfile')}></img>
                  </div>
                  <img src={uri} style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                  }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + jval) }} />
                </div>
              );
              Promise.all(badexteriorImages[i])
            }
          })
        }
        Promise.all(arrayofile[0])
      })
      this.setState({ badexteriorImages })

    }
    if (ans.chassis_no_file) {
      let images = JSON.parse(ans.chassis_no_file);
      let img = []
      if (images) {
        images.map((ival, i) => {
          let wait = img.push(
            <div>
              <div style={{ paddingLeft: '160px', position: 'relative' }} >
                <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                  onClick={() => this.removeimg(ival, i, 'chassisImage', 'Chassis_File', "single")}></img>
              </div>
              <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
            </div>
          )
        })
        this.setState({ chassisImage: img })
      }
    }
    if (ans.engine_no_file) {
      let images = JSON.parse(ans.engine_no_file);
      let img = []
      if (images) {
        images.map((ival, i) => {
          let wait = img.push(
            <div>
              <div style={{ paddingLeft: '160px', position: 'relative' }} >
                <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                  onClick={() => this.removeimg(ival, i, 'engineImage', 'Engine_File', "single")}></img>
              </div>
              <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                style={{
                  width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                }}
                onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
            </div>
          )
        })
        this.setState({ engineImage: img })
      }
    }
    if (ans.body_shop && ans.body_shop != 'undefined') {
      body_shop = parseInt(ans.body_shop);
    }
    if (ans.std_sell_price && ans.std_sell_price != 'undefined') {
      answerJson[343] = ans.std_sell_price;
    }
    if (ans.recondition_est && ans.recondition_est != 'undefined') {
      answerJson[344] = ans.recondition_est;
    }
    if (ans.margin && ans.margin != 'undefined') {
      answerJson[345] = ans.margin;
    }

    // if (answerJson[314] && answerJson[314].value && parseInt(answerJson[314].value) == 9 && ans.warranty_pool != 'undefined') {
    //   answerJson[346] = ans.warranty_pool;
    // } 

    if (ans.gst && ans.gst != 'undefined') {
      answerJson[347] = ans.gst;
    }
    if (ans.exchange_bonus && ans.exchange_bonus != 'undefined') {
      answerJson[348] = ans.exchange_bonus;
    }
    if (ans.body_shop && ans.body_shop != 'undefined') {
      answerJson[825] = ans.body_shop;
    }
    if (ans.isWarranty == 1) {
      this.setState({ radio: true })
      answerJson[346] = ans.warranty_pool ? ans.warranty_pool : null;
    }
    if (ans.trade_in_price && ans.trade_in_price != 'undefined') {
      answerJson[349] = ans.trade_in_price;
    }
    answerJson[327] = ans.smoke;
    answerJson[328] = ans.Engine_oil;
    answerJson[329] = ans.Missing;
    answerJson[330] = ans.Noise;
    answerJson[331] = ans.overheating;
    answerJson[332] = ans.pickup_power;
    answerJson[333] = ans.Radiator;
    answerJson[334] = ans.terms;
    answerJson[335] = ans.soot_deposit;
    answerJson[337] = ans.Shifting;
    answerJson[338] = ans.axles;
    answerJson[339] = ans.suspension;
    answerJson[340] = ans.steering;
    answerJson[341] = ans.clutch;
    answerJson[342] = ans.brake;
    answerJson[626] = ans.bankname;
    if (ans.fueloption) {
      this.state.fuel.map((ival, i) => {
        if (ival.label == ans.fueloption) {
          answerJson[525] = ival;
        }
      });
    }
    if (ans.Mt_cvt) {
      this.state.Mt.map((ival, i) => {
        if (ival.label == ans.Mt_cvt) {
          answerJson[336] = ival;
        }
      });
    }


    if (ans.transaction) {
      this.state.Typesoftrans.map((ival, b) => {
        if (ival.label == ans.transaction) {

          answerJson[311] = ival


        }
      });
    }

    await this.setState({
      finalprice: ans.finalprice ? ans.finalprice : "",
      answerJson,
      interiorJson,
      body_shop,
      exteriorJson,
      fileJson,
      fileJson1,
      oldexterfile: ans.exterfile ? JSON.parse(ans.exterfile) : null,
      oldinterfile: ans.interfile ? JSON.parse(ans.interfile) : null,
      oldIntFiles: ans.IntFiles ? JSON.parse(ans.IntFiles) : null,
      oldFiles: ans.Files ? JSON.parse(ans.Files) : null,
      Chassis_File: ans.chassis_no_file ? JSON.parse(ans.chassis_no_file) : null,
      Engine_File: ans.engine_no_file ? JSON.parse(ans.engine_no_file) : null,
      button: "Update"
    });
    // this.leads();
    this.process();
    // this.process2();
    this.checkinterior("start")
    this.checkexterior("start")

  };

  removeimg = async (key, i, section, file, type = 'multi') => {
    let { badexteriorImages, oldexterfile } = this.state;
    let images = null
    let img = null

    images = this.state[section] //images field
    img = this.state[file] //old file field
    if (type == 'multi') {
      if (images && img) {
        // console.log(images[key], key, images, i);
        images[key] && images[key].splice(i, 1)
        img[key] && img[key].splice(i, 1)
        await this.setState({ [section]: images, [file]: img })
        // this.imgFun(section)
        this.process()
      }
    } else if (type == 'single') {
      if (images && img) {
        console.log(images[key], key, images, i);
        images && images.splice(i, 1)
        img && img.splice(i, 1)
        await this.setState({ [section]: images, [file]: img })
        this.imgFun(section)
        this.process()

      }
    }

  }
  imgFun = async (section, ans = null) => {
    let { badexteriorImages, oldexterfile } = this.state;
    if (section == 'policy_img1') {
      let policy = ans ? JSON.parse(ans.policy_file) : this.state.oldpolicy_file;
      let img = []
      if (policy != null) {
        policy.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'policy_img1', 'oldpolicy_file', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a} style={{
                    width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                  }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            } else {
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'policy_img1', 'oldpolicy_file', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            }
          }
        })



        this.setState({ policy_img1: img })

      }

    }
    if (section == 'rc_img1') {
      let rc = ans ? JSON.parse(ans.rc_file) : this.state.oldrc_file;
      let img = []
      if (rc != null) {
        rc.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'rc_img1', 'oldrc_file', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            } else {
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'rc_img1', 'oldrc_file', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            }
          }
        })

        this.setState({ rc_img1: img })

      }

    }

    if (section == 'bump') {
      let images = ans ? JSON.parse(ans.bumperfiles) : this.state.oldbumperfiles;
      let img = []
      if (images != null) {
        images.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'bump', 'oldbumperfiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            } else {
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'bump', 'oldbumperfiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            }
          }
        })
        this.setState({ bump: img, filledbumper: true })
      }
    }


    if (section == 'light') {
      let images = ans ? JSON.parse(ans.lightfiles) : this.state.oldlightfiles;
      let img = []
      if (images != null) {
        images.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'light', 'oldlightfiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            } else {
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'light', 'oldlightfiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            }
          }
        })
        this.setState({ light: img, filledlight: true })
      }
    }
    if (section == 'tyre') {
      let images = ans ? JSON.parse(ans.tyrefiles) : this.state.oldtyrefiles;
      let img = []
      if (images != null) {
        images.map((ival, i) => {
          if (ival) {
            let spliting = ival.split(".");
            if (['pdf', 'doc', 'xlsx', 'csv'].includes(spliting[1])) {
              let a = null
              a = "false/pdfIconTrans_1599478202382.png";
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'tyre', 'oldtyrefiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + a}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            } else {
              let wait = img.push(
                <div>
                  <div style={{ paddingLeft: '160px', position: 'relative' }} >
                    <img src={iconClose} style={{ height: 39, width: 40, position: 'absolute', borderRadius: '70px', backgroundColor: 'black' }}
                      onClick={() => this.removeimg(ival, i, 'tyre', 'oldtyrefiles', 'single')}></img>
                  </div>
                  <img src={ACCESS_POINT + "/superAdmin/file?fileurl=" + ival}
                    style={{
                      width: "200px", height: "200px", display: "inline-flex", padding: "10px"
                    }}
                    onClick={() => { window.open(ACCESS_POINT + "/superAdmin/file?fileurl=" + ival) }} />
                </div>
              )
            }
          }
        })
        this.setState({ tyre: img, filledtyre: true })
      }
    }
  }

  async answers(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    console.log(label, index, options = null, key = null, type, questionId, formid);
    const { makeopt, modelopt, data, warranty_master, button, CountRepair } = this.state;
    console.log(label, 'labe ans')
    let answerJson = this.state.answerJson;

    if (index === "PanelRepair") {
      let NoPanelRepair = label.value * 2500
      // console.log(NoPanelRepair,"NoPanelRepair");
      await this.setState({ PanelRepair: label, CountRepair: NoPanelRepair })

      let { T_I, T_E, T_L, T_B, T_T, T_O, T_O1, T_O2, T_O3, body_shop } = this.state;

      answerJson[825] = NoPanelRepair
      answerJson[344] = NoPanelRepair + T_E + T_I + T_B + T_L + T_T + T_O1 + T_O2 + T_O3;

      answerJson[345] = answerJson[343] / 10
      let gst = (answerJson[345] / 1.12) * 12 / 100
      answerJson[347] = gst.toFixed(2)


      let A = answerJson[343] ? parseInt(answerJson[343]) : 0;
      let B = answerJson[345] ? parseInt(answerJson[345]) : 0;
      let C = answerJson[346] ? parseInt(answerJson[346]) : 0;
      let D = answerJson[347] ? parseInt(answerJson[347]) : 0;
      let E = answerJson[348] ? parseInt(answerJson[348]) : 0;
      let G = answerJson[344] ? parseInt(answerJson[344]) : 0;
      let F = answerJson[825] ? parseInt(answerJson[825]) : 0;

      let total = A - B - C - D - G - F + E;
      answerJson[349] = total.toString();
    }

    let get = [];
    // let modelOpt = []
    if (questionId == 714 && answerJson && answerJson[316]) {
      let dateofregmon = ""
      dateofregmon = label.value
      await this.setState({ dateofregmon })
    }


    if (questionId && questionId == 316) {
      let dateofregyear = ""
      // console.log("label", label.label);
      dateofregyear = label.label
      await this.setState({ dateofregyear })
      if (answerJson[315]) answerJson[315] = null;
      if (answerJson[544]) answerJson[544] = null;

      if (answerJson && answerJson[314]) {
        modelopt.map((mval, i) => {
          if (mval.makeid == parseInt(answerJson[314].value)) {
            get.push(mval);
          }
        });
        if (get.length && questionId == 316) {
          const manfuc = this.state.makeYearOption.filter(e => e.value == parseInt(label.value))[0];
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }
        data.map((ival, k) => {
          if (ival.id == 315) {
            ival.options = get;
          }
        });

      }
    }

    if (questionId == 314) {

      if (answerJson[315]) answerJson[315] = null;
      if (answerJson[544]) answerJson[544] = null;
      modelopt.map((mval, i) => {
        if (mval.makeid == parseInt(label.value)) {
          get.push(mval);
        }
      });

      if (get.length) {
        if (answerJson[316]) {
          const manfuc = this.state.makeYearOption.filter(e => e.value == answerJson[316].value)[0];
          get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
        }
      }

      data.map((ival, k) => {
        if (ival.id == 315) {
          ival.options = get;
        }
      });

    }
    if (questionId == 315) {
      if (answerJson[544]) answerJson[544] = null;
      this.state.varopt.map((mval, i) => {
        if (mval.modelid == label.value) {
          get.push(mval);
        }
      });
      data.map((ival, k) => {
        if (ival.id == 544) {
          ival.options = get;
        }
      });
    }
    if (questionId == 653) {
      let newget = this.state.newmodelopt.filter(x => x.makeid == label.value)
      // this.state.newmodelopt.map((mval, i) => {
      //   if (mval.makeid == label.value) {
      //     get.push(mval);
      //   }
      // });
      // let newget = get && get.length ? get.filter(e => e.syear <= moment().format('YYYY') && e.eyear >= moment().format('YYYY')) : []
      console.log(newget, "newget");
      data.map((ival, k) => {
        if (ival.id == 319) {
          ival.options = newget;
        }
      });
    }

    if (questionId == 532) {
      let city = [];
      state_city.map((jval, j) => {
        if (jval.state === label.label) {
          city.push(jval);
        }
      });
      data.map((ival, i) => {
        if (ival.id == 533) {
          ival.options = city;
        }
      });
    }
    //finance
    if (questionId == 534) {
      if (label.value != 170) {

        data.map((ival, k) => {
          if (ival.id == 626) {
            ival.type = '';
          }
        });
      } else {
        data.map((ival, k) => {
          if (ival.id == 626) {
            ival.type = 'text';
          }
        });
      }
    }



    if (type == "text" || type == "json") {

      answerJson[questionId] = label;
      if (questionId == 825) {
        let total = 0
        if (!label) {
          total = total + 0
        }
        else {
          total = total + parseInt(label)
        }
        let { T_I, T_E, T_L, T_B, T_T, T_O, T_O1, T_O2, T_O3, body_shop } = this.state;

        answerJson[344] = total + T_E + T_I + T_B + T_L + T_T + T_O1 + T_O2 + T_O3;
        answerJson[825] = total
      }

      if (
        questionId == 343 ||
        questionId == 344 ||
        questionId == 345 ||
        questionId == 346 ||
        questionId == 347 ||
        questionId == 348 ||
        questionId == 825
      ) {
        // 343 or 345
        answerJson[345] = answerJson[343] / 10
        let gst = (answerJson[345] / 1.12) * 12 / 100
        answerJson[347] = gst.toFixed(2)


        let A = answerJson[343] ? parseInt(answerJson[343]) : 0;
        let B = answerJson[345] ? parseInt(answerJson[345]) : 0;
        let C = answerJson[346] ? parseInt(answerJson[346]) : 0;
        let D = answerJson[347] ? parseInt(answerJson[347]) : 0;
        let E = answerJson[348] ? parseInt(answerJson[348]) : 0;
        let G = answerJson[344] ? parseInt(answerJson[344]) : 0;
        let F = answerJson[825] ? parseInt(answerJson[825]) : 0;

        let total = A - B - C - D - G - F + E;
        answerJson[349] = total.toString();
        // if (total && total < this.state.price) {
        //   let sum = this.state.price - total;
        //   if (sum >= 30000) {
        //     this.setState({ PrizeRaised: "Need to be approved by Teamleader" });
        //   } else {
        //     this.setState({ PrizeRaised: "" });
        //   }
        // }

      } else if (questionId == 349) {
        answerJson[questionId] = label;
      }

    } else if (type == "date") {
      var d = new Date(label);

      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label.toUpperCase();
    } else if (type == "selectbox") {
      answerJson[questionId] = label;
      // if (questionId == 314) {
      //   answerJson[questionId] = label;

      //   if (label.value != 9) {
      //     answerJson[346] = 4000;
      //   } else {
      //     answerJson[346] = "";
      //   }

      // }


    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
        // if (checkBox.checked == true) {
        //   check.push(ival);
        // }
      });
      // answerJson[questionId] = check.toString();
      answerJson[questionId] = label;
    }

    // if (answerJson[315] || answerJson[314]) {
    //   if (warranty_master && answerJson[315] && answerJson[314]) {
    //     warranty_master.map((lval, l) => {
    //       if (lval.model == answerJson[315].value && lval.make == answerJson[314].value) {

    //         answerJson[346] = lval.warranty;
    //       }
    //     })
    //   }
    // }
    if (button == 'Save') await this.formSave('FPROOF', this.state.customerId, answerJson)
    await this.setState({ answerJson, data });


    // await this.leads();
    await this.process();

  }
  NewMakeChanger = async (e = null) => {
    let { salesopt, userid, usertype, answerJson } = this.state
    let salesopt_filtered = []
    let usertypelog = usertype
    if (!e) { if (answerJson[653]) { e = answerJson[653] } }
    if (e && e.label) {
      if (e.label === "OTHERS") {
        this.setState({ newmake: e, others: true, salesopt_filtered: salesopt, })
      }
      else {
        this.setState({ others: false });
        if (usertypelog.orderBy == 1) {

          salesopt_filtered = salesopt.filter(x => x.brandid == e.value && userid.locationid == x.location)
        }
        else if (usertypelog.orderBy == 2) {
          salesopt_filtered = salesopt.filter(x => x.brandid == e.value && userid.id == x.manager)
        }
        else {
          salesopt_filtered = salesopt.filter(x => x.brandid == e.value && userid.locationid == x.location && userid.sub_loc_id == x.branch)
        }
        console.log(salesopt_filtered, "salesopt_filtered");
        if (salesopt_filtered.length) {
          this.setState({ salesopt_filtered, salesname: "" });
        } else {
          this.setState({ salesopt_filtered: [], salesname: "" });
        }
      }
    }
    console.log(salesopt_filtered, "salesopt_filtered");
  };
  async answers2(
    label,
    index,
    options = null,
    key = null,
    type,
    questionId,
    formid
  ) {
    const { makeopt, modelopt, data, warranty_master, button } = this.state;
    console.log(label, questionId, type, 'labe ans')

    let get = [];
    // let modelOpt = []
    let answerJson = this.state.answerJson;
    if (questionId == 714 && answerJson && answerJson[316]) {
      let dateofregmon = ""
      dateofregmon = label.value
      await this.setState({ dateofregmon })
    }


    if (questionId && questionId == 316) {
      let dateofregyear = ""
      // console.log("label", label.label);
      dateofregyear = label.label
      console.log(dateofregyear, "dateofregyear")
      await this.setState({ dateofregyear })
      if (answerJson[315]) answerJson[315] = null;
      if (answerJson[544]) answerJson[544] = null;

      if (answerJson && answerJson[314]) {
        modelopt.map((mval, i) => {
          if (mval.makeid == parseInt(answerJson[314].value)) {
            get.push(mval);
          }
        });
        if (get.length && questionId == 316) {
          const manfuc = this.state.makeYearOption.filter(e => e.value == parseInt(label.value))[0];
          if (manfuc && manfuc != "undefined") {
            get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
          } else {
            get = []
          }
        }
        data.map((ival, k) => {
          if (ival.id == 315) {
            ival.options = get;
          }
        });

      }
    }

    if (questionId == 314) {

      if (answerJson[315]) answerJson[315] = null;
      if (answerJson[544]) answerJson[544] = null;
      modelopt.map((mval, i) => {
        if (mval.makeid == parseInt(label.value)) {
          get.push(mval);
        }
      });

      if (get.length) {
        if (answerJson[316]) {
          const manfuc = this.state.makeYearOption.filter(e => e.value == answerJson[316].value)[0];
          get = get.filter(e => e.syear <= manfuc.label && e.eyear >= manfuc.label)
        }
      }

      data.map((ival, k) => {
        if (ival.id == 315) {
          ival.options = get;
        }
      });

    }
    if (questionId == 315) {
      if (answerJson[544]) answerJson[544] = null;
      this.state.varopt.map((mval, i) => {
        if (mval.modelid == label.value) {
          get.push(mval);
        }
      });
      data.map((ival, k) => {
        if (ival.id == 544) {
          ival.options = get;
        }
      });
    }
    if (questionId == 653) {
      let newget = this.state.newmodelopt.filter(x => x.makeid == label.value)
      console.log(newget, "newget");
      answerJson[319] = ""
      answerJson[308] = ""
      if (label.label === "OTHERS") { this.setState({ othernewcarmodel: "" }) }
      this.NewMakeChanger(label)

      data.map((ival, k) => {
        if (ival.id == 319) {
          ival.options = newget;
        }
      });

    }

    if (questionId == 532) {
      let city = [];
      state_city.map((jval, j) => {
        if (jval.state === label.label) {
          city.push(jval);
        }
      });
      data.map((ival, i) => {
        if (ival.id == 533) {
          ival.options = city;
        }
      });
    }
    //finance
    if (questionId == 534) {
      if (label.value != 170) {

        data.map((ival, k) => {
          if (ival.id == 626) {
            ival.type = '';
          }
        });
      } else {
        data.map((ival, k) => {
          if (ival.id == 626) {
            ival.type = 'text';
          }
        });
      }
    }



    if (type == "text" || type == "json") {

      answerJson[questionId] = label;


    } else if (type == "date") {
      var d = new Date(label);

      answerJson[questionId] = label;
    } else if (type == "textarea") {
      answerJson[questionId] = label.toUpperCase();
    } else if (type == "selectbox") {
      answerJson[questionId] = label;



    } else if (type == "radio") {
      answerJson[questionId] = label;
    } else if (type == "checkbox") {
      let check = [];
      options.map((ival, i) => {
        var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);

      });

      answerJson[questionId] = label;
    }
    if (button == 'Save') {
      await this.formSave('FPROOF', this.state.customerId, answerJson)
    }

    await this.setState({ answerJson, data });



    // await this.process2();

  }
  // leads = async () => {

  //   const { locvalue, answerJson } = this.state
  //   if (answerJson[314] && answerJson[315] && locvalue) {
  //     // console.log("ans1", answerJson[315], answerJson[314], locvalue);	
  //     let res = await CmsContent.getFreedom("*", `tbl_dfcarz_prosperities`, `make=${answerJson[314].value}  and model=${answerJson[315].value} and location=${locvalue}`, 1, 1)
  //     if (res) {
  //       //console.log("res", res.data);   
  //       this.setState({ leadsavailable: res.data });
  //     }

  //   }

  // }
  radio = (val, index, id, parentid) => {
    if (val == false) {
      this.answers(0,
        index,
        null,
        null,
        "json",
        id,
        parentid)
    }
    this.setState({ radio: val })
    this.process()
  }
  process = async () => {
    console.log(this.state.wheelEstimateSuggestoptions);

    let data = await this.state.data;
    const innerWidth = window.innerWidth
    var {
      interiorJson,
      exteriorJson,
      followJson,
      bidviewform,
      json1
    } = this.state;
    let { dateofregmon, dateofregyear } = this.state
    const dateofmanu = String(dateofregyear) + "-" + String(dateofregmon) + "-" + "01"
    // console.log(dateofregmon, dateofregyear, "dateofmanu");
    if (data) {
      let arr = data;
      let contentJson = [];
      let CalculateJson = []
      let json3 = []
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;


        this.state.interiors.map((ival, ind) => {
          if (interiorJson[ind] && interiorJson[ind].evaluation == null) {
            interiorJson[ind].evaluation = 'Good'
          }
        })

        await json3.push(
          <div className="bgcolor">
            <div class="set-form">
              <div className="">
                {innerWidth > 768 ? <div className="row">
                  <div className="col-sm-3">INTERIORS</div>
                  <div className="col-sm-3">EVALUATION</div>
                  <div className="col-sm-3">REMARKS</div>
                  <div className="col-sm-3">ESTIMATE</div>
                </div> : <div className="row">INTERIORS :</div>}
                <br />
                {this.state.interiors.map((ival, ind) => {
                  return (
                    <div >
                      <div className="row">
                        <div className="col-sm-3">
                          <p>{ival}{interiorJson[ind] && interiorJson[ind].evaluation &&
                            (interiorJson[ind].evaluation != "Good") ? <button className="btn btn-default btn-sm" value={this.state[ival]} onClick={e => { this.checkEval(e, `${ival}`) }}>
                            <MDBIcon icon="camera" size="lg" style={{ opacity: this.state[ival] ? 1 : 0.5 }} />
                          </button> : null}</p>
                        </div>
                        <div className="col-sm-3">
                          <SingleSelect
                            options={this.state.interiorOption}
                            onChange={e =>
                              this.checkinterior(ival, e.label, ind, "evaluation")
                            }
                            selectedService={interiorJson[ind] && interiorJson[ind].evaluation ?
                              this.state.interiorOption.filter(e => e.label == interiorJson[ind].evaluation)[0] : this.state.option[0]}
                          />
                        </div><br />
                        {interiorJson[ind] && interiorJson[ind].evaluation &&
                          interiorJson[ind].evaluation == "Good" && innerWidth > 768 ? (
                          <div className="col-sm-3">
                            <textarea
                              name="REMARKS"
                              className="form-control suggest-input"
                              placeholder="REMARKS"
                              id="remarks"
                              onChange={e => {
                                this.checkinterior(
                                  ival,
                                  e.target.value.toUpperCase(), ind,
                                  "remarks"
                                );
                              }}
                              disabled={true}
                              value={
                                interiorJson[ind] && interiorJson[ind].remarks
                                  ? interiorJson[ind].remarks
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></textarea>
                            {/* <div className="mic-icon-3">
                              <p
                                id={`para-${ind}`}
                                className={`bi bi-mic-fill ${!(interiorJson[ind] && interiorJson[ind].evaluation &&
                                  interiorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={() => this.voiceSetup("start", ind)}>
                              </p>
                            </div> */}
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(interiorJson[ind] && interiorJson[ind].evaluation &&
                                  interiorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffRemarks(e, ind, "interior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.interiorremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.interiorRemarkSuggestoptions.slice(0, -1).map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "interior");
                                    }}
                                    key={i}
                                  >
                                    <i className="bi bi-arrow-ri ght-short arrow-icon"></i> {val}
                                  </p>
                                ))}

                              </div>
                            </div>
                          </div>
                        ) : (interiorJson[ind] && interiorJson[ind].evaluation &&
                          interiorJson[ind].evaluation != "Good") ? (
                          <div className="col-sm-3">
                            <textarea
                              name="REMARKS"
                              className="form-control suggest-input"
                              placeholder="REMARKS"
                              id={`remarks-${ind}`}
                              onChange={e => {
                                this.checkinterior(
                                  ival,
                                  e.target.value.toUpperCase(), ind,
                                  "remarks"
                                );
                              }}
                              defaultValue={
                                interiorJson[ind] && interiorJson[ind].remarks
                                  ? interiorJson[ind].remarks
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></textarea>
                            <div className="mic-icon-3">
                              <p
                                id={`remarks-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("remarks", ival, ind, "remarks")}>
                              </p>
                            </div>
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(interiorJson[ind] && interiorJson[ind].evaluation &&
                                  interiorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffRemarks(e, ind, "interior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.interiorremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.interiorRemarkSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "interior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {interiorJson[ind] && interiorJson[ind].evaluation &&
                          interiorJson[ind].evaluation == "Good" && innerWidth > 768 ? (
                          <div className="col-sm-3">
                            <input
                              type="number"
                              name="Estimate"
                              placeholder="ESTIMATE"
                              className="form-control"
                              id="Estimate"
                              onChange={e => {
                                this.checkinterior(
                                  ival,
                                  e.target.value,
                                  ind,
                                  "estimate"
                                );
                              }}
                              disabled={true}
                              value={
                                interiorJson[ind] && interiorJson[ind].estimate
                                  ? interiorJson[ind].estimate
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></input>

                          </div>
                        ) : (interiorJson[ind] && interiorJson[ind].evaluation &&
                          interiorJson[ind].evaluation != "Good") ? (
                          <div className="col-sm-3">
                            <input
                              type="number"
                              name="Estimate"
                              placeholder="ESTIMATE"
                              id={`estimate-${ind}`}
                              className="form-control"
                              onChange={e => {
                                this.checkinterior(
                                  ival,
                                  e.target.value,
                                  ind,
                                  "estimate"
                                );
                              }}
                              value={
                                interiorJson[ind] && interiorJson[ind].estimate
                                  ? interiorJson[ind].estimate
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></input>
                            <div className="mic-icon-3">
                              <p
                                id={`estimate-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("estimate", ival, ind, "estimate")}>
                              </p>
                            </div>
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${!(interiorJson[ind] && interiorJson[ind].body && interiorJson[ind].body !== 'Good') ? '' : 'd-none'}`}
                                onClick={(e) => this.turnOffEstimate(e, ind, "interior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.interiorEstimatesuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.interiorEstimateSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "estimate", "interior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <br />
                      {this.state[ival] ? <div className="row">
                        <div className="col-sm-2" />
                        <div className="col-sm-2"> File upload :</div>
                        <div className="col-sm-8" >
                          <Camera
                            image={(e) => this.webcam(e, ind, 'badinteriorfiles', ival)}
                            width={400}
                            height={590}
                            id={ival}
                          />
                        </div>
                      </div>
                        :
                        interiorJson[ind] && interiorJson[ind].evaluation &&
                          (interiorJson[ind].evaluation != "Good") || this.state.badinteriorfiles[ind] ? (
                          <div className="row">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">File upload :</div>
                            <div className="col-sm-5" >
                              <FilePond
                                files={this.state.badinteriorfiles[ind]}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                                //  maxFileSize={'3072KB'}
                                // imageResizeMode="cover"
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.interiorfileupload(fileItems, ind);
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label> &nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>
                          </div>) : null}
                      <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col">
                          {this.state.badinteriorImages[ind] && interiorJson[ind] && interiorJson[ind].evaluation &&
                            interiorJson[ind].evaluation != "Good" ? this.state.badinteriorImages[ind] : null}


                        </div>
                      </div>
                    </div>

                  );
                })}
              </div>
            </div>
          </div >

        );
        this.state.exterior.map((ival, ind) => {
          if (exteriorJson[ind] && exteriorJson[ind].evaluation == null) {
            exteriorJson[ind].evaluation = 'Good'
          }
        })
        await json3.push(
          <div className="bgcolor">
            <div class="set-form">
              <div class="">
                {innerWidth > 768 ? <div class="row">
                  <div class="col-sm-3">EXTERIOR</div>
                  <div class="col-sm-3">EVALUATION</div>
                  <div class="col-sm-3">REMARKS</div>
                  <div class="col-sm-3"> ESTIMATE</div>
                </div> : <div class="row">EXTERIOR :</div>}
                <br />
                {this.state.exterior.map((ival, ind) => {
                  return (
                    <>
                      <div class="row">
                        <div class="col-sm-3">
                          <p>{ival}{exteriorJson[ind] && exteriorJson[ind].evaluation &&
                            (exteriorJson[ind].evaluation != "Good") ? <button className="btn btn-default btn-sm" value={this.state[ival]} onClick={e => { this.checkEval(e, `${ival}`) }}>
                            <MDBIcon icon="camera" size="lg" style={{ opacity: this.state[ival] ? 1 : 0.5 }} />
                          </button> : null}</p>
                        </div>
                        <div class="col-sm-3">
                          <SingleSelect
                            options={this.state.exteriorOption}
                            onChange={e => this.checkexterior(ival, e.label, ind, "evaluation")}
                            selectedService={exteriorJson && exteriorJson[ind] &&
                              exteriorJson[ind].evaluation ? this.state.exteriorOption.filter(e => e.label == exteriorJson[ind].evaluation)[0] : this.state.option[0]}
                          />

                        </div>
                        {exteriorJson[ind] && exteriorJson[ind].evaluation &&
                          exteriorJson[ind].evaluation == "Good" && innerWidth > 768 ? (
                          <div class="col-sm-3">
                            <textarea
                              name="REMARKS"
                              className="form-control"
                              placeholder="REMARKS"
                              id={`remarks-${ind}`}
                              onChange={e => {
                                this.checkexterior(
                                  ival,
                                  e.target.value.toUpperCase(), ind,
                                  "remarks"
                                );
                              }}
                              disabled={true}
                              value={
                                exteriorJson[ind] && exteriorJson[ind].remarks
                                  ? exteriorJson[ind].remarks
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></textarea>
                            {/* <div className="mic-icon-3">
                              <p
                                id={`remarks-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("remarks", ival, ind, "remarks")}>
                              </p>
                            </div> */}
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(exteriorJson[ind] && exteriorJson[ind].evaluation &&
                                  exteriorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffRemarks(e, ind, "exterior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.exteriorremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.exteriorRemarkSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "exterior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (exteriorJson[ind] && exteriorJson[ind].evaluation &&
                          exteriorJson[ind].evaluation != "Good") ? (
                          <div class="col-sm-3">
                            <textarea
                              name="REMARKS"
                              placeholder="REMARKS"
                              id={`remarks-ext-${ind}`}
                              className="form-control"
                              // onChange={e => {
                              //   this.checkexterior(
                              //     ival,
                              //     e.target.value.toUpperCase(),
                              //     ind,
                              //     "remarks"
                              //   );
                              // }}
                              onBlur={e => {
                                this.checkexterior(
                                  ival,
                                  e.target.value.toUpperCase(),
                                  ind,
                                  "remarks"
                                );
                              }}
                              defaultValue={
                                exteriorJson[ind] && exteriorJson[ind].remarks
                                  ? exteriorJson[ind].remarks
                                  : null
                              }
                              // value={
                              //   exteriorJson[ind] && exteriorJson[ind].remarks
                              //     ? exteriorJson[ind].remarks
                              //     : null
                              // }
                              style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                            ></textarea>
                            <div className="mic-icon-3">
                              <p
                                id={`remarks-ext-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("remarks-ext", ival, ind, "remarks")}>
                              </p>
                            </div>
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(exteriorJson[ind] && exteriorJson[ind].evaluation &&
                                  exteriorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffRemarks(e, ind, "exterior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.exteriorremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.exteriorRemarkSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "exterior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {exteriorJson[ind] && exteriorJson[ind].evaluation &&
                          exteriorJson[ind].evaluation == "Good" && innerWidth > 768 ? (
                          <div class="col-sm-3">
                            <input
                              type="number"
                              name="Estimate"
                              placeholder="ESTIMATE"
                              className="form-control"
                              id={`estimate-ext-${ind}`}
                              onChange={e => {
                                this.checkexterior(
                                  ival,
                                  e.target.value,
                                  ind,
                                  "estimate"
                                );
                              }}
                              disabled={true}
                              value={
                                exteriorJson[ind] && exteriorJson[ind].estimate
                                  ? exteriorJson[ind].estimate
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></input>
                            {/* <div className="mic-icon-3">
                              <p
                                id={`estimate-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("estimate", ival, ind, "estimate")}>
                              </p>
                            </div> */}
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(exteriorJson[ind] && exteriorJson[ind].evaluation &&
                                  exteriorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffEstimate(e, ind, "exterior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.exteriorEstimatesuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.exteriorEstimateSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "estimate", "exterior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (exteriorJson[ind] && exteriorJson[ind].evaluation &&
                          exteriorJson[ind].evaluation != "Good") ? (
                          <div class="col-sm-3">
                            <input
                              type="number"
                              name="Estimate"
                              placeholder="ESTIMATE"
                              id={`estimate-ext-${ind}`}
                              className="form-control"
                              onChange={e => {
                                this.checkexterior(
                                  ival,
                                  e.target.value,
                                  ind,
                                  "estimate"
                                );
                              }}
                              value={
                                exteriorJson[ind] && exteriorJson[ind].estimate
                                  ? exteriorJson[ind].estimate
                                  : null
                              }
                              style={{ resize: "none", width: "100%" }}
                            ></input>
                            <div className="mic-icon-3">
                              <p
                                id={`estimate-ext-para${ind}`}
                                className={`bi bi-mic-fill `}
                                onClick={() => this.voiceSetup2("estimate-ext", ival, ind, "estimate")}>
                              </p>
                            </div>
                            <div className="history-icon1" >
                              <i
                                className={`bi bi-clock-history histroyicon ${(exteriorJson[ind] && exteriorJson[ind].evaluation &&
                                  exteriorJson[ind].evaluation == "Good") ? 'd-none' : ''}`}
                                onClick={(e) => this.turnOffEstimate(e, ind, "exterior")}
                              ></i>
                            </div>
                            <div className={` subclass-parent ${this.state.exteriorEstimatesuggest[ind]?.value ? '' : 'd-none'}`}>
                              <div className="subclass mx-2">
                                {this.state.exteriorEstimateSuggestoptions.map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={(e) => {
                                      this.handleSuggestionSelect(ival, val, ind, "estimate", "exterior");
                                    }}
                                    key={i}
                                  >
                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                  </p>

                                ))}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <br />
                      {
                        this.state[ival] ? <div class="row">
                          <div className="col-sm-2" />
                          <div className="col-sm-2"> File upload :</div>
                          <div className="col-sm-5" >
                            <Camera
                              image={(e) => { this.webcam(e, ind, 'badexteriorfiles', ival) }}
                              width={400}
                              height={590}
                              id={ival}
                            /></div>
                        </div> : exteriorJson[ind] && exteriorJson[ind].evaluation &&
                          (exteriorJson[ind].evaluation != "Good") || this.state.badexteriorfiles[ind] ? (
                          <div class="row">
                            <div className="col-sm-2" />
                            <div className="col-sm-2"> File upload :</div>
                            <div className="col-sm-5" >
                              <FilePond
                                files={this.state.badexteriorfiles[ind]}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                                //  maxFileSize={'3072KB'}
                                // imageResizeMode="cover"
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.exteriorfileupload(fileItems, ind);
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>&nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div><div class="col"></div></div>) : null
                      }
                      <div class="row">
                        <div className="col-sm-4"></div>
                        <div class="col">
                          {this.state.badexteriorImages[ind] && exteriorJson[ind] && exteriorJson[ind].evaluation &&
                            exteriorJson[ind].evaluation != "Good" ? this.state.badexteriorImages[ind] : null}
                        </div>
                      </div>

                    </>);
                })}
              </div>
            </div>
          </div>);


        let contentJson2 = []
        // var mv = answerJson[314] ? answerJson[314].label : null;
        // var mo = answerJson[315] ? answerJson[315].label : null;
        // var my = answerJson[316] ? answerJson[316].label : null;
        // if (mv && mo && my) {
        //   let valdata = this.state.oldcardata.data.filter(
        //     mk => (mk.make == mv && (mk.model == mo && mk.year == my)))
        //   CalculateJson.push(valdata && valdata.length > 0 ?
        //     <div className="bgcolor">
        //       <div className="row form-group">
        //         {
        //           <Datatable
        //             data={valdata}
        //             columnHeading={this.state.carlist1} />
        //         }
        //       </div> </div> : null)
        // }
        let json = await arr.map(async (item, index) => {
          if (item.type == "json" && this.state.bidviewform) {

            await CalculateJson.push(
              <div className="bgcolor">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="position" style={{ marginTop: item.id == 346 ? 10 : null, color: item.id == 343 ? "orange" : "" }}>{item.question}</label>
                  </div>
                  <div className="col-sm-5">
                    {item.id == 346 ?
                      <div>
                        <div className="col-sm-6" >
                          <input type="radio" style={{ margin: "12px" }} name='radio' value={this.state.radio} checked={this.state.radio} onChange={() => this.radio(true, index, item.id, item.parentid)} /> Yes
                          <input type="radio" style={{ margin: "7px", marginLeft: "15px" }} name='radio' value={!this.state.radio} checked={!this.state.radio} onChange={() => this.radio(false, index, item.id, item.parentid)} /> No
                        </div>
                        {this.state.radio && <input
                          type="number"
                          className="form-control"
                          placeholder={item.placeholder}
                          style={
                            item.id == 349 && this.state.PrizeRaised != ""
                              ? {
                                border: "1px solid red",
                                boxShadow: " 0 0 0 0.2rem rgb(255 0 35 / 25%)"
                              }
                              : { borderColor: "#80bdff" }
                          }

                          onChange={e =>
                            this.answers(
                              e.target.value,
                              index,
                              null,
                              null,
                              "json",
                              item.id,
                              item.parentid
                            )
                          }
                          value={answerJson[item.id]}
                        />}
                      </div>
                      : <div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={item.placeholder}
                          style={
                            item.id == 349 && this.state.PrizeRaised != ""
                              ? {
                                border: "1px solid red",
                                boxShadow: " 0 0 0 0.2rem rgb(255 0 35 / 25%)"
                              }
                              : { borderColor: "#80bdff" }
                          }
                          onChange={e =>
                            this.answers(
                              e.target.value,
                              index,
                              null,
                              null,
                              "json",
                              item.id,
                              item.parentid
                            )
                          }
                          // disabled={item.id == 344 || item.id == 349 ? true : false}
                          value={answerJson[item.id]}
                        />

                        {["EXCHANGE", "STANDARD", "BODY"].includes(item.question.split(' ')[0]) && (
                          <div><div className="history-icon">
                            <i
                              className="bi bi-clock-history histroyicon"
                              onClick={() => this.turnoffOthers(item.question.split(' ')[0])}
                            ></i>
                          </div>
                            <div className={this.state[item.question.split(' ')[0]] ? "subclass-parent" : "d-none"}>
                              <div className="subclass mx-2">


                                {(item.question.split(' ')[0] === "EXCHANGE" ? this.state.exchange_bonus :
                                  item.question.split(' ')[0] === "STANDARD" ? this.state.standard_selling_price :
                                    this.state.evalotherEstimates
                                ).map((val, i) => (
                                  <p
                                    className="paragg"
                                    onClick={() =>
                                      this.handleSuggestionSelect3(
                                        val,
                                        index,
                                        null,
                                        null,
                                        "json",
                                        item.id,
                                        item.parentid,
                                        item.question.split(' ')[0]
                                      )
                                    }
                                    key={i}
                                  >
                                    <i className="bi bi-arrow-ri ght-short arrow-icon"></i>
                                    {val}
                                  </p>
                                ))}
                              </div>
                            </div></div>
                        )}

                      </div>

                    }
                    {/* {item.id == 349 ?
                      <button className="btn btn-primary" style={{ marginTop: "10px" }} onClick={this.calc}>
                        Calculate</button> : null} */}
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                      {item.id == 349 && this.state.PrizeRaised != "" ? (
                        <span>
                          <i class="fa fa-exclamation-triangle"></i>
                          {this.state.PrizeRaised}
                        </span>
                      ) : null}
                    </span>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
              </div>
            );

          }
        });

        await Promise.all(json)
        await this.setState({ json3, CalculateJson });

      }
    }
  };
  process2 = async () => {
    console.log("process 2")
    let data = await this.state.data;
    const innerWidth = window.innerWidth
    var {
      bidviewform
    } = this.state;
    let { dateofregmon, dateofregyear } = this.state
    const dateofmanu = String(dateofregyear) + "-" + String(dateofregmon) + "-" + "01"
    console.log(dateofmanu, "dateofmanu")
    if (data) {
      let arr = data;
      let contentJson = [];
      let CalculateJson = []
      let json3 = []
      if (arr && arr.length) {
        let answerJson = await this.state.answerJson;


        let json = await arr.map(async (item, index) => {

          if (bidviewform) {
            // if (item.id == 638 || item.id == 639 || item.id == 306 || item.id == 605) {
            //   item.type = "";
            // }
            // if (item.id == 312) {
            //   item.type = ""
            // }
            if (item.type == "text") {

              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}
                        {item.id == 313 && <button className="btn btn-default btn-sm" onClick={() => this.onregchange("Isreg", !this.state.Isreg)} >
                          <MDBIcon icon="camera" size="lg" />
                        </button>}

                      </label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type={item && item.id == 304 || item.id == 318 || item.id == 305 ? "number" : "text"}
                        className="form-control"
                        placeholder={item.placeholder}
                        onChange={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "text",
                            item.id,
                            item.parentid
                          )
                        }
                        value={answerJson[item.id]}
                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                  {item.id == 313 && this.state.Isreg && <div className="row">
                    <div className="col-sm-2" />
                    <div className="col">
                      <Imagereader Imagereader={this.Imagereader} />
                    </div>
                  </div>}
                </div>
              );
            }
            if (item.id == 306) {

              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        id="myDate"
                        className="form-control"
                        // onChange={e =>
                        //   this.answers2(
                        //     e.target.value,
                        //     index,
                        //     null,
                        //     null,
                        //     "date",
                        //     item.id,
                        //     item.parentid
                        //   )
                        // }
                        // value={this.state.date}
                        value={answerJson[item.id]}
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            }
            if (item.type == "date") {
              console.log(item.id, dateofmanu, "id", "date")
              console.log(item.question, "ques");
              this.state.date = answerJson[item.id];
              if (answerJson[322]) {
                if (answerJson[322].label == "Expired" && item.id == 323) {
                  return false
                }
              }

              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="date"
                        id="myDate"
                        // minDate={ival.id == 715 ? dateofmanu : mindate}
                        min={item.id == 715 ? `${this.state.dateofregyear}-01-01` : answerJson[item.id]}
                        // min={moment().format("YYYY-MM-DD")}
                        className="form-control"
                        onChange={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid,
                          )
                        }
                        // value={this.state.date}
                        value={answerJson[item.id]}
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            }
            // }
            if (item.type == "month") {
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="month"
                        id="myDate"
                        className="form-control"
                        onChange={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid
                          )
                        }
                        value={answerJson[item.id]}
                      // onChange={this.fromDateSelect}
                      // value={selectedstartdate}
                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            }
            if (item.type == "textarea") {
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}
                        {item.id == 307 &&
                          <button className="btn btn-default btn-sm" onClick={() => this.onregchange("Islocation", true)} >
                            <MDBIcon icon="location-arrow" size="lg" />
                          </button>}</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea
                        type="text"
                        className="form-control"
                        rows="5"
                        placeholder={item.placeholder}
                        onChange={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "textarea",
                            item.id,
                            item.parentid
                          )
                        }
                        value={answerJson && answerJson[item.id] ? answerJson[item.id] : ""}
                      ></textarea>
                    </div>
                    <div className="col-sm-3">
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                  </div>
                  {
                    item.id == 307 && this.state.Islocation && <div className="row">
                      <div className="col-sm-2" />
                      <div className="col">
                        <Geolocationfun location={true} address={this.addressget} />
                      </div>
                    </div>
                  }
                </div >
              );
            }
            if (item.type == "radio") {
              let arr1 = [];
              await item.options.split(",").map((element, key) => {
                arr1.push(
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name={`element${index}`}
                        // value={`${element}`}
                        id={`inlineCheckbox${index}`}
                        onChange={e =>
                          this.answers2(
                            element,
                            key,
                            null,
                            key,
                            "radio",
                            item.id,
                            item.parentid
                          )
                        }
                        checked={element == answerJson[item.id]}
                      />
                      <label
                        className="form-check-label"
                        for={`inlineCheckbox${key}`}
                        style={{ marginLeft: "10px" }}
                      >
                        {`${element}`}{" "}
                      </label>
                    </div>
                  </div>
                );
              });
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      {arr1}
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );
            }
            if (item.type == "selectbox") {
              let option = [];

              if (answerJson[311]) {

                if ((item.id == 319 || item.id == 308 || item.id == 653) && answerJson[311].value == "2") {
                  return false
                }
              }
              // if (item.options) {
              //   item.options.map((jval, j) => {
              //     if (715 == item.id) {
              //       if (answerJson[316] && jval.label >= answerJson[316].label) {
              //         option.push(jval);
              //       }
              //     } else {
              //       option.push(jval);
              //     }
              //   });
              // }
              // if (item.options) {
              //   item.options.map((jval, j) => {
              //     if (715 == item.id) {
              //       if (answerJson[714] && jval.label >= answerJson[714].label) {
              //         option.push(jval);
              //       }
              //     } else {
              //       option.push(jval);
              //     }
              //   });
              // }
              this.state.selectbox = answerJson[item.id];
              // item.options.split(",").map((ival, i) => {
              //   option.push({ label: ival, value: i });
              // });
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        options={item.options && Array.isArray(item.options) ? item.options : []}
                        placeholder={item.placeholder}

                        handleChange={e => {
                          this.answers2(
                            e,
                            index,
                            null,
                            null,
                            "selectbox",
                            item.id,
                            item.parentid
                          );
                        }}
                        selectedService={this.state.selectbox ? this.state.selectbox : null}
                      />
                    </div>
                    <span className="error-shows" id={`${index}`}>
                      {/* {this.state.error[index]} */}
                    </span>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
              );

            }
            if (item.type == "checkbox") {
              let arr1 = [];
              await item.options.split(",").map((element, key) => {
                arr1.push(
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`inlineCheckbox${index}${key}`}
                      onChange={e => {
                        this.answers2(
                          element,
                          index,
                          item.options.split(","),
                          key,
                          "checkbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      checked={element == answerJson[item.id]}
                    // checked={answerJson && answerJson[item.id] ? element == answerJson[item.id][key]}
                    // value={element}
                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${index}${key}`}
                      style={{ marginLeft: "10px" }}
                    >{`${element}`}</label>
                  </div>
                );
              });
              await contentJson.push(
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}</label>
                    </div>
                    <div className="col-sm-5">{arr1}</div>
                    <div className="col-sm-3">
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          }
        });



        await this.setState({ json: contentJson });


      }
    }
  };
  process3 = () => {
    let Inns_Expiry_Show = false
    let name_Finance = false
    let data = this.state.data;
    const innerWidth = window.innerWidth
    let { dateofregmon, dateofregyear } = this.state
    const dateofmanu = String(dateofregyear) + "-" + String(dateofregmon) + "-" + "01"
    // console.log(arr, "arr", dateofmanu, "dateofmanu");
    // console.log();
    if (data) {
      let arr = data;
      // console.log(arr, "arr", dateofmanu, "dateofmanu");
      if (arr && arr.length) {
        let answerJson = this.state.answerJson;
        if (answerJson && answerJson[322] && answerJson[322].value == 3) {
          Inns_Expiry_Show = true
        }
        if (arr && arr.length) {
          let answerJson = this.state.answerJson;
          if (answerJson && answerJson[325] && answerJson[325].value == 1) {
            name_Finance = true

          }
        }

        // console.log(answerJson[322], "answerJson", Inns_Expiry_Show, "Inns_Expiry_Show");

        return <>
          {arr.map((item, index) => {

            let arr1 = [];
            let option = [];
            if (item.type == "radio") {
              let arr1 = []
              item.options.split(",").map((element, key) => {
                arr1.push(
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name={`element${index}`}
                        id={`inlineCheckbox${index}`}
                        onChange={e =>
                          this.answers2(
                            element,
                            key,
                            null,
                            key,
                            "radio",
                            item.id,
                            item.parentid
                          )
                        }
                        checked={element == answerJson[item.id]}
                      />
                      <label
                        className="form-check-label"
                        for={`inlineCheckbox${key}`}
                        style={{ marginLeft: "10px" }}
                      >
                        {`${element}`}{" "}
                      </label>
                    </div>
                  </div>
                );
              });

            }
            if (item.type == "selectbox") {
              if (answerJson[325]) {
                if (name_Finance == false) {
                  if (item.id == 534) {
                    answerJson[534] = {}
                    return false

                  }
                }
              }
              if (answerJson[311]) {
                if ((item.id == 319 || item.id == 308 || item.id == 653) && answerJson[311].value == "2") {
                  return false
                }
              }

            }

            if (item.type == "checkbox") {
              item.options.split(",").map((element, key) => {
                arr1.push(
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`inlineCheckbox${index}${key}`}
                      onChange={e => {
                        this.answers2(
                          element,
                          index,
                          item.options.split(","),
                          key,
                          "checkbox",
                          item.id,
                          item.parentid
                        );
                      }}
                      checked={element == answerJson[item.id]}

                    />
                    <label
                      className="form-check-label"
                      for={`inlineCheckbox${index}${key}`}
                      style={{ marginLeft: "10px" }}
                    >{`${element}`}</label>
                  </div>
                );
              });
            }

            return <>
              {
                item.type == "text" ?
                  <div className="bgcolor">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="position" style={{ color: item.id == 318 || item.id == 313 ? "orange" : '' }}>{item.question}
                          {(item.id == 313 || item.id == 320 || item.id == 326) && <button className="btn btn-default btn-sm" onClick={() => this.onregchange(`Isreg${item.id}`, !this.state['Isreg' + item.id])} >
                            <MDBIcon icon="camera" size="lg" />
                          </button>}

                        </label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type={item && item.id == 304 || item.id == 318 || item.id == 305 ? "number" : "text"}
                          className="form-control"
                          placeholder={item.placeholder}
                          id={`input-${item.id}`}
                          onBlur={e =>
                            this.answers2(
                              e.target.value,
                              index,
                              null,
                              null,
                              "text",
                              item.id,
                              item.parentid
                            )
                          }
                          style={{ textTransform: item.id == 313 || item.id == 320 || item.id == 326 ? "uppercase" : "" }}
                          disabled={item.id == 304 ? true : false}
                          defaultValue={answerJson[item.id]}
                        // value={answerJson[item.id]}
                        />
                        {item && item.id == 304 || item.id == 318 || item.id == 305 ? <></> : <div className="mic-icon">
                          <p id={`para-${item.id}`} className="bi bi-mic-fill" onClick={() => this.voiceSetup("start", item.id)}></p>
                        </div>}

                        <span className="error-shows" id={`${index}`}>
                          {/* {this.state.error[index]} */}
                        </span>
                      </div>
                      <div className="col-sm-3"></div>
                    </div>
                    {(item.id == 313) && this.state['Isreg' + item.id] && <div className="row">
                      <div className="col-sm-2" />
                      <div className="col">
                        <Imagereader Imagereader={(e) => this.Imagereader(e, item.id)} />
                      </div>
                    </div>}
                    {(item.id == 320 || item.id == 326) && <div className="row">
                      <div className="col-sm-4" />
                      <div className="col-sm-5">
                        {
                          this.state['Isreg' + item.id]
                            ?
                            <Camera
                              image={(e) => { this.webcam1(e, `File${item.id}`, `Isreg${item.id}`) }}
                              width={400}
                              height={590}
                              id={`Isreg${item.id}`}
                            />
                            :
                            <div>
                              <FilePond
                                files={this.state[`File${item.id}`]}
                                allowReorder={true}
                                allowMultiple={true}
                                allowImageResize={true}
                                imagePreviewMinHeight="50"
                                imagePreviewMaxHeight="50"
                                //  maxFileSize={'3072KB'}
                                acceptedFileTypes={["image/*", "application/pdf"]}
                                onupdatefiles={fileItems => {
                                  this.FILEUPLOAD(fileItems, `File${item.id}`);
                                }}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              />
                              {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>&nbsp;&nbsp;
                              <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                            </div>
                        }
                      </div>
                    </div>}
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <div className="col-sm-5">
                        {item.id == 320 ? this.state.engineImage : item.id == 326 ? this.state.chassisImage : null}
                      </div>
                    </div>
                  </div>

                  : null
              }
              {item.id == 306 ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        id="myDate"
                        className="form-control"

                        value={answerJson[item.id]}
                      />
                      <span className="error-shows" id={`${index}`}>
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div> : null}

              {item.type == "date" && answerJson ?
                item.id == 323 && Inns_Expiry_Show ?
                  ""
                  :
                  <div className="bgcolor">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="position">{item.question}</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="date"
                          id="myDate"
                          // minDate={ival.id == 715 ? dateofmanu : mindate} dateofmanu
                          min={item.id == 323 ? moment().format("YYYY-MM-DD") : item.id == 715 ? `${this.state.dateofregyear}-01-01` : answerJson[item.id]}
                          className="form-control"
                          onChange={e =>
                            this.answers2(
                              e.target.value,
                              index,
                              null,
                              null,
                              "date",
                              item.id,
                              item.parentid
                            )
                          }

                          value={answerJson[item.id]}

                        />
                        <span className="error-shows" id={`${index}`}>
                          {/* {this.state.error[index]} */}
                        </span>
                      </div>
                      <div className="col-sm-3"></div>
                    </div>
                  </div>
                : null
              }

              {item.type == "month" ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="position">{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="month"
                        id="myDate"
                        className="form-control"
                        onChange={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "date",
                            item.id,
                            item.parentid
                          )
                        }
                        value={answerJson[item.id]}

                      />
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                : null
              }
              {item.type == "textarea" ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}
                        {item.id == 307 &&
                          <button className="btn btn-default btn-sm" onClick={() => this.onregchange("Islocation", true)} >
                            <MDBIcon icon="location-arrow" size="lg" />
                          </button>}</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea
                        type="text"
                        className="form-control"
                        rows="5"
                        id={`input-${item.id}`}
                        placeholder={item.placeholder}
                        onBlur={e =>
                          this.answers2(
                            e.target.value,
                            index,
                            null,
                            null,
                            "textarea",
                            item.id,
                            item.parentid
                          )
                        }
                        defaultValue={answerJson && answerJson[item.id] !== "undefined"  ? answerJson[item.id] : ""}
                        // value={answerJson && answerJson[item.id] ? answerJson[item.id] : ""}
                        style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                      ></textarea>
                      <div className="mic-icon">
                        <p id={`para-${item.id}`} className="bi bi-mic-fill" onClick={() => this.voiceSetup("start", item.id)}></p>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                  </div>
                  {
                    item.id == 307 && this.state.Islocation && <div className="row">
                      <div className="col-sm-2" />
                      <div className="col">
                        <Geolocationfun location={true} address={this.addressget} />
                      </div>
                    </div>
                  }
                </div >
                : null
              }

              {item.type == "radio" ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      {arr1}
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                : null
              }
              {item.type == "selectbox" ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label style={{ color: item.id == 325 || item.id == 314 || item.id == 315 || item.id == 317 || item.id == 321 || item.id == 544 || item.id == 316 || item.id == 525 ? "orange" : '' }}>{item.id == 319 && this.state.others ? "OTHER NEW CAR " : item.question}</label>
                    </div>
                    <div className="col-sm-5">
                      {item.id == 319 && this.state.others ?
                        <input
                          type={"text"}
                          className="form-control"
                          placeholder={"ENTER OTHER NEW CAR "}
                          onChange={e =>
                            this.setState({ othernewcarmodel: e.target.value })
                          }
                          defaultValue={this.state.othernewcarmodel}
                        // value={answerJson[item.id]}
                        />
                        :
                        <SingleSelect
                          options={item.options && Array.isArray(item.options) ? item.options : []}
                          placeholder={item.placeholder}
                          handleChange={e => {
                            this.answers2(
                              e,
                              index,
                              null,
                              null,
                              "selectbox",
                              item.id,
                              item.parentid
                            );
                          }}
                          selectedService={answerJson[item.id] ? answerJson[item.id] : null}
                        />
                      }
                    </div>
                    <span className="error-shows" id={`${index}`}>
                      {/*  {this.state.error[index]} */}
                    </span>
                    <div className="col-sm-3"></div>
                  </div>
                </div>
                : null
              }

              {item.type == "checkbox" ?
                <div className="bgcolor">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label>{item.question}</label>
                    </div>
                    <div className="col-sm-5">{arr1}</div>
                    <div className="col-sm-3">
                      <span className="error-shows" id={`${index}`}>
                        {/* {this.state.error[index]} */}
                      </span>
                    </div>
                  </div>
                </div> : null}

            </>



          })}
        </>
      }
    }

  }
  updateActiveInput = (transcript) => {
    const { activeInput, voiceIndex, answerJson, voiceOptions, interiorJson, voiceKey, voiceType, voiceItemId, voiceParentId, SpeechType } = this.state;
    if (SpeechType == "remarks") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = interiorJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          if (SpeechType === "remarks") {
            data[voiceIndex].remarks = transcript;
          }
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = interiorJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          if (SpeechType === "estimate") {
            data[voiceIndex].estimate = transcript;
          }

        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-ext") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.exteriorJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-ext") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.exteriorJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-bumper") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.bumperJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-bumper") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.bumperJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-bumper") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.bumperJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-bumper") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.bumperJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-light") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.lightJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-light") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.lightJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-wheel") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.tyersJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-wheel") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.tyersJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-wheel1") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.wheeklJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-wheel1") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.wheeklJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-wind") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      console.log(input, "inputtttttt")
      console.log("enters into type 2")
      let data = this.state.windJson
      console.log(data, "dataaa")
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        console.log(input.defaultValue, "defaultvalue", input.value, "inputValue", data, "interuirJson")
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
        console.log(data, "this.state.exteriorJson")
      }
    }
    else if (SpeechType == "estimate-wind") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.windJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "remarks-pro") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.proJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].remarks = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else if (SpeechType == "estimate-pro") {
      var input = document.getElementById(`${SpeechType}-${voiceIndex}`)
      let data = this.state.proJson
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
        if (data[voiceIndex]) {
          data[voiceIndex].estimate = transcript;
        } else {
          console.log(`Index ${voiceIndex} does not exist in the array.`);
        }
      }
    }
    else {
      var input = document.getElementById(`input-${voiceIndex}`)
      if (input) {
        input.defaultValue = transcript
        input.value = transcript
      }
      if (voiceIndex >= 0 && voiceIndex < answerJson.length) {
        answerJson[voiceIndex] = transcript;
      }
    }
  };
  voiceSetup = (value, index) => {
    var para = document.getElementById(`para-${index}`)
    if (para.classList[1] === "bi-mic-fill") {
      this.handleStart(index)
      para.classList.remove("bi-mic-fill")
      para.classList.add("bi-x-circle")
    }
    else {
      this.handleStop()
      var para = document.getElementById(`para-${index}`)
      para.classList.remove("bi-x-circle")
      para.classList.add("bi-mic-fill")
    }
  }
  handleStart = (index) => {
    const { selectedLanguage } = this.state;
    if (this.recognition) {
      this.recognition.lang = selectedLanguage;
      this.recognition.start();
    }
    this.setState({ micON: true, listening: true, voiceIndex: index }, () => {
      this.process(this.state.micON)
      this.updateActiveInput("hearing your voice", this.state.voiceIndex)
    });
  };
  handleStop = (val) => {
    if (this.recognition) {
      this.recognition.stop();
    }
    this.setState({ listening: false, activeInput: null, micON: false, voiceIndex: val, SpeechType: "" });
    this.setState({ micON: false, activeInput: null, listening: false, SpeechType: "" }, () => {
      this.process(this.state.micON)
      // this.updateActiveInput("sathesh", this.state.voiceIndex)
    });
  };
  voiceSetup2 = (value, ival, index, type) => {
    var { interiorJson, voiceIndex, inputType, voiceIval, SpeechType } = this.state
    var para = document.getElementById(`${value}-para${index}`)
    if (para.classList[1] === "bi-mic-fill") {
      para.classList.remove("bi-mic-fill")
      para.classList.add("bi-x-circle")
      this.setState({ voiceIndex: index, inputType: type, voiceIval: ival, SpeechType: value }, () => {
        this.handleStart1(index, voiceIndex, inputType, voiceIval, SpeechType)
      });
    }
    else {
      this.handleStop()
      var para = document.getElementById(`${value}-para${index}`)
      para.classList.remove("bi-x-circle")
      para.classList.add("bi-mic-fill")
    }
  }
  handleStart1 = (index) => {
    const { selectedLanguage } = this.state;
    if (this.recognition) {
      this.recognition.lang = selectedLanguage;
      this.recognition.start();
    }
    this.setState({ micON: true, listening: true, voiceIndex: index }, () => {
      this.process(this.state.micON)
      this.updateActiveInput("", this.state.voiceIndex, this.state.SpeechType)
    });
  };
  interiorfileupload = (e, ind) => {
    let badinteriorfiles = this.state.badinteriorfiles;
    if (e) {
      badinteriorfiles[ind] = e.map(fileItem => fileItem.file)
      this.setState(badinteriorfiles[ind]);
    }
  }
  exteriorfileupload = (e, ind) => {
    let badexteriorfiles = this.state.badexteriorfiles;
    if (e) {
      badexteriorfiles[ind] = e.map(fileItem => fileItem.file)
      this.setState(badexteriorfiles[ind]);
    }
  }
  carImageUpload = (e, ch) => {
    let fileSnap = this.state.fileSnap;
    if (e) {
      fileSnap[0][ch] = e.map(fileItem => fileItem.file)
      this.setState(fileSnap);
    }
  }
  carInnerImageUpload = (e, ch) => {
    let fileSnaps1 = this.state.fileSnaps1;
    if (e) {
      fileSnaps1[0][ch] = e.map(fileItem => fileItem.file)
      this.setState(fileSnaps1);
    }
  }
  calc = () => {
    let total = this.state.total;
    let answerJson = this.state.answerJson;
    let percentpredict = null
    let percentdata = [{ a: 9, b: 14 }, { a: 10, b: 14 }, { a: 11, b: 13 }, { a: 17, b: 14 }, { a: 21, b: 13 }]
    if (answerJson[314]) {
      percentdata.map((ival) => {
        if (ival.a == answerJson[314].value) {
          percentpredict = ival.b;
        }
      })
    }
    if (percentpredict) { } else {
      percentpredict = 10
    }
    let percent = answerJson[343] ? (parseInt(answerJson[343] / 100) * percentpredict) : 0
    let A = answerJson[343] ? parseInt(answerJson[343]) : 0;
    let B = answerJson[344] ? parseInt(answerJson[344]) : 0;
    let C = answerJson[345] ? percent : 0;
    let D = answerJson[346] ? parseInt(answerJson[346]) : 0;
    let E = answerJson[347] ? parseInt(answerJson[347]) : 0;
    let F = answerJson[348] ? parseInt(answerJson[348]) : 0;
    total = A - B - C - D - E + F;
    answerJson[349] = total;
    let car = []
    // let lst=[349,314,315]

    if (answerJson[349] && answerJson[314] && answerJson[315]) {
      this.state.Buyer.map((ival, i) => {
        if (answerJson[349] == ival.price && answerJson[314].label == ival.makename
          && answerJson[315].label == ival.modelname) {
          this.setState({ viewvisible: true });
          car.push(ival)
        }
      })
    }
    if (total && total < this.state.PrizeList) {
      let sum = this.state.PrizeList - total;
      if (sum >= 30000) {
        this.setState({ PrizeRaised: "Need to be approved by Teamleader" });
      }
    } else {
      this.setState({ PrizeRaised: "" });
    }
    this.setState({ answerJson });
    this.process();
  }
  checkJson = (title, label, jname, index, type) => {
    let checkListJson = this.state.checkListJson;
    // checkListJson[index].name = jname;
    checkListJson[index][type] = label;
    this.setState({ checkListJson });
  };
  followup = async (name, value, index, type) => {
    let interior_ans_Json = this.state.interior_ans_Json;
    interior_ans_Json[index].name = name;
    interior_ans_Json[index][type] = value;
    await this.setState({ interior_ans_Json });
  };
  checkEval = (e, name) => {
    this.setState({ [name]: !this.state[name] });
    this.process();
  }
  checkinterior = async (iname, value, index, type) => {
    let answerJson = this.state.answerJson;
    let interiorJson = this.state.interiorJson;
    // let T_I = this.state.T_I;
    let { T_E, T_B, T_L, T_T, T_O, T_O1, T_O2, T_O3, body_shop, button } = this.state;

    if (iname && iname != "start") {
      interiorJson[index].name = iname;
      interiorJson[index][type] = value;

    }
    let total = 0;
    let wait = interiorJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });
    await Promise.all(wait);
    answerJson[344] = total + T_E + T_B + T_L + T_T + T_O1 + T_O2 + T_O3 + body_shop;
    if (this.state.button == 'Save') await this.formSave('FPROOF', this.state.customerId, interiorJson, 'interiorJson')
    if (this.state.button == 'Save') await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ interiorJson, T_I: total, answerJson });
    this.process();
  };
  checkexterior = async (iname, value, index, type) => {
    let answerJson = this.state.answerJson;
    let { T_I, T_B, T_L, T_T, T_O, T_O1, T_O2, T_O3, body_shop } = this.state;
    let exteriorJson = this.state.exteriorJson;

    if (iname && iname != "start") {
      exteriorJson[index].name = iname;
      exteriorJson[index][type] = value;
    }
    let total = 0;
    let wait = exteriorJson.map((ival, i) => {
      if (ival.estimate == null || ival.estimate == '') {
        total = total + 0;
      } else {
        total = total + parseInt(ival.estimate);
      }
    });
    await Promise.all(wait);
    answerJson[344] = total + T_I + T_B + T_L + T_T + T_O1 + T_O2 + T_O3 + body_shop;
    if (this.state.button == 'Save') await this.formSave('FPROOF', this.state.customerId, exteriorJson, 'exteriorJson')
    if (this.state.button == 'Save') await this.formSave('FPROOF', this.state.customerId, answerJson)
    this.setState({ exteriorJson, T_E: total, answerJson });
    // console.log(exteriorJson[index]
    // );
    this.process();
  };
  interiorEva = async (name, value, index, type) => {
    let interiorJson = this.state.interiorJso;
    interiorJson[index] = {
      index: index,
      name: name
    };
    interiorJson[index].evaluation = value.value;
    await this.setState({ interiorJson });
  };
  interiorRem = async (name, value, index, type) => {
    let interiorJson = this.state.interiorJson;
    interiorJson[index] = {
      index: index,
      name: name
    };
    interiorJson[index].remarks = value;

    await this.setState({ interiorJson });
  };
  update = async () => {
    const {
      answerJson,
      interiorJson,
      files,
      badinteriorfiles,
      badexteriorfiles,
      exteriorJson,
      CanvasVals,
      fileJson,
      fileJson1,
      othernewcarmodel,
      followJson1,
      File320,
      File326,
      Engine_File,
      Chassis_File,
      fileJson11,
      fileJson2,
      fileJson3,
      insurancefileJson,
      rcfileJson,
      fileSnap,
      fileSnaps1
    } = this.state;
    // let formData = {};

    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }

    // let imagealeart = '';

    // if (File326 && File326.length) {
    //   const fileSize = File326[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (File320 && File320.length) {
    //   const fileSize = File320[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (insurancefileJson && insurancefileJson.length) {
    //   const fileSize = insurancefileJson[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (rcfileJson && rcfileJson.length) {
    //   const fileSize = rcfileJson[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (fileSnap && fileSnap.length) {
    //   let obj = Object.values(fileSnap[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true
    //       }
    //     }
    //   })
    // }
    // if (fileSnaps1 && fileSnaps1.length) {
    //   let obj = Object.values(fileSnaps1[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true
    //       }
    //     }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 3MB");
    //   return false;
    // }

    var strfiles = [];
    const formData = new FormData();
    if (!answerJson[316]) {
      alert("Please Select YEAR OF MANUFACTURE")
      return false
    }
    if (!answerJson[314]) {
      alert("Please Select Make")
      return false
    }
    if (!answerJson[315]) {
      alert("Please Select Model")
      return false
    }
    if (!answerJson[544]) {
      alert("Please Select Varient")
      return false
    }
    if (!answerJson[317]) {
      alert("Please Select Colour")
      return false
    }
    if (!answerJson[525]) {
      alert("Please Select Fuel Option")
      return false
    }
    if (!answerJson[318]) {
      alert("Please Enter KilloMeter")
      return false
    }
    if (!answerJson[321]) {
      alert("Please Select Ownernship No")
      return false
    }
    if (!answerJson[313]) {
      alert("Please Enter Reg No")
      return false
    }
    if (!answerJson[343]) {
      alert("Please Enter Standard Selling Price")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status1)) {
      alert("Please Select Status1")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status2)) {
      alert("Please Select Status2")
      return false
    }
    let id = answerJson.id;
    formData.append('id', id)
    let newfile = this.state.fileSnap;

    if (newfile) {

      if (newfile[0].LHS.length > 0) {

        newfile[0].LHS.map((ival, i) => {
          formData.append(`LHS`, ival);
        });
      }
      if (newfile[0].RHS.length > 0) {

        newfile[0].RHS.map((ival, i) => {
          formData.append(`RHS`, ival);
        });
      }
      if (newfile[0].FRONT.length > 0) {

        newfile[0].FRONT.map((ival, i) => {
          formData.append(`FRONT`, ival);
        });
      }
      // else {
      //   alert("please upload front view files")
      //   return false
      // }
      if (newfile[0].BACK.length > 0) {

        newfile[0].BACK.map((ival, i) => {
          formData.append(`BACK`, ival);
        });
      }
    }
    let newfile1 = this.state.fileSnaps1
    if (newfile1) {
      if (newfile1[0].ifleft.length > 0) {
        newfile1[0].ifleft.map((ival, i) => {
          formData.append(`ifleft`, ival);
        });
      }
      if (newfile1[0].ifright.length > 0) {
        newfile1[0].ifright.map((ival, i) => {
          formData.append(`ifright`, ival);
        });
      }
      if (newfile1[0].ibright.length > 0) {
        newfile1[0].ibright.map((ival, i) => {
          formData.append(`ibright`, ival);
        });
      }
      if (newfile1[0].ibleft.length > 0) {
        newfile1[0].ibleft.map((ival, i) => {
          formData.append(`ibleft`, ival);
        });
      }
      if (newfile1[0].dashborad.length > 0) {
        newfile1[0].dashborad.map((ival, i) => {
          formData.append(`dashborad`, ival);
        });
      }
      if (newfile1[0].meter.length > 0) {
        newfile1[0].meter.map((ival, i) => {
          formData.append(`meter`, ival);
        });
      }
      if (newfile1[0].engine.length > 0) {
        newfile1[0].engine.map((ival, i) => {
          formData.append(`engine`, ival);
        });
      }
    }
    if (answerJson[302]) {
      formData.append("source", answerJson[302].label);
    }

    formData.append("customer_name", answerJson[303]);
    formData.append("mobile", answerJson[304]);
    formData.append("alter_mob", answerJson[305]);
    if (this.state.finalprice) {
      formData.append("finalprice", this.state.finalprice);
    }
    if (answerJson[306]) {
      var d = new Date(answerJson[306]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      if (date < 10) date = "0" + date;
      if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      formData.append("entry_date", dateStr);
    }
    //formData.entry_date = answerJson[306];
    if (answerJson[633]) {
      formData.append('duplicatekey', answerJson[633].label)
    }
    formData.append("address", answerJson[307]);
    if (answerJson[605]) {

      formData.append("pincode", answerJson[605]);
    }
    if (answerJson[308]) {
      formData.append("saleCons_name", answerJson[308].label);
    }
    // formData.append("buyer_name", answerJson[309]);
    formData.append("email", answerJson[310]);
    //formData.append("new_car_model", answerJson[319]);
    if (answerJson[312]) {
      var d = new Date(answerJson[312]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      formData.append("closer_date", dateStr1);
    }
    formData.append("reg_no", answerJson[313]);
    if (answerJson[314]) {
      formData.append("make", answerJson[314].value);
    }
    if (answerJson[315]) {
      formData.append("model", answerJson[315].value);
    }
    if (answerJson[532]) {
      formData.append("state", answerJson[532].label);
    }
    if (answerJson[533]) {
      formData.append("city", answerJson[533].label);
    }
    if (answerJson[321]) {
      formData.append("ownership_no", answerJson[321].label);
    }
    if (answerJson[316]) {
      formData.append("year_of_manu", answerJson[316].label);
    }
    if (answerJson[653]) {
      formData.append("new_car_make", answerJson[653].label);
    }
    if (!othernewcarmodel == "") {
      formData.append("othernewcarmodel", othernewcarmodel);
    }
    if (answerJson[319]) {
      formData.append("new_car_model", answerJson[319].label);
    }
    if (answerJson[322]) {
      formData.append("insurance", answerJson[322].label);
    }
    if (answerJson[317]) {
      formData.append("color", answerJson[317].value);
    }
    if (answerJson[544]) {
      formData.append("variant", answerJson[544].value);
    }
    formData.append("ins_validity", answerJson[323] ? answerJson[323] : "");
    formData.append("km_travel", answerJson[318]);
    if (answerJson[324]) {
      formData.append("tax_paid", answerJson[324].label);
    }
    if (answerJson[326]) formData.append("chassis_no", answerJson[326]);
    if (answerJson[325]) {
      formData.append("hp_lease", answerJson[325].label);
    }
    if (answerJson[320]) formData.append("engine_no", answerJson[320]);

    formData.append("interiors", JSON.stringify(interiorJson));
    formData.append("exteriors", JSON.stringify(exteriorJson));

    formData.append("std_sell_price", answerJson[343]);
    formData.append("Missing", answerJson[329]);
    formData.append("smoke", answerJson[327]);
    formData.append("Engine_oil", answerJson[328]);
    formData.append("Noise", answerJson[330]);
    formData.append("overheating", answerJson[331]);
    formData.append("pickup_power", answerJson[332]);
    formData.append("Radiator", answerJson[333]);
    formData.append("terms", answerJson[334]);
    formData.append("soot_deposit", answerJson[335]);
    formData.append("Shifting", answerJson[337]);
    formData.append("axles", answerJson[338]);
    formData.append("suspension", answerJson[339]);
    formData.append("steering", answerJson[340]);
    formData.append("clutch", answerJson[341]);
    formData.append("brake", answerJson[342]);
    if (answerJson[336]) {
      formData.append("Mt_cvt", answerJson[336].label);
    }
    if (answerJson[311]) {
      formData.append("transaction", answerJson[311].label);
    }
    if (answerJson[534]) {
      formData.append("finance_name", answerJson[534].label);
    }



    formData.append("recondition_est", answerJson[344]);
    formData.append("bodymap", JSON.stringify(CanvasVals));
    formData.append("margin", answerJson[345]);
    formData.append("warranty_pool", answerJson[346]);
    formData.append("isWarranty", this.state.radio ? 1 : 0);
    formData.append("gst", answerJson[347]);
    formData.append("exchange_bonus", answerJson[348]);
    formData.append("body_shop", answerJson[825]);
    formData.append("total_panel_repair", this.state.PanelRepair && this.state.PanelRepair != "" ? this.state.PanelRepair.value : 0)
    formData.append("trade_in_price", answerJson[349]);
    if (answerJson[525]) {
      formData.append("fueloption", answerJson[525].label);
    }

    formData.append("customerid", this.state.customerId);

    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }

    if (answerJson[626]) {
      formData.append("bankname", answerJson[626])
    }
    // formData.remarks = answerJson[199];
    if (answerJson[715]) {
      formData.append("YOR", answerJson[715])
    }
    if (answerJson[714]) {

      formData.append("MOM", answerJson[714].value)
    }
    if (this.state.vcondition) {
      formData.append("VCondition", this.state.vcondition.value)
    }

    if (Engine_File) {
      formData.append('engine_no_file', JSON.stringify(Engine_File))
    }
    if (Chassis_File) {
      formData.append('chassis_no_file', JSON.stringify(Chassis_File))
    }
    if (File320 && File320.length) {
      File320.map((ival, i) => {
        formData.append(`EngineFile`, ival);
      });
    }
    if (File326 && File326.length) {
      File326.map((ival, i) => {
        formData.append(`ChassisFile`, ival);
      });
    }

    if (fileJson && fileJson.length) {
      fileJson.map((ival, i) => {
        formData.append(`files`, ival.file);
      });
    }

    if (fileJson1 && fileJson1.length) {
      fileJson1.map((jval, j) => {
        formData.append(`vehicles`, jval.file);
      });
    }



    if (badinteriorfiles) {
      if (badinteriorfiles.length > 0) {
        badinteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.inter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }

    if (badexteriorfiles) {
      if (badexteriorfiles.length > 0) {
        badexteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.exter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }



    formData.append("status1", followJson1[0].status1 ? followJson1[0].status1.label : null);
    formData.append("status2", followJson1[0].status2 ? followJson1[0].status2.label : null);
    if (this.state.oldexterfile) formData.append("oldexterfile", JSON.stringify(this.state.oldexterfile))
    if (this.state.oldinterfile) formData.append("oldinterfile", JSON.stringify(this.state.oldinterfile))
    if (this.state.oldIntFiles) formData.append("oldIntFiles", JSON.stringify(this.state.oldIntFiles))
    if (this.state.oldFiles) formData.append("oldFiles", JSON.stringify(this.state.oldFiles))

    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    this.setState({ btnDisable: true })
    try {
      const evalWheelSuggest = JSON.parse(localStorage.getItem("evalWheelSuggest")) || []
      const evallightSuggest = JSON.parse(localStorage.getItem("evallightSuggest")) || []
      const evalwindSuggest = JSON.parse(localStorage.getItem("evalwindSuggest")) || []
      const evalbumperSuggest = JSON.parse(localStorage.getItem("evalbumperSuggest")) || []
      const evalInteriorSuggest = JSON.parse(localStorage.getItem("evalInteriorSuggest")) || []
      const evalExteriorSuggest = JSON.parse(localStorage.getItem("evalExteriorSuggest")) || []
      const evalTyreSuggest = JSON.parse(localStorage.getItem("evalTyreSuggest")) || []
      const evalProSuggest = JSON.parse(localStorage.getItem("evalProSuggest")) || []

      const wheelvalues = this.state.wheeklJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const lightvalues = this.state.lightJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const windvalues = this.state.windJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const bumpervalues = this.state.bumperJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const interiorvalues = this.state.interiorJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const exteriorvalues = this.state.exteriorJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const tyrevalues = this.state.tyersJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const provalues = this.state.proJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const wheelfinal = evalWheelSuggest.concat(wheelvalues)
      const lightfinal = evallightSuggest.concat(lightvalues)
      const windfinal = evalwindSuggest.concat(windvalues)
      const bumperfinal = evalbumperSuggest.concat(bumpervalues)
      const interiorfinal = evalInteriorSuggest.concat(interiorvalues)
      const exteriorfinal = evalExteriorSuggest.concat(exteriorvalues)
      const tyrefinal = evalTyreSuggest.concat(tyrevalues)
      const profinal = evalProSuggest.concat(provalues)
      const bodyShopEstimation = this.state.answerJson[825]
      const ExhangeBonus = this.state.answerJson[348]
      const TotalRecondition = this.state.answerJson[344]
      const standardSelling = this.state.answerJson[343]
      const finalPrice = this.state.finalprice
      const evalclassremarks = this.state.followJson1[0].remarks


      const locclassRemarks = JSON.parse(localStorage.getItem("evalStatusRemarks")) || []
      locclassRemarks.push(evalclassremarks)

      const locExchangebonus = JSON.parse(localStorage.getItem("exchange_bonus")) || []
      locExchangebonus.push(ExhangeBonus)

      const locfinalPrice = JSON.parse(localStorage.getItem("finalPrice")) || []
      locfinalPrice.push(finalPrice)

      const locStandardSellingPrice = JSON.parse(localStorage.getItem("standard_selling_price")) || []
      locStandardSellingPrice.push(standardSelling)

      const evalOtherEstimates = JSON.parse(localStorage.getItem("evalOtherEstimates")) || []
      evalOtherEstimates.push(bodyShopEstimation, TotalRecondition)
      let result = await CmsContent.UpdateFProof("tbl_FProof", formData);

      if (result) {
        localStorage.setItem("standard_selling_price", JSON.stringify([...new Set(locStandardSellingPrice)]));
        localStorage.setItem("evalStatusRemarks", JSON.stringify([...new Set(locclassRemarks)]));
        localStorage.setItem("evalOtherEstimates", JSON.stringify([...new Set(evalOtherEstimates)]));
        localStorage.setItem("finalPrice", JSON.stringify([...new Set(locfinalPrice)]));
        localStorage.setItem("exchange_bonus", JSON.stringify([...new Set(locExchangebonus)]));
        localStorage.setItem("evalWheelSuggest", JSON.stringify([...new Set(wheelfinal)]));
        localStorage.setItem("evallightSuggest", JSON.stringify([...new Set(lightfinal)]));
        localStorage.setItem("evalwindSuggest", JSON.stringify([...new Set(windfinal)]));
        localStorage.setItem("evalbumperSuggest", JSON.stringify([...new Set(bumperfinal)]));
        localStorage.setItem("evalInteriorSuggest", JSON.stringify([...new Set(interiorfinal)]));
        localStorage.setItem("evalExteriorSuggest", JSON.stringify([...new Set(exteriorfinal)]));
        localStorage.setItem("evalTyreSuggest", JSON.stringify([...new Set(tyrefinal)]));
        localStorage.setItem("evalProSuggest", JSON.stringify([...new Set(profinal)]));
        this.update1();
      }


    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  formSubmit = async () => {

    const {
      answerJson,
      interiorJson,
      files,
      exteriorJson,
      fileJson,
      fileJson1,
      CanvasVals,
      followJson1,
      badinteriorfiles,
      badexteriorfiles,
      File320,
      File326,
      Engine_File,
      Chassis_File
      , othernewcarmodel,
      fileJson11,
      fileJson2,
      fileJson3,
      insurancefileJson,
      rcfileJson,
      fileSnap,
      fileSnaps1
    } = this.state;

    var strfiles = [];

    if (this.state.latitude == '' || this.state.longitude == '' || this.state.latitude == undefined || this.state.longitude == undefined) {
      alert('Please allow permission to access your location');;
      // return false;
    }

    // let imagealeart = '';
    // if (File326 && File326.length) {
    //   const fileSize = File326[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (File320 && File320.length) {
    //   const fileSize = File320[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (insurancefileJson && insurancefileJson.length) {
    //   const fileSize = insurancefileJson[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (rcfileJson && rcfileJson.length) {
    //   const fileSize = rcfileJson[0].size / 1024 / 1024;
    //   const maxFileSize = 3; // Maximum file size in MB
    //   // Validate file size
    //   if (fileSize > maxFileSize) {
    //     // alert("Please Check Image is Greater than 300KB");
    //     imagealeart = true
    //     return true;
    //   }
    // }
    // if (fileSnap && fileSnap.length) {
    //   let obj = Object.values(fileSnap[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //   })
    // }
    // if (fileSnaps1 && fileSnaps1.length) {
    //   let obj = Object.values(fileSnaps1[0]).map(e => e && e.length && e.map(x => x.size))
    //   obj.map((ival) => {
    //     if (ival !== undefined) {
    //       const fileSize = ival[0] / 1024 / 1024;
    //       const maxFileSize = 3; // Maximum file size in MB
    //       // Validate file size
    //       if (fileSize > maxFileSize) {
    //         // alert("Please Check Image is Greater than 300KB");
    //         imagealeart = true
    //         return true;
    //       }
    //     }
    //   })
    // }
    // if (imagealeart == true) {
    //   alert("Please Check Image is Greater than 300KB");
    //   return false;
    // }

    const formData = new FormData();
    if (!answerJson[316]) {
      alert("Please Select Year Of Manufacture")
      return false
    }
    if (!answerJson[314]) {
      alert("Please Select Make")
      return false
    }
    if (!answerJson[315]) {
      alert("Please Select Model")
      return false
    }
    if (!answerJson[544]) {
      alert("Please Select Varient")
      return false
    }
    if (!answerJson[317]) {
      alert("Please Select Colour")
      return false
    }
    if (!answerJson[525]) {
      alert("Please Select Fuel Option")
      return false
    }
    if (!answerJson[318]) {
      alert("Please Enter KilloMeter")
      return false
    }
    if (!answerJson[321]) {
      alert("Please Select Ownernship No")
      return false
    }
    if (!answerJson[313]) {
      alert("Please Enter Reg No")
      return false
    }
    if (!answerJson[325]) {
      alert("Please Enter Hp/Lease Field")
      return false
    }
    if (!answerJson[343]) {
      alert("Please Enter Standard Selling Price")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status1)) {
      alert("Please Select Status1")
      return false
    }
    if (!followJson1 || !followJson1[0] || (followJson1[0] && !followJson1[0].status2)) {
      alert("Please Select Status2")
      return false
    }

    let newfile = this.state.fileSnap;
    let newfile1 = this.state.fileSnaps1

    if (newfile) {

      if (newfile[0].LHS.length > 0) {

        newfile[0].LHS.map((ival, i) => {
          formData.append(`LHS`, ival);
        });
      }
      if (newfile[0].RHS.length > 0) {

        newfile[0].RHS.map((ival, i) => {
          formData.append(`RHS`, ival);
        });
      }
      if (newfile[0].FRONT.length > 0) {

        newfile[0].FRONT.map((ival, i) => {
          formData.append(`FRONT`, ival);
        });
      }
      else {

        alert("please upload front view files")
        return false
      }

      if (newfile[0].BACK.length > 0) {

        newfile[0].BACK.map((ival, i) => {
          formData.append(`BACK`, ival);
        });
      }
    }
    if (newfile1) {
      if (newfile1[0].ifleft.length > 0) {
        newfile1[0].ifleft.map((ival, i) => {
          formData.append(`ifleft`, ival);
        });
      }
      if (newfile1[0].ifright.length > 0) {
        newfile1[0].ifright.map((ival, i) => {
          formData.append(`ifright`, ival);
        });
      }
      if (newfile1[0].ibright.length > 0) {
        newfile1[0].ibright.map((ival, i) => {
          formData.append(`ibright`, ival);
        });
      }
      if (newfile1[0].ibleft.length > 0) {
        newfile1[0].ibleft.map((ival, i) => {
          formData.append(`ibleft`, ival);
        });
      }
      if (newfile1[0].dashborad.length > 0) {
        newfile1[0].dashborad.map((ival, i) => {
          formData.append(`dashborad`, ival);
        });
      }
      if (newfile1[0].meter.length > 0) {
        newfile1[0].meter.map((ival, i) => {
          formData.append(`meter`, ival);
        });
      }
      if (newfile1[0].engine.length > 0) {
        newfile1[0].engine.map((ival, i) => {
          formData.append(`engine`, ival);
        });
      }
    }

    if (answerJson[302]) {
      formData.append("source", answerJson[302].label);
    }

    formData.append("customer_name", answerJson[303]);
    formData.append("mobile", answerJson[304]);
    formData.append("alter_mob", answerJson[305]);
    if (this.state.finalprice) {
      formData.append("finalprice", this.state.finalprice);
    }

    if (answerJson[306]) {
      var d = new Date(answerJson[306]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      // if (date < 10) date = "0" + date;
      // if (month < 10) month = "0" + month;
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      formData.append("entry_date", dateStr);
    }

    if (answerJson[633]) {
      formData.append('duplicatekey', answerJson[633].label)
    }
    formData.append("address", answerJson[307]);
    if (answerJson[605]) {
      formData.append("pincode", answerJson[605]);
    }
    if (answerJson[308]) {
      formData.append("saleCons_name", answerJson[308].label);
    }

    formData.append("email", answerJson[310]);

    if (answerJson[312]) {
      var d = new Date(answerJson[312]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      if (date1 < 10) date1 = "0" + date1;
      if (month1 < 10) month1 = "0" + month1;
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      formData.append("closer_date", dateStr1);
    }

    formData.append("reg_no", answerJson[313]);
    if (answerJson[314]) {
      formData.append("make", answerJson[314].value);
    }
    if (answerJson[315]) {
      formData.append("model", answerJson[315].value);
    }
    if (answerJson[532]) {
      formData.append("state", answerJson[532].label);
    }
    if (answerJson[533]) {
      formData.append("city", answerJson[533].label);
    }
    if (answerJson[321]) {
      formData.append("ownership_no", answerJson[321].label);
    }
    if (answerJson[316]) {
      formData.append("year_of_manu", answerJson[316].label);
    }
    if (answerJson[653]) {
      formData.append("new_car_make", answerJson[653].label);
    }
    if (!othernewcarmodel == "") {
      formData.append("othernewcarmodel", othernewcarmodel);
    }
    if (answerJson[319]) {
      formData.append("new_car_model", answerJson[319].label);
    }
    if (answerJson[322]) {
      formData.append("insurance", answerJson[322].label);
    }
    if (answerJson[317]) {
      formData.append("color", parseInt(answerJson[317].value));
    }
    if (answerJson[544]) {
      formData.append("variant", parseInt(answerJson[544].value));
    }
    formData.append("ins_validity", answerJson[323] ? answerJson[323] : "");
    formData.append("km_travel", answerJson[318]);
    if (answerJson[324]) {
      formData.append("tax_paid", answerJson[324].label);
    }
    if (answerJson[326]) formData.append("chassis_no", answerJson[326]);
    if (answerJson[325]) {
      formData.append("hp_lease", answerJson[325].label);
    }
    if (answerJson[320]) formData.append("engine_no", answerJson[320]);

    formData.append("interiors", JSON.stringify(interiorJson));
    formData.append("exteriors", JSON.stringify(exteriorJson));

    if (answerJson[343]) formData.append("std_sell_price", answerJson[343]);
    if (answerJson[329]) formData.append("Missing", answerJson[329]);
    if (answerJson[327]) formData.append("smoke", answerJson[327]);
    if (answerJson[328]) formData.append("Engine_oil", answerJson[328]);
    if (answerJson[330]) formData.append("Noise", answerJson[330]);
    if (answerJson[331]) formData.append("overheating", answerJson[331]);
    if (answerJson[332]) formData.append("pickup_power", answerJson[332]);
    if (answerJson[333]) formData.append("Radiator", answerJson[333]);
    if (answerJson[334]) formData.append("terms", answerJson[334]);
    if (answerJson[335]) formData.append("soot_deposit", answerJson[335]);
    if (answerJson[337]) formData.append("Shifting", answerJson[337]);
    if (answerJson[338]) formData.append("axles", answerJson[338]);
    if (answerJson[339]) formData.append("suspension", answerJson[339]);
    if (answerJson[340]) formData.append("steering", answerJson[340]);
    if (answerJson[341]) formData.append("clutch", answerJson[341]);
    if (answerJson[342]) formData.append("brake", answerJson[342]);
    if (answerJson[336]) {
      formData.append("Mt_cvt", answerJson[336].label);
    }
    if (answerJson[311]) {
      formData.append("transaction", answerJson[311].label);
    }
    if (answerJson[534]) {
      formData.append("finance_name", answerJson[534].label);
    }



    formData.append("recondition_est", answerJson[344]);
    formData.append("bodymap", JSON.stringify(CanvasVals));
    formData.append("margin", answerJson[345]);
    formData.append("warranty_pool", answerJson[346]);
    formData.append("isWarranty", this.state.radio ? 1 : 0);
    formData.append("gst", answerJson[347]);
    formData.append("exchange_bonus", answerJson[348]);
    formData.append("body_shop", answerJson[825]);
    formData.append("total_panel_repair", this.state.PanelRepair && this.state.PanelRepair != "" ? this.state.PanelRepair.value : 0)
    formData.append("trade_in_price", answerJson[349]);
    if (answerJson[525]) {
      formData.append("fueloption", answerJson[525].label);
    }
    //  formData.append("pincode", answerJson[605]);
    formData.append("customerid", this.state.customerId);
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("userid", this.state.Evaluatorid);
    } else {
      formData.append("userid", this.state.userid.id);
    }
    if ([1, 2, 3].includes(this.state.usertype.orderBy)) {
      formData.append("UpdatedBy", this.state.userid.id)
    }
    if (answerJson[626]) {
      formData.append("bankname", answerJson[626])
    }
    if (answerJson[715]) {
      formData.append("YOR", answerJson[715])
    }
    if (answerJson[714]) {
      formData.append("MOM", answerJson[714].value)
    }
    if (this.state.vcondition) {
      formData.append("VCondition", this.state.vcondition.value)
    }
    if (Engine_File) {
      formData.append('engine_no_file', JSON.stringify(Engine_File))
    }
    if (Chassis_File) {
      formData.append('chassis_no_file', JSON.stringify(Chassis_File))
    }
    if (File320 && File320.length) {
      File320.map((ival, i) => {
        formData.append(`EngineFile`, ival);
      });
    }
    if (File326 && File326.length) {
      File326.map((ival, i) => {
        formData.append(`ChassisFile`, ival);
      });
    }
    if (fileJson && fileJson.length) {
      fileJson.map((ival, i) => {
        formData.append(`files`, ival.file);
      });
    }

    if (fileJson1 && fileJson1.length) {
      fileJson1.map((jval, j) => {
        formData.append(`vehicles`, jval.file);
      });
    }

    if (badinteriorfiles) {
      if (badinteriorfiles.length > 0) {
        badinteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.inter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }

    if (badexteriorfiles) {
      if (badexteriorfiles.length > 0) {
        badexteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.exter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }

    formData.append("approval", 0);

    formData.append("status1", followJson1[0].status1 ? followJson1[0].status1.label : null);
    formData.append("status2", followJson1[0].status2 ? followJson1[0].status2.label : null);
    if (followJson1[0].status1.label == "Deal Closed" && followJson1[0].status2.label == "Trade in") {
      formData.append("gotoapproval", 1);
    } else {
      formData.append("gotoapproval", 0);
    }
    formData.append("latitude", this.state.latitude);

    formData.append("longitude", this.state.longitude);

    this.setState({ btnDisable: true })
    try {  // || []
      const evalWheelSuggest = JSON.parse(localStorage.getItem("evalWheelSuggest")) || []
      const evallightSuggest = JSON.parse(localStorage.getItem("evallightSuggest")) || []
      const evalwindSuggest = JSON.parse(localStorage.getItem("evalwindSuggest")) || []
      const evalbumperSuggest = JSON.parse(localStorage.getItem("evalbumperSuggest")) || []
      const evalInteriorSuggest = JSON.parse(localStorage.getItem("evalInteriorSuggest")) || []
      const evalExteriorSuggest = JSON.parse(localStorage.getItem("evalExteriorSuggest")) || []
      const evalTyreSuggest = JSON.parse(localStorage.getItem("evalTyreSuggest")) || []
      const evalProSuggest = JSON.parse(localStorage.getItem("evalProSuggest")) || []

      const wheelvalues = this.state.wheeklJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const lightvalues = this.state.lightJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const windvalues = this.state.windJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const bumpervalues = this.state.bumperJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const interiorvalues = this.state.interiorJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const exteriorvalues = this.state.exteriorJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const tyrevalues = this.state.tyersJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const provalues = this.state.proJson.map(({ estimate, remarks }) => ({ estimate, remarks }));
      const wheelfinal = evalWheelSuggest.concat(wheelvalues)
      const lightfinal = evallightSuggest.concat(lightvalues)
      const windfinal = evalwindSuggest.concat(windvalues)
      const bumperfinal = evalbumperSuggest.concat(bumpervalues)
      const interiorfinal = evalInteriorSuggest.concat(interiorvalues)
      const exteriorfinal = evalExteriorSuggest.concat(exteriorvalues)
      const tyrefinal = evalTyreSuggest.concat(tyrevalues)
      const profinal = evalProSuggest.concat(provalues)
      const bodyShopEstimation = this.state.answerJson[825]
      const ExhangeBonus = this.state.answerJson[348]
      const TotalRecondition = this.state.answerJson[344]
      const standardSelling = this.state.answerJson[343]
      const finalPrice = this.state.finalprice
      const evalclassremarks = this.state.followJson1[0].remarks


      const locclassRemarks = JSON.parse(localStorage.getItem("evalStatusRemarks")) || []
      locclassRemarks.push(evalclassremarks)

      const locExchangebonus = JSON.parse(localStorage.getItem("exchange_bonus")) || []
      locExchangebonus.push(ExhangeBonus)

      const locfinalPrice = JSON.parse(localStorage.getItem("finalPrice")) || []
      locfinalPrice.push(finalPrice)

      const locStandardSellingPrice = JSON.parse(localStorage.getItem("standard_selling_price")) || []
      locStandardSellingPrice.push(standardSelling)

      const evalOtherEstimates = JSON.parse(localStorage.getItem("evalOtherEstimates")) || []
      evalOtherEstimates.push(bodyShopEstimation, TotalRecondition)


      let result = await CmsContent.addFProof("tbl_FProof", formData);

      if (result) {
        localStorage.setItem("standard_selling_price", JSON.stringify([...new Set(locStandardSellingPrice)]));
        localStorage.setItem("evalStatusRemarks", JSON.stringify([...new Set(locclassRemarks)]));
        localStorage.setItem("evalOtherEstimates", JSON.stringify([...new Set(evalOtherEstimates)]));
        localStorage.setItem("finalPrice", JSON.stringify([...new Set(locfinalPrice)]));
        localStorage.setItem("exchange_bonus", JSON.stringify([...new Set(locExchangebonus)]));
        localStorage.setItem("evalWheelSuggest", JSON.stringify([...new Set(wheelfinal)]));
        localStorage.setItem("evallightSuggest", JSON.stringify([...new Set(lightfinal)]));
        localStorage.setItem("evalwindSuggest", JSON.stringify([...new Set(windfinal)]));
        localStorage.setItem("evalbumperSuggest", JSON.stringify([...new Set(bumperfinal)]));
        localStorage.setItem("evalInteriorSuggest", JSON.stringify([...new Set(interiorfinal)]));
        localStorage.setItem("evalExteriorSuggest", JSON.stringify([...new Set(exteriorfinal)]));
        localStorage.setItem("evalTyreSuggest", JSON.stringify([...new Set(tyrefinal)]));
        localStorage.setItem("evalProSuggest", JSON.stringify([...new Set(profinal)]));
        await this.formSubmit1();

      }
    } catch (error) {
      console.log(error);
      this.setState({ btnDisable: false })
    }
  };
  upd = async () => {
    await this.update();
  }
  sub = () => {
    this.formSubmit();

  }
  newtab = e => {
    window.open(e, "_blank");
  };
  AddFile = () => {
    let fileJson = this.state.fileJson;
    fileJson.push({
      index: "",
      file: {},
      name: ""
    });
    this.setState({ fileJson });
  };
  authorimage = (e, index) => {
    let fileJson = this.state.fileJson;
    if (e) {
      let filename = e;
      let filepath = e.name;
      // var filesize = filepath.size;
      fileJson[index].index = index;
      fileJson[index].file = e;
      fileJson[index].name = e.name;
      this.setState({
        fileJson,
        file: filename,
        filename: filepath
        // filepath: filesize
      });
    }
  };

  remove = index => {
    let fileJson = this.state.fileJson;
    fileJson.splice(index, 1);
    this.setState({ fileJson });
  };

  fileupload = async e => {
    let files = this.state.files;
    let filesArray = this.state.filesArray;
    let fileJson = [];
    this.setState({ fileJson });
    e.map((fileItem, index) => {


      let f = URL.createObjectURL(fileItem.file)
      let f1 = this.state.watermark_link;
      let watermark_text = this.state.watermark_text;

      var options = {
        init: function (img) {
          img.crossOrigin = 'anonymous';
        }
      };

      watermark([f])
        .image(watermark.text.lowerRight(watermark_text, '28px Sans-serif', '#fff', 0.5))
        .then(function (img) {
          if (img) {
            function urltoFile(url, filename, mimeType) {
              return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
              );
            }
            urltoFile(img.src, fileItem.file.name, fileItem.file.type)
              .then(function (file) {

                let f3 = URL.createObjectURL(file)

                fileJson.push({
                  index: index,
                  file: file,
                  name: fileItem.file.name
                });
              });


          }
        });
    });

    this.setState({
      filesArray: e.map(fileItem => fileItem.file),
      fileJson
    });
  };

  insfileupload = async e => {
    let insuranceFiles = this.state.insuranceFiles
    let insurancefileJson = [];
    if (e) {
      insuranceFiles = e.map(fileItem => fileItem.file)
      this.setState({ insuranceFiles, insurancefileJson: insuranceFiles });

    }

  };

  rcfileuploadfun = async e => {
    let files = this.state.files;
    let rcfileupload = this.state.rcfileupload;
    let rcfileJson = [];
    if (e) {
      rcfileupload = e.map(fileItem => fileItem.file)
      this.setState({ rcfileupload, rcfileJson: rcfileupload });

    }

  };

  vehicleupload = async e => {
    let files = this.state.files;
    let filesArray1 = this.state.filesArray1;
    let fileJson1 = [];
    this.setState({ fileJson1 });
    const LOGO = 'https://upload.wikimedia.org/wikipedia/en/thumb/9/9f/Australian_Defence_Force_Academy_coat_of_arms.svg/1200px-Australian_Defence_Force_Academy_coat_of_arms.svg.png'
    e.map((fileItem, index) => {
      let f = URL.createObjectURL(fileItem.file)
      let f1 = this.state.watermark_link;
      let watermark_text = this.state.watermark_text;
      var options = {
        init: function (img) {
          img.crossOrigin = 'anonymous';
        }
      };
      watermark([f, f1], options)
        .image(watermark.image.lowerRight())
      watermark([f])
        .image(watermark.text.lowerRight(watermark_text, '28px Sans-serif', '#fff', 0.5))

        .then(function (img) {
          if (img) {
            function urltoFile(url, filename, mimeType) {
              return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
              );
            }
            urltoFile(img.src, fileItem.file.name, fileItem.file.type)
              .then(function (file) {

                let f3 = URL.createObjectURL(file)
                fileJson1.push({
                  index: index,
                  file: file,
                  name: fileItem.file.name
                });
              });


          }
        });

    });
    this.setState({
      filesArray1: e.map(fileItem => fileItem.file),
      fileJson1
    });
  };


  down = name => {
    window.open(`${ACCESS_POINT}/cmsContent/downloadfproof/${name}`, "_blank");
  };
  exportpdf = async () => {
    let answerJson = this.state.answerJson;
    let name = answerJson[303];
    let id = answerJson.id;
    let exportpdf = await CmsContent.Fproofpdf("tbl_FProof", id);
    if (exportpdf.data == "success") {
      window.open(
        `${ACCESS_POINT}/cmsContent/downloadfproof/${name}`,
        "_blank"
      );
    }
  };
  generatepdf = async (customerid, name) => {
    let exportpdf = await CmsContent.Fproofpdf("tbl_FProof", customerid);
    if (exportpdf.data == "success") {
      this.down(name);
    }
    // this.generatepdf1();
  };
  formSubmitPdf = async () => {
    const {
      answerJson,
      badinteriorfiles,
      badexteriorfiles,
      interiorJson,
      files,
      CanvasVals,
      fileJson1,
      exteriorJson,
      fileJson,
      followJson1
    } = this.state;

    var strfiles = [];

    const formData = new FormData();

    formData.append("source", answerJson[302]);
    formData.append("customer_name", answerJson[303]);
    formData.append("mobile", answerJson[304]);
    formData.append("alter_mob", answerJson[305]);
    if (answerJson[306]) {
      var d = new Date(answerJson[306]);
      var date = d.getDate();
      var month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year = d.getFullYear();
      var hr = d.getHours();
      var min = d.getMinutes();
      var sec = d.getSeconds();
      var dateStr =
        year + "-" + month + "-" + date + " " + hr + ":" + min + ":" + sec;
      formData.append("entry_date", dateStr);
    }

    formData.append("address", answerJson[307]);
    if (answerJson[308]) {
      formData.append("saleCons_name", answerJson[308].label);
    }

    formData.append("email", answerJson[310]);
    if (answerJson[319]) {
      formData.append("new_car_model", answerJson[319]);
    }

    if (answerJson[312]) {
      var d = new Date(answerJson[312]);
      var date1 = d.getDate();
      var month1 = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
      var year1 = d.getFullYear();
      var hr1 = d.getHours();
      var min1 = d.getMinutes();
      var sec1 = d.getSeconds();
      var dateStr1 =
        year1 +
        "-" +
        month1 +
        "-" +
        date1 +
        " " +
        hr1 +
        ":" +
        min1 +
        ":" +
        sec1;
      formData.append("closer_date", dateStr1);
    }
    if (answerJson[605]) {

      formData.append("pincode", answerJson[605]);
    }
    formData.append("reg_no", answerJson[313]);
    if (answerJson[314]) {
      formData.append("make", answerJson[314].value);
    }
    if (answerJson[315]) {
      formData.append("model", answerJson[315].value);
    }
    if (answerJson[532]) {
      formData.append("state", answerJson[532].label);
    }
    if (answerJson[533]) {
      formData.append("city", answerJson[533].label);
    }
    if (answerJson[321]) {
      formData.append("ownership_no", answerJson[321].label);
    }
    if (answerJson[316]) {
      formData.append("year_of_manu", answerJson[316].label);
    }
    if (answerJson[322]) {
      formData.append("insurance", answerJson[322].label);
    }
    if (answerJson[653]) {
      formData.append("new_car_make", answerJson[653].label);
    }
    if (answerJson[319]) {
      formData.append("new_car_model", answerJson[319].label);
    }
    if (answerJson[317]) {
      formData.append("color", answerJson[317].value);
    }
    if (answerJson[544]) {
      formData.append("variant", answerJson[544].value);
    }
    formData.append("ins_validity", answerJson[323] ? answerJson[323] : "");
    formData.append("km_travel", answerJson[318]);
    if (answerJson[324]) {
      formData.append("tax_paid", answerJson[324].label);
    }
    formData.append("chassis_no", answerJson[326]);
    if (answerJson[325]) {
      formData.append("hp_lease", answerJson[325].label);
    }
    formData.append("engine_no", answerJson[320]);
    // if (answerJson[326]) {
    //   formData.append("finance_name", answerJson[326].label);
    // }
    formData.append("interiors", JSON.stringify(interiorJson));
    formData.append("exteriors", JSON.stringify(exteriorJson));
    // formData.test_drive = answerJson[199];
    formData.append("std_sell_price", answerJson[343]);
    formData.append("Missing", answerJson[329]);
    formData.append("smoke", answerJson[327]);
    formData.append("Engine_oil", answerJson[328]);
    formData.append("Noise", answerJson[330]);
    formData.append("overheating", answerJson[331]);
    formData.append("pickup_power", answerJson[332]);
    formData.append("Radiator", answerJson[333]);
    formData.append("terms", answerJson[334]);
    formData.append("soot_deposit", answerJson[335]);
    formData.append("Shifting", answerJson[337]);
    formData.append("axles", answerJson[338]);
    formData.append("suspension", answerJson[339]);
    formData.append("steering", answerJson[340]);
    formData.append("clutch", answerJson[341]);
    formData.append("brake", answerJson[342]);
    if (answerJson[336]) {
      formData.append("Mt_cvt", answerJson[336].label);
    }
    if (answerJson[311]) {
      formData.append("transaction", answerJson[311].label);
    }
    if (answerJson[534]) {
      formData.append("finance_name", answerJson[534].label);
    }
    formData.append("recondition_est", answerJson[344]);
    formData.append("bodymap", JSON.stringify(CanvasVals));
    formData.append("margin", answerJson[345]);
    formData.append("warranty_pool", answerJson[346]);
    formData.append("isWarranty", this.state.radio ? 1 : 0);
    formData.append("gst", answerJson[347]);
    formData.append("exchange_bonus", answerJson[348]);
    formData.append("body_shop", answerJson[825]);
    formData.append("trade_in_price", answerJson[349]);
    if (answerJson[525]) {
      formData.append("fueloption", answerJson[525].label);
    }
    formData.append("customerid", this.state.customerId);
    formData.append("userid", this.state.userid.id);
    if (answerJson[626]) {
      formData.append("bankname", answerJson[626])
    }
    if (answerJson[715]) {
      formData.append("YOR", answerJson[715])
    }
    // formData.remarks = answerJson[199];
    if (fileJson && fileJson.length) {
      fileJson.map((ival, i) => {
        formData.append(`files`, ival.file);
      });
    }

    if (fileJson && fileJson1.length) {
      fileJson1.map((jval, j) => {
        formData.append(`vehicles`, jval.file);
      });
    }
    if (badinteriorfiles) {
      if (badinteriorfiles.length > 0) {
        badinteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.inter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }

    if (badexteriorfiles) {
      if (badexteriorfiles.length > 0) {
        badexteriorfiles.map((ival, i) => {
          if (ival.length > 0) {
            ival.map((jval, j) => {
              this.state.exter.map((lval, l) => {
                if (l == i) {
                  formData.append(lval, jval);
                }
              })
            });
          }
        })
      }
    }
    formData.append("approval", 0);
    formData.append("status1", followJson1[0].status1 ? followJson1[0].status1.label : null);
    formData.append("status2", followJson1[0].status2 ? followJson1[0].status2.label : null);
    if (followJson1[0].status1.label == "Deal Closed" && followJson1[0].status2.label == "Trade in") {
      formData.append("gotoapproval", 1);
    } else {
      formData.append("gotoapproval", 0);
    }




    try {
      let result = await CmsContent.addFProof("tbl_FProof", formData);
      if (result) {

        this.generatepdf(this.state.customerId, answerJson[303]);
        await this.setState({
          answerJson: "",
          json: "",
          fileJson: [],
          //files: "",
          //file: "",
          alertVisible: true,
          textalert: "Your form has been submitted successfully",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
      this.formSubmitPdf1();
    } catch (error) {
      //  console.log(error);
    }
  };
  viewlist = async => {
    this.setState({ viewlistcar: true })
  }

  cng = async (event, val) => {
    if (val === "ins") {
      this.setState({ insurance_val: event })
      await this.formSave('BPROOF', this.state.customerId, event, 'insurance_val')
    }
    else if (val === "rc") {
      this.setState({ rcval: event })
      await this.formSave('BPROOF', this.state.customerId, event, 'rcval')

    }
  }
  bodyShop = async (e) => {
    let total = 0
    if (!e.target.value) {
      total = total + 0
    }
    else {
      total = total + parseInt(e.target.value)
    }
    let { T_I, T_E, T_L, T_B, T_T, T_O, T_O1, T_O2, T_O3, answerJson, body_shop, button } = this.state;
    answerJson[344] = total + T_E + T_I + T_B + T_L + T_T + T_O1 + T_O2 + T_O3;
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, body_shop, 'body_shop')
    if (button == "Save") await this.formSave('FPROOF', this.state.customerId, answerJson)
    await this.setState({ answerJson, body_shop: total });

    this.process();
  }
  onChanges1 = async (value) => {

    await this.setState({ vcondition: value })

    this.process1()
  }
  webcam = (e, ind, field, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files[ind] = Files[ind] ? Files[ind] : []
      Files[ind].push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
      this.process()
    }
  }

  webcam1 = (e, field, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files = Files ? Files : []
      Files.push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }
  webcam2 = (e, field, side, ival = null) => {
    let Files = this.state[field];
    if (e) {
      Files[0][side] = Files[0][side] ? Files[0][side] : []
      Files[0][side].push({ source: e })
      this.setState({ [field]: Files })
      if (ival) this.setState({ [ival]: false })
    }
  }
  FILEUPLOAD = (e, field) => {
    let Files = this.state[field];
    if (e) {
      Files = e.map(fileItem => fileItem.file)
    }
    this.setState({ [field]: Files })

  }
  canvasmapping = async (canvasmapping) => {
    if (canvasmapping) {
      await this.setState({ CanvasVals: canvasmapping })
    }

  }
  Imagereader = async (Imagereader, id) => {
    let { answerJson, customerId } = this.state
    if (Imagereader) {
      answerJson[id] = Imagereader
      await this.formSave('FPROOF', customerId, answerJson)
      await this.setState({ answerJson })
      // this.process2()
    }
  }
  addressget = async (Getgeolocation) => {
    try {
      if (Getgeolocation) {
        let { answerJson, customerId } = this.state
        answerJson[307] = Getgeolocation
        await this.formSave('FPROOF', customerId, answerJson)
        this.setState({ answerJson })
        // this.process2()
      }
    } catch (error) {
      console.log(error);
    }

  }
  onregchange = async (F, value) => {
    this.setState({ [F]: value })
    // this.process2()
  }
  render() {
    const bodyShopEstimation = this.state.answerJson[825]
    const ExhangeBonus = this.state.answerJson[348]
    const TotalRecondition = this.state.answerJson[344]
    const standardSelling = this.state.answerJson[343]
    // if (this.state.vcondition && Object.values(this.state.vcondition)) {
    // }
    const {
      resSelected,
      bump,
      light,
      tyre,
      json,
      json1,
      button,
      fileJson,
      followJson,
      CalculateJson,
      files,
      filesArray,
      CanvasVals,
      filesArray1,
      fileSnap,
      fileSnaps1,
      fileJson1,
      wheeklJson,
      windJson,
      lightJson,
      bumperJson,
      tyersJson,
      proJson,
      arr,
      carlist,
      cardata,
      json3,
      followjsonbproof,
      interiorJson,
      exteriorJson,
      Loadingprocess3
    } = this.state;

    let viewFile = [];
    if (fileJson) {
      if (fileJson.length > 0) {
        fileJson.map((ival, index) => {

          //superAdmin / file ? fileurl = testAugust9 / imagesnew2_1598427810405.jpeg
          if (this.state.isedit) {
            ival = ACCESS_POINT + `/superAdmin/file?fileurl=${ival}`;
          }

          viewFile.push(
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="authorimage">File {index + 1}</label>
              </div>
              <div className="col-sm-5 custom-file">
                <input
                  type="file"
                  name="file"
                  accept="
                image/*,
                video/*,
                application/pdf,application/msword,
                applicationvnd.openxmlformats-officedocument.wordprocessingml.document,
                .zip"
                  className="custom-file-input"
                  id="authorimage"
                  onChange={e => this.authorimage(e, index)}
                />
                <label className="custom-file-label" htmlFor="authorimage">
                  {fileJson[index] && fileJson[index].name}
                </label>
              </div>

              <div className="col-sm-3">
                <i
                  className="fa fa-times-circle"
                  style={{
                    fontSize: "18px",
                    color: "black",
                    marginLeft: 20,
                    cursor: "Pointer"
                  }}
                  onClick={() => this.remove(index)}
                ></i>
              </div>

              <div style={{ width: 70, height: 70, marginLeft: 380 }}>
                <img src={ival.file} alt="" />
              </div>
              {/* </div> */}

              {this.state.isedit && (
                <div>
                  <div style={{ width: 70, height: 70, marginLeft: 380 }}>
                    <img src={ival} alt="" />
                  </div>
                </div>
              )}
            </div>
          );
        });
      }
    }

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    margin: "-20px",
                    marginTop: "5px",
                    boxShadow: "0px 0px 10px #b9b3b3",
                    borderRadius: "8px"
                  }}
                >
                  <div className="card-header">
                    <h1>Evaluation Checklist F Proof</h1>
                  </div>
                  <div className="card-body">
                    <div>
                      <Alert
                        className="badge-content"
                        color={this.state.color}
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        {this.state.textalert}
                      </Alert>
                      {this.process3()}
                      {/* {json && json.length ? json : null} */}
                      {json1 && json1.length ? json1 : null}
                      {/* {viewFile && viewFile.length ? viewFile : null} */}
                      {this.state.viewvisible && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">

                            <LoginModal
                              buttonTitle="View list of matched cars"
                              id="car"
                              title="Car list"
                              onClick={this.reset}
                              bodyText={
                                <div>
                                  <h1>Car list</h1>
                                  <Datatable
                                    data={this.state.cardata}
                                    columnHeading={this.state.carlist}
                                  />
                                </div>}
                            />

                          </div>
                        </div>
                      )}

                      {this.state.followJson && this.state.followJson.length
                        ? followJson
                        : null}
                      {json3 && json3.length ? json3 : null}

                      {(this.state.visible && json1 && json1.length) ? (
                        <div>
                          <div className="bgcolor">
                            <div class="set-form">
                              <div id="mydiv" class="div div-bordered">
                                {window.innerWidth > 768 ? <div class="row">
                                  <div class="col-sm-3">BUMPERS
                                    {bumperJson && bumperJson.filter(e => e.evaluation == 'Repair' || e.evaluation == 'Replacement' || e.evaluation == 'Average').length ? <button className="btn btn-default btn-sm" value={this.state.bumpfilevisible} onClick={e => { this.setState({ bumpfilevisible: !this.state.bumpfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}</div>
                                  <div class="col-sm-3">EVALUATION</div>
                                  <div class="col-sm-3">REMARKS</div>
                                  <div class="col-sm-3">ESTIMATE</div>
                                </div> : <div class="row">
                                  <div class="col">BUMPERS
                                    {bumperJson && bumperJson.filter(e => e.evaluation == 'Repair' || e.evaluation == 'Replacement' || e.evaluation == 'Average').length ? <button className="btn btn-default btn-sm" value={this.state.bumpfilevisible} onClick={e => { this.setState({ bumpfilevisible: !this.state.bumpfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}</div></div>}
                                <br />
                                {this.state.view.map((ival, ind) => {
                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <p>{ival}</p>
                                        </div>
                                        <div class="col-sm-3">
                                          <SingleSelect
                                            options={this.state.option}
                                            handleChange={e => this.bump(ival, e.label, ind, "evaluation")}
                                            selectedService={bumperJson[ind] && bumperJson[ind].evaluation ?
                                              this.state.option.filter(e => e.label == bumperJson[ind].evaluation)[0] : this.state.option[0]}
                                          />
                                        </div>
                                        <div class="col-sm-3">
                                          {bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control suggest-input"
                                                placeholder="Enter remarks"
                                                id="remarks"
                                                onBlur={e => {
                                                  this.bump(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={bumperJson[ind] && bumperJson[ind] && (bumperJson[ind].evaluation == 'GOOD' || bumperJson[ind].evaluation == 'Good' || bumperJson[ind].evaluation == '') ? true : false}
                                                defaultValue={
                                                  bumperJson[ind]
                                                    ? bumperJson[ind].remarks
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "bumper")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.bumperremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.bumpRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "bumper");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ? <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control suggest-input"
                                                placeholder="Enter remarks"
                                                id={`remarks-bumper-${ind}`}
                                                onBlur={e => {
                                                  this.bump(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={bumperJson[ind] && bumperJson[ind] && (bumperJson[ind].evaluation == 'GOOD' || bumperJson[ind].evaluation == 'Good' || bumperJson[ind].evaluation == '') ? true : false}
                                                defaultValue={
                                                  bumperJson[ind]
                                                    ? bumperJson[ind].remarks
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-bumper-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-bumper", ival, ind, "estimate")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "bumper")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.bumperremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.bumpRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "bumper");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                        <div class="col-sm-3">
                                          {bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control suggest-input"
                                                id="Estimate"
                                                onChange={e => {
                                                  this.bump(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={bumperJson[ind] && bumperJson[ind] && (bumperJson[ind].evaluation == 'GOOD' || bumperJson[ind].evaluation == 'Good' || bumperJson[ind].evaluation == '') ? true : false}
                                                value={
                                                  bumperJson[ind] && bumperJson[ind].estimate
                                                    ? bumperJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "bumper")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.bumperEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.bumpEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.suggescBump(ival, val, ind, "estimate");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ?
                                              <div>
                                                <input
                                                  type="number"
                                                  name="Estimate"
                                                  placeholder="ESTIMATE"
                                                  className="form-control suggest-input"
                                                  id={`estimate-bumper-${ind}`}
                                                  onChange={e => {
                                                    this.bump(
                                                      ival,
                                                      e.target.value,
                                                      ind,
                                                      "estimate"
                                                    );
                                                  }}
                                                  disabled={bumperJson[ind] && bumperJson[ind] && (bumperJson[ind].evaluation == 'GOOD' || bumperJson[ind].evaluation == 'Good' || bumperJson[ind].evaluation == '') ? true : false}
                                                  value={
                                                    bumperJson[ind] && bumperJson[ind].estimate
                                                      ? bumperJson[ind].estimate
                                                      : null
                                                  }
                                                  style={{ resize: "none", width: "100%" }}
                                                ></input>
                                                <div className="mic-icon-3">
                                                  <p
                                                    id={`estimate-bumper-para${ind}`}
                                                    className={`bi bi-mic-fill ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                    onClick={() => this.voiceSetup2("estimate-bumper", ival, ind, "estimate")}>
                                                  </p>
                                                </div>
                                                <div className="history-icon" >
                                                  <i
                                                    className={`bi bi-clock-history histroyicon ${!(bumperJson[ind] && bumperJson[ind].evaluation && bumperJson[ind].evaluation != 'Good') ? 'd-none' : ''}`}
                                                    onClick={(e) => this.turnOffEstimate(e, ind, "bumper")}
                                                  ></i>
                                                </div>
                                                <div className={` subclass-parent ${this.state.bumperEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                  <div className="subclass mx-2">
                                                    {this.state.bumpEstimateSuggestoptions.map((val, i) => (
                                                      <p
                                                        className="paragg"
                                                        onClick={(e) => {
                                                          this.suggescBump(ival, val, ind, "estimate");
                                                        }}
                                                        key={i}
                                                      >
                                                        <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                      </p>

                                                    ))}
                                                  </div>
                                                </div>
                                              </div> : null}
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  );
                                })}

                                {this.state.bumpfilevisible ? <div class="row">
                                  <div class="col-sm-3"></div>
                                  <div class="col-sm-2">File upload :</div>
                                  <div class="col-sm-5"><Camera
                                    image={(e) => { this.webcam1(e, 'filesArray11', 'bumpfilevisible') }}
                                    width={400}
                                    height={590}
                                    id={'LIGHTSfilevisible'}
                                  /></div>
                                </div> : (bumperJson.filter(e => e.evaluation == 'Repair' || e.evaluation == 'Replacement' || e.evaluation == 'Average').length) || this.state.filledbumper && this.state.filledbumper == true ?
                                  (<div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                      // style={{ marginLeft: "26px" }}
                                      >
                                        BUMPERS File Upload:
                                      </label></div>
                                    <div class="col-sm-5">
                                      <FilePond
                                        files={this.state.filesArray11}
                                        allowReorder={true}
                                        allowMultiple={true}
                                        allowImageResize={true}
                                        imagePreviewMinHeight="50"
                                        imagePreviewMaxHeight="50"
                                        //  maxFileSize={'3072KB'}
                                        // imageResizeMode="cover"
                                        acceptedFileTypes={["image/*", "application/pdf"]}
                                        onupdatefiles={fileItems => {
                                          this.fileupload1(fileItems);
                                        }}
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                      />
                                      {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                      <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    </div>
                                  </div>
                                  ) : null}
                                <div class="row">
                                  <div class="col-sm-4"></div>
                                  <div class="col">
                                    {/* {bump && bump.load} */}
                                    {bump && bump}
                                  </div>
                                </div>
                                <br />
                                {window.innerWidth > 768 ? <div class="row">
                                  <div class="col-sm-3">LIGHTS
                                    {lightJson && lightJson.filter(e => e && e.body && e.body == 'Repair' || e.body == 'Replacement' || e.body == 'Average').length > 0 ? <button className="btn btn-default btn-sm" value={this.state.LIGHTSfilevisible} onClick={e => { this.setState({ LIGHTSfilevisible: !this.state.LIGHTSfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}
                                  </div>
                                  <div class="col-sm-3">EVALUATION</div>
                                  <div class="col-sm-3">REMARKS</div>
                                  <div class="col-sm-3">ESTIMATE</div>
                                </div> : <div class="row">
                                  <div class="col">LIGHTS
                                    {lightJson && lightJson.filter(e => e && e.body && e.body == 'Repair' || e.body == 'Replacement' || e.body == 'Average').length > 0 ? <button className="btn btn-default btn-sm" value={this.state.LIGHTSfilevisible} onClick={e => { this.setState({ LIGHTSfilevisible: !this.state.LIGHTSfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}
                                  </div></div>}
                                <br />
                                {this.state.lights.map((ival, ind) => {
                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <p>{ival}</p>
                                        </div>

                                        <div class="col-sm-3">

                                          <SingleSelect
                                            options={this.state.option}
                                            placeholder="Enter RHS"
                                            handleChange={e => {
                                              this.lights(
                                                ival,
                                                e.label,
                                                ind,
                                                "body"
                                              );
                                            }}
                                            selectedService={
                                              lightJson[ind] && lightJson[ind].body
                                                ? this.state.option.filter(e => e.label == lightJson[ind].body)[0]
                                                : this.state.option[0]
                                            }
                                            style={{ resize: "none", width: "100%" }}
                                          />
                                        </div>
                                        <div class="col-sm-3">
                                          {lightJson[ind] && lightJson[ind].body && lightJson[ind].body != 'Good' && window.innerWidth < 768 ? <div>
                                            <input
                                              type="textarea"
                                              name="REMARKS"
                                              className="form-control suggest-input"
                                              placeholder="Enter remarks"
                                              id={`remarks-light-${ind}`}
                                              onBlur={e => {
                                                this.lights(
                                                  ival,
                                                  e.target.value.toUpperCase(),
                                                  ind,
                                                  "remarks"
                                                );
                                              }}
                                              disabled={lightJson[ind] && lightJson[ind] && (lightJson[ind].body == "Good" || lightJson[ind].body == "GOOD" || lightJson[ind].body == "") ? true : false}
                                              defaultValue={
                                                lightJson[ind]
                                                  ? lightJson[ind].remarks
                                                  : null
                                              }
                                              style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                            ></input>
                                            <div className="history-icon" >
                                              <i
                                                className={`bi bi-clock-history histroyicon ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                onClick={(e) => this.turnOffRemarks(e, ind, "lights")}
                                              ></i>
                                            </div>
                                            <div className={` subclass-parent ${this.state.lightremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                                              <div className="subclass mx-2">
                                                {this.state.lightRemarkSuggestoptions.map((val, i) => (
                                                  <p
                                                    className="paragg"
                                                    onClick={(e) => {
                                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "light");
                                                    }}
                                                    key={i}
                                                  >
                                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                  </p>

                                                ))}
                                              </div>
                                            </div>
                                          </div> : (window.innerWidth > 768) ?
                                            <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control suggest-input"
                                                placeholder="Enter remarks"
                                                id={`remarks-light-${ind}`}
                                                onBlur={e => {
                                                  this.lights(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={lightJson[ind] && lightJson[ind] && (lightJson[ind].body == "Good" || lightJson[ind].body == "GOOD" || lightJson[ind].body == "") ? true : false}
                                                defaultValue={
                                                  lightJson[ind]
                                                    ? lightJson[ind].remarks
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-light-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-light", ival, ind, "remark")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "lights")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.lightremarksuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.lightRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "light");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                        <div class="col-sm-3">
                                          {lightJson[ind] && lightJson[ind].body && lightJson[ind].body != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control"
                                                id={`estimate-light-${ind}`}
                                                onChange={e => {
                                                  this.lights(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={lightJson[ind] && lightJson[ind] && (lightJson[ind].body == "Good" || lightJson[ind].body == "GOOD" || lightJson[ind].body == "") ? true : false}
                                                value={
                                                  lightJson[ind] && lightJson[ind].estimate
                                                    ? lightJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "lights")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.lightEstimatesuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.lightEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        if (val === "No suggestion") return;
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "light");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ? <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control"
                                                id={`estimate-light-${ind}`}
                                                onChange={e => {
                                                  this.lights(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={lightJson[ind] && lightJson[ind] && (lightJson[ind].body == "Good" || lightJson[ind].body == "GOOD" || lightJson[ind].body == "") ? true : false}
                                                value={
                                                  lightJson[ind] && lightJson[ind].estimate
                                                    ? lightJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`estimate-light-para${ind}`}
                                                  className={`bi bi-mic-fill  ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("estimate-light", ival, ind, "remark")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(lightJson[ind] && lightJson[ind].body && lightJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "lights")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.lightEstimatesuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.lightEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        if (val === "No suggestion") return;
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "light");
                                                      }}
                                                      key={i}
                                                    >

                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  );
                                })}
                                {this.state.LIGHTSfilevisible ? <div class="row">
                                  <div class="col-sm-2"></div>
                                  <div class="col-sm-2">File upload :</div>
                                  <div class="col-sm-5"><Camera
                                    image={(e) => { this.webcam1(e, 'filesArray2', 'LIGHTSfilevisible') }}
                                    width={400}
                                    height={590}
                                    id={'LIGHTSfilevisible'}
                                  /></div></div> : (lightJson && lightJson.filter(e => e.body == 'Repair' || e.body == 'Replacement' || e.body == 'Average').length > 0) || this.state.filledlight && this.state.filledlight == true ?
                                  (<div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                      // style={{ marginLeft: "26px" }}
                                      >
                                        LIGHTS File Upload:
                                      </label></div>
                                    <div class="col-sm-5">
                                      <FilePond
                                        files={this.state.filesArray2}
                                        allowReorder={true}
                                        allowMultiple={true}
                                        allowImageResize={true}
                                        imagePreviewMinHeight="50"
                                        imagePreviewMaxHeight="50"
                                        //  maxFileSize={'3072KB'}
                                        // imageResizeMode="cover"
                                        acceptedFileTypes={["image/*", "application/pdf"]}
                                        onupdatefiles={fileItems => {
                                          this.fileupload2(fileItems);
                                        }}
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                      />
                                      {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                      <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    </div>
                                  </div>) : null}
                                <div class="row">
                                  <div class="col-sm-2">
                                  </div><div class="col-sm-2"></div>
                                  {/* {light && light.load} */}
                                  <div class="col">
                                    {light && light}
                                  </div>
                                </div>

                                <br />
                                {window.innerWidth > 768 ? <div class="row">
                                  <div class="col-sm-1" />
                                  <div class="col-sm-2">TYRES
                                    {tyersJson && tyersJson.filter(e => e.lhs == 'Repair' || e.rhs == 'Repair' || e.rhs == 'Worst' || e.lhs == 'Worst').length > 0 ? <button className="btn btn-default btn-sm" value={this.state.Tyresfilevisible} onClick={e => { this.setState({ Tyresfilevisible: !this.state.Tyresfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}
                                  </div>
                                  <div class="col-sm-2">RHS</div>
                                  <div class="col-sm-2">LHS</div>
                                  <div class="col-sm-2">REMARKS</div>
                                  <div class="col-sm-2">ESTIMATE</div>
                                  <div class="col-sm-1" />
                                </div> : <div class="row">
                                  <div class="col">TYRES
                                    {tyersJson && tyersJson.filter(e => e.lhs == 'Repair' || e.rhs == 'Repair' || e.rhs == 'Worst' || e.lhs == 'Worst').length > 0 ? <button className="btn btn-default btn-sm" value={this.state.Tyresfilevisible} onClick={e => { this.setState({ Tyresfilevisible: !this.state.Tyresfilevisible }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button> : null}
                                  </div>
                                </div>}
                                <br />
                                {this.state.view.map((ival, ind) => {

                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-1" />
                                        <div class="col-sm-2">
                                          <p>{ival}</p>
                                        </div>
                                        {window.innerWidth < 768 && <div class="col">RHS :</div>}
                                        <div class="col-sm-2">
                                          <SingleSelect
                                            options={this.state.tyreopt}
                                            placeholder="Enter RHS"

                                            handleChange={e => {
                                              this.tyers(
                                                ival,
                                                e.value,
                                                ind,
                                                "rhs"
                                              );
                                            }}

                                            selectedService={
                                              tyersJson[ind] && tyersJson[ind].rhs
                                                ? this.state.tyreopt.filter(e => e.value == tyersJson[ind].rhs)[0]
                                                : this.state.tyreopt[3]
                                            }

                                            style={{ resize: "none", width: "100%" }}
                                          />

                                        </div>
                                        {window.innerWidth < 768 && <div class="col">LHS :</div>}
                                        <div class="col-sm-2">
                                          <SingleSelect
                                            options={this.state.tyreopt}
                                            placeholder="Enter LHS"

                                            handleChange={e => {
                                              this.tyers(
                                                ival,
                                                e.value,
                                                ind,
                                                "lhs"
                                              );
                                            }}

                                            selectedService={
                                              tyersJson[ind]
                                                && tyersJson[ind].lhs ? this.state.tyreopt.filter(e => e.value == tyersJson[ind].lhs)[0]
                                                : this.state.tyreopt[3]
                                            }
                                            style={{ resize: "none", width: "100%" }}
                                          />
                                        </div>
                                        <div class="col-sm-2">
                                          <input
                                            type="textarea"
                                            name="REMARKS"
                                            className="form-control"
                                            placeholder="Enter remarks"
                                            id={`remarks-wheel-${ind}`}
                                            // onChange={e => {
                                            //   this.tyers(
                                            //     ival,
                                            //     e.target.value.toUpperCase(),
                                            //     ind,
                                            //     "remarks"
                                            //   );
                                            // }}
                                            onBlur={e => {
                                              this.tyers(
                                                ival,
                                                e.target.value.toUpperCase(),
                                                ind,
                                                "remarks"
                                              );
                                            }}
                                            defaultValue={
                                              tyersJson[ind]
                                                ? tyersJson[ind].remarks
                                                : null
                                            }
                                            // value={
                                            //   tyersJson[ind]
                                            //     ? tyersJson[ind].remarks
                                            //     : null
                                            // }
                                            style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                          ></input>
                                          <div className="mic-icon-3">
                                            <p
                                              id={`remarks-wheel-para${ind}`}
                                              className={`bi bi-mic-fill`}
                                              onClick={() => this.voiceSetup2("remarks-wheel", ival, ind, "estimate")}>
                                            </p>
                                          </div>
                                          <div className="history-icon1" >
                                            <i
                                              className={`bi bi-clock-history histroyicon `}
                                              onClick={(e) => this.turnOffRemarks(e, ind, "tyre")}
                                            ></i>
                                          </div>
                                          <div className={` subclass-parent ${this.state.tyreremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                            <div className="subclass mx-2">
                                              {this.state.tyreRemarkSuggestoptions.map((val, i) => (
                                                <p
                                                  className="paragg"
                                                  onClick={(e) => {
                                                    this.handleSuggestionSelect(ival, val, ind, "remarks", "tyre");
                                                  }}
                                                  key={i}
                                                >
                                                  <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                </p>

                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-2">
                                          <input
                                            type="number"
                                            name="Estimate"
                                            placeholder="ESTIMATE"
                                            className="form-control input-suggest"
                                            id={`estimate-wheel-${ind}`}
                                            onChange={e => {
                                              this.tyers(
                                                ival,
                                                e.target.value,
                                                ind,
                                                "estimate"
                                              );
                                            }}
                                            disabled={tyersJson[ind] && tyersJson[ind].evaluation == 'Good' ? true : false}
                                            value={
                                              tyersJson[ind] && tyersJson[ind].estimate
                                                ? tyersJson[ind].estimate
                                                : null
                                            }
                                            style={{ resize: "none", width: "100%" }}
                                          ></input>
                                          <div className="mic-icon-3">
                                            <p
                                              id={`estimate-wheel-para${ind}`}
                                              className={`bi bi-mic-fill`}
                                              onClick={() => this.voiceSetup2("estimate-wheel", ival, ind, "estimate")}>
                                            </p>
                                          </div>
                                          <div className="history-icon" >
                                            <i
                                              className={`bi bi-clock-history histroyicon `}
                                              onClick={(e) => this.turnOffEstimate(e, ind, "tyre")}
                                            ></i>
                                          </div>
                                          <div className={` subclass-parent ${this.state.tyreEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                            <div className="subclass mx-2">
                                              {this.state.tyreEstimateSuggestoptions.map((val, i) => (
                                                <p
                                                  className="paragg"
                                                  onClick={(e) => {
                                                    this.handleSuggestionSelect(ival, val, ind, "estimate", "tyre");
                                                  }}
                                                  key={i}
                                                >
                                                  <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                </p>

                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  );
                                })}
                                {this.state.Tyresfilevisible ? <div class="row">
                                  <div class="col-sm-2"></div>
                                  <div class="col-sm-2">File upload :</div>
                                  <div class="col-sm-5"><Camera
                                    image={(e) => { this.webcam1(e, 'filesArray3', 'Tyresfilevisible') }}
                                    width={400}
                                    height={590}
                                    id={'Tyresfilevisible'}
                                  /></div></div> : (tyersJson && tyersJson.filter(e => e.lhs == 'Repair' || e.rhs == 'Repair' || e.rhs == 'Worst' || e.lhs == 'Worst').length > 0) || this.state.filledtyre && this.state.filledtyre == true ?
                                  (<div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2"> <label
                                      htmlFor="exampleInputEmail1"
                                    // style={{ marginLeft: "26px]" }}
                                    >
                                      Tyres File Upload:
                                    </label></div>
                                    <div class="col-sm-5">
                                      <FilePond
                                        files={this.state.filesArray3}
                                        allowReorder={true}
                                        allowMultiple={true}
                                        allowImageResize={true}
                                        imagePreviewMinHeight="50"
                                        imagePreviewMaxHeight="50"
                                        //  maxFileSize={'3072KB'}
                                        // imageResizeMode="cover"
                                        acceptedFileTypes={["image/*", "application/pdf"]}
                                        onupdatefiles={fileItems => {
                                          this.fileupload3(fileItems);
                                        }}
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                      />
                                      {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                      <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    </div><div class="col"></div>
                                  </div>
                                  ) : null
                                }
                                <div class="row">
                                  <div class="col-sm-4"></div>
                                  <div class="col">
                                    {tyre && tyre}

                                  </div>
                                  {/* {tyre && tyre.load} */}
                                </div>

                                <br />
                                {window.innerWidth > 768 ? <div class="row">
                                  <div class="col-sm-3">OTHERS</div>
                                  <div class="col-sm-3">EVALUATION</div>
                                  <div class="col-sm-3">REMARKS</div>
                                  <div class="col-sm-3">ESTIMATE</div>
                                </div> : <div class="row">
                                  <div class="col">OTHERS</div>
                                </div>}
                                <br />
                                {this.state.wheel.map((ival, ind) => {
                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <p>{ival}</p>
                                        </div>
                                        {ival == "TOOL KIT&JACK" ? (<div class="col-sm-3">
                                          <SingleSelect
                                            options={this.state.option2}
                                            handleChange={e => {
                                              this.wheeelfun(
                                                ival, e.value, ind, "body"
                                              )
                                            }}
                                            selectedService={wheeklJson[ind] && wheeklJson[ind].body ?
                                              this.state.option2.filter(e => e.value == wheeklJson[ind].body)[0] : this.state.option2[0]}
                                          />
                                        </div>) :
                                          (<div class="col-sm-3">
                                            <SingleSelect
                                              options={this.state.option}
                                              handleChange={e => {
                                                this.wheeelfun(
                                                  ival, e.label, ind, "body"
                                                )
                                              }}
                                              selectedService={wheeklJson[ind] && wheeklJson[ind].body ?
                                                this.state.option.filter(e => e.label == wheeklJson[ind].body)[0] : this.state.option[0]}
                                            />
                                          </div>)}
                                        <div class="col-sm-3">
                                          {wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body != 'Good' && window.innerWidth < 768 ? <div>
                                            <input
                                              type="textarea"
                                              name="REMARKS"
                                              className="form-control suggest-input"
                                              placeholder="Enter remarks"
                                              id={`remarks-wheel1-${ind}`}
                                              value={
                                                wheeklJson[ind] && wheeklJson[ind].remarks
                                                  ? wheeklJson[ind].remarks
                                                  : null
                                              }
                                              onBlur={e => {
                                                this.wheeelfun(
                                                  ival,
                                                  e.target.value.toUpperCase(),
                                                  ind,
                                                  "remarks"
                                                );
                                              }}
                                              disabled={wheeklJson[ind] && wheeklJson[ind] && (wheeklJson[ind].body == 'GOOD' || wheeklJson[ind].body == 'Good' || wheeklJson[ind].body == '') ? true : false}
                                              defaultValue={
                                                wheeklJson[ind]
                                                  ? wheeklJson[ind].remarks
                                                  : null
                                              }

                                              style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                            ></input>
                                            <p
                                              id={`remarks-wheel1-para${ind}`}
                                              className={`bi bi-mic-fill ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                              onClick={() => this.voiceSetup2("remarks-wheel1", ival, ind, "remarks")}>
                                            </p>
                                            <div className="history-icon" >
                                              <i
                                                className={`bi bi-clock-history histroyicon ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                onClick={(e) => this.turnOffRemarks(e, ind, "wheel")}
                                              ></i>
                                            </div>
                                            <div className={` subclass-parent ${this.state.WheelremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                              <div className="subclass mx-2">
                                                {this.state.wheelRemarkSuggestoptions.map((val, i) => (
                                                  <p
                                                    className="paragg"
                                                    onClick={(e) => {
                                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "wheel");
                                                    }}
                                                    key={i}
                                                  >
                                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                  </p>

                                                ))}
                                              </div>
                                            </div>
                                          </div> : (window.innerWidth > 768) ?
                                            <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control suggest-input"
                                                placeholder="Enter remarks"
                                                id={`remarks-wheel1-${ind}`}
                                                value={
                                                  wheeklJson[ind] && wheeklJson[ind].remarks
                                                    ? wheeklJson[ind].remarks
                                                    : null
                                                }
                                                onBlur={e => {
                                                  this.wheeelfun(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={wheeklJson[ind] && wheeklJson[ind] && (wheeklJson[ind].body == 'GOOD' || wheeklJson[ind].body == 'Good' || wheeklJson[ind].body == '') ? true : false}
                                                defaultValue={
                                                  wheeklJson[ind]
                                                    ? wheeklJson[ind].remarks
                                                    : null
                                                }

                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-wheel1-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-wheel1", ival, ind, "remarks")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "wheel")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.WheelremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.wheelRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "wheel");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                            : null}
                                        </div>
                                        <div class="col-sm-3">
                                          {wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control suggest-input"
                                                id={`estimate-wheel1-${ind}`}
                                                onChange={e => {
                                                  this.wheeelfun(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={wheeklJson[ind] && wheeklJson[ind] && (wheeklJson[ind].body == 'GOOD' || wheeklJson[ind].body == 'Good' || wheeklJson[ind].body == '') ? true : false}

                                                value={
                                                  wheeklJson[ind] && wheeklJson[ind].estimate
                                                    ? wheeklJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`estimate-wheel1-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("estimate-wheel1", ival, ind, "estimate")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "wheel")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.WheelEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.wheelEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "wheel");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ?
                                              <div>
                                                <input
                                                  type="number"
                                                  name="Estimate"
                                                  placeholder="ESTIMATE"
                                                  className="form-control suggest-input"
                                                  id={`estimate-wheel1-${ind}`}
                                                  onChange={e => {
                                                    this.wheeelfun(
                                                      ival,
                                                      e.target.value,
                                                      ind,
                                                      "estimate"
                                                    );
                                                  }}
                                                  disabled={wheeklJson[ind] && wheeklJson[ind] && (wheeklJson[ind].body == 'GOOD' || wheeklJson[ind].body == 'Good' || wheeklJson[ind].body == '') ? true : false}

                                                  value={
                                                    wheeklJson[ind] && wheeklJson[ind].estimate
                                                      ? wheeklJson[ind].estimate
                                                      : null
                                                  }
                                                  style={{ resize: "none", width: "100%" }}
                                                ></input>
                                                <div className="mic-icon-3">
                                                  <p
                                                    id={`estimate-wheel1-para${ind}`}
                                                    className={`bi bi-mic-fill ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                    onClick={() => this.voiceSetup2("estimate-wheel1", ival, ind, "estimate")}>
                                                  </p>
                                                </div>
                                                <div className="history-icon" >
                                                  <i
                                                    className={`bi bi-clock-history histroyicon ${!(wheeklJson[ind] && wheeklJson[ind].body && wheeklJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                    onClick={(e) => this.turnOffEstimate(e, ind, "wheel")}
                                                  ></i>
                                                </div>
                                                <div className={` subclass-parent ${this.state.WheelEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                  <div className="subclass mx-2">
                                                    {this.state.wheelEstimateSuggestoptions.map((val, i) => (
                                                      <p
                                                        className="paragg"
                                                        onClick={(e) => {
                                                          this.handleSuggestionSelect(ival, val, ind, "estimate", "wheel");
                                                        }}
                                                        key={i}
                                                      >
                                                        <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                      </p>

                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                              : null}
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  );
                                })}

                                {this.state.wind.map((ival, ind) => {
                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <p>{ival}</p>
                                        </div>

                                        <div class="col-sm-3">
                                          <SingleSelect
                                            options={this.state.option}
                                            handleChange={e => {
                                              this.windfun(
                                                ival, e.label, ind, "body"
                                              )
                                            }}
                                            selectedService={windJson[ind] && windJson[ind].body ?
                                              this.state.option.filter(e => e.label == windJson[ind].body)[0] : this.state.option[0]}
                                          />
                                        </div>
                                        <div class="col-sm-3">
                                          {windJson[ind] && windJson[ind].body && windJson[ind].body != 'Good' && window.innerWidth < 768 ? <div>
                                            <input
                                              type="textarea"
                                              name="REMARKS"
                                              className="form-control"
                                              placeholder="Enter remarks"
                                              id="remarks"

                                              onBlur={e => {
                                                this.windfun(
                                                  ival,
                                                  e.target.value.toUpperCase(),
                                                  ind,
                                                  "remarks"
                                                );
                                              }}
                                              disabled={windJson[ind] && windJson[ind] && (windJson[ind].body == 'GOOD' || windJson[ind].body == 'Good' || windJson[ind].body == '') ? true : false}
                                              defaultValue={
                                                windJson[ind]
                                                  ? windJson[ind].remarks
                                                  : null
                                              }
                                              style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                            ></input>
                                            <div className="history-icon" >
                                              <i
                                                className={`bi bi-clock-history histroyicon ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                onClick={(e) => this.turnOffRemarks(e, ind, "wind")}
                                              ></i>
                                            </div>
                                            <div className={` subclass-parent ${this.state.windremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                              <div className="subclass mx-2">
                                                {this.state.windRemarkSuggestoptions.map((val, i) => (
                                                  <p
                                                    className="paragg"
                                                    onClick={(e) => {
                                                      this.handleSuggestionSelect(ival, val, ind, "remarks", "wind");
                                                    }}
                                                    key={i}
                                                  >
                                                    <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                  </p>

                                                ))}
                                              </div>
                                            </div>
                                          </div> : (window.innerWidth > 768) ?
                                            <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control"
                                                placeholder="Enter remarks"
                                                id={`remarks-wind-${ind}`}

                                                onBlur={e => {
                                                  this.windfun(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={windJson[ind] && windJson[ind] && (windJson[ind].body == 'GOOD' || windJson[ind].body == 'Good' || windJson[ind].body == '') ? true : false}
                                                defaultValue={
                                                  windJson[ind]
                                                    ? windJson[ind].remarks
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-wind-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-wind", ival, ind, "remarks")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "wind")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.windremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.windRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "wind");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                        <div class="col-sm-3">
                                          {windJson[ind] && windJson[ind].body && windJson[ind].body != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control"
                                                id="Estimate"
                                                onChange={e => {
                                                  this.windfun(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={windJson[ind] && windJson[ind] && (windJson[ind].body == 'GOOD' || windJson[ind].body == 'Good' || windJson[ind].body == '') ? true : false}
                                                value={
                                                  windJson[ind] && windJson[ind].estimate
                                                    ? windJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "wind")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.windEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.windEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "wind");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ?
                                              <div>
                                                <input
                                                  type="number"
                                                  name="Estimate"
                                                  placeholder="ESTIMATE"
                                                  className="form-control"
                                                  id={`estimate-wind-${ind}`}
                                                  onChange={e => {
                                                    this.windfun(
                                                      ival,
                                                      e.target.value,
                                                      ind,
                                                      "estimate"
                                                    );
                                                  }}
                                                  disabled={windJson[ind] && windJson[ind] && (windJson[ind].body == 'GOOD' || windJson[ind].body == 'Good' || windJson[ind].body == '') ? true : false}
                                                  value={
                                                    windJson[ind] && windJson[ind].estimate
                                                      ? windJson[ind].estimate
                                                      : null
                                                  }
                                                  style={{ resize: "none", width: "100%" }}
                                                ></input>
                                                <div className="mic-icon-3">
                                                  <p
                                                    id={`estimate-wind-para${ind}`}
                                                    className={`bi bi-mic-fill ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                    onClick={() => this.voiceSetup2("estimate-wind", ival, ind, "estimate")}>
                                                  </p>
                                                </div>
                                                <div className="history-icon" >
                                                  <i
                                                    className={`bi bi-clock-history histroyicon ${!(windJson[ind] && windJson[ind].body && windJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                    onClick={(e) => this.turnOffEstimate(e, ind, "wind")}
                                                  ></i>
                                                </div>
                                                <div className={` subclass-parent ${this.state.windEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                  <div className="subclass mx-2">
                                                    {this.state.windEstimateSuggestoptions.map((val, i) => (
                                                      <p
                                                        className="paragg"
                                                        onClick={(e) => {
                                                          this.handleSuggestionSelect(ival, val, ind, "estimate", "wind");
                                                        }}
                                                        key={i}
                                                      >
                                                        <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                      </p>

                                                    ))}
                                                  </div>
                                                </div>
                                              </div> : null}
                                        </div>
                                      </div>
                                      <br />
                                    </>)
                                })}
                                {this.state.pro.map((ival, ind) => {
                                  return (
                                    <>
                                      <div class="row">
                                        <div class="col-sm-3">
                                          <p>{ival}</p>
                                        </div>
                                        <div class="col-sm-3">
                                          <SingleSelect
                                            options={this.state.option}
                                            handleChange={e => {
                                              this.profun(
                                                ival, e.label, ind, "body"
                                              )
                                            }}
                                            selectedService={proJson[ind] && proJson[ind].body ?
                                              this.state.option.filter(e => e.label == proJson[ind].body)[0] : this.state.option[0]
                                            }
                                          />
                                        </div>
                                        <div class="col-sm-3">
                                          {proJson[ind] && proJson[ind].body && proJson[ind].body != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control"
                                                placeholder="Enter remarks"
                                                id={`remarks-pro-${ind}`}
                                                // onChange={e => {
                                                //   this.profun(
                                                //     ival,
                                                //     e.target.value.toUpperCase(),
                                                //     ind,
                                                //     "remarks"
                                                //   );
                                                // }}
                                                onBlur={e => {
                                                  this.profun(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                value={
                                                  proJson[ind] && proJson[ind].estimate
                                                    ? proJson[ind].remarks
                                                    : null
                                                }
                                                disabled={proJson[ind] && proJson[ind] && (proJson[ind].body == 'GOOD' || proJson[ind].body == 'Good' || proJson[ind].body == '') ? true : false}
                                                defaultValue={
                                                  proJson[ind]
                                                    ? proJson[ind].remarks
                                                    : null
                                                }
                                                // value={
                                                //   proJson[ind]
                                                //     ? proJson[ind].remarks
                                                //     : null
                                                // }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-pro-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-pro", ival, ind, "remarks")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "pro")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.proremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.proRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "pro");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                            : (window.innerWidth > 768) ? <div>
                                              <input
                                                type="textarea"
                                                name="REMARKS"
                                                className="form-control"
                                                placeholder="Enter remarks"
                                                id="remarks"
                                                // onChange={e => {
                                                //   this.profun(
                                                //     ival,
                                                //     e.target.value.toUpperCase(),
                                                //     ind,
                                                //     "remarks"
                                                //   );
                                                // }}
                                                onBlur={e => {
                                                  this.profun(
                                                    ival,
                                                    e.target.value.toUpperCase(),
                                                    ind,
                                                    "remarks"
                                                  );
                                                }}
                                                disabled={proJson[ind] && proJson[ind] && (proJson[ind].body == 'GOOD' || proJson[ind].body == 'Good' || proJson[ind].body == '') ? true : false}
                                                defaultValue={
                                                  proJson[ind]
                                                    ? proJson[ind].remarks
                                                    : null
                                                }
                                                //  value={
                                                //    proJson[ind]
                                                //      ? proJson[ind].remarks
                                                //      : null
                                                //  }
                                                style={{ resize: "none", width: "100%", textTransform: "uppercase" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`remarks-pro-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("remarks-pro", ival, ind, "remarks")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffRemarks(e, ind, "pro")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.proremarkSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.proRemarkSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "remarks", "pro");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>
                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                        <div class="col-sm-3">
                                          {proJson[ind] && proJson[ind].body && proJson[ind].body != 'Good' && window.innerWidth < 768 ?
                                            <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control"
                                                id={`estimate-pro-${ind}`}
                                                onChange={e => {
                                                  this.profun(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={proJson[ind] && proJson[ind] && (proJson[ind].body == 'GOOD' || proJson[ind].body == 'Good' || proJson[ind].body == '') ? true : false}
                                                value={
                                                  proJson[ind] && proJson[ind].estimate
                                                    ? proJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`estimate-pro-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("estimate-pro", ival, ind, "remarks")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "pro")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.proEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.proEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "pro");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : (window.innerWidth > 768) ? <div>
                                              <input
                                                type="number"
                                                name="Estimate"
                                                placeholder="ESTIMATE"
                                                className="form-control"
                                                id={`estimate-pro-${ind}`}
                                                onChange={e => {
                                                  this.profun(
                                                    ival,
                                                    e.target.value,
                                                    ind,
                                                    "estimate"
                                                  );
                                                }}
                                                disabled={proJson[ind] && proJson[ind] && (proJson[ind].body == 'GOOD' || proJson[ind].body == 'Good' || proJson[ind].body == '') ? true : false}
                                                value={
                                                  proJson[ind] && proJson[ind].estimate
                                                    ? proJson[ind].estimate
                                                    : null
                                                }
                                                style={{ resize: "none", width: "100%" }}
                                              ></input>
                                              <div className="mic-icon-3">
                                                <p
                                                  id={`estimate-pro-para${ind}`}
                                                  className={`bi bi-mic-fill ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={() => this.voiceSetup2("estimate-pro", ival, ind, "estimate")}>
                                                </p>
                                              </div>
                                              <div className="history-icon" >
                                                <i
                                                  className={`bi bi-clock-history histroyicon ${!(proJson[ind] && proJson[ind].body && proJson[ind].body !== 'Good') ? 'd-none' : ''}`}
                                                  onClick={(e) => this.turnOffEstimate(e, ind, "pro")}
                                                ></i>
                                              </div>
                                              <div className={` subclass-parent ${this.state.proEstimateSuggest[ind]?.value ? '' : 'd-none'}`}>
                                                <div className="subclass mx-2">
                                                  {this.state.proEstimateSuggestoptions.map((val, i) => (
                                                    <p
                                                      className="paragg"
                                                      onClick={(e) => {
                                                        this.handleSuggestionSelect(ival, val, ind, "estimate", "pro");
                                                      }}
                                                      key={i}
                                                    >
                                                      <i class="bi bi-arrow-ri ght-short arrow-icon"></i>   {val}
                                                    </p>

                                                  ))}
                                                </div>
                                              </div>
                                            </div> : null}
                                        </div>
                                      </div>
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          {/* <div class="set-form table-responsive"> */}
                          {/* </div>
                          </div> */}


                          {(json1.length > 0 && this.state.bidviewform) && (
                            <div className="bgcolor">
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label >Name of insurance company {<button className="btn btn-default btn-sm" value={this.state.Isinsurancecam} onClick={e => { this.setState({ Isinsurancecam: !this.state.Isinsurancecam }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}</label>
                                </div>
                                <div className="col-sm-4">
                                  <SingleSelect
                                    options={this.state.insurance_opn}
                                    onChange={e => this.cng(e, "ins")}
                                    selectedService={this.state.insurance_val} />
                                </div>
                              </div>
                              {this.state.Isinsurancecam ? <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    Insurance Upload :
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <Camera
                                    image={(e) => { this.webcam1(e, 'insuranceFiles', 'Isinsurancecam') }}
                                    width={400}
                                    height={590}
                                    id={'Isinsurancecam'}
                                  />
                                </div>
                              </div> : <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    Insurance Upload :
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <FilePond
                                    files={this.state.insuranceFiles}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.insfileupload(fileItems);
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              </div>}
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.policy_img1 && this.state.policy_img1}
                                </div>
                              </div>

                            </div>
                          )}
                          {(json1.length > 0 && this.state.bidviewform) && (
                            <div className="bgcolor">
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <lable
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    RC {<button className="btn btn-default btn-sm" value={this.state.Isrcfileuploadcam} onClick={e => { this.setState({ Isrcfileuploadcam: !this.state.Isrcfileuploadcam }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:&nbsp;
                                  </lable>
                                </div>
                                <div className="col-sm-4">
                                  <SingleSelect
                                    options={this.state.rcoptions}
                                    onChange={e => this.cng(e, "rc")}
                                    selectedService={this.state.rcval}
                                  />
                                </div>
                              </div>
                              {this.state.Isrcfileuploadcam ? <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    RC Upload :
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <Camera
                                    image={(e) => { this.webcam1(e, 'rcfileupload', 'Isrcfileuploadcam') }}
                                    width={400}
                                    height={590}
                                    id={'Isrcfileuploadcam'}
                                  />
                                </div>
                              </div> : <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    RC Upload:
                                  </label>
                                </div>
                                <div className="col-sm-3">
                                  <FilePond
                                    files={this.state.rcfileupload}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    //  maxFileSize={'3072KB'}
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.rcfileuploadfun(fileItems);
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                  {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                  <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                </div>
                              </div>}
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-3">
                                  {this.state.rc_img1 && this.state.rc_img1}
                                </div>
                              </div>
                            </div>
                          )}

                          {(json1.length > 0 && this.state.bidviewform) && (
                            <div className="bgcolor">
                              <div className="row form-group">
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  //  style={{ marginLeft: "26px" }}
                                  >
                                    CAR IMAGES UPLOAD:
                                  </label>
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >

                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    LHS VIEWS {<button className="btn btn-default btn-sm" value={this.state.IsLHS} onClick={e => { this.setState({ IsLHS: !this.state.IsLHS }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:
                                  </label>
                                </div>
                                {this.state.IsLHS ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnap', 'LHS', 'IsLHS') }}
                                      width={400}
                                      height={590}
                                      id={'IsLHS'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnap[0].LHS}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carImageUpload(fileItems, 'LHS');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleImg && this.state.vehicleImg.LHS}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >

                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "26px" }}
                                  >
                                    RHS VIEWS {<button className="btn btn-default btn-sm" value={this.state.IsRHS} onClick={e => { this.setState({ IsRHS: !this.state.IsRHS }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:
                                  </label>
                                </div>
                                {this.state.IsRHS ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnap', 'RHS', 'IsRHS') }}
                                      width={400}
                                      height={590}
                                      id={'IsRHS'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnap[0].RHS}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carImageUpload(fileItems, 'RHS');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleImg && this.state.vehicleImg.RHS}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    // style={{ marginLeft: "6px" }}
                                    style={{ color: "orange" }}
                                  >FRONT VIEWS * {<button className="btn btn-default btn-sm" value={this.state.IsFRONT} onClick={e => { this.setState({ IsFRONT: !this.state.IsFRONT }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}:
                                  </label>
                                </div>
                                {this.state.IsFRONT ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnap', 'FRONT', 'IsFRONT') }}
                                      width={400}
                                      height={590}
                                      id={'IsFRONT'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnap[0].FRONT}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carImageUpload(fileItems, 'FRONT');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                    <p style={{ color: "red", marginLeft: "10px", fontSize: "13px" }}>
                                      Note : Front View Image Upload is Used for Upcoming Cars Sectiion
                                    </p>
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleImg && this.state.vehicleImg.FRONT}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "14px" }}
                                  >BACK VIEWS  {<button className="btn btn-default btn-sm" value={this.state.IsBACK} onClick={e => { this.setState({ IsBACK: !this.state.IsBACK }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}:
                                  </label>
                                </div>
                                {this.state.IsBACK ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnap', 'BACK', 'IsBACK') }}
                                      width={400}
                                      height={590}
                                      id={'IsBACK'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnap[0].BACK}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carImageUpload(fileItems, 'BACK');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleImg && this.state.vehicleImg.BACK}
                                </div>
                              </div>
                            </div>

                          )}
                          {(json1.length > 0 && this.state.bidviewform) && (
                            <div className="bgcolor">
                              <div className="row form-group">
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  //  style={{ marginLeft: "26px" }}
                                  >
                                    INTERIOR IMAGE UPLOAD:
                                  </label>
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >

                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "-50px" }}
                                  >
                                    Interior from front left{<button className="btn btn-default btn-sm" value={this.state.Isifleft} onClick={e => { this.setState({ Isifleft: !this.state.Isifleft }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:
                                  </label>
                                </div>
                                {this.state.Isifleft ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnaps1', 'ifleft', 'Isifleft') }}
                                      width={400}
                                      height={590}
                                      id={'Isifleft'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnaps1[0].ifleft}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carInnerImageUpload(fileItems, 'ifleft');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.ifleft}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >

                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "-59px" }}
                                  >
                                    Interior from front right{<button className="btn btn-default btn-sm" value={this.state.Isifright} onClick={e => { this.setState({ Isifright: !this.state.Isifright }) }}>
                                      <MDBIcon icon="camera" size="lg" />
                                    </button>}:
                                  </label>
                                </div>
                                {this.state.Isifright ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnaps1', 'ifright', 'Isifright') }}
                                      width={400}
                                      height={590}
                                      id={'Isifright'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnaps1[0].ifright}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carInnerImageUpload(fileItems, 'ifright');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.ifright}
                                </div>
                              </div>
                              {/* <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-1" />
                                <label
                                  htmlFor="exampleInputEmail1"
                                  style={{ marginLeft: "-51px" }}
                                >Interior from back left:
                                </label>
                                <div className="col-sm-3">
                                  <FilePond
                                    files={fileSnaps1[0].ibleft}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    // imageResizeMode="cover"
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.carInnerImageUpload(fileItems, 'ibleft');
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                </div>
                              </div> */}
                              {/* <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.ibleft}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-1" />
                                <label
                                  htmlFor="exampleInputEmail1"
                                  style={{ marginLeft: "-59px" }}
                                >Interior from back right:
                                </label>
                                <div className="col-sm-3">
                                  <FilePond
                                    files={fileSnaps1[0].ibright}
                                    allowReorder={true}
                                    allowMultiple={true}
                                    allowImageResize={true}
                                    imagePreviewMinHeight="50"
                                    imagePreviewMaxHeight="50"
                                    // imageResizeMode="cover"
                                    acceptedFileTypes={["image/*", "application/pdf"]}
                                    onupdatefiles={fileItems => {
                                      this.carInnerImageUpload(fileItems, 'ibright');
                                    }}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                  />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.ibright}
                                </div>
                              </div> */}

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "27px" }}
                                  >Dashboard {<button className="btn btn-default btn-sm" value={this.state.Isdashborad} onClick={e => { this.setState({ Isdashborad: !this.state.Isdashborad }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}:
                                  </label>
                                </div>
                                {this.state.Isdashborad ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnaps1', 'dashborad', 'Isdashborad') }}
                                      width={400}
                                      height={590}
                                      id={'Isdashborad'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnaps1[0].dashborad}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carInnerImageUpload(fileItems, 'dashborad');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.dashborad}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "5px" }}
                                  >Meter console {<button className="btn btn-default btn-sm" value={this.state.Ismeter} onClick={e => { this.setState({ Ismeter: !this.state.Ismeter }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}:
                                  </label>
                                </div>
                                {this.state.Ismeter ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnaps1', 'meter', 'Ismeter') }}
                                      width={400}
                                      height={590}
                                      id={'Ismeter'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnaps1[0].meter}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carInnerImageUpload(fileItems, 'meter');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.meter}
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" >
                                  <label
                                    htmlFor="exampleInputEmail1"
                                  // style={{ marginLeft: "18px" }}
                                  >Engine room {<button className="btn btn-default btn-sm" value={this.state.Isengine} onClick={e => { this.setState({ Isengine: !this.state.Isengine }) }}>
                                    <MDBIcon icon="camera" size="lg" />
                                  </button>}:
                                  </label>
                                </div>
                                {this.state.Isengine ?
                                  <div className="col-sm-3">
                                    <Camera
                                      image={(e) => { this.webcam2(e, 'fileSnaps1', 'engine', 'Isengine') }}
                                      width={400}
                                      height={590}
                                      id={'Isengine'}
                                    />
                                  </div>
                                  : <div className="col-sm-3">
                                    <FilePond
                                      files={fileSnaps1[0].engine}
                                      allowReorder={true}
                                      allowMultiple={true}
                                      allowImageResize={true}
                                      imagePreviewMinHeight="50"
                                      imagePreviewMaxHeight="50"
                                      //  maxFileSize={'3072KB'}
                                      // imageResizeMode="cover"
                                      acceptedFileTypes={["image/*", "application/pdf"]}
                                      onupdatefiles={fileItems => {
                                        this.carInnerImageUpload(fileItems, 'engine');
                                      }}
                                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    />
                                    {/* <label style={{ color: "red" }} >Dimension : {`315 * 235`}</label>
                                    <label style={{ color: "red" }} >Maximum Size : 3MB</label><br /> */}
                                  </div>}
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4"></div>
                                <div className="col">
                                  {this.state.vehicleINTImg && this.state.vehicleINTImg.engine}
                                </div>
                              </div>
                            </div>

                          )}
                          <div className="bgcolor">
                            <div className="row form-group">
                              <div className="col">
                                <CanvasMap CanvasArr={this.canvasmapping} CanvasVals={CanvasVals} />
                              </div>
                            </div>
                          </div>
                          <div className="bgcolor">
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" >
                                <lable
                                >
                                  NO OF PANEL REPAIRS
                                </lable>
                              </div>
                              <div className="col-sm-4">
                                <SingleSelect
                                  options={this.state.PanelRepairopt}
                                  onChange={e => this.answers(e, "PanelRepair")}
                                  selectedService={this.state.PanelRepair}
                                />
                              </div>
                            </div>
                          </div>

                          {CalculateJson && CalculateJson.length
                            ? CalculateJson
                            : null}
                          <div className="bgcolor">
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="position"
                                  style={{ marginTop: 10 }}>
                                  Final Price </label>
                              </div>
                              <div className="col-sm-5">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={"Final Price"}
                                  onChange={(e) => this.setState({ finalprice: e.target.value })}
                                  value={this.state.finalprice}
                                />
                                <div className="history-icon" >
                                  <i
                                    className={`bi bi-clock-history histroyicon`}
                                    onClick={(e) => this.turnoffOthers("finalPrice")}
                                  ></i>
                                </div>
                                <div className={` subclass-parent ${this.state.vehclassFinalPrice ? '' : 'd-none'}`}>
                                  <div className="subclass mx-2">
                                    {this.state.evalFinalPrice.map((val, i) => (
                                      <p
                                        className="paragg"
                                        onClick={(e) => {
                                          this.handleSuggestionSelect2(val, "finalPrice")
                                        }}
                                        key={i}
                                      >
                                        <i class="bi bi-arrow-ri ght-short arrow-icon"></i>
                                        {val}
                                      </p>

                                    ))}
                                  </div>
                                </div>
                                <span className="error-shows">
                                </span>
                              </div>
                              <div className="col-sm-3"></div>
                            </div>
                          </div>
                          {/* <label style={{ marginLeft: "26px" }}>Leads Available:</label> {this.state.leadsavailable.length} */}
                          {followjsonbproof && followjsonbproof.length > 0 ? followjsonbproof : null}
                        </div>
                      ) : <div>Loading...</div>}

                      {json1.length > 0 && (
                        <div>
                          {this.state.global && (
                            <div className="row form-group">
                              {/* <div className="col-sm-2" >
                              </div>
                              <div className="col-sm-2" /> */}
                              <div className="col text-center">
                                {[1, 2, 3].includes(this.state.usertype.orderBy) ? (<button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    button == "Save"
                                      ? this.sub
                                      : this.upd
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  {button == "Save" ? "Save" : "Update"}
                                </button>) : button == "Save" ? (<button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={this.state.btnDisable}
                                  onClick={
                                    this.sub
                                  }
                                >
                                  {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                  Save
                                </button>) : null}
                                <div className="col-sm-4" >
                                  {this.state.error}
                                </div>
                              </div>


                            </div>
                          )}
                          {this.state.global && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2" />
                              <div className="col-sm-5">

                              </div>
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment >
    );
  }
}
export default Evaluation_Checklist_F_Proof;
