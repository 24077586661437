import React, { Component } from "react";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { FormMiddleWare, MultiSelect } from "../../../../components/Form";
import moment from "moment";
import Datatable from "../../../../components/Datatable/Datatable";
import SingleSelect from "../../../../components/Form/SingleSelect";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ifft } from "@tensorflow/tfjs";
// import { QrReader } from 'react-qr-reader';

class InventoryList extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      Car_Content: [],
      filterdata: [],
      maintence_work: "",
      leadsavailable: [],
      customerId: localStorage.getItem("userId"),
      User: JSON.parse(localStorage.getItem("userlog")),
      EnquiryData: [],
      Completed_data: [],
      Sold_data: [],
      OnGoing_data: [],
      dashboard: [],
      isView: false,
      FullData: [],
      data1: [],
      Rejected_data: [],
      Current_User: [],
      amount: "",
      data2: [],
      AuctionData: [],
      dataTablShow: false,
      alertVisible: false,
      dowenlineOption: [
        { label: "POC", value: 1 },
        { label: "Manager", value: 2 },
        { label: "TeamLeader", value: 3 },
      ],
      userTypeBuyingOption: [
        { label: "POC", value: 1 },
        { label: "Manager", value: 2 },
        { label: "TeamLeader", value: 3 },
        { label: "Evaluator", value: 9 },
        { label: "Refurbishment Technician", value: 5 },
        { label: "Refurbishment Incharge", value: 6 },
      ],
      userTypeSellingOption: [
        { label: "POC", value: 1 },
        { label: "Manager", value: 2 },
        { label: "TeamLeader", value: 3 },
        { label: "SalesConsultant", value: 8 },
        { label: "Refurbishment Technician", value: 5 },
        { label: "Refurbishment Incharge", value: 6 },
      ],
      userTypPocOption: "",
      userTypeManagerOption: "",
      userTypeTeamleaderOption: "",
      userTypeEvaluatorOption: "",
      userTypeSalesConsultantOption: "",
      userTypeOptions: "",
      pocselect: "",
      managerselect: "",
      teamleaderselect: "",
      evaluatorselect: "",
      salesconsutantselect: "",
      filterBuyingstageOption: [
        { label: "Enquiry", value: 1 },
        { label: "Evaluation", value: 2 },
        { label: "Documentation", value: 3 },
        { label: "PhysicallProcess", value: 4 },
        { label: "Refurbishment", value: 5 },
        ,
        { label: "Inventory", value: 6 },
      ],
      BuyingstageOption: [
        { label: "Enquiry", value: 1 },
        { label: "Evaluation", value: 2 },
        { label: "Documentation", value: 3 },
        { label: "PhysicallProcess", value: 4 },
        { label: "Refurbishment", value: 5 },
        { label: "Inventory", value: 6 },
      ],
      SellingstageOption: [
        { label: "Enquiry", value: 1 },
        { label: "Lead", value: 2 },
        { label: "Contract", value: 3 },
        { label: "Delivery", value: 4 },
        { label: "RcTransfer", value: 5 },
        { label: "Refurbishment", value: 6 },
        ,
        { label: "Inventory", value: 7 },
      ],
      filterSellingstageOption: [
        { label: "Enquiry", value: 1 },
        { label: "Lead", value: 2 },
        { label: "Contract", value: 3 },
        { label: "Delivery", value: 4 },
        { label: "RcTransfer", value: 5 },
        { label: "Refurbishment", value: 6 },
        ,
        { label: "Inventory", value: 7 },
      ],
      buyingSelectstage: "",
      sellingSelectstage: "",
      opt1: [
        { label: "SOLD", value: 0 },
        { label: "AVAILABLE", value: 1 },
      ],
      opt2: [
        { label: "Working", value: 0 },
        { label: "Completed", value: 1 },
      ],
      opt3: [
        { label: "Buying", value: 0 },
        { label: "Selling", value: 1 },
      ],
      filteredPrice1: "",
      filteredPrice2: "",
      disablepricefilter: true,
      disablefrompricefilter: true,
      priceOption1: [
        { label: "below 1 lakhs", value: 0, rangeStart: 0, rangeEnd: 100000 },
        { label: "1", value: 1, rangeStart: 100000 },
        { label: " 2 ", value: 2, rangeStart: 200000 },
        { label: "3", value: 3, rangeStart: 300000 },
        { label: "4", value: 4, rangeStart: 400000 },
        { label: "5", value: 5, rangeStart: 500000 },
        {
          label: "above 6 lakhs",
          value: 6,
          rangeStart: 600000,
          rangeEnd: 10000000,
        },
      ],
      priceOption2: [
        { label: " 2 ", value: 2, rangeEnd: 200000 },
        { label: "3", value: 3, rangeEnd: 300000 },
        { label: "4", value: 4, rangeEnd: 400000 },
        { label: "5", value: 5, rangeEnd: 500000 },
        { label: "6 ", value: 6, rangeEnd: 600000 },
      ],
      priceOption2_filter: [
        { label: " 2 ", value: 2, rangeEnd: 200000 },
        { label: "3", value: 3, rangeEnd: 300000 },
        { label: "4", value: 4, rangeEnd: 400000 },
        { label: "5", value: 5, rangeEnd: 500000 },
        { label: "6 ", value: 6, rangeEnd: 600000 },
      ],
      opt1s: [],
      opt2s: [],
      opt3s: [],
      filteredlistcars: "",
      BuyingWorking: "",
      disabledallfilter: true,
      disabledBuyingSellingfilter: true,
      selectedstartdate: "",
      selectedstartdateError: false,
      selectedenddate: "",
      selectedenddateError: false,
      column1: [],
      column: [
        {
          Header: "Register No",
          minWidth: 140,
          accessor: "Reg_no",
        },
        {
          Header: "Buyer Name",
          minWidth: 140,
          accessor: "name",
        },
        {
          Header: "ID",
          minWidth: 100,
          accessor: "id",
        },
        {
          Header: "UpdateBy",
          minWidth: 140,
          accessor: "DOWNLINE",
        },
        {
          Header: "STAGE",
          minWidth: 140,
          accessor: "liveStatus",
        },
        {
          Header: "MAKE",
          minWidth: 150,
          accessor: "makename",
        },
        {
          Header: "MODEL",
          minWidth: 170,
          accessor: "modelname",
        },
        {
          Header: "VARIANT",
          minWidth: 150,
          accessor: "variantname",
        },
        {
          Header: "SOURCE",
          minWidth: 150,
          accessor: "source",
          Cell: (d) => this.SOURCE(d),
          className: "text-center",
        },
        {
          Header: "YEAR",
          accessor: "make_year",
        },
        {
          Header: "KMS",
          accessor: "kms",
        },
        {
          Header: "COLOUR",
          accessor: "ColourName",
        },
        {
          Header: "Date",
          accessor: "created_At",
        },
        {
          Header: "OWNER TYPE",
          accessor: "owner_type",
        },
        {
          Header: "LOCATION",
          minWidth: 140,
          accessor: "locationname",
        },

        {
          Header: "PRICE",
          minWidth: 140,
          accessor: "price",
        },
        {
          Header: "RFCOST",
          minWidth: 140,
          accessor: "rfcost",
        },
      ],
      excelColumn1: [
        {
          label: "REGISTER NO",
          key: "Reg_no",
        },
        {
          label: "BUYER NAME",
          key: "name",
        },
        {
          label: "ID",
          key: "id",
        },
        {
          label: "STAGE",
          key: "liveStatus",
        },
        {
          label: "MAKE",
          key: "makename",
        },
        {
          label: "MODEL",
          key: "modelname",
        },
        {
          label: "VARIANT",
          key: "variantname",
        },
        {
          label: "SOURCE",
          key: "source",
        },
        {
          label: "YEAR",
          key: "make_year",
        },
        {
          label: "KMS",
          key: "kms",
        },
        {
          label: "COLOUR",
          key: "ColourName",
        },
        {
          label: "Date",
          key: "created_At",
        },
        {
          label: "OWNER TYPE",
          key: "owner_type",
        },
        {
          label: "LOCATION",
          key: "locationname",
        },

        {
          label: "PRICE",
          key: "price",
        },
        {
          label: "RFCOST",
          key: "rfcost",
        },
      ],
      EditData: {},
      Index: null,
      filteredLoc: "",
      filteredMake: "",
      LocationOptions: [],
      FilterData: [],
      MakeOptions: [],
      allcars: false,
      buying: false,
      selling: false,
      filteredYOM: "",
      filteredColour: "",
      filteredPrice: "",
      YomOptions: [],
      colourOptions: [],
      kmOptions: [],
      BuyerEnquiryData: [],
      SellerEnquiryData: [],
      BuyerEvalution: [],
      SellerEvalution: [],
      BuyerDocument: [],
      BuyerPhysical: [],
      userSelectError: false,
      userTypeeError: false,
      downlineError: false,
      carStatusSelectError: false,
      BuyerInventroydata: "",
      selectStageError: false,
      buyingSubmitBtn: false,
      submitResult: "",
      isDownline: false,
      userDownlineOption: "",
      userDownlineselect: "",
      loading: true,
      // showScanner: false,
      // scannedData: null,
      // facingMode: 'environment',
    };
    this.vals();
    this.qrRef = React.createRef();
  }

  async vals() {
    let allOpt = await this.decryption("getcar");
    if (allOpt) {
      await this.setState({
        MakeOptions: allOpt.makeopt,
        LocationOptions: allOpt.locopt,
        locatFind: allOpt.locopt,
        YomOptions: allOpt.yearopt,
        colourOptions: allOpt.coloropt,
        ModalOptions: allOpt.modelopt,
      });
    }
  }

  SOURCE = (d) => {
    if (d.original.source == "web") {
      return (
        <span
          className="btn btn-sm text-center btn-warning"
          style={{ minWidth: "130px" }}
        >
          Web
        </span>
      );
    } else {
      return (
        <span
          className="btn btn-success btn-sm text-center"
          style={{ minWidth: "130px" }}
        >
          Site
        </span>
      );
    }
  };

  mainfunc = async () => {
    try {
      this.setState({ loading: true });
      let results = await cmsContent.BUYDFCARZ(
        this.state.User.id,
        "inventlist"
      );

      if (results) {
        let Sold_data = [];
        let logos = results.Inventory;
        let data1 = logos;
        let Data = [];
        let BuyerInventroydata = [];
        Sold_data = data1.filter(
          (a) =>
            (a.delete_status == "Sold" && a.car_status == "delete") ||
            a.status == "inactive"
        );

        // Sold_data = data1.filter(a => a.status == "inactive")
        BuyerInventroydata = data1.filter(
          (a) => a.status !== "inactive" && a.ongoing !== 1
        );
        await this.setState({
          SellingInventroydata: Sold_data,
          Data,
          data1,
          restriction: false,
          FullData: Data,
          dashboard: results.data_for_f,
          FilterData: Data,
          BuyerInventroydata,
        });
        this.View();
      }
      let UserTypeOptions = await cmsContent.getUserTypeOption("id");
      if (UserTypeOptions) {
        this.setState({
          userTypePocOption: UserTypeOptions.statusdata[0].PocOption,
          userTypeManagerOption: UserTypeOptions.statusdata[0].ManagerOption,
          userTypeTeamleaderOption: UserTypeOptions.statusdata[0].TlOption,
          userTypeEvaluatorOption:
            UserTypeOptions.statusdata[0].EvaluatorOption,
          userTypeSalesConsultantOption:
            UserTypeOptions.statusdata[0].SalesConsultantOption,
          userTypeTechnicianOption:
            UserTypeOptions.statusdata[0].RfTechnicianOption,
          userTypeInchargeOption:
            UserTypeOptions.statusdata[0].RfInchargeOption,
        });
      }

      let EnquiryReport = await cmsContent.getDfcarzReportEnquiry(
        "id",
        "seller"
      );
      let Evaluvation_Prospective = await cmsContent.BUYDFCARZ(
        "id",
        "Reportevaluation"
      );
      let Document_Prospective = await cmsContent.BUYDFCARZ(
        "id",
        "ReportDocument"
      );
      let Physical_Prospective = await cmsContent.BUYDFCARZ(
        "id",
        "ReportPhysical"
      );

      if (EnquiryReport) {
        this.setState({
          BuyerEnquiryData: EnquiryReport.statusdata[0].Buyer,
          SellerEnquiryData: EnquiryReport.statusdata[0].Seller,
          loading: false,
        });
      }
      if (
        (Evaluvation_Prospective &&
          Evaluvation_Prospective.Buyer_Prospective) ||
        Evaluvation_Prospective.Seller_Prospective
      ) {
        this.setState({
          BuyerEvalution: Evaluvation_Prospective.Buyer_Prospective,
          SellerEvalution: Evaluvation_Prospective.Seller_Prospective,
          loading: false,
        });
      }
      if (Document_Prospective && Document_Prospective.Buyer_Document) {
        this.setState({
          BuyerDocument: Document_Prospective.Buyer_Document,
          loading: false,
        });
      }
      if (Physical_Prospective && Physical_Prospective.Physical_Prospective) {
        this.setState({
          BuyerPhysical: Physical_Prospective.Physical_Prospective,
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  View = () => {
    try {
      const data = this.state.Data;
      const modelid = localStorage.getItem("modelid");
      const makeid = localStorage.getItem("makeid");

      let result = data.filter(function (v, i) {
        return v.make == makeid && v.model == modelid;
      });

      this.setState({ filterdata: result });
    } catch (err) {
      console.log(err);
    }
  };

  async componentDidMount() {
    await this.setState({ loading: true });
    let ALL_User_Details = await this.decryption("Downline_Details");
    if (
      ALL_User_Details &&
      ALL_User_Details.Current_User &&
      ALL_User_Details.Current_User[0]
    ) {
      await this.setState({ Current_User: ALL_User_Details.Current_User[0] });
    }

    await this.mainfunc();
  }

  piechart() {
    let {
      filteredLoc,
      Data,
      LocationOptions,
      buyingSelectstage,
      sellingSelectstage,
    } = this.state;
    const dataPoints = [];
    // if (filteredLoc && filteredLoc.length) {

    //     filteredLoc.map((filteredLoc, j) => {

    //         const filtered_BuyerEnquirydata = Data.filter(item => {
    //             let itemLocation;
    //             if (buyingSelectstage.label == "Enquiry" || buyingSelectstage.label == "Evaluation" ||
    //                 sellingSelectstage.label == "Enquiry" || sellingSelectstage.label == "Lead") {

    //                 itemLocation = item.location;
    //             } else {
    //                 itemLocation = item.location;
    //             }

    //             if (filteredLoc) {
    //                 return itemLocation == filteredLoc.value;

    //             }

    //             return true; // return all items if no date range specified
    //         });
    //         dataPoints.push({ label: filtered_BuyerEnquirydata[0].locationname, y: filtered_BuyerEnquirydata.length })
    //     })
    // }
    if (!filteredLoc && filteredLoc.length == 0) {
      LocationOptions.map((LocationOption, j) => {
        const filtered_BuyerEnquirydata = Data.filter((item) => {
          let itemLocation;
          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead"
          ) {
            itemLocation = item.location;
          } else {
            itemLocation = item.location;
          }

          if (itemLocation == LocationOption.value) {
            return itemLocation == LocationOption.value;
          }
        });
        if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length) {
          dataPoints.push({
            label: filtered_BuyerEnquirydata[0].locationname,
            y: filtered_BuyerEnquirydata.length,
          });
        }
      });
    }
    var chart = new window.CanvasJS.Chart("chartContainerlocation", {
      theme: "light1", // "light1", "light2", "dark1", "dark2"
      exportEnabled: true,
      width: 1300,
      animationEnabled: true,
      title: {
        text: "Location pie chart",
      },
      data: [
        {
          type: "pie",
          startAngle: 25,
          toolTipContent: "<b>{label}</b>: {y}",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          indexLabel: "{label} - {y}",
          dataPoints: dataPoints,
        },
      ],
    });
    chart.render();
  }

  drawChart() {
    let {
      filteredMake,
      Data,
      MakeOptions,
      buyingSelectstage,
      sellingSelectstage,
    } = this.state;
    const dataPoints = [];
    // if (filteredMake && filteredMake.length) {

    //     filteredMake.map((filteredMake, j) => {

    //         const filtered_BuyerEnquirydata = Data.filter(item => {
    //             let itemMake;
    //             if (buyingSelectstage.label == "Enquiry" ||
    //                 sellingSelectstage.label == "Enquiry") {
    //                 itemMake = item.Make;
    //             } else {
    //                 itemMake = item.make;
    //             }

    //             if (filteredMake) {
    //                 return itemMake == filteredMake.value;

    //             }

    //             return true; // return all items if no date range specified
    //         });
    //         if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length && (buyingSelectstage.label == "Enquiry" ||
    //             sellingSelectstage.label == "Enquiry" || sellingSelectstage.label == "Lead")) {

    //             dataPoints.push({ label: filtered_BuyerEnquirydata[0].makename, y: filtered_BuyerEnquirydata.length, value: filtered_BuyerEnquirydata[0].Make })
    //         } else if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length && (buyingSelectstage.label != "Enquiry" ||
    //             sellingSelectstage.label != "Enquiry")) {

    //             dataPoints.push({ label: filtered_BuyerEnquirydata[0].makename, y: filtered_BuyerEnquirydata.length, value: filtered_BuyerEnquirydata[0].make })

    //         }
    //     })
    // }
    if (!filteredMake && filteredMake.length == 0) {
      MakeOptions.map((MakeOption, j) => {
        const filtered_BuyerEnquirydata = Data.filter((item) => {
          let itemMake;
          if (
            buyingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Enquiry"
          ) {
            itemMake = item.Make;
          } else {
            itemMake = item.make;
          }

          if (itemMake == MakeOption.value) {
            return itemMake == MakeOption.value;
          }
        });

        if (
          filtered_BuyerEnquirydata &&
          filtered_BuyerEnquirydata.length &&
          (buyingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Enquiry")
        ) {
          dataPoints.push({
            label: filtered_BuyerEnquirydata[0].makename,
            y: filtered_BuyerEnquirydata.length,
            value: filtered_BuyerEnquirydata[0].Make,
          });
        } else if (
          filtered_BuyerEnquirydata &&
          filtered_BuyerEnquirydata.length &&
          (buyingSelectstage.label != "Enquiry" ||
            sellingSelectstage.label != "Enquiry")
        ) {
          dataPoints.push({
            label: filtered_BuyerEnquirydata[0].makename,
            y: filtered_BuyerEnquirydata.length,
            value: filtered_BuyerEnquirydata[0].make,
          });
        }
      });
    }
    this.setState({ makechartOption: dataPoints });
    // const chart = new window.CanvasJS.Chart(this.refs.chartContainer, {
    const chart = new window.CanvasJS.Chart("chartContainermake", {
      animationEnabled: true,
      exportEnabled: true,
      theme: "light1",
      width: 1200,
      title: {
        // text: 'Simple Column Chart',
        text: "Make Column Chart",
      },
      axisY: {
        title: "Values",
      },
      data: [
        {
          type: "column",
          dataPoints: dataPoints,
        },
      ],
    });
    chart.render();
  }

  pricechart() {
    let {
      Data,
      filteredPrice1,
      filteredPrice2,
      RefurbushmentData,
      buyingSelectstage,
      sellingSelectstage,
    } = this.state;
    const dataPoints = [];

    if (
      (buyingSelectstage.label || sellingSelectstage.label) == "Refurbishment"
    ) {
      const filtered_BuyerEnquirydata = Data.filter((item) => {
        if (
          filteredPrice1 &&
          (filteredPrice1.value == 0 || filteredPrice1.value == 6)
        ) {
          return (
            item.rfcost >= filteredPrice1.rangeStart &&
            item.rfcost <= filteredPrice1.rangeEnd
          );
        } else if (
          filteredPrice1 &&
          (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
        ) {
          return (
            item.rfcost >= filteredPrice1.rangeStart &&
            item.rfcost <= filteredPrice2.rangeEnd
          );
        }
        return true;
      });
      filtered_BuyerEnquirydata.map((ival, i) => {
        dataPoints.push({ label: ival.created_At, y: ival.rfcost });
      });
    } else if (
      (buyingSelectstage.label || sellingSelectstage.label) == "Inventory"
    ) {
      const filtered_BuyerEnquirydata = Data.filter((item) => {
        if (
          filteredPrice1 &&
          (filteredPrice1.value == 0 || filteredPrice1.value == 6)
        ) {
          return (
            item.price >= filteredPrice1.rangeStart &&
            item.price <= filteredPrice1.rangeEnd
          );
        } else if (
          filteredPrice1 &&
          (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
        ) {
          return (
            item.price >= filteredPrice1.rangeStart &&
            item.price <= filteredPrice2.rangeEnd
          );
        }
        return true;
      });
      filtered_BuyerEnquirydata.map((ival, i) => {
        dataPoints.push({ label: ival.created_At, y: ival.price });
      });
    }

    var chart = new window.CanvasJS.Chart("chartContainerprice", {
      animationEnabled: true,
      exportEnabled: true,
      theme: "light1",
      width: 1200,
      title: {
        text: `${
          filteredPrice1 || filteredPrice2
            ? filteredPrice1 && filteredPrice2
              ? " car prices in " +
                filteredPrice1.label +
                " lakh to" +
                filteredPrice2.label +
                " lakh "
              : filteredPrice1
              ? " car prices in " + filteredPrice1.label
              : ""
            : `car prices in ${
                buyingSelectstage
                  ? buyingSelectstage.label
                  : sellingSelectstage
                  ? sellingSelectstage.label
                  : ""
              }`
        }`,
      },
      axisX: {
        valueFormatString: "DD MMM",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
        },
      },
      axisY: {
        valueFormatString: "₹ ##0.00",
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          labelFormatter: function (e) {
            return "$" + window.CanvasJS.formatNumber(e.value, "₹ ##0.00");
          },
        },
      },
      data: [
        {
          type: "area",
          xValueFormatString: "DD MMM",
          yValueFormatString: "₹ ##0.00",
          dataPoints: dataPoints,
        },
      ],
    });
    chart.render();
  }

  changeModelstate = async (s, v) => {
    this.setState({ [s]: v });
  };

  cng = (e, val) => {
    let data1 = [];
    let data2 = [];
    if (val === 1) {
      this.state.FilterData.map((ival, i) => {
        for (let i = 0; i < e.length; i++) {
          if (e[i].value == 0) {
            if (ival.status == "inactive" && ival.ongoing == 1) {
              data1.push(ival);
            }
          }
          if (e[i].value == 1) {
            if (ival.status !== "inactive") {
              data1.push(ival);
            }
          }
        }
      });
      if (e.length == 0) {
        this.setState({
          opt2s: "",
          opt1s: e,
          Data: data1,
          disabledallfilter: true,
        });
      } else {
        this.setState({
          opt2s: "",
          opt1s: e,
          Data: data1,
          disabledallfilter: false,
        });
      }
    }
    if (val === 2) {
      this.state.FullData.map((ival, i) => {
        if (e.value == 1) {
          if (parseInt(ival.Movedtosite) === 1) {
            data2.push(ival);
          }
        }
        if (e.value == 0) {
          if (parseInt(ival.Movedtosite) !== 1) {
            data2.push(ival);
          }
        }
      });
      this.setState({ opt1s: "", opt2s: e, Data: data2 });
    }
  };

  maintanence_onchange = async (e, name) => {
    if (e) {
      await this.setState({ [name]: e });
    }
  };

  maintenance_start = async () => {
    let { EditData, maintence_work } = this.state;
    if (!maintence_work) {
      alert("please Enter Maintenance Work");
      return false;
    }
    if (maintence_work) {
      let json = maintence_work.split(",");
      let categoryArray = {};
      categoryArray.commit_work = JSON.stringify(json);
    }
  };

  priceFilter = async (d, index) => {
    if (index === "from") {
      this.setState({ filteredPrice1: d });
      if ((d && d.value == 0) || d.value == 6) {
        this.setState({ disablepricefilter: true });
      } else {
        this.setState({ disablepricefilter: false });
      }
      if (d && d.value == 1) {
        this.setState({
          priceOption2_filter: this.state.priceOption2,
          filteredPrice2: "",
        });
      }
      if (d && d.value == 2) {
        let OptionFilter = this.state.priceOption2.filter(
          (el) => el.value !== 2
        );
        this.setState({
          priceOption2_filter: OptionFilter,
          filteredPrice2: "",
        });
      }
      if (d && d.value == 3) {
        let OptionFilter = this.state.priceOption2.filter(
          (el) => el.value !== 2 && el.value !== 3
        );
        this.setState({
          priceOption2_filter: OptionFilter,
          filteredPrice2: "",
        });
      }
      if (d && d.value == 4) {
        let OptionFilter = this.state.priceOption2.filter(
          (el) => el.value !== 2 && el.value !== 3 && el.value !== 4
        );
        this.setState({
          priceOption2_filter: OptionFilter,
          filteredPrice2: "",
        });
      }
      if (d && d.value == 5) {
        let OptionFilter = this.state.priceOption2.filter(
          (el) =>
            el.value !== 2 && el.value !== 3 && el.value !== 4 && el.value !== 5
        );
        this.setState({
          priceOption2_filter: OptionFilter,
          filteredPrice2: "",
        });
      }
    }
    if (index === "to") {
      this.setState({ filteredPrice2: d });
    }
  };

  makeFilter = async (d) => {
    await this.setState({ filteredMake: d });
  };

  locationFilter = async (d) => {
    await this.setState({ filteredLoc: d });
  };

  clearFilter = async () => {
    let chart = document.getElementById("Charview");
    let chart1 = document.getElementById("chartContainermakeSelectModal");
    if (chart.style.display == "block" || chart1.style.display == "block") {
      chart.style.display = "none";
      chart1.style.display = "none";
    } else {
      chart.style.display = "none";
      chart1.style.display = "none";
    }
    await this.setState({
      submitResult: "",
      BuyingstageOption: this.state.filterBuyingstageOption,
      pocselect: "",
      SellingstageOption: this.state.filterSellingstageOption,
      managerselect: "",
      evaluatorselect: "",
      teamleaderselect: "",
      salesconsutantselect: "",
      filteredMake: "",
      filteredLoc: "",
      filteredYOM: "",
      filteredColour: "",
      filteredPrice: "",
      filteredlistcars: "",
      opt1s: "",
      selectedstartdate: "",
      selectedenddate: "",
      disabledBuyingSellingfilter: true,
      buyingSelectstage: "",
      sellingSelectstage: "",
      userTypeOptions: "",
      Data: "",
      filteredPrice1: "",
      filteredPrice2: "",
      disablepricefilter: true,
      Select: "",
      buyingSubmitBtn: false,
      rfinchargeselect: "",
      rftechnicianselect: "",
      disabledallfilter: true,
      disablefrompricefilter: true,
      isDownline: false,
      userDownlineselect: "",
      LocationOptions: this.state.locatFind,
      chartfilteredMake: "",
      //  Data: this.state.FilterData
    });
  };

  listcarsfilter = async (e) => {
    if (e) {
      await this.setState({
        filteredlistcars: e,
        disabledallfilter: false,
        BuyingWorking: e.label,
        disabledBuyingSellingfilter: false,
        userTypeOptions: "",
        buyingSelectstage: "",
        managerselect: "",
        pocselect: "",
        teamleaderselect: "",
        evaluatorselect: "",
        rfinchargeselect: "",
        rftechnicianselect: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
        isDownline: false,
      });

      if (e.label == "Selling") {
        let Contract = await cmsContent.BUYDFCARZ("id", "ReportContract");
        let Delivery = await cmsContent.BUYDFCARZ("id", "ReportDelivery");
        let Rc = await cmsContent.BUYDFCARZ("id", "ReportRc");

        if (Contract) {
          this.setState({ ContractData: Contract.fulldata });
        }
        if (Delivery) {
          this.setState({ DeliveryData: Delivery.ReportDelivery });
        }
        if (Rc) {
          this.setState({ RcData: Rc.ReportRc });
        }
      }
    }
    if (e.length == 0) {
      await this.setState({
        disabledallfilter: true,
        disabledBuyingSellingfilter: true,
      });
    }
  };

  userTypefilter = async (e) => {
    if (e) {
      await this.setState({ userTypeOptions: e });
      if (
        e &&
        (e.value == 1 ||
          e.value == 2 ||
          e.value == 3 ||
          e.value == 9 ||
          e.value == 8)
      ) {
        let filterBuyStage = this.state.filterBuyingstageOption.filter(
          (el) => el.value != 5 && el.value != 6
        );
        let filterSelStage = this.state.filterSellingstageOption.filter(
          (el) => el.value != 6 && el.value != 7
        );
        this.setState({
          BuyingstageOption: filterBuyStage,
          disablefrompricefilter: true,
          buyingSelectstage: "",
          managerselect: "",
          pocselect: "",
          teamleaderselect: "",
          evaluatorselect: "",
          rfinchargeselect: "",
          rftechnicianselect: "",
          filteredMake: "",
          filteredLoc: "",
          userDownlineselect: "",
          SellingstageOption: filterSelStage,
        });
      } else if (e && (e.value == 5 || e.value == 6)) {
        let filterBuyStage = this.state.filterBuyingstageOption.filter(
          (el) => el.value == 5
        );
        let filterSelStage = this.state.filterSellingstageOption.filter(
          (el) => el.value == 6
        );

        this.setState({
          BuyingstageOption: filterBuyStage,
          disablefrompricefilter: false,
          buyingSelectstage: "",
          managerselect: "",
          pocselect: "",
          teamleaderselect: "",
          evaluatorselect: "",
          rfinchargeselect: "",
          rftechnicianselect: "",
          filteredMake: "",
          filteredLoc: "",
          userDownlineselect: "",
          SellingstageOption: filterSelStage,
        });
      } else {
        this.setState({
          BuyingstageOption: this.state.filterBuyingstageOption,
          buyingSelectstage: "",
          managerselect: "",
          pocselect: "",
          teamleaderselect: "",
          evaluatorselect: "",
          rfinchargeselect: "",
          rftechnicianselect: "",
          filteredMake: "",
          filteredLoc: "",
          userDownlineselect: "",
          SellingstageOption: this.state.filterSellingstageOption,
        });
      }
    }
  };

  managerfilter = async (e) => {
    if (e) {
      await this.setState({
        managerselect: e,
        disablefrompricefilter: true,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
      if (this.state.isDownline && e) {
        let userDownline;
        if (this.state.BuyingWorking == "Buying") {
          userDownline = await cmsContent.getFreedom(
            "tbl_user_web.id as value,tbl_user_web.userName as label,tbl_user_web.userType,tbl_UserType.usertype as name,tbl_user_web.locationid",
            "tbl_user_web,tbl_UserType",
            `tbl_user_web.serviceId = 10 && tbl_user_web.customerId = 620 and tbl_user_web.downline=${e.value}  and tbl_user_web.userType !=124  and tbl_user_web.userType = tbl_UserType.id`,
            "tbl_user_web.id",
            "tbl_user_web.id DESC"
          );
        } else {
          userDownline = await cmsContent.getFreedom(
            "tbl_user_web.id as value,tbl_user_web.userName as label,tbl_user_web.userType,tbl_UserType.usertype as name,tbl_user_web.locationid",
            "tbl_user_web,tbl_UserType",
            `tbl_user_web.serviceId = 10 && tbl_user_web.customerId = 620 and tbl_user_web.downline=${e.value} and tbl_user_web.userType !=123 or tbl_user_web.userType=129 and tbl_user_web.userType = tbl_UserType.id `,
            "tbl_user_web.id",
            "tbl_user_web.id DESC"
          );
        }
        userDownline.data.map((ival) => {
          ival.label = ival.label + `(${ival.name})`;
        });
        await this.setState({ userDownlineOption: userDownline.data });
      }
    }
    if (e && e.locationid) {
      let locationFind = this.state.locatFind.filter(
        (el) => el.value == e.locationid
      );
      this.setState({ LocationOptions: locationFind });
    }
  };

  pocfilter = async (e) => {
    if (e) {
      await this.setState({
        pocselect: e,
        disablefrompricefilter: true,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
        LocationOptions: this.state.locatFind,
      });

      if (this.state.isDownline && e) {
        let userDownline = await cmsContent.getFreedom(
          "tbl_user_web.id as value,tbl_user_web.userName as label,tbl_user_web.userType,tbl_UserType.usertype as name,tbl_user_web.locationid",
          "tbl_user_web,tbl_UserType",
          `tbl_user_web.serviceId = 10 && tbl_user_web.customerId = 620 and tbl_user_web.downline=${e.value} and tbl_user_web.userType = tbl_UserType.id and tbl_user_web.userType=130`,
          "tbl_user_web.id",
          "tbl_user_web.id DESC"
        );

        userDownline.data.map((ival) => {
          ival.label = ival.label + `(${ival.name})`;
        });

        await this.setState({ userDownlineOption: userDownline.data });
      }
    }
  };

  tlfilter = async (e) => {
    if (e) {
      await this.setState({
        teamleaderselect: e,
        disablefrompricefilter: true,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
      if (this.state.isDownline && e) {
        let userDownline;
        if (this.state.BuyingWorking == "Buying") {
          userDownline = await cmsContent.getFreedom(
            "tbl_user_web.id as value,tbl_user_web.userName as label,tbl_user_web.userType,tbl_UserType.usertype as name,tbl_user_web.locationid",
            "tbl_user_web,tbl_UserType",
            `tbl_user_web.serviceId = 10 && tbl_user_web.customerId = 620 and tbl_user_web.downline=${e.value} and tbl_user_web.userType = tbl_UserType.id and tbl_user_web.userType !=124`,
            "tbl_user_web.id",
            "tbl_user_web.id DESC"
          );
        } else {
          userDownline = await cmsContent.getFreedom(
            "tbl_user_web.id as value,tbl_user_web.userName as label,tbl_user_web.userType,tbl_UserType.usertype as name,tbl_user_web.locationid",
            "tbl_user_web,tbl_UserType",
            `tbl_user_web.serviceId = 10 && tbl_user_web.customerId = 620 and tbl_user_web.downline=${e.value} and tbl_user_web.userType = tbl_UserType.id and tbl_user_web.userType !=123`,
            "tbl_user_web.id",
            "tbl_user_web.id DESC"
          );
        }
        userDownline.data.map((ival) => {
          ival.label = ival.label + `(${ival.name})`;
        });
        await this.setState({ userDownlineOption: userDownline.data });
      }
    }
    if (e && e.locationid) {
      let locationFind = this.state.locatFind.filter(
        (el) => el.value == e.locationid
      );
      this.setState({ LocationOptions: locationFind });
    }
  };

  downlinefilter = async (e) => {
    // console.log(e, "e");
    if (e) {
      await this.setState({
        userDownlineselect: e,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
      });
    }
    if (e && e.locationid) {
      let locationFind = this.state.locatFind.filter(
        (el) => el.value == e.locationid
      );
      this.setState({ LocationOptions: locationFind });
    }
  };

  evaluatorfilter = async (e) => {
    if (e) {
      await this.setState({
        evaluatorselect: e,
        disablefrompricefilter: true,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
    }
    if (e && e.locationid) {
      let locationFind = this.state.locatFind.filter(
        (el) => el.value == e.locationid
      );
      this.setState({ LocationOptions: locationFind });
    }
  };

  salesconsultantfilter = async (e) => {
    if (e) {
      await this.setState({
        salesconsutantselect: e,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
    }
    if (e && e.locationid) {
      let locationFind = this.state.locatFind.filter(
        (el) => el.value == e.locationid
      );
      this.setState({ LocationOptions: locationFind });
    }
  };

  rfInchagefilter = async (e) => {
    if (e) {
      await this.setState({
        rfinchargeselect: e,
        disablefrompricefilter: false,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
    } else {
      await this.setState({ disablefrompricefilter: true });
    }
  };

  rfTechnicianfilter = async (e) => {
    if (e) {
      await this.setState({
        rftechnicianselect: e,
        disablefrompricefilter: false,
        buyingSelectstage: "",
        sellingSelectstage: "",
        filteredMake: "",
        filteredLoc: "",
        userDownlineselect: "",
      });
    } else {
      await this.setState({ disablefrompricefilter: true });
    }
  };

  Buyingstage = async (e) => {
    if (e) {
      let chart = document.getElementById("Charview");
      let chart1 = document.getElementById("chartContainermakeSelectModal");
      if (chart.style.display == "block" || chart1.style.display == "block") {
        chart.style.display = "none";
        chart1.style.display = "none";
      } else {
        chart.style.display = "none";
        chart1.style.display = "none";
      }
      await this.setState({
        buyingSelectstage: e,
        filteredMake: "",
        filteredLoc: "",
        chartfilteredMake: "",
      });
      if (
        (e && e.label === "Refurbishment") ||
        (e && e.label === "Inventory")
      ) {
        await this.setState({ disablefrompricefilter: false });
      } else {
        await this.setState({ disablefrompricefilter: true });
      }
      if (e && e.label === "Refurbishment") {
        let refurbushmentData = await cmsContent.BUYDFCARZ(
          this.state.User.id,
          "ReportRefurbishment"
        );
        if (refurbushmentData) {
          this.setState({ RefurbushmentData: refurbushmentData.Fulldata });
        }
      }
    }
  };

  Sellingstage = async (e) => {
    if (e) {
      let chart = document.getElementById("Charview");
      let chart1 = document.getElementById("chartContainermakeSelectModal");
      if (chart.style.display == "block" || chart1.style.display == "block") {
        chart.style.display = "none";
        chart1.style.display = "none";
      } else {
        chart.style.display = "none";
        chart1.style.display = "none";
      }
      await this.setState({
        sellingSelectstage: e,
        filteredMake: "",
        filteredLoc: "",
        chartfilteredMake: "",
      });
      if (
        (e && e.label === "Refurbishment") ||
        (e && e.label === "Inventory")
      ) {
        await this.setState({ disablefrompricefilter: false });
      } else {
        await this.setState({ disablefrompricefilter: true });
      }
      if (e && e.label === "Refurbishment") {
        let SellingrefurbushmentData = await cmsContent.BUYDFCARZ(
          this.state.User.id,
          "SellingReportRefurbishment"
        );
        if (SellingrefurbushmentData) {
          this.setState({
            RefurbushmentData: SellingrefurbushmentData.Fulldata,
          });
        }
      }
    }
  };

  fromDateSelect = (e) => {
    this.setState({ selectedstartdate: e.target.value });
  };

  endDateSelect = async (e) => {
    this.setState({ selectedenddate: e.target.value });
  };

  Submit_Buying_Selling = async () => {
    let {
      dataTablShow,
      FilterData,
      buyingSelectstage,
      BuyerEnquiryData,
      BuyerEvalution,
      BuyerDocument,
      BuyerPhysical,
      selectedstartdate,
      selectedenddate,
      filteredlistcars,
      userSelectError,
      userTypeeError,
      downlineError,
      carStatusSelectError,
      pocselect,
      managerselect,
      teamleaderselect,
      evaluatorselect,
      salesconsutantselect,
      userTypeOptions,
      RefurbushmentData,
      rftechnicianselect,
      rfinchargeselect,
      BuyerInventroydata,
      filteredPrice1,
      filteredPrice2,
      filteredMake,
      filteredLoc,
      userDownlineselect,
      BuyingWorking,
      ContractData,
      DeliveryData,
      RcData,
      SellerEnquiryData,
      SellerEvalution,
      SellingInventroydata,
      sellingSelectstage,
    } = this.state;

    if (!filteredlistcars) {
      await this.setState({ carStatusSelectError: true });
      setTimeout(() => {
        this.setState({ carStatusSelectError: false });
      }, 3000);
    } else if (
      (userTypeOptions.label == "POC" && !pocselect) ||
      (userTypeOptions.label == "Manager" && !managerselect) ||
      (userTypeOptions.label == "TeamLeader" && !teamleaderselect) ||
      (userTypeOptions.label == "Evaluator" && !evaluatorselect) ||
      (userTypeOptions.label == "Refurbishment Technician" &&
        !rftechnicianselect) ||
      (userTypeOptions.label == "Refurbishment Incharge" &&
        !rfinchargeselect) ||
      (userTypeOptions.label == "SalesConsultant" && !salesconsutantselect)
    ) {
      await this.setState({ userSelectError: true });
      setTimeout(() => {
        this.setState({ userSelectError: false });
      }, 3000);
    } else if (selectedenddate && !selectedstartdate) {
      await this.setState({ selectedstartdateError: true });
      setTimeout(() => {
        this.setState({ selectedstartdateError: false });
      }, 3000);
    } else if (selectedstartdate && !selectedenddate) {
      await this.setState({ selectedenddateError: true });
      setTimeout(() => {
        this.setState({ selectedenddateError: false });
      }, 3000);
    } else {
      //----------------get datas-----------------------------

      let DownlinUserIds;
      if (managerselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          managerselect
        );
      } else if (teamleaderselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          teamleaderselect
        );
      } else if (evaluatorselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          evaluatorselect
        );
      } else if (rfinchargeselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          rfinchargeselect
        );
      } else if (rftechnicianselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          rftechnicianselect
        );
      } else if (pocselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          pocselect
        );
      } else if (salesconsutantselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          BuyingWorking,
          "downlineUsersIds",
          salesconsutantselect
        );
      }
      // console.log(DownlinUserIds, "DownlinUserIds");
      if (BuyingWorking == "Buying") {
        if (!buyingSelectstage) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        } else if (
          (pocselect && !buyingSelectstage) ||
          (managerselect && !buyingSelectstage) ||
          (teamleaderselect && !buyingSelectstage) ||
          (evaluatorselect && !buyingSelectstage) ||
          (rfinchargeselect && !buyingSelectstage) ||
          (rftechnicianselect && !buyingSelectstage)
        ) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        }

        //---------------filtered manager,tl,evaluator,rf incharge,rf technician datas with location and make-------------------------------
        else if (
          (pocselect && (filteredMake || filteredLoc)) ||
          (managerselect && (filteredMake || filteredLoc)) ||
          (teamleaderselect && (filteredMake || filteredLoc)) ||
          (evaluatorselect && (filteredMake || filteredLoc)) ||
          (rfinchargeselect && (filteredMake || filteredLoc)) ||
          (rftechnicianselect && (filteredMake || filteredLoc))
        ) {
          this.setState({ dataTablShow: true });

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_poc = "";
          if (pocselect && pocselect.length) {
            pocselect.map((name, index) => {
              const comma = index < pocselect.length - 1 ? "," : "";
              filtered_poc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_manager = "";
          if (managerselect && managerselect.length) {
            managerselect.map((name, index) => {
              const comma = index < managerselect.length - 1 ? "," : "";
              filtered_manager += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_tl = "";
          if (teamleaderselect && teamleaderselect.length) {
            teamleaderselect.map((name, index) => {
              const comma = index < teamleaderselect.length - 1 ? "," : "";
              filtered_tl += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_evaluator = "";
          if (evaluatorselect && evaluatorselect.length) {
            evaluatorselect.map((name, index) => {
              const comma = index < evaluatorselect.length - 1 ? "," : "";
              filtered_evaluator += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rfincharge = "";
          if (rfinchargeselect && rfinchargeselect.length) {
            rfinchargeselect.map((name, index) => {
              const comma = index < rfinchargeselect.length - 1 ? "," : "";
              filtered_rfincharge += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rftechnician = "";
          if (rftechnicianselect && rftechnicianselect.length) {
            rftechnicianselect.map((name, index) => {
              const comma = index < rftechnicianselect.length - 1 ? "," : "";
              filtered_rftechnician += " " + name.label + " " + comma + " ";
            });
          }

          let RESULT;
          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }
          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess" ||
            buyingSelectstage.label == "Refurbishment"
          ) {
            let data;
            let filter;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            } else if (buyingSelectstage.label == "Refurbishment") {
              data = RefurbushmentData;
            }
            if (buyingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            } else if (
              buyingSelectstage.label == "Evaluation" ||
              buyingSelectstage.label == "Documentation" ||
              buyingSelectstage.label == "PhysicallProcess"
            ) {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (buyingSelectstage.label == "Refurbishment") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "PRICE"
                );
              });
            }

            let filtered_BuyerEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      filteredMake.map((filteredMake, j) => {
                        const filtered_BuyerEnquirydata = data.filter(
                          (item) => {
                            let itemData;
                            let itmData2;

                            if (
                              buyingSelectstage.label == "Enquiry" ||
                              buyingSelectstage.label == "Evaluation" ||
                              buyingSelectstage.label == "Documentation" ||
                              buyingSelectstage.label == "PhysicallProcess"
                            ) {
                              itemData = item.companyid;
                              itmData2 = item.evaluatorid;
                            } else if (
                              buyingSelectstage.label == "Refurbishment"
                            ) {
                              if (rfinchargeselect) {
                                itemData = item.RFInchargeID;
                              } else if (rftechnicianselect) {
                                itemData = item.RFTechnicianID;
                              }
                            }
                            let itemDate;
                            let itemMake;
                            if (buyingSelectstage.label == "Enquiry") {
                              itemDate = new Date(item.createdAt);
                              itemMake = item.Make;
                            } else {
                              itemDate = new Date(item.created_At);
                              itemMake = item.make;
                            }
                            const itemLocation = item.location;

                            if (
                              DownlinUserIds &&
                              selectedstartdate &&
                              selectedenddate &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itmData2 == element.value) &&
                                itemDate >= new Date(selectedstartdate) &&
                                itemDate <= new Date(selectedenddate)
                              );
                            } else if (
                              DownlinUserIds &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itmData2 == element.value)
                              );
                            }

                            return true;
                          }
                        );

                        for (
                          let index = 0;
                          index < filtered_BuyerEnquirydata.length;
                          index++
                        ) {
                          const element1 = filtered_BuyerEnquirydata[index];
                          element1.DOWNLINE = element.label;
                          filtered_BuyerEnquirydatas.push(element1);
                        }
                      });
                    });
                  })
                : filteredMake && filteredMake.length
                ? DownlinUserIds.map((element, id) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_BuyerEnquirydata = data.filter((item) => {
                        let itemData;
                        let itmData2;

                        if (
                          buyingSelectstage.label == "Enquiry" ||
                          buyingSelectstage.label == "Evaluation" ||
                          buyingSelectstage.label == "Documentation" ||
                          buyingSelectstage.label == "PhysicallProcess"
                        ) {
                          itemData = item.companyid;
                          itmData2 = item.evaluatorid;
                        } else if (buyingSelectstage.label == "Refurbishment") {
                          if (rfinchargeselect) {
                            itemData = item.RFInchargeID;
                          } else if (rftechnicianselect) {
                            itemData = item.RFTechnicianID;
                          }
                        }
                        let itemDate;
                        let itemMake;
                        if (buyingSelectstage.label == "Enquiry") {
                          itemDate = new Date(item.createdAt);
                          itemMake = item.Make;
                        } else {
                          itemDate = new Date(item.created_At);
                          itemMake = item.make;
                        }
                        const itemLocation = item.location;

                        if (
                          DownlinUserIds &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          return (
                            itemMake == filteredMake.value &&
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (DownlinUserIds && filteredMake) {
                          return (
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemMake == filteredMake.value
                          );
                        }

                        return true; // return all items if no date range specified
                      });

                      for (
                        let index = 0;
                        index < filtered_BuyerEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_BuyerEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_BuyerEnquirydatas.push(element1);
                      }
                    });
                  })
                : DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      const filtered_BuyerEnquirydata = data.filter((item) => {
                        let itemData;
                        let itmData2;

                        if (
                          buyingSelectstage.label == "Enquiry" ||
                          buyingSelectstage.label == "Evaluation" ||
                          buyingSelectstage.label == "Documentation" ||
                          buyingSelectstage.label == "PhysicallProcess"
                        ) {
                          itemData = item.companyid;
                          itmData2 = item.evaluatorid;
                        } else if (buyingSelectstage.label == "Refurbishment") {
                          if (rfinchargeselect) {
                            itemData = item.RFInchargeID;
                          } else if (rftechnicianselect) {
                            itemData = item.RFTechnicianID;
                          }
                        }
                        let itemDate;
                        let itemMake;
                        if (buyingSelectstage.label == "Enquiry") {
                          itemDate = new Date(item.createdAt);
                          itemMake = item.Make;
                        } else {
                          itemDate = new Date(item.created_At);
                          itemMake = item.make;
                        }
                        const itemLocation = item.location;

                        if (
                          DownlinUserIds &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          return (
                            itemLocation == filteredLoc.value &&
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (DownlinUserIds && filteredLoc) {
                          return (
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemLocation == filteredLoc.value
                          );
                        }
                        return true; // return all items if no date range specified
                      });

                      for (
                        let index = 0;
                        index < filtered_BuyerEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_BuyerEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_BuyerEnquirydatas.push(element1);
                      }
                    });
                  });
            }

            this.setState({
              Data: filtered_BuyerEnquirydatas,
              column1: filter,
            });
          }
          await this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.pricechart();
        }
        //---------------filtered manager,tl,evaluator,rf incharge,rf technician datas-------------------------------
        else if (
          pocselect ||
          managerselect ||
          teamleaderselect ||
          evaluatorselect ||
          rfinchargeselect ||
          rftechnicianselect
        ) {
          this.setState({ dataTablShow: true });
          let filtered_poc = "";
          if (pocselect && pocselect.length) {
            pocselect.map((name, index) => {
              const comma = index < pocselect.length - 1 ? "," : "";
              filtered_poc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_manager = "";
          if (managerselect && managerselect.length) {
            managerselect.map((name, index) => {
              const comma = index < managerselect.length - 1 ? "," : "";
              filtered_manager += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_tl = "";
          if (teamleaderselect && teamleaderselect.length) {
            teamleaderselect.map((name, index) => {
              const comma = index < teamleaderselect.length - 1 ? "," : "";
              filtered_tl += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_evaluator = "";
          if (evaluatorselect && evaluatorselect.length) {
            evaluatorselect.map((name, index) => {
              const comma = index < evaluatorselect.length - 1 ? "," : "";
              filtered_evaluator += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rfincharge = "";
          if (rfinchargeselect && rfinchargeselect.length) {
            rfinchargeselect.map((name, index) => {
              const comma = index < rfinchargeselect.length - 1 ? "," : "";
              filtered_rfincharge += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rftechnician = "";
          if (rftechnicianselect && rftechnicianselect.length) {
            rftechnicianselect.map((name, index) => {
              const comma = index < rftechnicianselect.length - 1 ? "," : "";
              filtered_rftechnician += " " + name.label + " " + comma + " ";
            });
          }
          let RESULT;
          if (selectedstartdate && selectedenddate) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : evaluatorselect
                ? filtered_evaluator
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }

          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess" ||
            buyingSelectstage.label == "Refurbishment"
          ) {
            let data;
            let filter;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            } else if (buyingSelectstage.label == "Refurbishment") {
              data = RefurbushmentData;
            }
            if (buyingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            } else if (
              buyingSelectstage.label == "Evaluation" ||
              buyingSelectstage.label == "Documentation" ||
              buyingSelectstage.label == "PhysicallProcess"
            ) {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (buyingSelectstage.label == "Refurbishment") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "PRICE"
                );
              });
            }
            let alldata = [];
            for (let index = 0; index < DownlinUserIds.length; index++) {
              const element = DownlinUserIds[index];
              const filtered_BuyerEnquirydata = data.filter((item) => {
                let itemData;
                let itmData2;
                if (
                  buyingSelectstage.label == "Enquiry" ||
                  buyingSelectstage.label == "Evaluation" ||
                  buyingSelectstage.label == "Documentation" ||
                  buyingSelectstage.label == "PhysicallProcess"
                ) {
                  itemData = item.companyid;
                  itmData2 = item.evaluatorid;
                } else if (buyingSelectstage.label == "Refurbishment") {
                  if (rfinchargeselect) {
                    itemData = item.RFInchargeID;
                  } else if (rftechnicianselect) {
                    itemData = item.RFTechnicianID;
                  }
                }
                let itemDate;
                if (buyingSelectstage.label == "Enquiry") {
                  itemDate = new Date(item.createdAt);
                } else {
                  itemDate = new Date(item.created_At);
                }

                if (DownlinUserIds && selectedstartdate && selectedenddate) {
                  return (
                    (itemData == element.value || itmData2 == element.value) &&
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                } else if (DownlinUserIds) {
                  return itemData == element.value || itmData2 == element.value;
                }

                return true;
              });
              if (filtered_BuyerEnquirydata) {
                filtered_BuyerEnquirydata.map(async (ival) => {
                  ival.DOWNLINE = element.label;
                  await alldata.push(ival);
                });
              }
            }
            await this.setState({ Data: alldata, column1: filter });
          }

          await this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.drawChart();
          await this.piechart();
          await this.pricechart();
        }
        //--------------------filtered without manager,tl,evaluator,rf incharge,rf technician datas and with location and make-------------------------------
        else if (filteredMake || filteredLoc) {
          this.setState({ dataTablShow: true });

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }

          let RESULT;
          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by  location of  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars  from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars in location of  ${filtered_Loc ? filtered_Loc : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by  location of  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by  location of  ${filtered_Loc ? filtered_Loc : ""}`;
          } else if (filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars `;
          }
          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess"
          ) {
            let data;
            let filter;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            }
            if (buyingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "PRICE"
                );
              });
            }
            let filtered_BuyerEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_BuyerEnquirydata = data.filter((item) => {
                        let itemDate;
                        let itemMake;
                        if (buyingSelectstage.label == "Enquiry") {
                          itemDate = new Date(item.createdAt);
                          itemMake = item.Make;
                        } else {
                          itemDate = new Date(item.created_At);
                          itemMake = item.make;
                        }
                        const itemLocation = item.location;

                        if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake &&
                          filteredLoc
                        ) {
                          return (
                            itemMake == filteredMake.value &&
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredMake && filteredLoc) {
                          return (
                            itemMake == filteredMake.value &&
                            itemLocation == filteredLoc.value
                          );
                        }
                        return true;
                      });

                      for (
                        let index = 0;
                        index < filtered_BuyerEnquirydata.length;
                        index++
                      ) {
                        const element = filtered_BuyerEnquirydata[index];
                        filtered_BuyerEnquirydatas.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filtered_BuyerEnquirydata = data.filter((item) => {
                      let itemDate;
                      let itemMake;
                      if (buyingSelectstage.label == "Enquiry") {
                        itemDate = new Date(item.createdAt);
                        itemMake = item.Make;
                      } else {
                        itemDate = new Date(item.created_At);
                        itemMake = item.make;
                      }
                      const itemLocation = item.location;

                      if (
                        selectedstartdate &&
                        selectedenddate &&
                        filteredMake
                      ) {
                        return (
                          itemMake == filteredMake.value &&
                          itemDate >= new Date(selectedstartdate) &&
                          itemDate <= new Date(selectedenddate)
                        );
                      } else if (filteredMake) {
                        return itemMake == filteredMake.value;
                      }
                      return true;
                    });

                    for (
                      let index = 0;
                      index < filtered_BuyerEnquirydata.length;
                      index++
                    ) {
                      const element = filtered_BuyerEnquirydata[index];
                      filtered_BuyerEnquirydatas.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filtered_BuyerEnquirydata = data.filter((item) => {
                      let itemDate;
                      let itemMake;
                      if (buyingSelectstage.label == "Enquiry") {
                        itemDate = new Date(item.createdAt);
                        itemMake = item.Make;
                      } else {
                        itemDate = new Date(item.created_At);
                        itemMake = item.make;
                      }
                      const itemLocation = item.location;

                      if (selectedstartdate && selectedenddate && filteredLoc) {
                        return (
                          itemLocation == filteredLoc.value &&
                          itemDate >= new Date(selectedstartdate) &&
                          itemDate <= new Date(selectedenddate)
                        );
                      } else if (filteredLoc) {
                        return itemLocation == filteredLoc.value;
                      }
                      return true;
                    });

                    for (
                      let index = 0;
                      index < filtered_BuyerEnquirydata.length;
                      index++
                    ) {
                      const element = filtered_BuyerEnquirydata[index];
                      filtered_BuyerEnquirydatas.push(element);
                    }
                  });
            }
            this.setState({
              Data: filtered_BuyerEnquirydatas,
              column1: filter,
              submitResult: RESULT,
            });
          }
          if (buyingSelectstage.label == "Refurbishment") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "PRICE"
              );
            });
            let RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } datas`;

            let filtered_Refurbushments = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_Refurbushment = RefurbushmentData.filter(
                        (item) => {
                          const itemDate = new Date(item.created_At);
                          const itemLocation = item.location;
                          const itemMake = item.make;

                          if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            filteredPrice2 &&
                            (filteredPrice1.value != 0 ||
                              filteredPrice2.value != 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } to ${filteredPrice2.label} lakhs from ${moment(
                              selectedstartdate
                            ).format("DD-MM-YYYY")} to ${moment(
                              selectedenddate
                            ).format("DD-MM-YYYY")} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${
                              filtered_Loc ? filtered_Loc : ""
                            }.`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value !== 0 ||
                              filteredPrice1.value !== 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost between ${
                              filteredPrice1.label
                            } to ${filteredPrice2.label} lakhs in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${
                              filtered_Loc ? filtered_Loc : ""
                            }..`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${buyingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (filteredMake && filteredLoc) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } in ${buyingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value
                            );
                          }
                          return true;
                        }
                      );

                      for (
                        let index = 0;
                        index < filtered_Refurbushment.length;
                        index++
                      ) {
                        const element = filtered_Refurbushment[index];
                        filtered_Refurbushments.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filtered_Refurbushment = RefurbushmentData.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost between ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs in ${
                            filtered_Make ? filtered_Make : ""
                          }  .`;
                          return (
                            itemMake == filteredMake.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${buyingSelectstage.label}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredMake) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  in ${buyingSelectstage.label}`;
                          return itemMake == filteredMake.value;
                        }

                        return true;
                      }
                    );

                    for (
                      let index = 0;
                      index < filtered_Refurbushment.length;
                      index++
                    ) {
                      const element = filtered_Refurbushment[index];
                      filtered_Refurbushments.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filtered_Refurbushment = RefurbushmentData.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost between ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs in location ${
                            filtered_Loc ? filtered_Loc : ""
                          }.`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            buyingSelectstage.label
                          } in Location ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredLoc) {
                          RESULT = `Cars in ${
                            buyingSelectstage.label
                          } in Location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return itemLocation == filteredLoc.value;
                        }

                        return true;
                      }
                    );

                    for (
                      let index = 0;
                      index < filtered_Refurbushment.length;
                      index++
                    ) {
                      const element = filtered_Refurbushment[index];
                      filtered_Refurbushments.push(element);
                    }
                  });
            }

            await this.setState({
              Data: filtered_Refurbushments,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          if (buyingSelectstage.label == "Inventory") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "STAGE" &&
                el.Header !== "UpdateBy"
              );
            });
            let RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } datas`;

            let filter_prices = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filter_price = this.state.BuyerInventroydata.filter(
                        (item) => {
                          const itemDate = new Date(item.created_At);
                          const itemLocation = item.location;
                          const itemMake = item.make;

                          if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars ${
                              filteredPrice1.label
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            filteredPrice2 &&
                            (filteredPrice1.value != 0 ||
                              filteredPrice2.value != 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars between${filteredPrice1.label} to ${
                              filteredPrice2.label
                            } lakhs from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars ${filteredPrice1.label} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}.`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value !== 0 ||
                              filteredPrice1.value !== 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars between ${filteredPrice1.label} to ${
                              filteredPrice2.label
                            } lakhs in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }..`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${buyingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (filteredMake && filteredLoc) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } in ${buyingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value
                            );
                          }
                          return true;
                        }
                      );

                      for (
                        let index = 0;
                        index < filter_price.length;
                        index++
                      ) {
                        const element = filter_price[index];
                        filter_prices.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filter_price = this.state.BuyerInventroydata.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredMake
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredMake
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs in ${filtered_Make ? filtered_Make : ""}  .`;
                          return (
                            itemMake == filteredMake.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${buyingSelectstage.label}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredMake) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  in ${buyingSelectstage.label}`;
                          return itemMake == filteredMake.value;
                        }
                        return true;
                      }
                    );

                    for (let index = 0; index < filter_price.length; index++) {
                      const element = filter_price[index];
                      filter_prices.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filter_price = this.state.BuyerInventroydata.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Cars ${
                            filteredPrice1.label
                          } in location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }.`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            buyingSelectstage.label
                          } in Location ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredLoc) {
                          RESULT = `Cars in ${
                            buyingSelectstage.label
                          } in Location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return itemLocation == filteredLoc.value;
                        }
                        return true;
                      }
                    );

                    for (let index = 0; index < filter_price.length; index++) {
                      const element = filter_price[index];
                      filter_prices.push(element);
                    }
                  });
            }

            await this.setState({
              Data: filter_prices,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          await this.setState({ buyingSubmitBtn: false });
        }
        //--------------------filtered without manager,tl,evaluator,rf incharge,rf technician datas-------------------------------
        else {
          this.setState({ dataTablShow: true });
          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess"
          ) {
            let data;
            let filter;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            }
            if (buyingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "UpdateBy" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "UpdateBy" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "Buyer Name" &&
                  el.Header !== "PRICE"
                );
              });
            }
            let RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            }  datas`;
            const filtered_BuyerEnquirydata = data.filter((item) => {
              let itemDate;
              if (buyingSelectstage.label == "Enquiry") {
                itemDate = new Date(item.createdAt);
              } else {
                itemDate = new Date(item.created_At);
              }

              if (selectedstartdate && selectedenddate) {
                RESULT = `Cars from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                  buyingSelectstage.label
                }`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate)
                );
              }

              return true;
            });

            await this.setState({
              Data: filtered_BuyerEnquirydata,
              column1: filter,
              submitResult: RESULT,
            });
          }
          if (buyingSelectstage.label == "Refurbishment") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "PRICE" &&
                el.Header !== "UpdateBy"
              );
            });
            let RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } datas`;

            const filtered_Refurbushment = RefurbushmentData.filter((item) => {
              const itemDate = new Date(item.created_At);
              if (
                filteredPrice1 &&
                (filteredPrice1.value == 0 || filteredPrice1.value == 6) &&
                selectedstartdate &&
                selectedenddate
              ) {
                RESULT = `Cars ${filteredPrice1.label} from ${moment(
                  selectedstartdate
                ).format("DD-MM-YYYY")} to ${moment(selectedenddate).format(
                  "DD-MM-YYYY"
                )}`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate) &&
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice1.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                filteredPrice2 &&
                (filteredPrice1.value != 0 || filteredPrice2.value != 6) &&
                selectedstartdate &&
                selectedenddate
              ) {
                RESULT = `Cars ${filteredPrice1.label} to ${
                  filteredPrice2.label
                } lakhs from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")}`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate) &&
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice2.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                (filteredPrice1.value == 0 || filteredPrice1.value == 6)
              ) {
                RESULT = `Cars ${filteredPrice1.label} `;
                return (
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice1.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
              ) {
                RESULT = `Cars between ${filteredPrice1.label} to ${filteredPrice2.label} lakhs .`;
                return (
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice2.rangeEnd
                );
              } else if (selectedstartdate && selectedenddate) {
                RESULT = `Cars from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                  buyingSelectstage.label
                }`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate)
                );
              }

              return true;
            });
            await this.setState({
              Data: filtered_Refurbushment,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          if (buyingSelectstage.label == "Inventory") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "STAGE" &&
                el.Header !== "UpdateBy"
              );
            });
            let RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } datas`;

            const filter_price = this.state.BuyerInventroydata.filter(
              (item) => {
                const itemDate = new Date(item.created_At);
                if (
                  filteredPrice1 &&
                  (filteredPrice1.value == 0 || filteredPrice1.value == 6) &&
                  selectedstartdate &&
                  selectedenddate
                ) {
                  RESULT = `Cars ${filteredPrice1.label} in ${
                    buyingSelectstage.label
                  } from ${moment(selectedstartdate).format(
                    "DD-MM-YYYY"
                  )} to ${moment(selectedenddate).format("DD-MM-YYYY")}`;
                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate) &&
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice1.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  filteredPrice2 &&
                  (filteredPrice1.value != 0 || filteredPrice2.value != 6) &&
                  selectedstartdate &&
                  selectedenddate
                ) {
                  RESULT = `Cars between ${filteredPrice1.label} to ${
                    filteredPrice2.label
                  } lakhs in ${buyingSelectstage.label} from ${moment(
                    selectedstartdate
                  ).format("DD-MM-YYYY")} to ${moment(selectedenddate).format(
                    "DD-MM-YYYY"
                  )}`;
                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate) &&
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice2.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  (filteredPrice1.value == 0 || filteredPrice1.value == 6)
                ) {
                  RESULT = `Cars ${filteredPrice1.label} in ${buyingSelectstage.label}`;
                  return (
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice1.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
                ) {
                  RESULT = `Cars between ${filteredPrice1.label} to ${filteredPrice2.label} lakhs in ${buyingSelectstage.label}`;
                  return (
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice2.rangeEnd
                  );
                } else if (selectedstartdate && selectedenddate) {
                  RESULT = `Cars from ${moment(selectedstartdate).format(
                    "DD-MM-YYYY"
                  )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                    buyingSelectstage.label
                  }`;

                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                }
                return true;
              }
            );
            await this.setState({
              Data: filter_price,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          await this.setState({ buyingSubmitBtn: false });
          await this.drawChart();
          await this.piechart();
        }
      } else {
        if (!sellingSelectstage) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        } else if (
          (pocselect && !sellingSelectstage) ||
          (managerselect && !sellingSelectstage) ||
          (teamleaderselect && !sellingSelectstage) ||
          (evaluatorselect && !sellingSelectstage) ||
          (rfinchargeselect && !sellingSelectstage) ||
          (rftechnicianselect && !sellingSelectstage)
        ) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        }

        //----------------get datas-----------------------------
        //---------------filtered manager,tl,evaluator,rf incharge,rf technician datas with location and make-------------------------------
        else if (
          (pocselect && (filteredMake || filteredLoc)) ||
          (managerselect && (filteredMake || filteredLoc)) ||
          (teamleaderselect && (filteredMake || filteredLoc)) ||
          (salesconsutantselect && (filteredMake || filteredLoc)) ||
          (rfinchargeselect && (filteredMake || filteredLoc)) ||
          (rftechnicianselect && (filteredMake || filteredLoc))
        ) {
          this.setState({ dataTablShow: true });
          let RESULT;

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_poc = "";
          if (pocselect && pocselect.length) {
            pocselect.map((name, index) => {
              const comma = index < pocselect.length - 1 ? "," : "";
              filtered_poc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_manager = "";
          if (managerselect && managerselect.length) {
            managerselect.map((name, index) => {
              const comma = index < managerselect.length - 1 ? "," : "";
              filtered_manager += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_tl = "";
          if (teamleaderselect && teamleaderselect.length) {
            teamleaderselect.map((name, index) => {
              const comma = index < teamleaderselect.length - 1 ? "," : "";
              filtered_tl += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_salesconsultant = "";
          if (salesconsutantselect && salesconsutantselect.length) {
            salesconsutantselect.map((name, index) => {
              const comma = index < salesconsutantselect.length - 1 ? "," : "";
              filtered_salesconsultant += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rfincharge = "";
          if (rfinchargeselect && rfinchargeselect.length) {
            rfinchargeselect.map((name, index) => {
              const comma = index < rfinchargeselect.length - 1 ? "," : "";
              filtered_rfincharge += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rftechnician = "";
          if (rftechnicianselect && rftechnicianselect.length) {
            rftechnicianselect.map((name, index) => {
              const comma = index < rftechnicianselect.length - 1 ? "," : "";
              filtered_rftechnician += " " + name.label + " " + comma + " ";
            });
          }

          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }
          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer" ||
            sellingSelectstage.label == "Refurbishment"
          ) {
            let filter;
            let data;
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (
              sellingSelectstage.label == "Lead" ||
              sellingSelectstage.label == "Contract" ||
              sellingSelectstage.label == "Delivery" ||
              sellingSelectstage.label == "RcTransfer"
            ) {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (sellingSelectstage.label == "Refurbishment") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            }

            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            } else if (sellingSelectstage.label == "Refurbishment") {
              data = RefurbushmentData;
            }

            let filtered_SellingEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      filteredMake.map((filteredMake, j) => {
                        const filtered_SellingEnquirydata = data.filter(
                          (item) => {
                            let itemDate;
                            let itemMake;
                            if (sellingSelectstage.label == "Enquiry") {
                              itemDate = new Date(item.createdAt);
                              itemMake = item.Make;
                            } else {
                              itemDate = new Date(item.created_At);
                              itemMake = item.make;
                            }
                            const itemLocation = item.location;
                            let itemData;
                            let itemData2;
                            if (
                              sellingSelectstage.label == "Enquiry" ||
                              sellingSelectstage.label == "Lead" ||
                              sellingSelectstage.label == "Contract" ||
                              sellingSelectstage.label == "Delivery" ||
                              sellingSelectstage.label == "RcTransfer"
                            ) {
                              itemData = item.updatedby;
                              itemData2 = item.salesid;
                            } else if (
                              sellingSelectstage.label == "Refurbishment"
                            ) {
                              if (rfinchargeselect) {
                                itemData = item.RFInchargeID;
                              } else if (rftechnicianselect) {
                                itemData = item.RFTechnicianID;
                              }
                            }

                            if (
                              DownlinUserIds &&
                              selectedstartdate &&
                              selectedenddate &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itemData2 == element.value) &&
                                itemDate >= new Date(selectedstartdate) &&
                                itemDate <= new Date(selectedenddate)
                              );
                            } else if (
                              DownlinUserIds &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itemData2 == element.value)
                              );
                            }

                            return true;
                          }
                        );

                        for (
                          let index = 0;
                          index < filtered_SellingEnquirydata.length;
                          index++
                        ) {
                          const element1 = filtered_SellingEnquirydata[index];
                          element1.DOWNLINE = element.label;
                          filtered_SellingEnquirydatas.push(element1);
                        }
                      });
                    });
                  })
                : filteredMake && filteredMake.length
                ? DownlinUserIds.map((element, id) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_SellingEnquirydata = data.filter(
                        (item) => {
                          let itemDate;
                          let itemMake;
                          if (sellingSelectstage.label == "Enquiry") {
                            itemDate = new Date(item.createdAt);
                            itemMake = item.Make;
                          } else {
                            itemDate = new Date(item.created_At);
                            itemMake = item.make;
                          }
                          const itemLocation = item.location;
                          let itemData;
                          let itemData2;
                          if (
                            sellingSelectstage.label == "Enquiry" ||
                            sellingSelectstage.label == "Lead" ||
                            sellingSelectstage.label == "Contract" ||
                            sellingSelectstage.label == "Delivery" ||
                            sellingSelectstage.label == "RcTransfer"
                          ) {
                            itemData = item.updatedby;
                            itemData2 = item.salesid;
                          } else if (
                            sellingSelectstage.label == "Refurbishment"
                          ) {
                            if (rfinchargeselect) {
                              itemData = item.RFInchargeID;
                            } else if (rftechnicianselect) {
                              itemData = item.RFTechnicianID;
                            }
                          }
                          if (
                            DownlinUserIds &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake
                          ) {
                            return (
                              itemMake == filteredMake.value &&
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (DownlinUserIds && filteredMake) {
                            return (
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemMake == filteredMake.value
                            );
                          }

                          return true;
                        }
                      );

                      for (
                        let index = 0;
                        index < filtered_SellingEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_SellingEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_SellingEnquirydatas.push(element1);
                      }
                    });
                  })
                : DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      const filtered_SellingEnquirydata = data.filter(
                        (item) => {
                          let itemDate;
                          let itemMake;
                          if (sellingSelectstage.label == "Enquiry") {
                            itemDate = new Date(item.createdAt);
                            itemMake = item.Make;
                          } else {
                            itemDate = new Date(item.created_At);
                            itemMake = item.make;
                          }
                          const itemLocation = item.location;
                          let itemData;
                          let itemData2;
                          if (
                            sellingSelectstage.label == "Enquiry" ||
                            sellingSelectstage.label == "Lead" ||
                            sellingSelectstage.label == "Contract" ||
                            sellingSelectstage.label == "Delivery" ||
                            sellingSelectstage.label == "RcTransfer"
                          ) {
                            itemData = item.updatedby;
                            itemData2 = item.salesid;
                          } else if (
                            sellingSelectstage.label == "Refurbishment"
                          ) {
                            if (rfinchargeselect) {
                              itemData = item.RFInchargeID;
                            } else if (rftechnicianselect) {
                              itemData = item.RFTechnicianID;
                            }
                          }

                          if (
                            DownlinUserIds &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredLoc
                          ) {
                            return (
                              itemMake == filteredLoc.value &&
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (DownlinUserIds && filteredLoc) {
                            return (
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemMake == filteredLoc.value
                            );
                          }

                          return true;
                        }
                      );

                      for (
                        let index = 0;
                        index < filtered_SellingEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_SellingEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_SellingEnquirydatas.push(element1);
                      }
                    });
                  });
            }
            await this.setState({
              Data: filtered_SellingEnquirydatas,
              column1: filter,
            });
          }
          await this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.pricechart();
        }

        //---------------filtered manager,tl,evaluator,rf incharge,rf technician datas-------------------------------
        else if (
          pocselect ||
          managerselect ||
          teamleaderselect ||
          salesconsutantselect ||
          rfinchargeselect ||
          rftechnicianselect
        ) {
          this.setState({ dataTablShow: true });
          let filtered_poc = "";
          if (pocselect && pocselect.length) {
            pocselect.map((name, index) => {
              const comma = index < pocselect.length - 1 ? "," : "";
              filtered_poc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_manager = "";
          if (managerselect && managerselect.length) {
            managerselect.map((name, index) => {
              const comma = index < managerselect.length - 1 ? "," : "";
              filtered_manager += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_tl = "";
          if (teamleaderselect && teamleaderselect.length) {
            teamleaderselect.map((name, index) => {
              const comma = index < teamleaderselect.length - 1 ? "," : "";
              filtered_tl += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_salesconsultant = "";
          if (salesconsutantselect && salesconsutantselect.length) {
            salesconsutantselect.map((name, index) => {
              const comma = index < salesconsutantselect.length - 1 ? "," : "";
              filtered_salesconsultant += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rfincharge = "";
          if (rfinchargeselect && rfinchargeselect.length) {
            rfinchargeselect.map((name, index) => {
              const comma = index < rfinchargeselect.length - 1 ? "," : "";
              filtered_rfincharge += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_rftechnician = "";
          if (rftechnicianselect && rftechnicianselect.length) {
            rftechnicianselect.map((name, index) => {
              const comma = index < rftechnicianselect.length - 1 ? "," : "";
              filtered_rftechnician += " " + name.label + " " + comma + " ";
            });
          }
          let RESULT;
          if (selectedstartdate && selectedenddate) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars by ${
              pocselect
                ? filtered_poc
                : managerselect
                ? filtered_manager
                : teamleaderselect
                ? filtered_tl
                : salesconsutantselect
                ? filtered_salesconsultant
                : rfinchargeselect
                ? filtered_rfincharge
                : rftechnicianselect
                ? filtered_rftechnician
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }

          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer" ||
            sellingSelectstage.label == "Refurbishment"
          ) {
            let filter;
            let data;
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (
              sellingSelectstage.label == "Lead" ||
              sellingSelectstage.label == "Contract" ||
              sellingSelectstage.label == "Delivery" ||
              sellingSelectstage.label == "RcTransfer"
            ) {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else if (sellingSelectstage.label == "Refurbishment") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "Buyer Name"
                );
              });
            }

            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            } else if (sellingSelectstage.label == "Refurbishment") {
              data = RefurbushmentData;
            }
            let alldata = [];
            for (let index = 0; index < DownlinUserIds.length; index++) {
              const element = DownlinUserIds[index];
              const filtered_BuyerEnquirydata = data.filter((item) => {
                let itemDate;
                if (sellingSelectstage.label == "Enquiry") {
                  itemDate = new Date(item.createdAt);
                } else {
                  itemDate = new Date(item.created_At);
                }
                let itemData;
                let itemData2;
                if (
                  sellingSelectstage.label == "Enquiry" ||
                  sellingSelectstage.label == "Lead" ||
                  sellingSelectstage.label == "Contract" ||
                  sellingSelectstage.label == "Delivery" ||
                  sellingSelectstage.label == "RcTransfer"
                ) {
                  itemData = item.updatedby;
                  itemData2 = item.salesid;
                } else if (sellingSelectstage.label == "Refurbishment") {
                  if (rfinchargeselect) {
                    itemData = item.RFInchargeID;
                  } else if (rftechnicianselect) {
                    itemData = item.RFTechnicianID;
                  }
                }

                if (DownlinUserIds && selectedstartdate && selectedenddate) {
                  return (
                    (itemData == element.value || itemData2 == element.value) &&
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                } else if (DownlinUserIds) {
                  return (
                    itemData == element.value || itemData2 == element.value
                  );
                }
                return true;
              });
              console.log(
                filtered_BuyerEnquirydata,
                "filtered_BuyerEnquirydata"
              );
              if (filtered_BuyerEnquirydata) {
                filtered_BuyerEnquirydata.map(async (ival) => {
                  ival.DOWNLINE = element.label;
                  await alldata.push(ival);
                });
              }
            }

            await this.setState({ Data: alldata, column1: filter });
          }
          await this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.drawChart();
          await this.piechart();
          await this.pricechart();
        }
        //--------------------filtered without manager,tl,evaluator,rf incharge,rf technician datas and with location and make-------------------------------
        else if (filteredMake || filteredLoc) {
          this.setState({ dataTablShow: true });

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }

          let RESULT;
          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by  location of  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars  from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars in location of  ${filtered_Loc ? filtered_Loc : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by  location of  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Cars by  location of  ${filtered_Loc ? filtered_Loc : ""}`;
          } else if (filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars `;
          }

          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer"
          ) {
            let filter;
            let data;
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            }
            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            }
            let filtered_SellingEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_SellingEnquirydata = data.filter(
                        (item) => {
                          let itemDate;
                          let itemMake;
                          if (sellingSelectstage.label == "Enquiry") {
                            itemDate = new Date(item.createdAt);
                            itemMake = item.Make;
                          } else {
                            itemDate = new Date(item.created_At);
                            itemMake = item.make;
                          }
                          const itemLocation = item.location;

                          if (
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (filteredMake && filteredLoc) {
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value
                            );
                          }
                          return true;
                        }
                      );
                      for (
                        let index = 0;
                        index < filtered_SellingEnquirydata.length;
                        index++
                      ) {
                        const element = filtered_SellingEnquirydata[index];
                        filtered_SellingEnquirydatas.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filtered_SellingEnquirydata = data.filter((item) => {
                      let itemDate;
                      let itemMake;
                      if (sellingSelectstage.label == "Enquiry") {
                        itemDate = new Date(item.createdAt);
                        itemMake = item.Make;
                      } else {
                        itemDate = new Date(item.created_At);
                        itemMake = item.make;
                      }
                      const itemLocation = item.location;

                      if (
                        selectedstartdate &&
                        selectedenddate &&
                        filteredMake
                      ) {
                        return (
                          itemMake == filteredMake.value &&
                          itemDate >= new Date(selectedstartdate) &&
                          itemDate <= new Date(selectedenddate)
                        );
                      } else if (filteredMake) {
                        return itemMake == filteredMake.value;
                      }
                      return true;
                    });
                    for (
                      let index = 0;
                      index < filtered_SellingEnquirydata.length;
                      index++
                    ) {
                      const element = filtered_SellingEnquirydata[index];
                      filtered_SellingEnquirydatas.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filtered_SellingEnquirydata = data.filter((item) => {
                      let itemDate;
                      let itemMake;
                      if (sellingSelectstage.label == "Enquiry") {
                        itemDate = new Date(item.createdAt);
                        itemMake = item.Make;
                      } else {
                        itemDate = new Date(item.created_At);
                        itemMake = item.make;
                      }
                      const itemLocation = item.location;

                      if (selectedstartdate && selectedenddate && filteredLoc) {
                        return (
                          itemLocation == filteredLoc.value &&
                          itemDate >= new Date(selectedstartdate) &&
                          itemDate <= new Date(selectedenddate)
                        );
                      } else if (filteredLoc) {
                        return itemLocation == filteredLoc.value;
                      }
                      return true;
                    });
                    for (
                      let index = 0;
                      index < filtered_SellingEnquirydata.length;
                      index++
                    ) {
                      const element = filtered_SellingEnquirydata[index];
                      filtered_SellingEnquirydatas.push(element);
                    }
                  });
            }
            this.setState({
              Data: filtered_SellingEnquirydatas,
              column1: filter,
              submitResult: RESULT,
            });
          }
          if (sellingSelectstage.label == "Refurbishment") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "PRICE" &&
                el.Header !== "Buyer Name"
              );
            });
            let RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } datas`;

            let filtered_Refurbushments = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_Refurbushment = RefurbushmentData.filter(
                        (item) => {
                          const itemDate = new Date(item.created_At);
                          const itemLocation = item.location;
                          const itemMake = item.make;

                          if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            filteredPrice2 &&
                            (filteredPrice1.value != 0 ||
                              filteredPrice2.value != 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } to ${filteredPrice2.label} lakhs from ${moment(
                              selectedstartdate
                            ).format("DD-MM-YYYY")} to ${moment(
                              selectedenddate
                            ).format("DD-MM-YYYY")} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost ${
                              filteredPrice1.label
                            } in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${
                              filtered_Loc ? filtered_Loc : ""
                            }.`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value !== 0 ||
                              filteredPrice1.value !== 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Refurbishment cost between ${
                              filteredPrice1.label
                            } to ${filteredPrice2.label} lakhs in ${
                              filtered_Make ? filtered_Make : ""
                            } location of  ${
                              filtered_Loc ? filtered_Loc : ""
                            }..`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.rfcost >= filteredPrice1.rangeStart &&
                              item.rfcost <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${sellingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (filteredMake && filteredLoc) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } in ${sellingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value
                            );
                          }

                          return true;
                        }
                      );
                      for (
                        let index = 0;
                        index < filtered_Refurbushment.length;
                        index++
                      ) {
                        const element = filtered_Refurbushment[index];
                        filtered_Refurbushments.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filtered_Refurbushment = RefurbushmentData.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredMake
                        ) {
                          RESULT = `Refurbishment cost between ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs in ${
                            filtered_Make ? filtered_Make : ""
                          }  .`;
                          return (
                            itemMake == filteredMake.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${sellingSelectstage.label}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredMake) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  in ${sellingSelectstage.label}`;
                          return itemMake == filteredMake.value;
                        }

                        return true;
                      }
                    );
                    for (
                      let index = 0;
                      index < filtered_Refurbushment.length;
                      index++
                    ) {
                      const element = filtered_Refurbushment[index];
                      filtered_Refurbushments.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filtered_Refurbushment = RefurbushmentData.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost ${
                            filteredPrice1.label
                          } in location of  ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Refurbishment cost between ${
                            filteredPrice1.label
                          } to ${filteredPrice2.label} lakhs in location ${
                            filtered_Loc ? filtered_Loc : ""
                          }.`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.rfcost >= filteredPrice1.rangeStart &&
                            item.rfcost <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            sellingSelectstage.label
                          } in Location ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredLoc) {
                          RESULT = `Cars in ${
                            sellingSelectstage.label
                          } in Location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return itemLocation == filteredLoc.value;
                        }

                        return true;
                      }
                    );
                    for (
                      let index = 0;
                      index < filtered_Refurbushment.length;
                      index++
                    ) {
                      const element = filtered_Refurbushment[index];
                      filtered_Refurbushments.push(element);
                    }
                  });
            }

            await this.setState({
              Data: filtered_Refurbushments,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          if (sellingSelectstage.label == "Inventory") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "STAGE" &&
                el.Header !== "UpdateBy"
              );
            });
            let RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } datas`;

            let filter_prices = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? filteredLoc.map((filteredLoc, i) => {
                    filteredMake.map((filteredMake, j) => {
                      const filter_price =
                        this.state.SellingInventroydata.filter((item) => {
                          const itemDate = new Date(item.created_At);
                          const itemLocation = item.location;
                          const itemMake = item.make;

                          if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars ${
                              filteredPrice1.label
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            filteredPrice2 &&
                            (filteredPrice1.value != 0 ||
                              filteredPrice2.value != 6) &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars between${filteredPrice1.label} to ${
                              filteredPrice2.label
                            } lakhs from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate) &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value == 0 ||
                              filteredPrice1.value == 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars ${filteredPrice1.label} in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${filtered_Loc ? filtered_Loc : ""}.`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice1.rangeEnd
                            );
                          } else if (
                            filteredPrice1 &&
                            (filteredPrice1.value !== 0 ||
                              filteredPrice1.value !== 6) &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars between ${filteredPrice1.label} to ${
                              filteredPrice2.label
                            } lakhs in ${
                              filtered_Make ? filtered_Make : ""
                            } location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }..`;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              item.price >= filteredPrice1.rangeStart &&
                              item.price <= filteredPrice2.rangeEnd
                            );
                          } else if (
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake &&
                            filteredLoc
                          ) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } from ${moment(selectedstartdate).format(
                              "DD-MM-YYYY"
                            )} to ${moment(selectedenddate).format(
                              "DD-MM-YYYY"
                            )} in ${sellingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (filteredMake && filteredLoc) {
                            RESULT = `Cars  ${
                              filtered_Make ? filtered_Make : ""
                            } in ${sellingSelectstage.label} in Location of ${
                              filtered_Loc ? filtered_Loc : ""
                            }  `;
                            return (
                              itemMake == filteredMake.value &&
                              itemLocation == filteredLoc.value
                            );
                          }
                          return true;
                        });
                      for (
                        let index = 0;
                        index < filter_price.length;
                        index++
                      ) {
                        const element = filter_price[index];
                        filter_prices.push(element);
                      }
                    });
                  })
                : filteredMake && filteredMake.length
                ? filteredMake.map((filteredMake, j) => {
                    const filter_price = this.state.SellingInventroydata.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${filtered_Make ? filtered_Make : ""}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredMake
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} in ${
                            filtered_Make ? filtered_Make : ""
                          }`;
                          return (
                            itemMake == filteredMake.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredMake
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs in ${filtered_Make ? filtered_Make : ""}  .`;
                          return (
                            itemMake == filteredMake.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in ${sellingSelectstage.label}`;
                          return (
                            itemMake == filteredMake.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredMake) {
                          RESULT = `Cars  ${
                            filtered_Make ? filtered_Make : ""
                          }  in ${sellingSelectstage.label}`;
                          return itemMake == filteredMake.value;
                        }
                        return true;
                      }
                    );
                    for (let index = 0; index < filter_price.length; index++) {
                      const element = filter_price[index];
                      filter_prices.push(element);
                    }
                  })
                : filteredLoc.map((filteredLoc, i) => {
                    const filter_price = this.state.SellingInventroydata.filter(
                      (item) => {
                        const itemDate = new Date(item.created_At);
                        const itemLocation = item.location;
                        const itemMake = item.make;

                        if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars ${filteredPrice1.label} from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          filteredPrice2 &&
                          (filteredPrice1.value != 0 ||
                            filteredPrice2.value != 6) &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs from ${moment(selectedstartdate).format(
                            "DD-MM-YYYY"
                          )} to ${moment(selectedenddate).format(
                            "DD-MM-YYYY"
                          )} in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate) &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value == 0 ||
                            filteredPrice1.value == 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Cars ${
                            filteredPrice1.label
                          } in location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice1.rangeEnd
                          );
                        } else if (
                          filteredPrice1 &&
                          (filteredPrice1.value !== 0 ||
                            filteredPrice1.value !== 6) &&
                          filteredLoc
                        ) {
                          RESULT = `Cars between ${filteredPrice1.label} to ${
                            filteredPrice2.label
                          } lakhs in location of ${
                            filtered_Loc ? filtered_Loc : ""
                          }.`;
                          return (
                            itemLocation == filteredLoc.value &&
                            item.price >= filteredPrice1.rangeStart &&
                            item.price <= filteredPrice2.rangeEnd
                          );
                        } else if (
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          RESULT = `Cars from ${moment(
                            selectedstartdate
                          ).format("DD-MM-YYYY")} to ${moment(
                            selectedenddate
                          ).format("DD-MM-YYYY")} in ${
                            sellingSelectstage.label
                          } in Location ${filtered_Loc ? filtered_Loc : ""}`;
                          return (
                            itemLocation == filteredLoc.value &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (filteredLoc) {
                          RESULT = `Cars in ${
                            sellingSelectstage.label
                          } in Location of ${filtered_Loc ? filtered_Loc : ""}`;
                          return itemLocation == filteredLoc.value;
                        }
                        return true;
                      }
                    );
                    for (let index = 0; index < filter_price.length; index++) {
                      const element = filter_price[index];
                      filter_prices.push(element);
                    }
                  });
            }
            await this.setState({
              Data: filter_prices,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          await this.setState({ buyingSubmitBtn: false });
        } else {
          this.setState({ dataTablShow: true });

          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer"
          ) {
            let filter;
            let data;
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE" &&
                  el.Header !== "UpdateBy"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "UpdateBy" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            }
            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            }

            let RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            }  datas`;
            const filtered_SellerEnquirydata = data.filter((item) => {
              let itemDate;
              if (sellingSelectstage.label == "Enquiry") {
                itemDate = new Date(item.createdAt);
              } else {
                itemDate = new Date(item.created_At);
              }
              if (selectedstartdate && selectedenddate) {
                RESULT = `Cars from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                  sellingSelectstage.label
                }`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate)
                );
              }
              return true;
            });

            this.setState({
              Data: filtered_SellerEnquirydata,
              column1: filter,
              submitResult: RESULT,
            });
          }
          if (sellingSelectstage.label == "Refurbishment") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "UpdateBy" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "PRICE" &&
                el.Header !== "Buyer Name"
              );
            });
            let RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } datas`;

            const filtered_Refurbushment = RefurbushmentData.filter((item) => {
              const itemDate = new Date(item.created_At);
              if (
                filteredPrice1 &&
                (filteredPrice1.value == 0 || filteredPrice1.value == 6) &&
                selectedstartdate &&
                selectedenddate
              ) {
                RESULT = `Cars ${filteredPrice1.label} from ${moment(
                  selectedstartdate
                ).format("DD-MM-YYYY")} to ${moment(selectedenddate).format(
                  "DD-MM-YYYY"
                )}`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate) &&
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice1.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                filteredPrice2 &&
                (filteredPrice1.value != 0 || filteredPrice2.value != 6) &&
                selectedstartdate &&
                selectedenddate
              ) {
                RESULT = `Cars ${filteredPrice1.label} to ${
                  filteredPrice2.label
                } lakhs from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")}`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate) &&
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice2.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                (filteredPrice1.value == 0 || filteredPrice1.value == 6)
              ) {
                RESULT = `Cars ${filteredPrice1.label} `;
                return (
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice1.rangeEnd
                );
              } else if (
                filteredPrice1 &&
                (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
              ) {
                RESULT = `Cars between ${filteredPrice1.label} to ${filteredPrice2.label} lakhs .`;
                return (
                  item.rfcost >= filteredPrice1.rangeStart &&
                  item.rfcost <= filteredPrice2.rangeEnd
                );
              } else if (selectedstartdate && selectedenddate) {
                RESULT = `Cars from ${moment(selectedstartdate).format(
                  "DD-MM-YYYY"
                )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                  sellingSelectstage.label
                }`;
                return (
                  itemDate >= new Date(selectedstartdate) &&
                  itemDate <= new Date(selectedenddate)
                );
              }

              return true;
            });
            await this.setState({
              Data: filtered_Refurbushment,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          if (sellingSelectstage.label == "Inventory") {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "KMS" &&
                el.Header !== "OWNER TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "STAGE" &&
                el.Header !== "UpdateBy"
              );
            });
            let RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } datas`;

            const filter_price = this.state.SellingInventroydata.filter(
              (item) => {
                const itemDate = new Date(item.created_At);
                if (
                  filteredPrice1 &&
                  (filteredPrice1.value == 0 || filteredPrice1.value == 6) &&
                  selectedstartdate &&
                  selectedenddate
                ) {
                  RESULT = `Cars ${filteredPrice1.label} in ${
                    sellingSelectstage.label
                  } from ${moment(selectedstartdate).format(
                    "DD-MM-YYYY"
                  )} to ${moment(selectedenddate).format("DD-MM-YYYY")}`;
                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate) &&
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice1.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  filteredPrice2 &&
                  (filteredPrice1.value != 0 || filteredPrice2.value != 6) &&
                  selectedstartdate &&
                  selectedenddate
                ) {
                  RESULT = `Cars between ${filteredPrice1.label} to ${
                    filteredPrice2.label
                  } lakhs in ${sellingSelectstage.label} from ${moment(
                    selectedstartdate
                  ).format("DD-MM-YYYY")} to ${moment(selectedenddate).format(
                    "DD-MM-YYYY"
                  )}`;
                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate) &&
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice2.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  (filteredPrice1.value == 0 || filteredPrice1.value == 6)
                ) {
                  RESULT = `Cars ${filteredPrice1.label} in ${sellingSelectstage.label}`;
                  return (
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice1.rangeEnd
                  );
                } else if (
                  filteredPrice1 &&
                  (filteredPrice1.value !== 0 || filteredPrice1.value !== 6)
                ) {
                  RESULT = `Cars between ${filteredPrice1.label} to ${filteredPrice2.label} lakhs in ${sellingSelectstage.label}`;
                  return (
                    item.price >= filteredPrice1.rangeStart &&
                    item.price <= filteredPrice2.rangeEnd
                  );
                } else if (selectedstartdate && selectedenddate) {
                  RESULT = `Cars from ${moment(selectedstartdate).format(
                    "DD-MM-YYYY"
                  )} to ${moment(selectedenddate).format("DD-MM-YYYY")} in ${
                    sellingSelectstage.label
                  }`;

                  return (
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                }
                return true;
              }
            );
            await this.setState({
              Data: filter_price,
              column1: filter,
              submitResult: RESULT,
            });
            await this.pricechart();
          }
          await this.setState({ buyingSubmitBtn: false });
          await this.drawChart();
          await this.piechart();
        }
      }
    }
  };

  SubmitDownline = async () => {
    let {
      dataTablShow,
      FilterData,
      buyingSelectstage,
      BuyerEnquiryData,
      BuyerEvalution,
      BuyerDocument,
      BuyerPhysical,
      selectedstartdate,
      selectedenddate,
      filteredlistcars,
      userSelectError,
      userTypeeError,
      downlineError,
      carStatusSelectError,
      pocselect,
      managerselect,
      teamleaderselect,
      evaluatorselect,
      salesconsutantselect,
      userTypeOptions,
      RefurbushmentData,
      rftechnicianselect,
      rfinchargeselect,
      BuyerInventroydata,
      filteredPrice1,
      filteredPrice2,
      filteredMake,
      filteredLoc,
      userDownlineselect,
      BuyingWorking,
      ContractData,
      DeliveryData,
      RcData,
      SellerEnquiryData,
      SellerEvalution,
      SellingInventroydata,
      sellingSelectstage,
    } = this.state;
    if (!filteredlistcars) {
      await this.setState({ carStatusSelectError: true });
      setTimeout(() => {
        this.setState({ carStatusSelectError: false });
      }, 3000);
    } else if (!userTypeOptions) {
      await this.setState({ userTypeeError: true });
      setTimeout(() => {
        this.setState({ userTypeeError: false });
      }, 3000);
    } else if (
      (userTypeOptions.label == "POC" && !pocselect) ||
      (userTypeOptions.label == "Manager" && !managerselect) ||
      (userTypeOptions.label == "TeamLeader" && !teamleaderselect) ||
      (userTypeOptions.label == "Evaluator" && !evaluatorselect) ||
      (userTypeOptions.label == "Refurbishment Technician" &&
        !rftechnicianselect) ||
      (userTypeOptions.label == "Refurbishment Incharge" &&
        !rfinchargeselect) ||
      (userTypeOptions.label == "SalesConsultant" && !salesconsutantselect)
    ) {
      await this.setState({ userSelectError: true });
      setTimeout(() => {
        this.setState({ userSelectError: false });
      }, 3000);
    } else if (selectedenddate && !selectedstartdate) {
      await this.setState({ selectedstartdateError: true });
      setTimeout(() => {
        this.setState({ selectedstartdateError: false });
      }, 3000);
    } else if (selectedstartdate && !selectedenddate) {
      await this.setState({ selectedenddateError: true });
      setTimeout(() => {
        this.setState({ selectedenddateError: false });
      }, 3000);
    } else if (!userDownlineselect && this.state.isDownline) {
      await this.setState({ downlineError: true });
      setTimeout(() => {
        this.setState({ downlineError: false });
      }, 3000);
    } else {
      let DownlinUserIds;
      if (userDownlineselect) {
        DownlinUserIds = await cmsContent.BUYDFCARZ(
          "downline",
          "downlineUsersIds",
          userDownlineselect
        );
      }
      // console.log(DownlinUserIds, "DownlinUserIds");

      if (BuyingWorking == "Buying") {
        if (!buyingSelectstage) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        } else if (
          (pocselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (managerselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (teamleaderselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (evaluatorselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (rfinchargeselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (rftechnicianselect &&
            !buyingSelectstage &&
            this.state.isDownline &&
            userDownlineselect)
        ) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        }
        //---------------filtered manager,tl,evaluator,rf incharge,rf technician datas-------------------------------
        else if (userDownlineselect && (filteredMake || filteredLoc)) {
          this.setState({ dataTablShow: true });

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }
          let userDownlineselect_values = "";
          if (userDownlineselect && userDownlineselect.length) {
            userDownlineselect.map((name, index) => {
              const comma = index < userDownlineselect.length - 1 ? "," : "";
              userDownlineselect_values += " " + name.label + " " + comma + " ";
            });
          }

          let RESULT;
          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            } in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredMake) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  in ${filtered_Make ? filtered_Make : ""} Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }

          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess"
          ) {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "PRICE" &&
                el.Header !== "Buyer Name"
              );
            });
            let data;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            }

            let filtered_BuyerEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      filteredMake.map((filteredMake, j) => {
                        const filtered_BuyerEnquirydata = data.filter(
                          (item) => {
                            let itemData;
                            let itmData2;

                            if (
                              buyingSelectstage.label == "Enquiry" ||
                              buyingSelectstage.label == "Evaluation" ||
                              buyingSelectstage.label == "Documentation" ||
                              buyingSelectstage.label == "PhysicallProcess"
                            ) {
                              itemData = item.companyid;
                              itmData2 = item.evaluatorid;
                            }
                            // const itemData = item.companyid; // assuming "date" is the property in your object
                            // const itemDate = new Date(item.createdAt);
                            let itemDate;
                            if (buyingSelectstage.label == "Enquiry") {
                              itemDate = new Date(item.createdAt);
                            } else {
                              itemDate = new Date(item.created_At);
                            }
                            const itemLocation = item.location;
                            const itemMake = item.Make;

                            if (
                              DownlinUserIds &&
                              selectedstartdate &&
                              selectedenddate &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itmData2 == element.value) &&
                                itemDate >= new Date(selectedstartdate) &&
                                itemDate <= new Date(selectedenddate)
                              );
                            } else if (
                              DownlinUserIds &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itmData2 == element.value)
                              );
                            }

                            return true; // return all items if no date range specified
                          }
                        );

                        for (
                          let index = 0;
                          index < filtered_BuyerEnquirydata.length;
                          index++
                        ) {
                          const element1 = filtered_BuyerEnquirydata[index];
                          element1.DOWNLINE = element.label;
                          filtered_BuyerEnquirydatas.push(element1);
                        }
                      });
                    });
                  })
                : filteredMake && filteredMake.length
                ? DownlinUserIds.map((element, id) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_BuyerEnquirydata = data.filter((item) => {
                        let itemData;
                        let itmData2;

                        if (
                          buyingSelectstage.label == "Enquiry" ||
                          buyingSelectstage.label == "Evaluation" ||
                          buyingSelectstage.label == "Documentation" ||
                          buyingSelectstage.label == "PhysicallProcess"
                        ) {
                          itemData = item.companyid;
                          itmData2 = item.evaluatorid;
                        }
                        // const itemData = item.companyid; // assuming "date" is the property in your object
                        let itemDate;
                        if (buyingSelectstage.label == "Enquiry") {
                          itemDate = new Date(item.createdAt);
                        } else {
                          itemDate = new Date(item.created_At);
                        }
                        // const itemDate = new Date(item.createdAt);
                        const itemLocation = item.location;
                        const itemMake = item.Make;

                        if (
                          DownlinUserIds &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredMake
                        ) {
                          return (
                            itemMake == filteredMake.value &&
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (DownlinUserIds && filteredMake) {
                          return (
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemMake == filteredMake.value
                          );
                        }

                        return true; // return all items if no date range specified
                      });

                      for (
                        let index = 0;
                        index < filtered_BuyerEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_BuyerEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_BuyerEnquirydatas.push(element1);
                      }
                    });
                  })
                : DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      const filtered_BuyerEnquirydata = data.filter((item) => {
                        let itemData;
                        let itmData2;

                        if (
                          buyingSelectstage.label == "Enquiry" ||
                          buyingSelectstage.label == "Evaluation" ||
                          buyingSelectstage.label == "Documentation" ||
                          buyingSelectstage.label == "PhysicallProcess"
                        ) {
                          itemData = item.companyid;
                          itmData2 = item.evaluatorid;
                        }
                        // const itemData = item.companyid; // assuming "date" is the property in your object
                        // const itemDate = new Date(item.createdAt);
                        let itemDate;
                        if (buyingSelectstage.label == "Enquiry") {
                          itemDate = new Date(item.createdAt);
                        } else {
                          itemDate = new Date(item.created_At);
                        }
                        const itemLocation = item.location;
                        const itemMake = item.Make;

                        if (
                          DownlinUserIds &&
                          selectedstartdate &&
                          selectedenddate &&
                          filteredLoc
                        ) {
                          return (
                            itemLocation == filteredLoc.value &&
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemDate >= new Date(selectedstartdate) &&
                            itemDate <= new Date(selectedenddate)
                          );
                        } else if (DownlinUserIds && filteredLoc) {
                          return (
                            (itemData == element.value ||
                              itmData2 == element.value) &&
                            itemLocation == filteredLoc.value
                          );
                        }
                        return true; // return all items if no date range specified
                      });

                      for (
                        let index = 0;
                        index < filtered_BuyerEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_BuyerEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_BuyerEnquirydatas.push(element1);
                      }
                    });
                  });
            }

            this.setState({
              Data: filtered_BuyerEnquirydatas,
              column1: filter,
            });
          }
          this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
        } else if (userDownlineselect) {
          this.setState({ dataTablShow: true });
          let userDownlineselect_values = "";
          if (userDownlineselect && userDownlineselect.length) {
            userDownlineselect.map((name, index) => {
              const comma = index < userDownlineselect.length - 1 ? "," : "";
              userDownlineselect_values += " " + name.label + " " + comma + " ";
            });
          }
          let RESULT;
          if (selectedstartdate && selectedenddate) {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by  ${
              userDownlineselect ? userDownlineselect_values : ""
            } in ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else {
            RESULT = `Buying ${
              buyingSelectstage.label ? buyingSelectstage.label : ""
            } Cars by ${
              userDownlineselect ? userDownlineselect_values : ""
            } in ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : evaluatorselect
                ? evaluatorselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }

          if (
            buyingSelectstage.label == "Enquiry" ||
            buyingSelectstage.label == "Evaluation" ||
            buyingSelectstage.label == "Documentation" ||
            buyingSelectstage.label == "PhysicallProcess"
          ) {
            let filter = this.state.column.filter(function (el) {
              return (
                el.Header !== "SHARE" &&
                el.Header !== "Buyer Name" &&
                el.Header !== "DATA TYPE" &&
                el.Header !== "RFCOST" &&
                el.Header !== "PRICE"
              );
            });
            let data;
            if (buyingSelectstage.label == "Enquiry") {
              data = BuyerEnquiryData;
            } else if (buyingSelectstage.label == "Evaluation") {
              data = BuyerEvalution;
            } else if (buyingSelectstage.label == "Documentation") {
              data = BuyerDocument;
            } else if (buyingSelectstage.label == "PhysicallProcess") {
              data = BuyerPhysical;
            }

            let alldata = [];
            for (let index = 0; index < DownlinUserIds.length; index++) {
              const element = DownlinUserIds[index];
              const filtered_BuyerEnquirydata = data.filter((item) => {
                let itemData;
                let itmData2;

                if (
                  buyingSelectstage.label == "Enquiry" ||
                  buyingSelectstage.label == "Evaluation" ||
                  buyingSelectstage.label == "Documentation" ||
                  buyingSelectstage.label == "PhysicallProcess"
                ) {
                  itemData = item.companyid;
                  itmData2 = item.evaluatorid;
                }
                // const itemData = item.companyid; // assuming "date" is the property in your object
                let itemDate;
                if (buyingSelectstage.label == "Enquiry") {
                  itemDate = new Date(item.createdAt);
                } else {
                  itemDate = new Date(item.created_At);
                }
                if (DownlinUserIds && selectedstartdate && selectedenddate) {
                  return (
                    (itemData == element.value || itmData2 == element.value) &&
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                } else if (DownlinUserIds) {
                  return itemData == element.value || itmData2 == element.value;
                }

                return true; // return all items if no date range specified
              });

              if (filtered_BuyerEnquirydata) {
                filtered_BuyerEnquirydata.map(async (ival) => {
                  ival.DOWNLINE = element.label;
                  await alldata.push(ival);
                });
              }
            }

            this.setState({ Data: alldata, column1: filter });
          }
          this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.drawChart();
          await this.piechart();
        }
      } else {
        if (!sellingSelectstage) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        } else if (
          (pocselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (managerselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (teamleaderselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (evaluatorselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (rfinchargeselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect) ||
          (rftechnicianselect &&
            !sellingSelectstage &&
            this.state.isDownline &&
            userDownlineselect)
        ) {
          await this.setState({ selectStageError: true });
          setTimeout(() => {
            this.setState({ selectStageError: false, buyingSubmitBtn: false });
          }, 3000);
        } else if (userDownlineselect && (filteredMake || filteredLoc)) {
          this.setState({ dataTablShow: true });
          let RESULT;

          let filtered_Loc = "";
          if (filteredLoc && filteredLoc.length) {
            filteredLoc.map((name, index) => {
              const comma = index < filteredLoc.length - 1 ? "," : "";
              filtered_Loc += " " + name.label + " " + comma + " ";
            });
          }
          let filtered_Make = "";
          if (filteredMake && filteredMake.length) {
            filteredMake.map((name, index) => {
              const comma = index < filteredMake.length - 1 ? "," : "";
              filtered_Make += " " + name.label + " " + comma + " ";
            });
          }
          let userDownlineselect_values = "";
          if (userDownlineselect && userDownlineselect.length) {
            userDownlineselect.map((name, index) => {
              const comma = index < userDownlineselect.length - 1 ? "," : "";
              userDownlineselect_values += " " + name.label + " " + comma + " ";
            });
          }

          if (
            selectedstartdate &&
            selectedenddate &&
            filteredMake &&
            filteredLoc
          ) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (selectedstartdate && selectedenddate && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            } from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else if (filteredMake && filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredLoc) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} in  ${
              filtered_Loc ? filtered_Loc : ""
            }`;
          } else if (filteredMake) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } in ${filtered_Make ? filtered_Make : ""} Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }

          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer"
          ) {
            let filter = "";
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            }

            let data;
            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            }

            let filtered_SellingEnquirydatas = [];
            {
              filteredMake &&
              filteredMake.length &&
              filteredLoc &&
              filteredLoc.length
                ? DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      filteredMake.map((filteredMake, j) => {
                        const filtered_SellingEnquirydata = data.filter(
                          (item) => {
                            const itemDate = new Date(item.createdAt);
                            const itemLocation = item.location;
                            const itemMake = item.Make;
                            let itemData = item.updatedby;
                            let itemData2 = item.salesid;

                            if (
                              DownlinUserIds &&
                              selectedstartdate &&
                              selectedenddate &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itemData2 == element.value) &&
                                itemDate >= new Date(selectedstartdate) &&
                                itemDate <= new Date(selectedenddate)
                              );
                            } else if (
                              DownlinUserIds &&
                              filteredMake &&
                              filteredLoc
                            ) {
                              return (
                                itemMake == filteredMake.value &&
                                itemLocation == filteredLoc.value &&
                                (itemData == element.value ||
                                  itemData2 == element.value)
                              );
                            }

                            return true; // return all items if no date range specified
                          }
                        );

                        for (
                          let index = 0;
                          index < filtered_SellingEnquirydata.length;
                          index++
                        ) {
                          const element1 = filtered_SellingEnquirydata[index];
                          element1.DOWNLINE = element.label;
                          filtered_SellingEnquirydatas.push(element1);
                        }
                      });
                    });
                  })
                : filteredMake && filteredMake.length
                ? DownlinUserIds.map((element, id) => {
                    filteredMake.map((filteredMake, j) => {
                      const filtered_SellingEnquirydata = data.filter(
                        (item) => {
                          const itemDate = new Date(item.createdAt);
                          const itemLocation = item.location;
                          const itemMake = item.Make;
                          let itemData = item.updatedby;
                          let itemData2 = item.salesid;

                          if (
                            DownlinUserIds &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredMake
                          ) {
                            return (
                              itemMake == filteredMake.value &&
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (DownlinUserIds && filteredMake) {
                            return (
                              itemMake == filteredMake.value &&
                              (itemData == element.value ||
                                itemData2 == element.value)
                            );
                          }

                          return true; // return all items if no date range specified
                        }
                      );

                      for (
                        let index = 0;
                        index < filtered_SellingEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_SellingEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_SellingEnquirydatas.push(element1);
                      }
                    });
                  })
                : DownlinUserIds.map((element, id) => {
                    filteredLoc.map((filteredLoc, i) => {
                      const filtered_SellingEnquirydata = data.filter(
                        (item) => {
                          const itemDate = new Date(item.createdAt);
                          const itemLocation = item.location;
                          const itemMake = item.Make;
                          let itemData = item.updatedby;
                          let itemData2 = item.salesid;

                          if (
                            DownlinUserIds &&
                            selectedstartdate &&
                            selectedenddate &&
                            filteredLoc
                          ) {
                            return (
                              itemLocation == filteredLoc.value &&
                              (itemData == element.value ||
                                itemData2 == element.value) &&
                              itemDate >= new Date(selectedstartdate) &&
                              itemDate <= new Date(selectedenddate)
                            );
                          } else if (DownlinUserIds && filteredLoc) {
                            return (
                              itemLocation == filteredLoc.value &&
                              (itemData == element.value ||
                                itemData2 == element.value)
                            );
                          }
                          return true; // return all items if no date range specified
                        }
                      );

                      for (
                        let index = 0;
                        index < filtered_SellingEnquirydata.length;
                        index++
                      ) {
                        const element1 = filtered_SellingEnquirydata[index];
                        element1.DOWNLINE = element.label;
                        filtered_SellingEnquirydatas.push(element1);
                      }
                    });
                  });
            }

            this.setState({
              Data: filtered_SellingEnquirydatas,
              column1: filter,
            });
          }
          this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
        } else if (userDownlineselect) {
          this.setState({ dataTablShow: true });

          let userDownlineselect_values = "";
          if (userDownlineselect && userDownlineselect.length) {
            userDownlineselect.map((name, index) => {
              const comma = index < userDownlineselect.length - 1 ? "," : "";
              userDownlineselect_values += " " + name.label + " " + comma + " ";
            });
          }

          let RESULT;
          if (selectedstartdate && selectedenddate) {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""} from ${
              selectedstartdate
                ? moment(selectedstartdate).format("DD-MM-YYYY")
                : ""
            } to ${
              selectedenddate
                ? moment(selectedenddate).format("DD-MM-YYYY")
                : ""
            }`;
          } else {
            RESULT = `Selling ${
              sellingSelectstage.label ? sellingSelectstage.label : ""
            } Downline ${
              userDownlineselect ? userDownlineselect_values : ""
            }  Cars by ${
              pocselect
                ? pocselect.label
                : managerselect
                ? managerselect.label
                : teamleaderselect
                ? teamleaderselect.label
                : salesconsutantselect
                ? salesconsutantselect.label
                : ""
            } of ${userTypeOptions.label ? userTypeOptions.label : ""}`;
          }
          if (
            sellingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Lead" ||
            sellingSelectstage.label == "Contract" ||
            sellingSelectstage.label == "Delivery" ||
            sellingSelectstage.label == "RcTransfer"
          ) {
            let filter = "";
            if (sellingSelectstage.label == "Enquiry") {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            } else {
              filter = this.state.column.filter(function (el) {
                return (
                  el.Header !== "SHARE" &&
                  el.Header !== "DATA TYPE" &&
                  el.Header !== "KMS" &&
                  el.Header !== "OWNER TYPE" &&
                  el.Header !== "RFCOST" &&
                  el.Header !== "PRICE"
                );
              });
            }
            let data;
            if (sellingSelectstage.label == "Enquiry") {
              data = SellerEnquiryData;
            } else if (sellingSelectstage.label == "Lead") {
              data = SellerEvalution;
            } else if (sellingSelectstage.label == "Contract") {
              data = ContractData;
            } else if (sellingSelectstage.label == "Delivery") {
              data = DeliveryData;
            } else if (sellingSelectstage.label == "RcTransfer") {
              data = RcData;
            }
            // console.log(data,"data");
            let alldata = [];
            for (let index = 0; index < DownlinUserIds.length; index++) {
              const element = DownlinUserIds[index];
              const filtered_BuyerEnquirydata = data.filter((item) => {
                let itemDate;
                if (sellingSelectstage.label == "Enquiry") {
                  itemDate = new Date(item.createdAt);
                } else {
                  itemDate = new Date(item.created_At);
                }
                let itemData = item.updatedby;
                let itemData2 = item.salesid;

                if (DownlinUserIds && selectedstartdate && selectedenddate) {
                  return (
                    (itemData == element.value || itemData2 == element.value) &&
                    itemDate >= new Date(selectedstartdate) &&
                    itemDate <= new Date(selectedenddate)
                  );
                } else if (DownlinUserIds) {
                  return (
                    itemData == element.value || itemData2 == element.value
                  );
                }
                return true;
              });

              // console.log(filtered_BuyerEnquirydata, "filtered_BuyerEnquirydata");

              if (filtered_BuyerEnquirydata) {
                filtered_BuyerEnquirydata.map(async (ival) => {
                  ival.DOWNLINE = element.label;
                  await alldata.push(ival);
                });
              }
            }

            this.setState({ Data: alldata, column1: filter });
          }
          this.setState({ buyingSubmitBtn: false, submitResult: RESULT });
          await this.drawChart();
          await this.piechart();
        }
      }
    }
  };

  isDownline = (e) => {
    this.setState({
      isDownline: !this.state.isDownline,
      userTypeOptions: "",
      buyingSelectstage: "",
      managerselect: "",
      pocselect: "",
      teamleaderselect: "",
      evaluatorselect: "",
      rfinchargeselect: "",
      rftechnicianselect: "",
      filteredMake: "",
      filteredLoc: "",
      userDownlineselect: "",
      sellingSelectstage: "",
    });
  };

  chartView = async (d) => {
    let chart = document.getElementById("Charview");
    if (chart.style.display == "block") {
      chart.style.display = "none";
    } else {
      chart.style.display = "block";
    }
    let creditText = document.getElementsByClassName("canvasjs-chart-credit");
    if (creditText[0]) {
      creditText[0].style.display = "none";
      if (creditText[1]) {
        creditText[1].style.display = "none";
      }
      if (creditText[2]) {
        creditText[2].style.display = "none";
      }
    }
  };

  BackchartView = async (d) => {
    let chart = document.getElementById("Charview");
    let chart1 = document.getElementById("chartContainermakeSelectModal");
    if (chart.style.display == "block" || chart1.style.display == "block") {
      chart.style.display = "none";
      chart1.style.display = "none";
    } else {
      chart.style.display = "none";
      chart1.style.display = "none";
    }
    await this.setState({
      chartfilteredMake: "",
      //  Data: this.state.FilterData
    });
  };

  chartmakeFilter = async (d) => {
    await this.setState({ chartfilteredMake: d });
    if (d) {
      let chart = document.getElementById("chartContainermakeSelectModal");
      if (chart.style.display == "none") {
        chart.style.display = "block";
      } else {
        chart.style.display = "block";
      }
    }
    await this.drawChartmodal();
  };

  drawChartmodal() {
    let {
      chartfilteredMake,
      Data,
      buyingSelectstage,
      ModalOptions,
      sellingSelectstage,
    } = this.state;
    const dataPoints = [];
    if (chartfilteredMake) {
      const datas = Data.filter((item) => {
        let itemMake;
        if (
          buyingSelectstage.label == "Enquiry" ||
          sellingSelectstage.label == "Enquiry"
        ) {
          itemMake = item.Make;
        } else {
          itemMake = item.make;
        }

        if (chartfilteredMake) {
          return itemMake == chartfilteredMake.value;
        }

        return true; // return all items if no date range specified
      });
      ModalOptions.map((ModalOption, j) => {
        const filtered_BuyerEnquirydata = datas.filter((item) => {
          let itemMake;
          if (
            buyingSelectstage.label == "Enquiry" ||
            sellingSelectstage.label == "Enquiry"
          ) {
            itemMake = item.Model;
          } else {
            itemMake = item.model;
          }

          if (itemMake == ModalOption.value) {
            return itemMake == ModalOption.value;
          }
        });
        if (filtered_BuyerEnquirydata && filtered_BuyerEnquirydata.length) {
          dataPoints.push({
            label: filtered_BuyerEnquirydata[0].modelname,
            y: filtered_BuyerEnquirydata.length,
          });
        }
      });
    }
    const chart = new window.CanvasJS.Chart("chartContainermakeSelectModal", {
      animationEnabled: true,
      exportEnabled: true,
      theme: "light1",
      width: 1200,
      title: {
        // text: 'Simple Column Chart',
        text: `Models of ${chartfilteredMake.label} Car`,
      },
      axisY: {
        title: "Values",
      },
      data: [
        {
          type: "column",
          dataPoints: dataPoints,
        },
      ],
    });

    chart.render();
  }

  // handleScan = (data) => {
  //     if (data) {
  //         // Handle scanned data
  //         this.setState({ scannedData: data });
  //     }
  // };

  // handleError = (err) => {
  //     console.error('QR Scanner Error:', err);
  // };

  // toggleScanner = () => {
  //     this.setState((prevState) => ({
  //         showScanner: !prevState.showScanner,
  //         scannedData: null // Reset scanned data when opening the scanner
  //     }));
  // };

  render() {
    const {
      match: { params },
    } = this.props;
    let {
      loading,
      EditData,
      maintence_work,
      LocationOptions,
      priceOption,
      MakeOptions,
      Car_Content,
      YomOptions,
      colourOptions,
      disabledallfilter,
      disabledBuyingSellingfilter,
      BuyingWorking,
      dataTablShow,
      priceOption1,
      priceOption2,
      priceOption2_filter,
      disablepricefilter,
      userSelectError,
      userTypeeError,
      downlineError,
      carStatusSelectError,
      selectedstartdateError,
      selectedenddateError,
      selectStageError,
      buyingSubmitBtn,
      buyingSelectstage,
      disablefrompricefilter,
      isDownline,
      userTypeBuyingOption,
      dowenlineOption,
      userTypeSellingOption,
      pocselect,
      teamleaderselect,
      managerselect,
      sellingSelectstage,
    } = this.state;
    const makeid = localStorage.getItem("makeid");
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Global Report</h1>
                  </div>
                  <div className="card-body">
                    <>
                      <div className="container">
                        {/* from and to date */}
                        <div className="row">
                          <div className="col-sm-6">
                            <label
                              htmlFor="FromDate"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              FromDate:
                              {selectedstartdateError ? (
                                <span style={{ color: "red" }}>
                                  Please Select From Date
                                </span>
                              ) : (
                                ""
                              )}{" "}
                            </label>
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="ToDate"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              ToDate:
                              {selectedenddateError ? (
                                <span style={{ color: "red" }}>
                                  Please Select To Date
                                </span>
                              ) : (
                                ""
                              )}{" "}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 my-3 my-lg-0 my-md-0">
                            <input
                              type="date"
                              id="myDate"
                              className="form-control"
                              onChange={this.fromDateSelect}
                              value={this.state.selectedstartdate}
                            />
                          </div>
                          <div className="col-sm-6 my-3 my-lg-0 my-md-0">
                            <input
                              type="date"
                              className="form-control"
                              onChange={this.endDateSelect}
                              value={this.state.selectedenddate}
                            />
                          </div>
                        </div>
                        <br />

                        {/* car status*/}
                        <div className="row">
                          <div className="col-sm-5" />
                          <div className="col-sm-6">
                            <label
                              htmlFor="ListCarsFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Cars Status:
                              {carStatusSelectError ? (
                                <span style={{ color: "red" }}>
                                  Please Select a carStatus
                                </span>
                              ) : (
                                ""
                              )}{" "}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3" />
                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <SingleSelect
                              id="ListCarsFilter"
                              name="ListCars"
                              placeholder="Filter ListCars"
                              options={this.state.opt3}
                              handleChange={(e) => this.listcarsfilter(e)}
                              selectedService={this.state.filteredlistcars}
                            />
                          </div>
                        </div>
                        <br />

                        {/* usertype and user */}
                        <div className="row">
                          {/* <div className="col-sm-6">
                                                    <label htmlFor="UserTypeFilter" style={{ fontWeight: 500, fontSize: "medium" }} > Select UserType: </label>
                                                </div> */}
                          <div className="col-sm-6">
                            <div
                              style={{
                                position: "absolute",
                                bottom: "1px",
                                right: "100px",
                                display: "flex",
                              }}
                            >
                              <input
                                className=""
                                id={`badselect`}
                                checked={this.state.isDownline}
                                type="checkbox"
                                onChange={(e) => this.isDownline(e)}
                              />
                              <label
                                htmlFor="UserTypeFilter"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "medium",
                                  paddingLeft: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                {" "}
                                Downline{" "}
                              </label>
                            </div>

                            <label
                              htmlFor="UserTypeFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Select UserType:{" "}
                            </label>
                            <br />
                            {userTypeeError ? (
                              <span style={{ color: "red" }}>
                                Please Select a UserType
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <label
                              htmlFor="userFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Select User:
                              {userSelectError ? (
                                <span style={{ color: "red" }}>
                                  Please Select a User
                                </span>
                              ) : (
                                ""
                              )}{" "}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.BuyingWorking == "Buying" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="UserTypeFilter"
                                name="UserType"
                                placeholder="Select UserType"
                                options={
                                  this.state.isDownline
                                    ? dowenlineOption
                                    : userTypeBuyingOption
                                }
                                handleChange={(e) => this.userTypefilter(e)}
                                selectedService={this.state.userTypeOptions}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="UserTypeFilter"
                                name="UserType"
                                placeholder="Select UserType"
                                options={
                                  this.state.isDownline
                                    ? dowenlineOption
                                    : userTypeSellingOption
                                }
                                handleChange={(e) => this.userTypefilter(e)}
                                selectedService={this.state.userTypeOptions}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          )}

                          {this.state.userTypeOptions.label == "POC" &&
                          this.state.isDownline ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select POC"
                                options={this.state.userTypePocOption}
                                handleChange={(e) => this.pocfilter(e)}
                                selectedService={this.state.pocselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label == "Manager" &&
                            this.state.isDownline ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Manager"
                                options={this.state.userTypeManagerOption}
                                handleChange={(e) => this.managerfilter(e)}
                                selectedService={this.state.managerselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label ==
                              "TeamLeader" && this.state.isDownline ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select TL"
                                options={this.state.userTypeTeamleaderOption}
                                handleChange={(e) => this.tlfilter(e)}
                                selectedService={this.state.teamleaderselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label == "POC" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select POC"
                                options={this.state.userTypePocOption}
                                handleChange={(e) => this.pocfilter(e)}
                                selectedService={this.state.pocselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label == "Manager" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Manager"
                                options={this.state.userTypeManagerOption}
                                handleChange={(e) => this.managerfilter(e)}
                                selectedService={this.state.managerselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label ==
                            "TeamLeader" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select TL"
                                options={this.state.userTypeTeamleaderOption}
                                handleChange={(e) => this.tlfilter(e)}
                                selectedService={this.state.teamleaderselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label ==
                            "Evaluator" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Evaluator"
                                options={this.state.userTypeEvaluatorOption}
                                handleChange={(e) => this.evaluatorfilter(e)}
                                selectedService={this.state.evaluatorselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.label ==
                            "SalesConsultant" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Sales Consultant"
                                options={
                                  this.state.userTypeSalesConsultantOption
                                }
                                handleChange={(e) =>
                                  this.salesconsultantfilter(e)
                                }
                                selectedService={
                                  this.state.salesconsutantselect
                                }
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.value == 6 ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Incharge"
                                options={this.state.userTypeInchargeOption}
                                handleChange={(e) => this.rfInchagefilter(e)}
                                selectedService={this.state.rfinchargeselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : this.state.userTypeOptions.value == 5 ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <MultiSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select Technician"
                                options={this.state.userTypeTechnicianOption}
                                handleChange={(e) => this.rfTechnicianfilter(e)}
                                selectedService={this.state.rftechnicianselect}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="userFilter"
                                name="user"
                                placeholder="Select "
                                selectedService={this.state.Select}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          )}
                        </div>
                        <br />
                        {/* Downline  */}
                        {this.state.isDownline ? (
                          <>
                            <div className="row">
                              <div className="col-sm-5" />
                              <div className="col-sm-6">
                                <label
                                  htmlFor="ListCarsFilter"
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "medium",
                                  }}
                                >
                                  {" "}
                                  Downline User:{" "}
                                  {downlineError && this.state.isDownline ? (
                                    <span style={{ color: "red" }}>
                                      Please Select a downline
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-3" />
                              <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                                <MultiSelect
                                  id="ListCarsFilter"
                                  name="ListCars"
                                  placeholder="Downline users"
                                  options={
                                    this.state.userTypeOptions &&
                                    (pocselect ||
                                      teamleaderselect ||
                                      managerselect) &&
                                    this.state.userDownlineOption.length
                                      ? this.state.userDownlineOption
                                      : console.log()
                                  }
                                  handleChange={(e) => this.downlinefilter(e)}
                                  selectedService={
                                    this.state.userDownlineselect
                                  }
                                  disabled={disabledBuyingSellingfilter}
                                />
                              </div>
                            </div>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}

                        {/* Stage and price */}
                        <div className="row">
                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <label
                              htmlFor="stageFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Select Stage:{" "}
                              {selectStageError ? (
                                <span style={{ color: "red" }}>
                                  Please Select a Stage
                                </span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="stageFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {buyingSelectstage && buyingSelectstage.value == 5
                                ? "Cost :"
                                : "Price :"}{" "}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.BuyingWorking == "Buying" ? (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="stageFilter"
                                name="stage"
                                placeholder="Select Stage"
                                options={this.state.BuyingstageOption}
                                handleChange={(e) => this.Buyingstage(e)}
                                selectedService={this.state.buyingSelectstage}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          ) : (
                            <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                              <SingleSelect
                                id="stageFilter"
                                name="stage"
                                placeholder="Select Stage"
                                options={this.state.SellingstageOption}
                                handleChange={(e) => this.Sellingstage(e)}
                                selectedService={this.state.sellingSelectstage}
                                disabled={disabledBuyingSellingfilter}
                              />
                            </div>
                          )}

                          {/* price filter */}
                          <div className="col-sm-3 my-2 my-lg-0 my-md-0">
                            <SingleSelect
                              id="Pricefilter"
                              name="Price"
                              placeholder="from"
                              options={priceOption1}
                              handleChange={(d) => this.priceFilter(d, "from")}
                              selectedService={this.state.filteredPrice1}
                              disabled={disablefrompricefilter}
                            />
                          </div>
                          {disablepricefilter ? (
                            <></>
                          ) : (
                            <>
                              <p
                                style={{
                                  fontWeight: 500,
                                  fontSize: "medium",
                                  paddingTop: "5px",
                                }}
                              >
                                TO
                              </p>
                              <div className="col-sm-2 my-2 my-lg-0 my-md-0">
                                <SingleSelect
                                  id="Pricefilter"
                                  name="Price"
                                  placeholder="to"
                                  options={priceOption2_filter}
                                  handleChange={(d) =>
                                    this.priceFilter(d, "to")
                                  }
                                  selectedService={this.state.filteredPrice2}
                                  disabled={disablepricefilter}
                                />
                              </div>
                              <p
                                style={{
                                  fontWeight: 400,
                                  fontSize: "medium",
                                  paddingTop: "5px",
                                }}
                              >
                                Lakhs
                              </p>
                            </>
                          )}
                        </div>
                        <br />

                        {/* location and make */}
                        <div className="row">
                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <label
                              htmlFor="locationFilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Location filter:{" "}
                            </label>
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="makefilter"
                              style={{ fontWeight: 500, fontSize: "medium" }}
                            >
                              {" "}
                              Make filter:{" "}
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <MultiSelect
                              id="locationFilter"
                              name="location"
                              placeholder="Filter Location"
                              options={LocationOptions}
                              handleChange={(d) => this.locationFilter(d)}
                              selectedService={this.state.filteredLoc}
                              disabled={disabledallfilter}
                            />
                          </div>
                          <div className="col-sm-6 my-2 my-lg-0 my-md-0">
                            <MultiSelect
                              id="makefilter"
                              name="make"
                              placeholder="Filter Make"
                              options={MakeOptions}
                              handleChange={(d) => this.makeFilter(d)}
                              selectedService={this.state.filteredMake}
                              disabled={disabledallfilter}
                            />
                          </div>
                        </div>
                        <br />

                        {/* submit and clear button */}
                        <br></br>
                        <div className="row">
                          <div className="col-sm-4" />
                          {this.state.isDownline == false ? (
                            <div className="col-sm-2 my-2 my-lg-0 my-md-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                disabled={buyingSubmitBtn}
                                onClick={() => {
                                  this.Submit_Buying_Selling();
                                }}
                              >
                                {" "}
                                SUBMIT
                              </button>
                            </div>
                          ) : this.state.isDownline ? (
                            <div className="col-sm-2 my-2 my-lg-0 my-md-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  this.SubmitDownline();
                                }}
                              >
                                {" "}
                                SUBMIT
                              </button>
                            </div>
                          ) : (
                            <div className="col-sm-2 my-2 my-lg-0 my-md-0">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                              >
                                {" "}
                                SUBMIT
                              </button>
                            </div>
                          )}
                          <div className="col-sm-2 my-2 my-lg-0 my-md-0">
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary"
                              onClick={() => {
                                this.clearFilter();
                              }}
                            >
                              {" "}
                              Clear filter
                            </button>
                          </div>
                          <div>
                            {/* <button className="btn btncolor" onClick={this.toggleScanner}>QR Scanner</button>
                                            {this.state.showScanner &&
                                                <div>
                                                    <QrReader
                                                        ref={this.qrRef}
                                                          facingMode="environment"
                                                        // delay={300}
                                                        onError={this.handleError}
                                                        onScan={this.handleScan}
                                                        style={{ width: '100%', maxWidth: '500px', margin: '20px auto' }}
                                                    />
                                                </div>
                                            }
                                            {this.state.scannedData && <p>Scanned Data: {this.state.scannedData}</p>} */}
                          </div>
                          <div className="col-sm-4 my-2 my-lg-0 my-md-0"></div>
                        </div>
                      </div>
                      <br></br>
                      <div
                        className="containerchart "
                        id="Charview"
                        style={{
                          border: "1px solid",
                          display: "none",
                          padding: "50px",
                          overflow: "hidden",
                        }}
                      >
                        <div id="Charview" style={{}}>
                          <div
                            className="row SelectModelDiv"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="BackBtn">
                              <div
                                style={{
                                  backgroundColor: "#212529de",
                                  color: "white",
                                  padding: "5px 15px",
                                }}
                                onClick={() => {
                                  this.BackchartView();
                                }}
                              >
                                Back
                              </div>
                            </div>
                            <div
                              className="selectMakeModel"
                              style={{ width: "300px" }}
                            >
                              <SingleSelect
                                id="makefilter"
                                name="make"
                                placeholder="Filter Make"
                                options={this.state.makechartOption}
                                handleChange={(d) => this.chartmakeFilter(d)}
                                selectedService={this.state.chartfilteredMake}
                              />
                            </div>
                          </div>
                          <div
                            id="chartContainermake"
                            style={{
                              height: "300px",
                              width: "100%",
                              marginTop: "30px",
                            }}
                          ></div>
                          <br></br>
                          <br></br>
                          <div
                            id="chartContainermakeSelectModal"
                            style={{
                              height: "300px",
                              width: "100%",
                              display: "none",
                              marginTop: "50px",
                            }}
                          ></div>
                          <br></br>
                          <br></br>
                          <div
                            id="chartContainerlocation"
                            style={{
                              height: "400px",
                              width: "100%",
                              marginBottom: "30px",
                            }}
                          ></div>
                          <br></br>
                          <br></br>
                          {(buyingSelectstage.label ||
                            sellingSelectstage.label) == "Refurbishment" ||
                          (buyingSelectstage.label ||
                            sellingSelectstage.label) == "Inventory" ? (
                            <div
                              id="chartContainerprice"
                              style={{
                                height: "400px",
                                width: "100%",
                                marginBottom: "30px",
                              }}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <br></br>
                    </>

                    {loading ? (
                      <div className="text-center">
                        <i
                          className="fa fa-spinner fa-3x fa-spin"
                          style={{ color: "blueviolet" }}
                        ></i>
                      </div>
                    ) : params.id === makeid ? (
                      <Datatable
                        data={this.state.Data}
                        columnHeading={this.state.column1}
                      />
                    ) : this.state.Data &&
                      this.state.Data.length &&
                      dataTablShow ? (
                      <>
                        <p
                          className="col-sm-12 text-left"
                          style={{ marginBottom: "10px", fontSize: "19px" }}
                        >
                          {" "}
                          Result For{" "}
                          <span
                            style={{
                              marginLeft: "43px",
                              backgroundColor: "#dcdcdc9e",
                              padding: "5px",
                            }}
                          >
                            {" "}
                            : {this.state.submitResult}
                          </span>
                        </p>

                        {this.state.filteredLoc || this.state.filteredMake ? (
                          <></>
                        ) : (
                          <button
                            className="btn btn-primary"
                            style={{ float: "right" }}
                            onClick={() => {
                              this.chartView();
                            }}
                          >
                            ViewChart
                          </button>
                        )}
                        <Datatable
                          data={this.state.Data}
                          columnHeading={this.state.column1}
                          excelColumn={this.state.excelColumn1}
                          result={this.state.submitResult}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          style={{ textAlign: "center", fontSize: "larger" }}
                        >
                          <b>No rows to show</b>
                        </div>
                        <br></br>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default InventoryList;
